<div class="custom-container">
    <div class="registration_form_wrapper mt-5">

        <div class="row">
            <div class="col-12 col-md-9 mx-auto">
                <h4 class="font-size-18  text_light_blue fw-bold">Change your password</h4>
                <div class="hr_line my-2"></div>
                <form [formGroup]="changePasswordForm">
                    <div class=" my-4">

                        <div class="row align-items-center mb-3">
                            <div class="col-sm-4 col-12 text-start text-sm-end pe-4 custom_input">
                                <label class="form-label font-size-14">Old Password <span
                                        style="color: red">*</span></label>
                            </div>
                            <div class="col-sm-7 col-12 position-relative">
                                <div class="custom_input">
                                  <div class="input-icons">

                                    <i class="bi bi-eye icon" *ngIf="show" (click)="onClick()"></i>
                                    <i class="bi bi-eye-slash icon" *ngIf="!show" (click)="onClick()"></i>
                                    <input [type]="password" class="form-control" name="oldpassword"
                                        formControlName="oldpassword"
                                        placeholder="********">
                                        <div class="error"
                                        *ngIf="changePasswordForm.controls['oldpassword'].hasError('required') && (changePasswordForm.controls['oldpassword'].dirty || changePasswordForm.controls['oldpassword'].touched)">
                                        Password is required</div>
                                      <div class="error"
                                        *ngIf="changePasswordForm.controls['oldpassword'].hasError('pattern') && (changePasswordForm.controls['oldpassword'].dirty || changePasswordForm.controls['oldpassword'].touched)">
                                        Please enter valid Password</div></div>

                                        <div *ngIf="submitted && changePasswordForm.controls.oldpassword.errors" class="text-danger m-1">
                                            <p class="m-0 text-danger font-size-12" *ngIf="submitted && changePasswordForm.controls.oldpassword?.errors?.required">* Old Password is required
                                            </p>

                                        </div>
                                </div>

                            </div>
                        </div>


                        <div class="row align-items-center mb-3">
                            <div class="col-sm-4 col-12 text-start text-sm-end pe-4 custom_input">
                                <label class="form-label font-size-14">New Password <span
                                        style="color: red">*</span></label>
                            </div>
                            <div class="col-sm-7 col-12 position-relative">
                                <div class="custom_input">
                                  <div class="input-icons">

                                    <i class="bi bi-eye icon" *ngIf="show2" (click)="onClickNew()"></i>
                                    <i class="bi bi-eye-slash icon" *ngIf="!show2" (click)="onClickNew()"></i>
                                    <input type="password" class="form-control" name="newPassword" [type]="passwords"
                                        formControlName="newpassword"  (ngModelChange)="checkPasswordMatch()"
                                        placeholder="********"></div>
                                        <div class="error"
                                        *ngIf="changePasswordForm.controls['newpassword'].hasError('required') && (changePasswordForm.controls['newpassword'].dirty || changePasswordForm.controls['newpassword'].touched)">
                                        Password is required</div>
                                      <div class="error"
                                        *ngIf="changePasswordForm.controls['newpassword'].hasError('pattern') && (changePasswordForm.controls['newpassword'].dirty || changePasswordForm.controls['newpassword'].touched)">
                                        Please enter valid Password</div>
                                    <div *ngIf="submitted && changePasswordForm.controls.newpassword.errors" class="text-danger m-1">
                                        <p class="m-0 text-danger font-size-12" *ngIf="changePasswordForm.controls.newpassword.errors?.required">* Password is required
                                        </p>
                                        <p  *ngIf="changePasswordForm.controls.newpassword.errors?.minlength"
                                            class="m-0 text-danger font-size-12">* Your newpassword must have minimum 8
                                            characters
                                        </p>
                                        <p *ngIf="changePasswordForm.controls.newpassword.errors?.maxlength"
                                        class="m-0 text-danger font-size-12">* Your newpassword must have maximum 16
                                            characters
                                        </p>
                                        <p *ngIf="changePasswordForm.controls.newpassword.errors?.hasCapitalCase"
                                             class="m-0 text-danger font-size-12">* Your newpassword should contain at
                                            least 1 capital letter
                                        </p>
                                        <p *ngIf="changePasswordForm.controls.newpassword.errors?.hasSpecialCharacters" class="m-0 text-danger font-size-12">* Your newpassword should contain at
                                            least 1 special character
                                        </p>
                                        <p *ngIf="changePasswordForm.controls.newpassword.errors?.hasNumber" class="m-0 text-danger font-size-12">* Your newpassword should contain at
                                            least 1 number
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-sm-4 col-12 text-start text-sm-end pe-4 custom_input">
                                <label class="form-label font-size-14">Confirm Password <span
                                        style="color: red">*</span></label>
                            </div>
                            <div class="col-sm-7 col-12 position-relative">
                                <div class="custom_input">
                                  <div class="input-icons">

                                    <i class="bi bi-eye icon" *ngIf="show3" (click)="onClick3()"></i>
                                    <i class="bi bi-eye-slash icon" *ngIf="!show3" (click)="onClick3()"></i>
                                    <input type="password" class="form-control" name="confirmpassword" [type]="passwordss"
                                        formControlName="confirmpassword" (ngModelChange)="checkPasswordMatch()"
                                        placeholder="********"></div>
                                        <div class="error"
                                        *ngIf="changePasswordForm.controls['confirmpassword'].hasError('required') && (changePasswordForm.controls['confirmpassword'].dirty || changePasswordForm.controls['confirmpassword'].touched)">
                                        Password is required</div>
                                      <div class="error"
                                        *ngIf="changePasswordForm.controls['confirmpassword'].hasError('pattern') && (changePasswordForm.controls['confirmpassword'].dirty || changePasswordForm.controls['confirmpassword'].touched)">
                                        Please enter valid Password</div>
                                </div>
                                <div *ngIf="!passwordMatched">
                                    <span class="font-size-12 text-danger">New Password and Confirm Password should
                                        match</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="hr_line my-2"></div>
                <div class="row align-items-center mt-4">
                    <div class="col-sm-8 col-12  pe-4">
                        <i class="bi bi-info-circle-fill"></i> Password shall have:
                                <ul>
                                    <li>Minimum 8 and Maximum 16 characters</li>
                                    <li>Atleast one character in uppercase and lowercase
                                    </li>
                                    <li>Atleast one special character
                                    </li>
                                    <li>Atleast one numeric value
                                    </li>
                                </ul>
                    </div>
                    <div class="col-sm-4 col-12 text-end">
                        <button type="submit" class="btn  btn-dark font-size-16"


                            (click)="setPassword()">Set Password</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
