import { Component, OnInit, ViewChild } from '@angular/core';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ToastrService } from 'ngx-toastr';
import { captchaKey, InvoiceStatus, TaxPayerType, AnnualTurnover, EInvoiceEnable } from 'src/app/app.config';
import { API_STATUS } from 'src/app/constant';
import { UtilityService } from 'src/app/taxPayer/utilities/utilities.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { TaxpayerDashboardService } from 'src/app/taxPayer/taxpayer-dashboard/taxpayer-dashboard.service';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-generic-einvoice-enablement-status',
  templateUrl: './generic-einvoice-enablement-status.component.html',
  styleUrls: ['./generic-einvoice-enablement-status.component.scss']
})
export class GenericEinvoiceEnablementStatusComponent implements OnInit {
  // @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  isErrorPresent: boolean;
  errorMessage: any;
  invDetail: any;
  gstin: any;
  showResults: boolean;
  siteKey = captchaKey.INVISIBLE;
  captchSuccessfulToken: any;
  private captchaChange = new BehaviorSubject<number>(0);
  recaptcha;
  captchaVerified: boolean;
  showReason: boolean = false;
  reasonValue: string;
  showDisclaimer: boolean = false;
  setCaptch: any;
  captchString: any;
  captcha: any;
  captchaVisible1: boolean = false;

  constructor(private utilityService: UtilityService, private dashboardService: TaxpayerDashboardService, private invoiceService: EInvoiceService, private toasterService: ToastrService, private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.setCaptch = localStorage.getItem('token');
    this.generateRandomString(5);
  }
  checkCaptcha() {
    if (this.gstin) {
      this.captchaVisible1 = true;
    } else {
      this.generateRandomString(5);
      this.captchaVisible1 = false;
    }
  }
  searchEinvoice() {

    this.showReason = false;

    this.showDisclaimer = false;




    // if(this.captchaVerified == true){

    this.utilityService.getInvoiceStatus(this.gstin.toUpperCase()).subscribe(res => {

      this.showResults = true;




      if (res && res['status'] == API_STATUS.error) {

        this.isErrorPresent = true;

        this.errorMessage = res['message'];




      } else {

        this.isErrorPresent = false;

        if (res && res['response']) {

          this.invDetail = res['response'];

          if (this.invoiceService.isEmpty(this.invDetail.einvoiceEnable)) {

            if (this.invDetail.einvoiceStatus === 'Yes') {

              this.invDetail.einvoiceEnable = 'E-Invoice is ENABLED for this Taxpayer.';

            }

            else if (this.invDetail.einvoiceStatus === 'No') {

              this.invDetail.einvoiceEnable = 'E-Invoice is NOT ENABLED for this Taxpayer.';

            }

            else if (this.invoiceService.isEmpty(this.invDetail.einvoiceStatus)) {

              this.invDetail.einvoiceEnable = '';

            }

          } else {

            this.invDetail.einvoiceEnable = this.invDetail.einvoiceEnable;

          }

          this.checkEinvoiceStatus(res)

          this.trackAnalytics("Einvoicenablementstatus_Search")

        }

      }




    })

    // }else{

    //   this.toasterService.error('Please select captcha before proceeding.');




    // }





  }
  searchEinv() {
    this.showReason = false;
    this.showDisclaimer = false;
    if (this.gstin && !this.captcha) {
      this.toasterService.warning("Please Enter Captcha")
    } else {
      if (this.gstin) {
        if (this.captchString === this.captcha) {
          this.utilityService.getInvoiceStatus(this.gstin.toUpperCase()).subscribe(res => {
            this.showResults = true;

            if (this.gstin && this.showResults) {
              this.captchaVisible1 = false;
            }
            if (res && res['status'] == API_STATUS.error) {
              this.isErrorPresent = true;
              this.errorMessage = res['message'];

            } else {
              this.isErrorPresent = false;
              if (res && res['response']) {
                this.invDetail = res['response'];
                if (this.invoiceService.isEmpty(this.invDetail.einvoiceEnable)) {
                  if (this.invDetail.einvoiceStatus === 'Yes') {
                    this.invDetail.einvoiceEnable = 'E-Invoice is ENABLED for this Taxpayer.';
                  }
                  else if (this.invDetail.einvoiceStatus === 'No') {
                    this.invDetail.einvoiceEnable = 'E-Invoice is NOT ENABLED for this Taxpayer.';
                  }
                  else if (this.invoiceService.isEmpty(this.invDetail.einvoiceStatus)) {
                    this.invDetail.einvoiceEnable = '';
                  }
                } else {
                  this.invDetail.einvoiceEnable = this.invDetail.einvoiceEnable;
                }
                this.checkEinvoiceStatus(res)
                this.trackAnalytics("Pre_Einvoicenablementstatus_Search")
                this.generateRandomString(5);

              }
            }

          })
        } else {
          this.toasterService.error("Please Enter Valid Captcha")

        }
      } else {

        this.utilityService.getInvoiceStatus(this.gstin.toUpperCase()).subscribe(res => {
          this.showResults = true;
          if (res && res['status'] == API_STATUS.error) {
            this.isErrorPresent = true;
            this.errorMessage = res['message'];
          } else {
            this.isErrorPresent = false;
          }
        })

      }
    }


  }
  checkEinvoiceStatus(res) {
    if (res && res.response.einvoiceEnable === EInvoiceEnable.notEnabled) {
      this.showReason = true;
      const disallowedTypes = [TaxPayerType.Regular, TaxPayerType.TCS, TaxPayerType.SEZDeveloper];
      if (!disallowedTypes.includes(this.invDetail?.type)) {
        this.reasonValue = "Taxpayer type is not allowed/AATO is less than 10 CR";
      }
      else if (this.invDetail?.type === TaxPayerType.TCS) {
        this.showDisclaimer = true;
        this.reasonValue = "Taxpayer type is not allowed"
      }
      else if (this.invDetail?.type === TaxPayerType.Regular || this.invDetail?.type === TaxPayerType.SEZDeveloper) {
        this.reasonValue = "AATO is less than 10 CR"
      }
    }
  }
  clear() {
    this.gstin = null;
    this.captcha = null;
    this.showResults = false;
    this.generateRandomString(5);
    this.captchaVisible1 = false;

  }

  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
  getUpdatedData() {
    this.dashboardService
      .updateFromCommonPortal(this.gstin.toUpperCase())
      .subscribe((res) => {
        if (res['status'] !== API_STATUS.success) {
          //  this.isErrorPresent = true;
          // this.errorMessage = res['message'];
          this.toasterService.error(res['message']);
        } else {
          this.toasterService.success(res['message']);
          this.utilityService.getInvoiceStatus(this.gstin.toUpperCase()).subscribe(res => {
            if (res && res['status'] == API_STATUS.error) {

              this.toasterService.error(res['message']);
            } else {
              // this.isErrorPresent = false;
              if (res && res['response']) {
                this.invDetail = res['response'];
                if (this.invoiceService.isEmpty(this.invDetail.einvoiceEnable)) {
                  if (this.invDetail.einvoiceStatus === 'Yes') {
                    this.invDetail.einvoiceEnable = 'E-Invoice is ENABLED for this Taxpayer.';
                  }
                  else if (this.invDetail.einvoiceStatus === 'No') {
                    this.invDetail.einvoiceEnable = 'E-Invoice is NOT ENABLED for this Taxpayer.';
                  }
                  else if (this.invoiceService.isEmpty(this.invDetail.einvoiceStatus)) {
                    this.invDetail.einvoiceEnable = '';
                  }
                } else {
                  this.invDetail.einvoiceEnable = this.invDetail.einvoiceEnable;
                }
                this.checkEinvoiceStatus(res)
                this.trackAnalytics("Einvoicenablementstatus_Search")
              }
            }

          })
          // this.invDetail = res['response'];
          this.trackAnalytics("GSTINStatus_Search")
          // this.isErrorPresent = false;


        }
        this.trackAnalytics("Update_From_Common_Portal");
      });
    // this.utilityService.getUpdatedStatus(this.gstin).subscribe(res => {
    //   console.log(res)
    //   this.showResults = true;
    //   if (res && res['status'] == API_STATUS.error) {
    //     // this.isErrorPresent = true;
    //     this.errorMessage = res['message'];


    //   } else {
    //     // this.isErrorPresent = false;
    //     if (res && res['response']) {
    //       console.log(res);
    //       // this.gstinDetail = res['response'];
    //       this.trackAnalytics("GSTINStatus_Search")
    //     }
    //   }

    // })

  }




  generateRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    this.captchString = result;
    this.captcha = null;

    return result;
  }
}
