
import { throwError as observableThrowError, Observable, BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import {  catchError, finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { ToastrService } from 'ngx-toastr';
import { tokenErrorMessages } from 'src/app/app.config';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  identificationManagementEndPoint;
  nonBlockingAPIs;
  totalRequests = 0;
  nonBlockingRouterURL: string[];
  constructor(
    private sharedService:SharedService,

    private router: Router,
    private toastrService: ToastrService
  ) {
    this.nonBlockingAPIs = ['/portal/user/signup', '/portal/user/generate/otp',
      '/portal/user/verify/otp', '/portal/user/login', '/oauth/token','/portal/user/forgot/password'];
    this.nonBlockingRouterURL = ['/masters-code', '/einvoice-enablement-status', '/verify-qrcode', '/verify-signed-invoice', '/gstin-status', '/signup', '/terms-conditions', '/privacy'];
  }

  testMatchingNonBlockingUrls(url: string) {
    return this.nonBlockingAPIs.some(res => url.indexOf(res) > -1);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   const token = this.sharedService.getCookie('token');
    let isNonBlockingAPI = (this.nonBlockingAPIs.includes(req.url) || this.testMatchingNonBlockingUrls(req.url));
    let requestToForward = req;
  
    if (!isNonBlockingAPI) {

      if (token !== '') {
        const tokenValue = `Bearer ${token}`;
        requestToForward = req.clone({ setHeaders: { 'Authorization': tokenValue } });
      } else {
        if (!this.nonBlockingRouterURL.includes(this.router.url)) {
          this.sharedService.logout()
        }
      }
    }
    return next.handle(requestToForward).pipe(
      map(event => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401 && tokenErrorMessages.includes(error.error)) {
          this.toastrService.error(error.error);
          this.sharedService.logout()
          return throwError(error.error);
        }
        else if (error && error.error &&  tokenErrorMessages.includes(error.error.text) ) {
          this.toastrService.error(error.error.text);
          this.sharedService.logout()
          return throwError(error.error.text);
        }
        else {
          throw error
        }
      }),
      finalize(() => {
     
      })
    );
  }


}
