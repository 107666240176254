<div class="custom_container">
  <!-- post login -->
  <!-- <div class="pt-2 m-1" >
        <div class="custom_tab">
            <ul class="nav nav-tabs font-size-16" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('stateCode')" [class.active]="activeTab =='stateCode'"
                        id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="a" role="tab"
                        aria-controls="home" aria-selected="true">
                        State Codes
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('countryCode')"
                        [class.active]="activeTab =='countryCode'" id="countryCode-tab" data-bs-toggle="tab"
                        data-bs-target="#countryCode" type="a" role="tab" aria-controls="countryCode"
                        aria-selected="false">
                        Country Codes
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('portCode')" [class.active]="activeTab =='portCode'"
                        id="portCode-tab" data-bs-toggle="tab" data-bs-target="#portCode" type="a" role="tab"
                        aria-controls="portCode" aria-selected="false">
                        Port Codes
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('currencyUqcCode')"
                        [class.active]="activeTab =='currencyUqcCode'" id="currencyUqcCode-tab" data-bs-toggle="tab"
                        data-bs-target="#currencyUqcCode" type="a" role="tab" aria-controls="currencyUqcCode"
                        aria-selected="false">
                        Currency Codes
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('uqcCode')" [class.active]="activeTab =='uqcCode'"
                        id="uqcCode-tab" data-bs-toggle="tab" data-bs-target="#uqcCode" type="a" role="tab"
                        aria-controls="uqcCode" aria-selected="false">
                        UQC Codes
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('taxRate')" [class.active]="activeTab =='taxRate'"
                        id="taxRate-tab" data-bs-toggle="tab" data-bs-target="#taxRate" type="a" role="tab"
                        aria-controls="taxRate" aria-selected="false">
                        Tax Rates
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('pinCode')" [class.active]="activeTab =='pinCode'"
                        id="pinCode-tab" data-bs-toggle="tab" data-bs-target="#pinCode" type="a" role="tab"
                        aria-controls="pinCode" aria-selected="false">
                        Pincodes
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('pincodeStateMap')"
                        [class.active]="activeTab =='pincodeStateMap'" id="pincodeStateMap-tab" data-bs-toggle="tab"
                        data-bs-target="#pincodeStateMap" type="a" role="tab" aria-controls="pincodeStateMap"
                        aria-selected="false">
                        Pincode State Mapping
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" (click)="tabClicked('errorList')" [class.active]="activeTab =='errorList'"
                        id="errorList-tab" data-bs-toggle="tab" data-bs-target="#errorList" type="a" role="tab"
                        aria-controls="errorList" aria-selected="false">
                        Error List
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " [class.active]="activeTab =='stateCode'"
                    [class.show]="activeTab =='stateCode'" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <form [formGroup]="stateDataForm">
                        <div class="row">
                            <div class="col-md-2 mt-2 w12">
                                <label for="inputPassword6" class="col-form-label">Search :</label>
                            </div>
                            <div class="col-md-3 mt-2">
                                <input type="text" placeholder="Search State" (input)="getStateValue('State_Code')"
                                    formControlName="state" class="form-control" />
                            </div>
                            <div class="col-md-7 mt-1">
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="SearchStateValue('State_Code')">
                                    Search
                                </a>
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="downloadMaster('State_Code')">
                                    Download
                                </a>
                                <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                                    <!- <i class="bi bi-arrow-clockwise "></i> ->
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                                            fill="#252525" />
                                    </svg>

                                </a>

                            </div>
                        </div>
                    </form>
                    <div class="table-responsive mt-60">
                        <div *ngIf="stateData?.length !== 0">
                            <table class="data_table table table-bordered table-striped">
                                <thead class="font-size-14">
                                    <tr>
                                        <th>State Codes</th>
                                        <th>State Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody class="font-size-14">
                                    <tr *ngFor="let s of stateData">
                                        <td>{{s.scode}}</td>
                                        <td>{{s.sName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="stateData?.length === 0">
                            <div class="card-body text-center  mt-4">
                                <h4 class="mb-4 error-field">Error Details</h4>
                                <div class="col-md-22">
                                    <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                                    <p> No records found</p>
                                </div>
                            </div>
                        </div>
                        <div class="hr_line mb-3"></div>

                    </div>
                </div>
                <div class="tab-pane fade " [class.active]="activeTab =='countryCode'"
                    [class.show]="activeTab =='countryCode'" id="countryCode" role="tabpanel"
                    aria-labelledby="countryCode-tab">
                    <form [formGroup]="countryForm">
                        <div class="row">
                          <div class="col-md-2 mt-2 w12">
                            <label for="inputPassword6" class="col-form-label">Search :</label>
                        </div>
                            <div class="col-md-3 mt-2">
                                <input type="text" placeholder="Search Country"
                                    (input)="getCountryValue('Country_Code')" formControlName="countryName"
                                    class="form-control" />
                            </div>
                            <div class="col-md-7 mt-1">
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="SearchCountryValue('Country_Code')">
                                    Search
                                </a>
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="downloadCountry('Country_code')">
                                    Download
                                </a>
                                <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                                    <!- <i class="bi bi-arrow-clockwise "></i> ->
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                                            fill="#252525" />
                                    </svg>

                                </a>

                            </div>
                        </div>
                    </form>
                    <div class="table-responsive mt-60">
                        <div *ngIf="countryData?.length !== 0">
                            <table class="data_table table table-bordered table-striped">
                                <thead class="font-size-14">
                                    <tr>
                                        <th>Country Codes</th>
                                        <th>Country Name</th>
                                    </tr>
                                </thead>
                                <tbody class="font-size-14">
                                    <tr *ngFor="let c of countryData">
                                        <td>{{c.ccode}}</td>
                                        <td>{{c.cname}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="countryData?.length === 0">
                            <div class="card-body text-center  mt-4">
                                <h4 class="mb-4 error-field">Error Details</h4>
                                <div class="col-md-22">
                                    <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                                    <p> No records found</p>
                                </div>
                            </div>
                        </div>
                        <div class="hr_line mb-3"></div>

                    </div>
                </div>
                <div class="tab-pane fade " [class.active]="activeTab =='portCode'"
                    [class.show]="activeTab =='portCode'" id="portCode" role="tabpanel" aria-labelledby="portCode-tab">
                    <form [formGroup]="portForm">
                        <div class="row">
                          <div class="col-md-2 mt-2 w12">
                            <label for="inputPassword6" class="col-form-label">Search :</label>
                        </div>
                            <div class="col-md-3 mt-2">
                                <input type="text" placeholder="Search Port" (input)="getPortValue('Port_Code')"
                                    formControlName="port" class="form-control" />
                            </div>
                            <div class="col-md-7 mt-1">
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="searchPort('Port_Code')">
                                    Search
                                </a>
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="downloadPort('Port_code')">
                                    Download
                                </a>
                                <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                                    <!- <i class="bi bi-arrow-clockwise "></i> ->
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                                            fill="#252525" />
                                    </svg>

                                </a>

                            </div>
                        </div>
                    </form>
                    <div class="table-responsive mt-60">
                        <div *ngIf="portData?.length !== 0">
                            <table class="data_table table table-bordered table-striped">
                                <thead class="font-size-14">
                                    <tr>
                                        <th>Port Codes</th>
                                        <th>Port Name</th>
                                    </tr>
                                </thead>
                                <tbody class="font-size-14">
                                    <tr *ngFor="let p of portData">
                                        <td>{{p.pcode}}</td>
                                        <td>{{p.pname}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="portData?.length === 0">
                            <div class="card-body text-center  mt-4">
                                <h4 class="mb-4 error-field">Error Details</h4>
                                <div class="col-md-22">
                                    <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                                    <p> No records found</p>
                                </div>
                            </div>
                        </div>
                        <div class="hr_line mb-3"></div>

                    </div>
                </div>
                <div class="tab-pane fade " [class.active]="activeTab =='currencyUqcCode'"
                    [class.show]="activeTab =='currencyUqcCode'" id="currencyUqcCode" role="tabpanel"
                    aria-labelledby="currencyUqcCode-tab">
                    <form [formGroup]="currencyForm">
                        <div class="row">
                          <div class="col-md-2 mt-2 w12">
                            <label for="inputPassword6" class="col-form-label">Search :</label>
                        </div>
                            <div class="col-md-3 mt-2">
                                <input type="text" placeholder="Search Currency code"
                                    (input)="getCurrencyValue('Currency_Code')" formControlName="currency"
                                    class="form-control" />
                            </div>
                            <div class="col-md-7 mt-1">
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="searchCurrency('Currency_Code')">
                                    Search
                                </a>
                                <a type="a" class="btn btn_primary_theme m-1"
                                    (click)="downloadCurrency('Currency_code')">
                                    Download
                                </a>
                                <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                                    <!- <i class="bi bi-arrow-clockwise "></i> ->
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                                            fill="#252525" />
                                    </svg>

                                </a>
                            </div>
                        </div>
                    </form>
                    <div class="table-responsive mt-60">
                        <div *ngIf="currencyData?.length !== 0">
                            <table class="data_table table table-bordered table-striped">
                                <thead class="font-size-14">
                                    <tr>
                                        <th>Currency Codes</th>
                                        <th>Currency Name</th>
                                    </tr>
                                </thead>
                                <tbody class="font-size-14">

                                    <tr *ngFor="let currency of currencyData">
                                        <td>{{currency.ccode}}</td>
                                        <td>{{currency.cname}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="currencyData?.length === 0">
                            <div class="card-body text-center  mt-4">
                                <h4 class="mb-4 error-field">Error Details</h4>
                                <div class="col-md-22">
                                    <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                                    <p> No records found</p>
                                </div>
                            </div>
                        </div>
                        <div class="hr_line mb-3"></div>

                    </div>
                </div>
                <div class="tab-pane fade " [class.active]="activeTab =='uqcCode'" [class.show]="activeTab =='uqcCode'"
                id="uqcCode" role="tabpanel" aria-labelledby="uqcCode-tab">
                <form [formGroup]="uqcForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                  </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Unit" (input)="getUqcValue('Uqc_Code')" formControlName="uqcName"
                        class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchUqcValue('Uqc_Code')">
                        Search
                      </a>
                      <a type="a" class="btn btn_primary_theme m-1" (click)="downloadUqc('Uqc_code')">
                        Download
                      </a>
                      <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                        <!- <i class="bi bi-arrow-clockwise "></i> ->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>
                  
                      </a>
      
                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="uqcData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>Unit Code</th>
                          <th>Unit Description</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let uqc of uqcData">
                          <td>{{uqc.ucode}}</td>
                          <td>{{uqc.uname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="uqcData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>
      
                </div>
              </div>
              <div class="tab-pane fade " [class.active]="activeTab =='taxRate'" [class.show]="activeTab =='taxRate'"
                id="taxRate" role="tabpanel" aria-labelledby="taxRate-tab">
                <form [formGroup]="taxForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                  </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Tax Rate" (input)="getTaxValue('Tax_Rate')"
                        formControlName="taxName" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchTaxValue('Tax_Rate')">
                        Search
                      </a>
                      <a type="a" class="btn btn_primary_theme m-1" (click)="downloadTax('Tax_Rate')">
                        Download
                      </a>
                      <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                        <!- <i class="bi bi-arrow-clockwise "></i> ->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>
                  
                      </a>
      
                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="taxData?.length !== 0">
                    <table class="data_table tableheadTaxRate">
                      <thead class="font-size-14">
                        <tr>
                          <th>Tax Rate %</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let rate of taxData">
                          <td>{{rate.txrt}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="taxData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>
      
                </div>
              </div>
              <div class="tab-pane fade " [class.active]="activeTab =='pinCode'" [class.show]="activeTab =='pinCode'"
                id="pinCode" role="tabpanel" aria-labelledby="pinCode-tab">
                <form [formGroup]="pincodeForm">
                  <div class="row">
                    <div class="col-md-2 mt-2">
                      <label class="form-label font-size-14">State<span style="color: red">*</span></label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <ng-select [items]="pinState" class="stateBorder" bindLabel="name" bindValue="code"
                        (change)="getPincodeData($event)" formControlName="pincode"
                        placeholder="POS defines transaction is inter or intra state" [clearable]="false">
                      </ng-select>
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="downloadPinCode('Pin_code')">
                        Download
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="row align-items-center mt-3">
                      <div class="table-responsive mt-60">
      
                        <ng-container *ngIf="pincodeListStateWide">
                          <table class="data_table table table-bordered table-striped">
                            <thead class="font-size-14">
                              <tr>
                                <th>SN.</th>
                                <th>Pincode</th>
                              </tr>
                            </thead>
                            <tbody class="font-size-14">
                              <!- <tr *ngFor="let pin of pinState">
                          <td *ngIf="pin.code === '25'">No Records Available</td>
                        </tr> ->
                              <tr *ngFor="let pin of pinState">
                              </tr>
                              <tr *ngFor="let pinList of pincodeListStateWide">
                                <td>{{pinList.sn}}</td>
                                <td>{{pinList.pincode}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>
                        <div class="hr_line mb-3"></div>
      
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade " [class.active]="activeTab =='pincodeStateMap'"
                [class.show]="activeTab =='pincodeStateMap'" id="pincodeStateMap" role="tabpanel"
                aria-labelledby="pincodeStateMap-tab">
                <form [formGroup]="pinStateForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                  </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Pincode" (input)="getPinStateValue('PinCode_State_Mapping')"
                        formControlName="pinState" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchPinState('PinCode_State_Mapping')">
                        Search
                      </a>
                      <a type="a" class="btn btn_primary_theme m-1"
                        (click)="downloadPinStateList('PinCode_State_Mapping')">
                        Download
                      </a>
                      <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                        <!- <i class="bi bi-arrow-clockwise "></i> ->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>
                  
                      </a>
      
                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="pinStateData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>State Codes</th>
                          <th>State Name</th>
                          <th>Pincode</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let statePin of pinStateData">
                          <td>{{statePin.scode}}</td>
                          <td>{{statePin.sname}}</td>
                          <td>{{statePin.range}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="pinStateData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>
      
                </div>
              </div>
              <div class="tab-pane fade " [class.active]="activeTab =='errorList'" [class.show]="activeTab =='errorList'"
                id="errorList" role="tabpanel" aria-labelledby="errorList-tab">
                <form [formGroup]="errorForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                  </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Error" (input)="getErrorValue('Error_List')"
                        formControlName="error" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchError('Error_List')">
                        Search
                      </a>
                      <a type="a" class="btn btn_primary_theme m-1" (click)="downloadErrorList('Error_List')">
                        Download
                      </a>
                      <a class="btn btn-sm custom-icon-a noborder mlr5 m-1" (click)="clear()">
                        <!- <i class="bi bi-arrow-clockwise "></i> ->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>
                  
                      </a>
      
                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="errData?.length !== 0">
                    <table class="data_table tableSize">
                      <thead class="font-size-14">
                        <tr>
                          <th>Error Code</th>
                          <th>Error Type</th>
                          <th>Error Message</th>
                          <th>Reason for Error</th>
                          <th>Resolution for Error</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let error of errData">
                          <td>{{error.code}}</td>
                          <td>{{error.type}}</td>
                          <td>{{error.message}}</td>
                          <td class="tbody">{{error.reason}}</td>
                          <td class="tbody">{{error.rsnWithError}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="errData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>
      
                </div>
              </div>
            </div>
        </div>
    </div> -->
  <!-- pre login  -->
  <div class="row" *ngIf="setCaptch">
    <div class="col-3">
      <ul class="nav nav-pills nav-pills-vertical nav-pills-info" id="v-pills-tab" role="tablist"
        aria-orientation="vertical">
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('stateCode')" [class.active]="activeTab =='stateCode'" id="home-tab"
            data-bs-toggle="tab" data-bs-target="#home" type="a" role="tab" aria-controls="home" aria-selected="true"
            style="cursor: pointer;">
            State Codes
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('countryCode')" [class.active]="activeTab =='countryCode'"
            id="countryCode-tab" data-bs-toggle="tab" data-bs-target="#countryCode" type="a" role="tab"
            aria-controls="countryCode" aria-selected="false" style="cursor: pointer;">
            Country Codes
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('portCode')" [class.active]="activeTab =='portCode'" id="portCode-tab"
            data-bs-toggle="tab" data-bs-target="#portCode" type="a" role="tab" aria-controls="portCode"
            aria-selected="false" style="cursor: pointer;">
            Port Codes
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('currencyUqcCode')" [class.active]="activeTab =='currencyUqcCode'"
            id="currencyUqcCode-tab" data-bs-toggle="tab" data-bs-target="#currencyUqcCode" type="a" role="tab"
            aria-controls="currencyUqcCode" aria-selected="false" style="cursor: pointer;">
            Currency Codes
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('uqcCode')" [class.active]="activeTab =='uqcCode'" id="uqcCode-tab"
            data-bs-toggle="tab" data-bs-target="#uqcCode" type="a" role="tab" aria-controls="uqcCode"
            aria-selected="false" style="cursor: pointer;">
            UQC Codes
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('taxRate')" [class.active]="activeTab =='taxRate'" id="taxRate-tab"
            data-bs-toggle="tab" data-bs-target="#taxRate" type="a" role="tab" aria-controls="taxRate"
            aria-selected="false" style="cursor: pointer;">
            Tax Rates
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('pinCode')" [class.active]="activeTab =='pinCode'" id="pinCode-tab"
            data-bs-toggle="tab" data-bs-target="#pinCode" type="a" role="tab" aria-controls="pinCode"
            aria-selected="false" style="cursor: pointer;">
            Pincodes
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('pincodeStateMap')" [class.active]="activeTab =='pincodeStateMap'"
            id="pincodeStateMap-tab" data-bs-toggle="tab" data-bs-target="#pincodeStateMap" type="a" role="tab"
            aria-controls="pincodeStateMap" aria-selected="false" style="cursor: pointer;">
            Pincode State Mapping
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" (click)="tabClicked('errorList')" [class.active]="activeTab =='errorList'"
            id="errorList-tab" data-bs-toggle="tab" data-bs-target="#errorList" type="a" role="tab"
            aria-controls="errorList" aria-selected="false" style="cursor: pointer;">
            Error List
          </a>
        </li>
      </ul>
    </div>
    <div class="col-9">
      <div class="tab-content tab-content-vertical" id="v-pills-tabContent">
        <div class="tab-pane fade " [class.active]="activeTab =='stateCode'" [class.show]="activeTab =='stateCode'"
          id="home" role="tabpanel" aria-labelledby="home-tab">
          <form [formGroup]="stateDataForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search State" (input)="getStateValue('State_Code')"
                  formControlName="state" class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="SearchStateValue('State_Code')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" (click)="downloadMaster('State_Code')"
                  [disabled]="stateData?.length === 0">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>

              </div>
            </div>
          </form>
          <div>
            <div class="table-responsive mt-60" *ngIf="stateData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>State Codes</th>
                    <th>State Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let s of stateData">
                    <td>{{s.scode}}</td>
                    <td>{{s.sName}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="stateData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='countryCode'" [class.show]="activeTab =='countryCode'"
          id="countryCode" role="tabpanel" aria-labelledby="countryCode-tab">
          <form [formGroup]="countryForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search Country" (input)="getCountryValue('Country_Code')"
                  formControlName="countryName" class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="SearchCountryValue('Country_Code')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" (click)="downloadCountry('Country_code')"
                  [disabled]="countryData?.length === 0">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>

              </div>
            </div>
          </form>
          <div class="table-responsive mt-60">
            <div *ngIf="countryData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>Country Codes</th>
                    <th>Country Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let c of countryData">
                    <td>{{c.ccode}}</td>
                    <td>{{c.cname}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="countryData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='portCode'" [class.show]="activeTab =='portCode'"
          id="portCode" role="tabpanel" aria-labelledby="portCode-tab">
          <form [formGroup]="portForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search Port" (input)="getPortValue('Port_Code')" formControlName="port"
                  class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="searchPort('Port_Code')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" [disabled]="portData?.length === 0"
                  (click)="downloadPort('Port_code')">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>

              </div>
            </div>
          </form>
          <div class="table-responsive mt-60">
            <div *ngIf="portData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>Port Codes</th>
                    <th>Port Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let p of portData">
                    <td>{{p.pcode}}</td>
                    <td>{{p.pname}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="portData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='currencyUqcCode'"
          [class.show]="activeTab =='currencyUqcCode'" id="currencyUqcCode" role="tabpanel"
          aria-labelledby="currencyUqcCode-tab">
          <form [formGroup]="currencyForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search Currency code" (input)="getCurrencyValue('Currency_Code')"
                  formControlName="currency" class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="searchCurrency('Currency_Code')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" [disabled]="currencyData?.length === 0"
                  (click)="downloadCurrency('Currency_code')">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>
              </div>
            </div>
          </form>
          <div class="table-responsive mt-60">
            <div *ngIf="currencyData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>Currency Codes</th>
                    <th>Currency Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">

                  <tr *ngFor="let currency of currencyData">
                    <td>{{currency.ccode}}</td>
                    <td>{{currency.cname}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="currencyData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='uqcCode'" [class.show]="activeTab =='uqcCode'"
          id="uqcCode" role="tabpanel" aria-labelledby="uqcCode-tab">
          <form [formGroup]="uqcForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search Unit" (input)="getUqcValue('Uqc_Code')" formControlName="uqcName"
                  class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="searchUqcValue('Uqc_Code')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" (click)="downloadUqc('Uqc_code')"
                  [disabled]="uqcData?.length === 0">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>

              </div>
            </div>
          </form>
          <div class="table-responsive mt-60">
            <div *ngIf="uqcData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>Unit Code</th>
                    <th>Unit Description</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let uqc of uqcData">
                    <td>{{uqc.ucode}}</td>
                    <td>{{uqc.uname}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="uqcData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='taxRate'" [class.show]="activeTab =='taxRate'"
          id="taxRate" role="tabpanel" aria-labelledby="taxRate-tab">
          <form [formGroup]="taxForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search Tax Rate" (input)="getTaxValue('Tax_Rate')"
                  formControlName="taxName" class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="searchTaxValue('Tax_Rate')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" (click)="downloadTax('Tax_Rate')"
                  [disabled]="taxData?.length === 0">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>

              </div>
            </div>
          </form>
          <div class="table-responsive mt-60">
            <div *ngIf="taxData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>Tax Rate %</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let rate of taxData">
                    <td>{{rate.txrt}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="taxData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='pinCode'" [class.show]="activeTab =='pinCode'"
          id="pinCode" role="tabpanel" aria-labelledby="pinCode-tab">
          <form [formGroup]="pincodeForm">
            <div class="row">
              <div class="col-md-2 mt-2">
                <label class="form-label font-size-14">State<span style="color: red">*</span></label>
              </div>
              <div class="col-md-3 mt-2">
                <ng-select [items]="pinState" class="stateBorder" bindLabel="name" bindValue="code"
                  (change)="getPincodeData($event)" formControlName="pincode"
                  placeholder="POS defines transaction is inter or intra state" [clearable]="false">
                </ng-select>
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="downloadPinCode('Pin_code')">
                  Download
                </button>
              </div>
            </div>
            <div class="col-12">
              <div class="row align-items-center mt-3">
                <div class="table-responsive mt-60">

                  <ng-container *ngIf="pincodeListStateWide">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>SN.</th>
                          <th>Pincode</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <!-- <tr *ngFor="let pin of pinState">
                          <td *ngIf="pin.code === '25'">No Records Available</td>
                        </tr> -->
                        <tr *ngFor="let pin of pinState">
                        </tr>
                        <tr *ngFor="let pinList of pincodeListStateWide">
                          <td>{{pinList.sn}}</td>
                          <td>{{pinList.pincode}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='pincodeStateMap'"
          [class.show]="activeTab =='pincodeStateMap'" id="pincodeStateMap" role="tabpanel"
          aria-labelledby="pincodeStateMap-tab">
          <form [formGroup]="pinStateForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search Pincode" (input)="getPinStateValue('PinCode_State_Mapping')"
                  formControlName="pinState" class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1"
                  (click)="searchPinState('PinCode_State_Mapping')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" [disabled]="pinStateData?.length === 0"
                  (click)="downloadPinStateList('PinCode_State_Mapping')">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>

              </div>
            </div>
          </form>
          <div>
            <div class="table-responsive mt-60" *ngIf="pinStateData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>State Codes</th>
                    <th>State Name</th>
                    <th>Pincode</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let statePin of pinStateData">
                    <td>{{statePin.scode}}</td>
                    <td>{{statePin.sname}}</td>
                    <td>{{statePin.range}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="pinStateData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
        <div class="tab-pane fade " [class.active]="activeTab =='errorList'" [class.show]="activeTab =='errorList'"
          id="errorList" role="tabpanel" aria-labelledby="errorList-tab">
          <form [formGroup]="errorForm">
            <div class="row">
              <div class="col-md-2 mt-2 w12">
                <label for="inputPassword6" class="col-form-label">Search :</label>
              </div>
              <div class="col-md-3 mt-2">
                <input type="text" placeholder="Search Error" (input)="getErrorValue('Error_List')"
                  formControlName="error" class="form-control" />
              </div>
              <div class="col-md-7 mt-1">
                <button type="button" class="btn btn_primary_theme m-1" (click)="searchError('Error_List')">
                  Search
                </button>
                <button type="button" class="btn btn_primary_theme m-1" [disabled]="errData?.length === 0"
                  (click)="downloadErrorList('Error_List')">
                  Download
                </button>
                <button class="btn btn-sm custom-icon-button noborder mlr5 m-1" (click)="clear()">
                  <!-- <i class="bi bi-arrow-clockwise "></i> -->
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                      fill="#252525" />
                  </svg>

                </button>

              </div>
            </div>
          </form>
          <div class="table-responsive mt-60">
            <div *ngIf="errData?.length !== 0">
              <table class="data_table table table-bordered table-striped">
                <thead class="font-size-14">
                  <tr>
                    <th>Error Code</th>
                    <th>Error Type</th>
                    <th>Error Message</th>
                    <th>Reason for Error</th>
                    <th>Resolution for Error</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let error of errData">
                    <td>{{error.code}}</td>
                    <td>{{error.type}}</td>
                    <td>{{error.message}}</td>
                    <td class="tbody">{{error.reason}}</td>
                    <td class="tbody">{{error.rsnWithError}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="errData?.length === 0">
              <div class="card-body text-center  mt-4">
                <h4 class="mb-4 error-field">Error Details</h4>
                <div class="col-md-22">
                  <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                  <p> No records found</p>
                </div>
              </div>
            </div>
            <div class="hr_line mb-3"></div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom_container">

    <div class="row" *ngIf="!setCaptch">
      <div class="iris-dashboard-cards">
        <div class="row">
          <div class="col-3">
            <ul class="nav nav-pills nav-pills-vertical nav-pills-info" id="v-pills-tab" role="tablist"
              aria-orientation="vertical">
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('stateCode')" [class.active]="preActiveTab =='stateCode'"
                  id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="a" role="tab" aria-controls="home"
                  aria-selected="true" style="cursor: pointer;">
                  State Codes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('countryCode')" [class.active]="preActiveTab =='countryCode'"
                  id="countryCode-tab" data-bs-toggle="tab" data-bs-target="#countryCode" type="a" role="tab"
                  aria-controls="countryCode" aria-selected="false" style="cursor: pointer;">
                  Country Codes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('portCode')" [class.active]="preActiveTab =='portCode'"
                  id="portCode-tab" data-bs-toggle="tab" data-bs-target="#portCode" type="a" role="tab"
                  aria-controls="portCode" aria-selected="false" style="cursor: pointer;">
                  Port Codes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('currencyUqcCode')"
                  [class.active]="preActiveTab =='currencyUqcCode'" id="currencyUqcCode-tab" data-bs-toggle="tab"
                  data-bs-target="#currencyUqcCode" type="a" role="tab" aria-controls="currencyUqcCode"
                  aria-selected="false" style="cursor: pointer;">
                  Currency Codes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('uqcCode')" [class.active]="preActiveTab =='uqcCode'"
                  id="uqcCode-tab" data-bs-toggle="tab" data-bs-target="#uqcCode" type="a" role="tab"
                  aria-controls="uqcCode" aria-selected="false" style="cursor: pointer;">
                  UQC Codes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('taxRate')" [class.active]="preActiveTab =='taxRate'"
                  id="taxRate-tab" data-bs-toggle="tab" data-bs-target="#taxRate" type="a" role="tab"
                  aria-controls="taxRate" aria-selected="false" style="cursor: pointer;">
                  Tax Rates
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('pinCode')" [class.active]="preActiveTab =='pinCode'"
                  id="pinCode-tab" data-bs-toggle="tab" data-bs-target="#pinCode" type="a" role="tab"
                  aria-controls="pinCode" aria-selected="false" style="cursor: pointer;">
                  Pincodes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('pincodeStateMap')"
                  [class.active]="preActiveTab =='pincodeStateMap'" id="pincodeStateMap-tab" data-bs-toggle="tab"
                  data-bs-target="#pincodeStateMap" type="a" role="tab" aria-controls="pincodeStateMap"
                  aria-selected="false" style="cursor: pointer;">
                  Pincode State Mapping
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="pretabClicked('errorList')" [class.active]="preActiveTab =='errorList'"
                  id="errorList-tab" data-bs-toggle="tab" data-bs-target="#errorList" type="a" role="tab"
                  aria-controls="errorList" aria-selected="false" style="cursor: pointer;">
                  Error List
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" href="https://einvoice6.gst.gov.in/content/general-master/"
                  id="errorList-tab" type="a" role="tab"
                   aria-selected="false" style="cursor: pointer;">
                  General Master
                </a>
              </li>
            </ul>
          </div>
          <div class="col-9">
            <div class="tab-content tab-content-vertical" id="v-pills-tabContent">
              <div class="tab-pane fade " [class.active]="preActiveTab =='stateCode'"
                [class.show]="preActiveTab =='stateCode'" id="home" role="tabpanel" aria-labelledby="home-tab">
                <form [formGroup]="stateDataForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search State" (input)="getStateValue('State_Code')"
                        formControlName="state" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="SearchStateValue('State_Code')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>

                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="stateData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>State Codes</th>
                          <th>State Name</th>

                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let s of stateData">
                          <td>{{s.scode}}</td>
                          <td>{{s.sName}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="stateData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='countryCode'"
                [class.show]="preActiveTab =='countryCode'" id="countryCode" role="tabpanel"
                aria-labelledby="countryCode-tab">
                <form [formGroup]="countryForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Country" (input)="getCountryValue('Country_Code')"
                        formControlName="countryName" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="SearchCountryValue('Country_Code')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>

                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="countryData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>Country Codes</th>
                          <th>Country Name</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let c of countryData">
                          <td>{{c.ccode}}</td>
                          <td>{{c.cname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="countryData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='portCode'"
                [class.show]="preActiveTab =='portCode'" id="portCode" role="tabpanel" aria-labelledby="portCode-tab">
                <form [formGroup]="portForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Port" (input)="getPortValue('Port_Code')"
                        formControlName="port" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchPort('Port_Code')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>

                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="portData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>Port Codes</th>
                          <th>Port Name</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let p of portData">
                          <td>{{p.pcode}}</td>
                          <td>{{p.pname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="portData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='currencyUqcCode'"
                [class.show]="preActiveTab =='currencyUqcCode'" id="currencyUqcCode" role="tabpanel"
                aria-labelledby="currencyUqcCode-tab">
                <form [formGroup]="currencyForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Currency code" (input)="getCurrencyValue('Currency_Code')"
                        formControlName="currency" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchCurrency('Currency_Code')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>
                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="currencyData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>Currency Codes</th>
                          <th>Currency Name</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">

                        <tr *ngFor="let currency of currencyData">
                          <td>{{currency.ccode}}</td>
                          <td>{{currency.cname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="currencyData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='uqcCode'"
                [class.show]="preActiveTab =='uqcCode'" id="uqcCode" role="tabpanel" aria-labelledby="uqcCode-tab">
                <form [formGroup]="uqcForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Unit" (input)="getUqcValue('Uqc_Code')"
                        formControlName="uqcName" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchUqcValue('Uqc_Code')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>

                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="uqcData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>Unit Code</th>
                          <th>Unit Description</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let uqc of uqcData">
                          <td>{{uqc.ucode}}</td>
                          <td>{{uqc.uname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="uqcData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='taxRate'"
                [class.show]="preActiveTab =='taxRate'" id="taxRate" role="tabpanel" aria-labelledby="taxRate-tab">
                <form [formGroup]="taxForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Tax Rate" (input)="getTaxValue('Tax_Rate')"
                        formControlName="taxName" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchTaxValue('Tax_Rate')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>

                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="taxData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>Tax Rate %</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let rate of taxData">
                          <td>{{rate.txrt}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="taxData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='pinCode'"
                [class.show]="preActiveTab =='pinCode'" id="pinCode" role="tabpanel" aria-labelledby="pinCode-tab">
                <form [formGroup]="pincodeForm">
                  <div class="row">
                    <div class="col-md-2 mt-2">
                      <label class="form-label font-size-14">State<span style="color: red">*</span></label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <ng-select [items]="pinState" class="stateBorder" bindLabel="name" bindValue="code"
                        (change)="getPincodeData($event)" formControlName="pincode"
                        placeholder="POS defines transaction is inter or intra state" [clearable]="false">
                      </ng-select>
                    </div>
                    <div class="col-md-7 mt-1">

                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row align-items-center mt-3">
                      <div class="table-responsive mt-60">

                        <ng-container *ngIf="pincodeListStateWide">
                          <table class="data_table table table-bordered table-striped">
                            <thead class="font-size-14">
                              <tr>
                                <th>SN.</th>
                                <th>Pincode</th>
                              </tr>
                            </thead>
                            <tbody class="font-size-14">
                              <!-- <tr *ngFor="let pin of pinState">
                <td *ngIf="pin.code === '25'">No Records Available</td>
              </tr> -->
                              <tr *ngFor="let pin of pinState">
                              </tr>
                              <tr *ngFor="let pinList of pincodeListStateWide">
                                <td>{{pinList.sn}}</td>
                                <td>{{pinList.pincode}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>
                        <div class="hr_line mb-3"></div>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='pincodeStateMap'"
                [class.show]="preActiveTab =='pincodeStateMap'" id="pincodeStateMap" role="tabpanel"
                aria-labelledby="pincodeStateMap-tab">
                <form [formGroup]="pinStateForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Pincode"
                        (input)="getPinStateValue('PinCode_State_Mapping')" formControlName="pinState"
                        class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchPinState('PinCode_State_Mapping')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>

                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="pinStateData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>State Codes</th>
                          <th>State Name</th>
                          <th>Pincode</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let statePin of pinStateData">
                          <td>{{statePin.scode}}</td>
                          <td>{{statePin.sname}}</td>
                          <td>{{statePin.range}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="pinStateData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
              <div class="tab-pane fade " [class.active]="preActiveTab =='errorList'"
                [class.show]="preActiveTab =='errorList'" id="errorList" role="tabpanel"
                aria-labelledby="errorList-tab">
                <form [formGroup]="errorForm">
                  <div class="row">
                    <div class="col-md-2 mt-2 w12">
                      <label for="inputPassword6" class="col-form-label">Search :</label>
                    </div>
                    <div class="col-md-3 mt-2">
                      <input type="text" placeholder="Search Error" (input)="getErrorValue('Error_List')"
                        formControlName="error" class="form-control" />
                    </div>
                    <div class="col-md-7 mt-1">
                      <a type="a" class="btn btn_primary_theme m-1" (click)="searchError('Error_List')">
                        Search
                      </a>

                      <button class="btn btn-sm custom-icon-a noborder mlr5 m-1 generic-refresh-pre-login" (click)="clear()">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>

                    </div>
                  </div>
                </form>
                <div class="table-responsive mt-60">
                  <div *ngIf="errData?.length !== 0">
                    <table class="data_table table table-bordered table-striped">
                      <thead class="font-size-14">
                        <tr>
                          <th>Error Code</th>
                          <th>Error Type</th>
                          <th>Error Message</th>
                          <th>Reason for Error</th>
                          <th>Resolution for Error</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr *ngFor="let error of errData">
                          <td>{{error.code}}</td>
                          <td>{{error.type}}</td>
                          <td>{{error.message}}</td>
                          <td class="tbody">{{error.reason}}</td>
                          <td class="tbody">{{error.rsnWithError}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="errData?.length === 0">
                    <div class="card-body text-center  mt-4">
                      <h4 class="mb-4 error-field">Error Details</h4>
                      <div class="col-md-22">
                        <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                        <p> No records found</p>
                      </div>
                    </div>
                  </div>
                  <div class="hr_line mb-3"></div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="mb-0">Source: <a href="https://einvoice.gst.gov.in/einvoice/master-codes" target="_blank"
      title="This is an external link that opens in new tab. You will be moving out of einvoice6.gst.gov.in website">Goods
      and Services Tax (GST) | Download e-Invoice</a></p>
  <p>Last Update date : 28-03-2023</p>
</div>