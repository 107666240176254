import { Component, OnInit } from '@angular/core';
import { MastersService } from 'src/app/taxPayer/utilities/masters.service';
import { ToastrService } from 'ngx-toastr';
import { API_STATUS } from 'src/app/constant';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';



@Component({
  selector: 'app-generic-hsn-search',
  templateUrl: './generic-hsn-search.component.html',
  styleUrls: ['./generic-hsn-search.component.scss']
})
export class GenericHsnSearchComponent implements OnInit {
  hsnDescValue;
  hsnCodeValue;
  hsncodeListWide: any;
  hsnCode: any = [];
  desc: any;
  Desc: any = [];
  hsnDescError: boolean;
  isAPIError;
  apiErrorMsg: any;


  constructor(private masterServices: MastersService, private toastrService: ToastrService, private googleAnalyticsService:GoogleAnalyticsService) { }
  ngOnInit(): void {
  }

  keyPressNumbers(event) {
    var hsnLength = event.target.value.length;

    // if(hsnLength == 0 || hsnLength == 4 || hsnLength == 6 || hsnLength == 8)
    // {
    //   this.toastrService.error("2 digit, 4 digit, 6 digit and 8 digit are allowed in HSN code");
    // }
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  searchHSN(value) {
    const hsncode = this.hsnCodeValue;
    const hsnDesc = this.hsnDescValue;
    if (hsncode && hsncode.length >= 2) {
      this.hsnCode = this.masterServices.getHsnSearch(hsncode).subscribe(res => {
        if (res["status"] == API_STATUS.success) {
          this.hsncodeListWide = res["response"];
          this.isAPIError = false;
          this.trackAnalytics("HSNCode")

        }
        if (res["status"] == API_STATUS.error) {
          this.hsncodeListWide = false
          this.isAPIError = true;
          this.apiErrorMsg = res['message'];
        }


      });
    }
    else    if (hsncode && hsncode.length < 2) {
      this.toastrService.error('Minimum 2 digits are required to initiate search')

    }
    if (hsnDesc && hsnDesc.length >= 3) {
      this.hsnDescError= false;
      this.Desc = this.masterServices.getHsnSearchByDesc(hsnDesc).subscribe(res => {
        if (res["status"] == API_STATUS.success) {
          this.hsncodeListWide = res["response"];
          this.isAPIError = false;
          this.trackAnalytics("HSNDescription")
        }else{
          // this.toastrService.error(res['message']);
          this.hsncodeListWide = false
          this.isAPIError = true;
          this.apiErrorMsg = res['message'];
        }
      })
    }else if(hsnDesc && hsnDesc.length < 3){
      this.toastrService.error('Description shall not less than 3 characters. Please enter 3 or more characters')
    }
  }
  // getHsnDesc(event) {
  //   if (String(event.target.value)) {
  //     this.desc = String(event.target.value).substring(0, 8);

  //   }
  // }
  clear() {
    this.hsnCodeValue = null;
    this.hsnDescValue = null;
    this.hsncodeListWide = null;
    this.isAPIError = false;
  }

  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
}
