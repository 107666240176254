import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/shared/shared.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  userId: string;
  token = this.sharedService.getCookie('token');
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.token}`,
    'Content-Type': 'application/json',
  });
  constructor(private http: HttpClient, private sharedService: SharedService, private router: Router) {}

  /**
   * create user account
   * @param signUpDetails user details for signup
   * @returns signup response
   */
  createUserAccount(signUpDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/user/signup`, signUpDetails)
      .toPromise();
  }

  /**
   * Login user into IRP application
   * @param loginDetails user login details
   * @returns login response
   */
  login(loginDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/user/login`, loginDetails)
      .toPromise();
  }

   /**
 * logout already logged in user, if new user logs in a new tab
 * track router events in app.component.ts
 * @param event StorageEvent, only fires when localStorage is modified in context of another document
 */
   logoutCurrentUserOnTokenChange = (event: StorageEvent) => {
    if (event.key === 'token') {
      this.router.navigateByUrl('/login', { state: { token_changed: true } });
    };
  }

  /**
   * creates password for user
   * @param userPassword user password and token
   * @returns create password response
   */
  createPassword(userPassword: any) {
    return this.http
      .post(`${environment.baseUrl}/user/setPassword`, userPassword)
      .toPromise();
  }
  resetPassword(body){
    return this.http
    .post(`${environment.baseUrl}/user/reset/password`, body)
  }

  /**
   * validate the token to redirect to complete signup
   * @param token complete signup token
   * @returns token validity response
   */
  validateToken(token: string): any {
    return this.http
      .get(`${environment.baseUrl}/user/checktokenvalidity?token=${token}`)
      .toPromise();
  }

  /**
   * set password for user account
   * @param paswordDetails password and valid token
   * @returns created password response
   */
  setPassword(paswordDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/user/reset/password`, paswordDetails)
      .toPromise();
  }
 

  /**
   * Generate OTP
   * @param signUpData signup details
   * @returns otp data
   */
   genetateOTP(signUpData :any){
    return this.http
    .post(`${environment.baseUrl}/user/generate/otp`, signUpData)
    .toPromise();
    
  }

  /**
   * OTP verification
   * @param otpData signup details and OTP
   * @returns 
   */
   verifyOTP(otpData : any){
    return this.http
    .post(`${environment.baseUrl}/user/verify/otp`, otpData)
    .toPromise();
  }


  // Forgot Password Api
  forgotPassword(forgotPasswordDetails:any): any {
    return this.http
    .post(`${environment.baseUrl}/user/forgot/password`, forgotPasswordDetails)
    .toPromise();
  }
   
  //  Forgot Password  OTP Api
  forgotPasswordOtpVerify(OTPVerify:any): any{
    return this.http
    .post(`${environment.baseUrl}/user/forgot/password/otp/verify`, OTPVerify)
    .toPromise();
  }

  // Password Expired Api
  changePassword(password:any): any{
    return this.http
    .post(`${environment.baseUrl}/user/password/change`, password,{headers: this.headers})
    .toPromise();
  }
  resetPasswordAfterLogin(details){
    return this.http
    .post(`${environment.baseUrl}/user/password/change`, details,{headers: this.headers})
    
  }
  setPasswordPostSignup(body){
    return this.http
    .post(`${environment.baseUrl}/user/setPassword`, body,{headers: this.headers})
    
  }
  resetExpiredPassword(body){
    return this.http
    .post(`${environment.baseUrl}/user/expired/password/change`, body,{headers: this.headers})
  }
}