<div class="col-12 font-size-12 top-space-55">
<app-marquee></app-marquee>
</div>
<div class="custom_container mt-5">
  <div class="row">
    <div class="col-lg-8">
      <div class="card border-0 shadow p-3 w-75 mx-auto">
        <div class="card-body">
          <h2>Welcome to IRIS IRP!</h2>
          <p class="text-secondary mt-3">To get access to APIs on production environment as Direct API
            user, Intermediary or as Ecommerce Operator</p>
          <button (click)="registerGstin()" class="btn btn-dark mt-4 px-5">Get Started</button>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <app-news-videotutorial></app-news-videotutorial>
    </div>
  </div>
</div>