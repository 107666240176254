<app-auth-header></app-auth-header>
<div class="container-fluid">
    <div class="row backgroudBox">
        <div class="col-12">
            <h1 class="h1Heading">Privacy Policy</h1>
        </div>
    </div>
</div>
<div class="container">
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent"> IRIS IRP is GSTN authorised Invoice Registration Portal to help
                    taxpayers, GSPs, and solution providers generate IRN (Invoice Reference Number) under e-invoicing,
                    GST. IRIS has been appointed as the official IRP by the government of India in June 2022.</span>
            </h5>
            <h5 class="mt-3">
                <span class="h5DetailContent">
                    This website is maintained and operated by IRIS GST, a business line of IRIS Business Services
                    Limited.
                </span>
            </h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h1 class="h1Head">IRIS IRP Privacy Policy</h1>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5 class="h4Head">We at IRIS Business Services Limited (“Company”) and our Affiliates are committed to
                protecting your privacy.</h5>
            <h5><span class="h5DetailContent">This Privacy Policy applies to the information and data collected by IRIS
                    IRP when you (“User”) interact with us on our websites (https://einvoice6.gst.gov.in) or through other channels (for
                    example, by communicating with us or attending one of our events), and when you use IRIS Products,
                    IRIS Apps and Services. This Privacy Policy (“Policy”) describes how we collect, receive, use,
                    store, share, transfer, and process your Personal Data. It also describes your choices and rights
                    with respect to your Personal Data, including your rights of access and correction of your Personal
                    Data. If you do not agree with this Privacy Policy, you should not use our websites or the
                    Subscription Service.</span></h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">We update this Privacy Policy from time to time and we encourage you to
                    review this Privacy Policy periodically. We will post any Privacy Policy changes on this page and,
                    if the changes are material, we will provide more prominent notice by sending you an email
                    notification or some other communication mode as deemed fit at the time.</span></h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">This Policy is incorporated into and is subject to, the Terms of Use. Any
                    capitalized terms not defined in this policy have the meaning set forth in the Terms of Use.</span>
            </h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">If you have any questions about this Privacy Policy or our treatment of
                    your Personal Data, please write to us by mail at IRIS Business Service Limited, T-231,
                    International Infotech Park, Vashi, Navi Mumbai, Maharashtra – 400703.</span>
            </h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">1. What Information We Collect About You</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">1.1 Information you provide to IRIS
            </h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">When you interact with us via our websites or use the Subscription Service
                    or the app, we may collect Personal Data and other information from you, as further described
                    below.</span>
            </h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="h3Title">
                <h3 class="h3Content">1.1.1 When you visit our Website
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We collect Personal Data from you when you submit web
                        forms or
                        interact with our websites, try our app, sign up for a webinar, or initiate product enquiries.
                        Personal Data is any data about the User, by which he/ she/ it, as a person can be identified,
                        including without limitation, GSTIN, business name, address, email address, date of
                        registration, GSTIN, invoice details, sales and purchase data, phone number, address etc.
                        mentioned on the invoices to which IRIS IRP has or is likely to have access by virtue of the
                        User’s consensual submission. You are free to explore some of our websites without providing any
                        Personal Data about yourself. We use this information to communicate with you and in some cases
                        facilitate your registration for the product. Additionally, we may share your registration data
                        with our partners in order to improve your overall experience of IRIS IRP.</span>
                </h5>
            </div>
            <div class="h3Title mt-3">
                <h3 class="h3Content">1.1.2 Account and User Information
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We collect Personal Data when you sign-up for IRIS IRP or
                        take a paid subscription, create or modify user information, set preferences, or provide any
                        other related information to access or utilize our Subscription Service. You may further provide
                        us with details pertaining to your customers and vendors for availing of our value-added
                        services. The details including but not limited to contact details, turnover, GSTIN, or industry
                        shall be collected.</span>
                </h5>
            </div>
            <div class="h3Title mt-3">
                <h3 class="h3Content">1.1.3 Payment Information
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We collect payment and billing information when you
                        register for paid products or services. For example, we may ask you to provide a billing address
                        or a billing contact for IRIS IRP. If you give us payment information, we use it solely as
                        authorized by you in accordance with this Privacy Policy.</span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">You may also provide payment information, such as a
                        credit card number or bank account number, when purchasing products or services. We use secure
                        third-party payment service providers to manage payment processing, which is collected through a
                        secure payment process.</span>
                </h5>
            </div>
            <div class="h3Title mt-3">
                <h3 class="h3Content">1.1.4 Customer Testimonials
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We post customer testimonials and comments on our
                        websites, which may contain Personal Data. We obtain each customer’s consent via email or
                        through other agreements between customers and IRIS (or IRIS IRP) prior to posting the
                        customer’s name and testimonial.</span>
                </h5>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">1.2 Information We Collect and Process When You Use the Subscription Service
            </h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="h3Title">
                <h3 class="h3Content">1.2.1 Log Files
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">When you visit our websites or when you use the
                        Subscription Service, we automatically collect information about your computer hardware and
                        software. This information can include your IP address, browser type, domain names, internet
                        service provider (ISP), and the files viewed on our site (e.g., HTML pages, graphics, etc.),
                        operating system, clickstream data, and access times and referring website addresses.</span>
                </h5>
            </div>
            <div class="h3Title mt-3">
                <h3 class="h3Content">1.2.2 Usage Data
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We collect usage data when you or your users of IRIS IRP
                        or website interact with the Subscription Service. We engage third party providers to collect
                        usage data, and we only share data with these providers in an unidentified manner. Usage data
                        includes metrics and information regarding your use and interaction with the Subscription
                        Service such as what product features you use the most when a report is downloaded and uploaded,
                        and how often certain features (like workflows) are triggered in your account.</span>
                </h5>
            </div>
            <div class="h3Title mt-3">
                <h3 class="h3Content">1.2.3 Mobile
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">When you access or use the Subscription Service via our
                        mobile applications, we automatically collect information such as your device model and version,
                        operating system, or device identifier.</span>
                </h5>
            </div>
            <div class="h3Title mt-3">
                <h3 class="h3Content">1.2.4 Third Party Integrations
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">You may connect third-party integrations to your IRIS IRP
                        account, which may ask for certain permissions to access data or send information to your IRIS
                        IRP account. It is your responsibility to review any third-party integrations you
                        authorize.</span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">Any permission(s) granted by you, grants these third
                        parties access to your data, which may include (but is not limited to) granting third-party
                        applications access to view, store, and modify your IRIS IRP account data. We are not
                        responsible for the practices of third-party integrations, so please carefully review the
                        permissions you grant to third-party applications.</span>
                </h5>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">1.3. Information We Collect From Other Sources
            </h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="h3Title">
            <h3 class="h3Content">1.3.1 Partners
            </h3>
            <h5 class="mt-3"><span class="h5DetailContent">We may receive information about you or other users of your
                    IRIS IRP account from our network of partners. We may receive this data from our deal and/or lead
                    registration process through our partners or through our partner co-marketing agreements.</span>
            </h5>
        </div>
        <div class="h3Title">
            <h3 class="h3Content">1.3.2 Third Parties
            </h3>
            <h5 class="mt-3"><span class="h5DetailContent">We may receive information from third-party service
                    providers, related companies, and from our business and solution partners.</span>
            </h5>
        </div>
        <div class="h3Title">
            <h3 class="h3Content">1.3.3 Information Retention
            </h3>
            <h5 class="mt-3"><span class="h5DetailContent">Company will retain the information even after the user
                    closes his account with IRIS IRP for a stipulated period of time as necessary to comply with its
                    legal obligations, resolve disputes, and enforce any agreements.</span>
            </h5>
        </div>
        <div class="h3Title">
            <h3 class="h3Content">1.3.4. Usage Information
            </h3>
            <h5 class="mt-3"><span class="h5DetailContent">IRISIRP may automatically collect certain other information,
                    including but not limited to usernames, passwords, and other security and usage-related information,
                    whenever you visit or utilize the Services such as Log Data, Analytical Code, Time Stamp, Geo Stamp,
                    IP address, version and identification number of the device, browser type, browser language, the
                    date and time of your request, your profile, websites visited by you, search terms used, platform
                    type, number of clicks, phone number, requested status of other Users and various status information
                    and other personal setting information with regard to your usage of the Services provided through
                    the Website (“Usage Information”). Such Usage Information collected is not associated with any
                    Personal Data and is tagged to the unique identifier of a particular device.</span>
            </h5>
        </div>
        <div class="h3Title">
            <h3 class="h3Content">1.3.5 Information We Process on Behalf of Customers
            </h3>
            <h5 class="mt-3"><span class="h5DetailContent">Our Subscription Service allows our customers to create and
                    share GST Returns and related information. When customers use the Subscription Service, they may
                    collect Personal Data such as first and last name, email address, physical address, phone number, or
                    other information about you as their vendors. We do not control the content of our customers’
                    webpages or the types of Personal Data that our customers may choose to collect or manage using the
                    Subscription Service. We store our customers’ information on our service providers’ servers but
                    process it as a processor under our customers’ instructions and in accordance with our Customer
                    Terms of Service, which prohibit us from using the information except as necessary to provide and
                    improve the Subscription Service and as required by law.</span>
            </h5>
            <h5 class="mt-3"><span class="h5DetailContent">Our customers control and are responsible for correcting,
                    deleting or updating the information they process using the Subscription Service and for complying
                    with any regulations or laws that require providing notice, disclosure, and/or obtaining consent
                    prior to transferring the Personal Data to IRIS IRP for processing purposes.</span>
            </h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">2. How We Use Your Information</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">2.1 To Provide the Product and Services
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="h3Title">
                <h3 class="h3Content">2.1.1 To Market and Promote the Subscription Service
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We use the information you provide on IRIS IRP to market
                        and promote the products, services, and other offerings. For example, we use the information,
                        like your email or physical address, to send information or IRIS IRP content to you that we
                        think may be of interest to you by post, email, or other means and send you marketing
                        communications relating to our business. We use this information to promote the use of our
                        services to you and share promotional and information content with you in accordance with your
                        communication preferences.
                    </span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">We may also reach out to you via telephone to ask about
                        other products or services you may be interested in. If you have a call scheduled with our
                        representative, we may record and transcribe the call. You will be notified prior to the call
                        that the call is being recorded, and you will be offered an opportunity to opt-out of having the
                        call recorded.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.2 To Communicate with You About the Services
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We use the data you provide to IRIS IRP when signing up
                        for the Subscription Service to contact you via email or in-app notification about billing,
                        account management, and other administrative matters. We send updates to you regarding our
                        Customer Terms of Service or other legal agreements. We may also communicate with you about
                        security incidents via email or in-app notification.
                    </span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">We use your information to provide customer support, such
                        as resolving technical issues you encounter and analyzing product outages or bugs.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.3 To Provide and Improve the Subscription Service
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We use your account information and Customer Data to
                        provide the product and services to you. For example, we use the email address you provide when
                        signing up for the product to create your user account, and we use your payment information to
                        process payments for paid use of the Subscription Service.
                    </span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">We collect data about how our products and services are
                        used by monitoring and tracking our product users. We use this data to develop and improve our
                        products and services. For example, we use usage data to assess trends and usage across the
                        product to help us determine what new features or integrations our users may be interested in.
                        We may share usage data externally but will only do so in an anonymized and/or aggregated manner
                        to build and improve product features.
                    </span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">We use Customer Data in an anonymized manner for machine
                        learning that supports certain product features and functionality with the Subscription Service.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.4 To Secure and Protect our Product and IRIS IRP Users
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We use your account information to investigate and help
                        prevent security incidents. We may also use this information to meet legal requirements. We use
                        your information to verify user accounts and new product sign-ups, and to detect and prevent
                        product abuse.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.5 For Research and Development
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We may share externally or provide other companies with
                        statistical information about our users, but this information will not be used to identify any
                        individual user and will only be shared on an aggregate and de-identified basis. For example, we
                        may publish blog posts on trends or insights into how users are interacting with the product.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.6 Mobile
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">If you use the mobile applications, we may send you
                        push-notifications from time to time in order to update you about events and promotions. If you
                        no longer wish to receive such communications, you may turn them off at the device level.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.7 Log Files
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">Log files are used by IRIS IRP to provide general
                        statistics regarding use of the websites. For these purposes, we do link this
                        automatically-collected data to other personal data such as name, email address, address, and
                        phone number. We also use log files to troubleshoot and investigate security incidents.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.8 Third Parties
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">We receive information from third-party service
                        providers. We may receive and use this information from these service providers in combination
                        with other information we collect through other means described in this Privacy Policy for the
                        purposes of providing you with updates and an improved Subscription Service, like personalized
                        communications, event promotion, and tailoring the websites or product to improve user
                        experience.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">2.1.9 Social Media Features
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">Our websites include social media features, such as the
                        Facebook ‘like’ button and widgets, such as the share button or interactive mini-programs that
                        run on our websites. These features may collect your IP address, which page you visit on our
                        sites, and may set a cookie to enable the feature to function properly. Social media features
                        and widgets are either hosted by a third party or hosted directly on our websites. This Privacy
                        Policy does not apply to these features. Your interactions with these features are governed by
                        the privacy policy and other policies of the companies providing them.
                    </span>
                </h5>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">3. How We Share Information We Collect</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">3.1 Third Parties and Service Providers
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5 class="mt-3"><span class="h5DetailContent">We employ other third parties and service providers to
                    provide services on our behalf to our website visitors, our customers, and users of the product and
                    services. We may need to share your information with service providers to provide information about
                    products or services to you. Examples may include removing repetitive information from prospect
                    lists, analyzing data or performing statistical analysis on your use of the Subscription Service or
                    interactions on our websites, providing marketing assistance, processing credit card payments,
                    supplementing the information you provide to us in order to provide you with better service,
                    developing and improving the product and services, and providing customer service or support. These
                    service providers are prohibited from using your Personal Data except for these purposes, and they
                    are required to maintain the confidentiality of your information. In all cases where we share your
                    information with such agents, we explicitly require the agent to acknowledge and adhere to our
                    privacy and data protection policies and standards.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">3.2 Partners
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">We may share data with trusted IRIS IRP partners to contact
                    you based on your request to receive such communications, help us perform statistical analysis, or
                    provide sales support, or provide customer support.<u> Partners are prohibited from using your
                        Personal
                        Data except for these purposes, and they are required to maintain the confidentiality of your
                        data.</u></span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">We partner with trusted third parties to provide you with
                    co-marketing content that we think may be relevant to you. When you engage with these co-marketing
                    partners, we will tell you who we are sharing data with. These co-marketing partners are required to
                    adhere to our privacy and data protection policies.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">3.3 Corporate Events
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">If we (or our assets) are acquired by another company, whether by merger,
                    acquisition, bankruptcy or otherwise, that company would receive all information gathered by IRIS
                    IRP on the websites and the Subscription Service. In this event, you will be notified via email
                    and/or a prominent notice on our website, of any change in ownership, uses of your Personal Data,
                    and choices you may have regarding your Personal Data.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">3.4 Compelled Disclosure
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">We reserve the right to use or disclose your Personal Data if required by
                    law or if we reasonably believe that use or disclosure is necessary to protect our rights, protect
                    your safety or the safety of others, investigate fraud, or comply with a law, court order, or legal
                    process. If you use our Service, Customer Data is considered Confidential Information and you should
                    review the Confidentiality terms in the Customer Terms of Service for more information.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">The Company shall not rent, sell or otherwise provide Information received
                    from the Users to third parties without their consent, except as described in this Policy or as
                    required by law.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">4. How We Store and Secure Your Information</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">4.1 Data Storage and Security
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">We use a variety of security technologies and procedures to help protect
                    your Personal Data from unauthorized access, use or disclosure. We secure the Personal Data you
                    provide on computer servers in a controlled, secure environment, protected from unauthorized access,
                    use or disclosure. All Personal Data is protected using appropriate physical, technical and
                    organizational measures.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">4.2 Retention of Personal Data
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">How long we keep information we collect about you depends on the type of
                    information and how we collect and store it.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">We retain Personal Data that you provide to us where we have an ongoing
                    legitimate business need to do so (for example, as needed to comply with our legal obligations,
                    resolve disputes and enforce our agreements).
                    When we have no ongoing legitimate business need to process your Personal Data, we securely delete
                    the information or anonymize it or, if this is not possible, securely store your Personal Data and
                    isolate it from any further processing until deletion is possible.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">If you have elected to receive marketing communications from us, we retain
                    information about your marketing preferences for a reasonable period of time from the date you last
                    expressed interest in our content, products, or services, such as when you last opened an email from
                    us or ceased using your IRIS IRP account. We retain information derived from cookies and other
                    tracking technologies for a reasonable period of time from the date such information was
                    created.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">5. Cookies and Similar Technologies</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">5.1 Cookies
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">When you visit our websites, sign up for an IRIS IRP account, attend an
                    IRIS IRP virtual event, or request more information about us, we collect information automatically
                    using tracking technologies, like cookies and tracking pixels.
                    IRIS IRP and our partners use cookies or similar technologies (such as web beacons and JavaScript)
                    to analyze trends, administer the website, monitor how visitors navigate around the websites, and to
                    gather demographic information about our user base as a whole.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">5.2 Third Party Tracking Technologies
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">The use of cookies and web beacons by any tracking utility company is not
                    covered by our Privacy Policy</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">6. How to Access & Control Your Personal Data</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">6.1 Reviewing, Correcting and Removing Your Personal Data
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">You have the following data protection rights:</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="h3Title">
                <h3 class="h3Content">6.1.1 You can request access, correction, updates or deletion of your Personal
                    Data.
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">You can object to our processing of your Personal Data,
                        ask us to restrict processing of your Personal Data or request portability of your Personal
                        Data.
                    </span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">If we have collected and processed your Personal Data
                        with your consent, then you can withdraw your consent at any time. Withdrawing your consent will
                        not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it
                        affect processing of your Personal Data conducted in reliance on lawful processing grounds other
                        than consent.
                    </span>
                </h5>
                <h5 class="mt-3"><span class="h5DetailContent">If you are a customer, prospect, or otherwise interact
                        with an IRIS IRP customer that uses our Subscription Service and would like to access, correct,
                        amend or delete your data controlled by the customer, please contact the relevant customer
                        directly. IRIS IRP acts as a processor for our customers and will work with our customers to
                        fulfill these requests when applicable.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">6.1.2 To Unsubscribe From Our Communications
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">You may unsubscribe from our marketing communications by
                        clicking on the “unsubscribe” link located at the bottom of our e-mails, updating your
                        communication preferences, or by contacting us at hello@irisirp.com or via postal mail to IRIS
                        Business Services Limited, T-231, International Infotech Park, Vashi, Navi Mumbai, Maharashtra,
                        400703. Customers cannot opt-out of receiving transactional emails related to their account with
                        us or the Subscription Service.
                    </span>
                </h5>
            </div>
            <div class="h3Title">
                <h3 class="h3Content mt-3">6.1.3 To Unsubscribe From Our Customers’ Communications
                </h3>
                <h5 class="mt-3"><span class="h5DetailContent">IRIS IRP provides API Integration to system integrators
                        who in turn provide E-Invoicing services to their customers. So, these API Integrators are also
                        our customers. Such customers are solely responsible for their own marketing emails and other
                        communications; we cannot unsubscribe you from their communications. If you are a customer,
                        prospect, or otherwise interact with an IRIS IRP customer, you can unsubscribe from our
                        customers’ marketing communications by clicking on the “unsubscribe” link located at the bottom
                        of their emails, or by contacting them directly.
                    </span>
                </h5>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">7. Data Security</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">7.1 Protecting Data
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">The Company is committed to protect the security of any Information and
                    uses reasonable efforts including a variety of security technologies and procedures to help protect
                    such Information from any unauthorized access, use or disclosure. The Company will take all
                    reasonable measures in furtherance of the above commitment. However, the Company cannot guarantee
                    that the Information received from the User and other information stored on the servers will be
                    absolutely protected in all circumstances. There may be instances where IRIS IRP Products may be
                    subject to security breaches and failures due to circumstances beyond Company’s reasonable control.
                    These may include computer “hacking”, data theft or and physical theft among others. To prevent this
                    we recommend that the User take additional measures to protect his/her information, such as
                    installing anti-virus software, closing browsers after use, keeping confidential log-in credentials
                    and passwords, and making sure that the User has regularly updated Software to ensure the presence
                    of the latest security enabled features on the device.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Content">7.2 Data Disposal
            </h2>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">Any data destroyed shall be disposed off in a manner that protects the
                    privacy of the Information received by IRIS IRP Products from the User in an appropriate manner as
                    per the industry standard practices and norms.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">The User hereby acknowledges and understands that all Information provided
                    in using IRIS IRP Products is with their knowledgeable consent and at the User’s own risk.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">8. Modifications</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">Company reserves the right to change or add to privacy or security
                    conditions at any time without prior notice. Any changes in this Policy by the Company shall be
                    posted on https://einvoice6.gst.gov.in/privacy to reflect changes in its Privacy Policy so that the
                    Users are always aware of what information IRIS IRP Products collects, uses, and under what
                    circumstances, if any, products may disclose it. Since the Company reserves the right to change the
                    privacy conditions, it is necessary to regularly follow and read the terms. Subsequent use of this
                    type of modification to this site implies that changes to privacy conditions are being
                    accepted.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">9. Commercial Communication</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">IRIS IRP may send electronic communications or trade electronic
                    communications to your contact address on the site or on your behalf, which you have forwarded to or
                    registered with. It is assumed that you have given prior permission / consent to the sending of an
                    electronic message to be sent via any electronic communication whether commercial or not. You always
                    have the right to revoke your consent / rejection and refuse commercial electronic
                    communications.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">10. Link to Other Sites</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">We may provide content that may link to other sites. If you click on a
                    third-party link, you will be directed to that site. Note that these external sites may not be
                    operated by us and are subject to the Privacy Policy of these websites.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <ul>
                <div class="h3Title">

                    <li>
                        <h5><span class="h5DetailContent">Google Play Services
                            </span>
                        </h5>
                    </li>

                </div>
                <div class="h3Title">

                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">AdMob
                            </span>
                        </h5>
                    </li>
                </div>
                <div class="h3Title">

                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">Firebase Crashlytics
                            </span>
                        </h5>
                    </li>
                </div>
                <div class="h3Title">

                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">Google Analytics for Firebase
                            </span>
                        </h5>
                    </li>
                </div>
            </ul>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">Please check individual Terms and Conditions of third party service
                    providers for more details.</span>
            </h5>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2 class="h3Head">11. CONTACT US</h2>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">Any grievances or questions regarding this document should be directed to
                    the following e-mail address: [<a class="anchor">hello@irisirp.com</a>]</span>
            </h5>
        </div>

    </div>
</div>
<div style="padding: 70px 30px 10px;;background-color: #fafafa;">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h4>Our products</h4>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoicing for
                        Taxpayers
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoice APIs
                        for
                        Solution
                        Providers
                    </li>
                </ul>
                <h4>Find us here</h4>
                <i class="bi bi-twitter"></i>
                <i class="mx-2 bi bi-linkedin"></i>
            </div>
            <div class="col-md-4">
                <h4>Resources</h4>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                        margin-right: 5px;"></i>Notifications
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> Blog
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <h4>Contact us</h4>
                <ul style="list-style: none; padding-left: 0px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                        Maharashtra, India
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                        <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-4">
                <p>© 2022 IRIS Business Services Ltd. All Rights Reserved. | <a (click)="redirectToPrivacy()" href="javascript:;">Privacy Policy</a> | <a (click)="redirectToTerms()" href="javascript:;">Terms &
                    Conditions</a></p>
            </div>
        </div>
    </div>
</div>