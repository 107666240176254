import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private sharedService: SharedService, private router: Router) {}

  /**
   * Check authorization and if not authorized navigate to login page
   * @param next object of activated route
   * @param state state of snapshot of route
   * @returns Boolean (true/false) user is logged in or not
   */
  canActivate(next: ActivatedRouteSnapshot) {
    const userDetails = this.sharedService.getCookie('userDetails');
    const userRole = JSON.parse(userDetails || "{}")?.userType;
    if (!userDetails) {
      this.sharedService.deleteUserDetails();
      this.router.navigate(['/login']);
      return false;
    }
    if (userRole !== next.data.roles[0]) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}