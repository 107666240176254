import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-integrator-dashboard',
  templateUrl: './api-integrator-dashboard.component.html',
  styleUrls: ['./api-integrator-dashboard.component.scss']
})
export class ApiIntegratorDashboardComponent implements OnInit {
  openCollapsibleBar: any;

  constructor() { }

  ngOnInit(): void {
  }
  generateQuickly(){
    this.openCollapsibleBar = !this.openCollapsibleBar;
    var generateBtnElement =document.querySelector('.generate-btn') as HTMLElement;
    var generateBtnInnerElement =document.querySelector('.generate-btn-inner') as HTMLElement;
    var siedmenuwidth =generateBtnInnerElement;
    if(this.openCollapsibleBar){
      generateBtnInnerElement.style.width = '265px';
      generateBtnElement.style.right = '255px';
    }else{
      generateBtnInnerElement.style.width = '0px';
      generateBtnElement.style.right = '0px';
    }
   


  }

}
