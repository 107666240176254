import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hsn-search-prelogin',
  templateUrl: './hsn-search-prelogin.component.html',
  styleUrls: ['./hsn-search-prelogin.component.scss']
})
export class HsnSearchPreloginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
