import { Injectable } from '@angular/core';
declare var gtag;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    constructor() {
    }
    trackAnalytics(btnName) {
        gtag('event', btnName, {
            'event_category': 'button_click',
            'event_label': btnName,
            'value': 'btn has been clicked'
        })

    }

}
