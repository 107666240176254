<div class="">
  <app-auth-header></app-auth-header>


  <router-outlet></router-outlet>
</div>
<div class="container-fluid" id="increaseFont" style="background-color: #faf9fa;">
  <div class="row align-items-center banner-sidebar customVh">
    <div class="col-lg-5 text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12  px-lg-5 text-lg-start">
          <h6>IRIS IRP for an easy, efficient and enhanced E-invoicing journey</h6>
          <ul>
            <li>Manage e-invoicing for multiple GSTINs from single login</li>
            <li>Bulk IRN generation as well as cancellation</li>
            <li>Multiple modes for generation – API, Web and Desktop Utility</li>
            <li>Simple and intuitive UI designed for business teams</li>
          </ul>
          <h6>Sign-up and explore the value-added services, extending beyond e-invoicing</h6>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
        <div class="card-body">
          <form [formGroup]="signUpForm" (ngSubmit)="signUp()" *ngIf="!signUpSuccess">
            <h2 class="card-title mb-3" style="font-weight: 700 !important; color: #252525;">Sign Up</h2>
            <div class="row signupBox">
              <div class="col-12">
                <label class="mb-1"><b>User Type</b></label>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio1" checked="checked" name="role"
                    formControlName="role" value="TAXPAYER">
                  <label class="form-check-label" for="inlineRadio1">TaxPayer</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio"  id="inlineRadio2" name="role" formControlName="role"
                    value="API_INTEGRATOR">
                  <label class="form-check-label" for="inlineRadio2">API Integrator</label>
                </div>
              </div>
              <div class="col-12 small text-muted mb-3">
                <i class="bi bi-info-circle-fill me-2"></i> <span
                  *ngIf="signUpForm && signUpForm.controls.role.value == 'TAXPAYER'">Businesses who need to be
                  e-invoicing compliant.
                  All GSTINs belonging to the business need to be registered on IRIS IRP.
                </span>
                <span *ngIf="signUpForm && signUpForm.controls.role.value == 'API_INTEGRATOR'">Solution Providers or businesses who want to directly integrate e-invoice functionalities in their
                  systems.
                </span>
              </div>
              <div class="col-12 mb-3">
                <label for="userName" class="form-label"><b>Your Name</b></label>
                <input type="text" class="form-control" placeholder="Your Name" id="userName" maxlength="250"
                  formControlName="userName">

                <div class="error"
                  *ngIf="submitted && signUpForm.controls.userName?.errors && signUpForm.controls.userName?.errors?.required">
                  Username is required
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="email" class="form-label"><b>Email Id / Mobile</b></label>
                <input type="email" class="form-control" placeholder="Preferably Work Email Id/Mobile" id="email" maxlength="250"
                  formControlName="email" [title]="toolTip()">

                <div class="error"
                  *ngIf="submitted && signUpForm.controls.email?.errors && signUpForm.controls.email?.errors?.required">
                  Email id / Mobile is required
                </div>

                <div class="error"
                  *ngIf="submitted && signUpForm.controls.email?.errors && signUpForm.controls.email?.errors?.pattern">
                  Please enter valid email address or phone number
                </div>

              </div>
              <div class="col-12 mb-3">
                <label class="custom_checkbox form-check-label textAlign">By signing up, I accept the
                  <a routerLink="/terms-conditions" class="anchorLink" target="_blank">Terms of service</a>
                  and
                  <a routerLink="/privacy" class="anchorLink" target="_blank">Privacy policy</a>
                  <input type="checkbox" checked="checked" [value]="acceptTermsAndConditions"
                    (change)="acceptTermsAndConditions = !acceptTermsAndConditions" />
                  <span class="checkmark"></span>
                </label>
              </div>
             
              <div class="mb-3 row">
                
              </div>
              <div class="col-12 my-3 text-center">
                <button type="submit" class="btn btn-dark px-5 py-2">Signup</button>
              </div>
              <div class="col-12 text-center">
                <p>Already have an account? <span class="text-primary"><a routerLink="/login" class="anchorLink">Login</a></span></p>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body" *ngIf="signUpSuccess">

          <form [formGroup]="OTPForm">
            <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">OTP Verification</h2>
            <div class="row">
              <div class="col-12 mb-5">
                <b>We've sent OTP on :{{maskMobileNumber()}}</b>
              </div>
              <div class="col-12 mb-3">
                <label for="userName" class="form-label"><b>Email / Mobile OTP <sup
                  class="clrMandate">*</sup></b></label>
                  <input type="text" placeholder="OTP" class="form-control" id="otp" name="otp"  minLength="6" onKeyPress="if(this.value.length==6) return false;"  formControlName="otp" />
                <small class="text-muted">OTP will remain valid for 10 mins</small>
              </div>
              <div class="content-center">
                <button type="submit" class="btn btn-dark px-5 py-2" [disabled]="!OTPForm.controls['otp'].valid" (click)="verifyOTP()">
                 Validate OTP
                </button>
              </div>
            <div class="col-12 text-center">
                <p class="mb-0 mt-2">Didn't receive an OTP? </p>
                <a (click)="sendOTP()" class="text-decoration-none custom-text anchorLink mt-2 cursor-pointer">Resend OTP</a>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid footer">
  <div class="row footerDiv">
    <div class="col-md-9">
      <h3 class="display-4 text-white">IRIS IRP is govt authorised E-Invoice Registration Portal</h3>
    </div>
    <div class="col-md-3">
      <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank" class="btn btn-primary rounded mb-0 text-nowrap">Contact Us</a>
    </div>
    <hr class="hrdiv">
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">Important links</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.gstn.org.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://cbic-gst.gov.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">CBIC</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/notifications/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GST notifications</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.india.gov.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">National Portal</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN Authorization of IRIS IRP</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS IRP Sandbox</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">IRIS IRP Policies</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">Terms and Condition</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/privacy/" >Privacy Policy</a>
        </li>
        <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Disclaimer
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Grievance Redressal
        </li> -->
      </ul>

    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">About IRIS</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS IRP</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS GST</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisbusiness.com/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS Business</a>
        </li>

      </ul>
      <h4 class="widget-title text-white mb-3">Need Help?</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="mailto:support@irisirp.com">Write to: support@irisirp.com</a>
        </li>
        <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Feedback
        </li> -->
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">Other Products</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a href="https://irisgst.com/irissapphire/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS GST Software</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-onyx/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS E-Invoicing</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/gst-litigation-management-system/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS Litigation Management</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iristopaz/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS E-Way Bill</a>
        </li>

        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-lookup/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS LookUp</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/irisperidot/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS Peridot</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://easywaybill.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">EWB app for transporters</a>
        </li>
      </ul>
    </div>
    <hr class="hrdiv mb10">
    <div class="row">
      <div class="col-md-3">
        <div class="social social-white social-center">
          <span><a href="https://twitter.com/IRIS_IRP"  target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-twitter"></i></a></span>
          <span><a href="https://www.linkedin.com/showcase/82795027/admin/"  target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-linkedin"></i></a></span>
          <span><a href="https://www.youtube.com/@irisirp"  target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-youtube"></i></a></span>
        </div>
      </div>
      <div class="col-md-6">
        <p class="text-center text-white">© 2023 IRIS Business Services Ltd. All rights reserved.</p>
      </div>
      <div class="col-md-3 social-center">
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png" alt=""></span>

      </div>
    </div>
  </div>
</div>

