import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { encode } from 'querystring';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EInvoiceService {
  token = this.sharedService.getCookie('token');
  headersWithContentType = new HttpHeaders({
    Authorization: `Bearer ${this.token}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.token}`,
  });
  apiKeyHeader = new HttpHeaders({
    apikey: '22d3c2c3-c51e-46ff-870c-a52ed8fa1b6a',
    'Access-Control-Allow-Origin': '*',
  });
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  printEinvoice(irn: string) {
    return this.http
      .post(
        `${environment.baseUrl}/print/invoice?irn=${irn}`,
        {},
        {
          headers: this.headersWithContentType,
          responseType: 'blob' as 'blob',
        }
      )
      .pipe(
        map((res: any) => {
          let blob = new Blob([res], {
            type: 'application/pdf',
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${irn}.pdf`;
          link.click();
          window.URL.revokeObjectURL(link.href);
        })
      )
      .subscribe((result: any) => {});
  }

  getEInvoices(queryParams): any {
    const url = encode(queryParams);
    return this.http.get(`${environment.baseUrl}/invoice/search?${url}`, {
      headers: this.headers,
    });
  }
  updateRecordData(ewbData) {
    return this.http.post(`${environment.baseUrl}/update/ewb/details`,ewbData);
  }
  getEwayBillByIrn(irn: string, gstin: string) {
    return this.http
      .get(`${environment.ewbUrl}/invoice/ewaybill?gstin=${gstin}&irn=${irn}`, {
        headers: this.headers,
      });
  }
  generateEwbByIRN(ewbData,gstin) {
    return this.http.post(`${environment.ewbUrl}/gen/ewbByIrn?gstin=${gstin}`,ewbData)
  }
  getBuyerInvoices(queryParams): any {
    const url = encode(queryParams);
    return this.http.get(`${environment.baseUrl}/buyer/invoice/search?${url}`, {
      headers: this.headers,
    });
  }
  getAllEWBCount(gstin,from,to) {
    return this.http.get(`${environment.baseUrl}/dbd/gstin/invoice/totalcount?gstin=${gstin}&from=${from}&to=${to}`);
  }
  getBuyerInvoiceCount(queryParams): any {
    const url = encode(queryParams);

    return this.http.get(`${environment.baseUrl}/buyer/invoice/count?${url}`, {
      headers: this.headers,
    });
  }
  getEInvoiceCount(queryParams): any {
    const url = encode(queryParams);
    return this.http.get(`${environment.baseUrl}/invoice/count?${url}`, {
      headers: this.headers,
    });
  }

  getIrpDetails(irn: string, gstin: string): any {
    return this.http
      .get(`${environment.baseUrl}/irn/invoice?irn=${irn}&gstin=${gstin}`, {
        headers: this.headers,
      })
      .toPromise();
  }

  cancelIrn(irnDetails: any): any {
    return this.http.post(`${environment.baseUrl}/irn/cancel`, irnDetails, {
      headers: this.headers,
    });
  }

  getIrpByDocDetail(
    type: string,
    docNo: string,
    date: string,
    gstin: string
  ): any {
    return this.http
      .get(
        `${environment.baseUrl}/irn/invoice/byDocDtls?docTyp=${type}&docNo=${docNo}&docDt=${date}&gstin=${gstin}`,
        {
          headers: this.headers,
        }
      )
      .toPromise();
  }

  viewDownload(type: string, gstin: any): any {
    return this.http
      .post(
        `${environment.baseUrl}/download/view/file?downloadType=${type}`,
        gstin,
        {
          headers: this.headersWithContentType,
        }
      )
      .toPromise();
  }
  download(type, body) {
    return this.http.post(
      `${environment.baseUrl}/download/view/file?downloadType=${type}`,
      body
    );
  }
  viewDownloadBuyer(type: string, gstin: any): any {
    return this.http
      .post(
        `${environment.baseUrl}/download/buyer/view/file?downloadType=${type}`,
        gstin,
        {
          headers: this.headersWithContentType,
        }
      )
      .toPromise();
  }
  downloadBuyer(type, body) {
    return this.http.post(
      `${environment.baseUrl}/download/buyer/view/file?downloadType=${type}`,
      body
    );
  }
  viewDownloadStatus(gstin: string, type: string, id = null): any {
    return this.http.get(
      `${environment.baseUrl}/download/view/file/status?gstin=${gstin}&downloadType=${type}&id=${id}`,
      {
        headers: this.headersWithContentType,
      }
    );
  }
  viewBulkPrintStatus(id = null): any {
    return this.http.get(`${environment.baseUrl}/bulk/print/status?id=${id}`, {
      headers: this.headersWithContentType,
    });
  }
  viewSingleFile(irn: any) {
    return this.http
      .post(
        `${environment.baseUrl}/print/invoice?irn=${irn}`,
        {},
        {
          headers: this.headersWithContentType,
          responseType: 'blob' as 'blob',
        }
      )
      .pipe(
        map((res: any) => {
          let blob = new Blob([res], {
            type: 'application/pdf',
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          let tab = window.open();
          link.download = `invoice.pdf`;
          tab.location.href = link.href;
          link.click();
          window.URL.revokeObjectURL(link.href);
          // window.open(link.href, '_blank');
        })
      );
  }
  printEwbDetail(irn, ewbNo) {
    return this.http
      .post(
        `${environment.baseUrl}/print/ewb/invoice?irn=${irn}&ewbNo=${ewbNo}`,
        {},
        {
          headers: this.headersWithContentType,
          responseType: 'blob' as 'blob',
        }
      )
      .pipe(
        map((res: any) => {
          let blob = new Blob([res], {
            type: 'application/pdf',
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          let tab = window.open();
          link.download = `ewb.pdf`;
          tab.location.href = link.href;
          link.click();
          window.URL.revokeObjectURL(link.href);
          // window.open(link.href, '_blank');
        })
      );
  }
  getHistory(gstin: string, type: string) {
    return this.http.get(
      `${environment.baseUrl}/download/history?gstin=${gstin}&downloadType=${type}&partnerUser=false&page=0&cnt=1`,
      {
        headers: this.headersWithContentType,
      }
    );
  }
  getBHistory(
    gstin: string,
    type: string,
    partnerUser: any,
    page: any,
    cnt: any
  ) {
    return this.http.get(
      `${environment.baseUrl}/download/buyer/history?gstin=${gstin}&downloadType=${type}&partnerUser=${partnerUser}&page=${page}&cnt=${cnt}`
    );
  }
  getPrintDetail(gstin, irn) {
    return this.http.get(
      `${environment.baseUrl}/invoice/detail/data?gstin=${gstin}&irn=${irn}`,
      {
        headers: this.headersWithContentType,
      }
    );
  }
  getPrintDetailShared(ctin, irn) {
    return this.http.get(
      `${environment.baseUrl}/invoice/detail/data?gstin=${ctin}&irn=${irn}`,
      {
        headers: this.headersWithContentType,
      }
    );
  }
  getLogo(gstin) {
    return this.http.get(`${environment.baseUrl}/gstin/logo?gstin=${gstin}`, {
      headers: this.headersWithContentType,
    });
  }

  generateInvoice(gstin: string, invoiceData: any): any {
    return this.http.post(
      `${environment.baseUrl}/irn/invoice?gstin=${gstin}`,
      invoiceData,
      {
        headers: this.headers,
      }
    );
  }

  generateInvoiceWithEWB(gstin: string, invoiceData: any): any {
    return this.http.post(
      `${environment.ewbUrl}/irn/invoice/ewb?gstin=${gstin}`,
      invoiceData,
      {
        headers: this.headers,
      }
    );
  }
  getIRN(irn: string, gstin: string) {
    return this.http
      .get(`${environment.baseUrl}/irn/invoice?irn=${irn}&gstin=${gstin}`, {
        headers: this.headers,
      })
      .toPromise();
  }
  generateAnalyticReport(gstinDetails: any) {
    return this.http.post(
      `${environment.baseUrl}/generate/analytics/report`,
      gstinDetails
    );
  }
  getReports(gstin: string, start: string, cnt: string): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/analytics/report?gstin=${gstin}&start=${start}&cnt=${cnt}`
    );
  }
  getSalesReport(gstin, docmon) {
    let payload = {
      gstin: gstin,
      docMonth: docmon,
    };
    return this.http.post(
      `${environment.baseUrl}/generate/analytics/report`,
      payload
    );
  }
  getHistoryReport(gstin, docmon) {
    return this.http.get(
      `${environment.baseUrl}/analytics/report?gstin=${gstin}&docMonth=${docmon}&start=0&cnt=1`
    );
  }
  sendBulkPrintRequest(body) {
    return this.http.post(`${environment.baseUrl}/bulk/print/invoice`, body);
  }
  verifyQRCode(qrcode) {
    return this.http.post(`${environment.baseUrl}/verify/signedqrcode`, {
      qrcode: qrcode,
    });
  }
  verifySignedInvoice(body) {
    return this.http.post(`${environment.baseUrl}/verify/signedjson`, body);
  }
  searchIRN(irn, gstin) {
    return this.http.get(
      `${environment.baseUrl}/irn/invoice?irn=${irn}&gstin=${gstin}`
    );
  }
  buildAttachment(payload) {
    return this.http.post(
      `${environment.baseUrl}/mail/buildAttachment`,
      payload
    );
  }
  getEmailStatus(id) {
    return this.http.get(
      `${environment.baseUrl}/mail/attachment/status?id=${id}`
    );
  }
  sendMail(body) {
    return this.http.post(`${environment.baseUrl}/mail/sendMail`, body);
  }
  geterrorData(gstin, fromdt, todt) {
    return this.http.get(
      `${environment.baseUrl}/dbd/gstin/errors?gstin=${gstin}&fromdt=${fromdt}&todt=${todt}`
    );
  }

  isEmpty(data: any): boolean {
    return data === undefined || data === null || data === '';
  }
}