import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plans-pre-login',
  templateUrl: './plans-pre-login.component.html',
  styleUrls: ['./plans-pre-login.component.scss']
})
export class PlansPreLoginComponent implements OnInit {
  userType = 'BASIC';
  constructor(private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.userType =  this.route.snapshot.paramMap.get('subfeature');

  }


}
