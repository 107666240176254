<div>

    <!-- <div class="plan-btn">
        <button type="button" class="btn btn_gradient font-size-16" id="planModalOpen" (click)="planTemplate.show()">
          My Plans
        </button>
      </div> -->
    <div class="get_started_box startBox">
        <h2 class="font-size-26">
            Welcome to <span class="fw-bold">IRIS IRP!</span>
        </h2>
        <p class="text_green font-size-18">Get Started</p>
        <button type="button" class="btn btn_primary_theme iconColor font-size-16" (click)="addGSTIn()">
            <i class="bi bi-plus-lg addIcon pe-2"></i> ADD GSTINs
        </button>
    </div>
    <div style="text-align:left" class="offset-3 mt-5 col-8">
        <i class="bi bi-info-circle-fill icon-helptext font-size-16  "></i> <span style="font-weight:bold">A one time
            activity to
            get your gstin registered on IRIS IRP. Some important instructions</span>
        <ul>
            <div class="font-size-14  mt-3   ">
                <li> GSTINs enabled for einvoicing can be registered for generating einvoice. Here is the quick link to check your <a target="_blank" href="#"
                        onclick="window.open('https://irisirp.com/e-invoice-applicability-checker/');return false;">enablement
                        status.</a></li>
            </div>
            <div class="font-size-14  mt-3  ">
                <li> Keep handy the email and mobile number of primary
                    authorised signatory for the GSTIN as defined on GST portal. You can confirm the authorised
                    signatory
                    details in Profile section of GST portal.</li>
            </div>
            <div class="font-size-14  mt-3  ">
                <li>To complete registration an OTP will be sent to the
                    Authorised Signatory and will be valid for 10 min.</li>
            </div>
        </ul>

    </div>
</div>
<div bsModal #planTemplate="bs-modal" class="modal fade show" data-bs-backdrop="static" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Subscription</h5>
                <button type="button" (click)="planTemplate.hide()" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <div class="subscription-box">
                            <div class="subscription-title">
                                <h3 class="current-plan">Free</h3>
                            </div>
                            <div class="ribbon ribbon-top-right"><span class="current-plan">Current Plan</span></div>
                            <div class="subscription-content">
                                <ul class="details">
                                    <li>Generation of IRN (Upload Json, Web based form, Excel Tool, Mobile app)
                                    </li>
                                    <li>Cancellation of IRN</li>
                                    <li>Validations to check the correctness of data</li>
                                    <li>Print e-Invoice</li>
                                    <li>Creation of masters for recipient GSTINs and HSN details</li>
                                    <li>Onboarding of GSPs/ASPs/ERPs/account-billing companies/IT software
                                        companies
                                    </li>
                                    <li>E-Invoice APIs communicate and exchange the data between Taxpayers or
                                        GSP
                                        systems and the e-invoice system</li>
                                    <li>Generation of E-way Bill</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="subscription-box">
                            <div class="subscription-title">
                                <h3 class="recommended-plan">Premium</h3>
                            </div>
                            <div class="ribbon ribbon-top-right"><span class="recommended-plan">Recommended</span></div>
                            <div class="subscription-content">
                                <ul class="details">
                                    <li>Bulk Action(Cancellation, Print, Email)
                                    </li>
                                    <li>Customized Template for Print</li>
                                    <li>Print with Esign</li>
                                    <li>User configuration settings on UI</li>
                                    <li>MIS reports
                                    </li>
                                    <li>Share invoices with recipient</li>
                                    <li>Store invoices for longer period</li>
                                    <li>Alerts to buyer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #whtsnew>
    <div class="modal-content">
        <div class="modal-header" style="background-color:#252525;cursor: pointer;">
            <h4 class="" style="color: #ffffff;">Dear Taxpayer</h4>
            <span class="close" style="color: #ffffff;" (click)="modalRef?.hide()">&times;</span>
        </div>
        <div class="modal-body text-center">

            <!-- <img src="assets/images/popUpImage.png"  class="news-img" alt="Image" class="img-fluid" style="width:50%;align-items: center;"/> -->
            <img [src]="newsDataPopup.moreInfoImageLink"
                *ngIf="newsDataPopup.moreInfoImageLink && newsDataPopup.moreInfoImageLink != null" class="news-img"
                alt="Image" class="img-fluid" style="width:50%;align-items: center;" />
        </div>
        <div class="modal-body py-0">
            <b>
                <h4 class="blackColor">{{newsDataPopup.newsHeader}}</h4>
            </b>
            <div class="d-block main-content">
                <!-- <div *ngFor="let news of newsDataPopup"> -->

                <!-- <img src="../../../../assets/images/API-integrator-home-new.png" alt="Image" class="img-fluid" style="background-color: #b2fcff;width:70%;"> -->
                <div class="content-text p-4">
                    <!-- <h4 class="mb-4">{{newsDataPopup.newsHeader}}</h4> -->
                    <p class="mb-4" [innerHtml]="newsDataPopup.newsBody">{{newsDataPopup.newsBody}}</p>
                    <p> <a [href]="newsDataPopup.moreInfoExternalLink"
                            *ngIf="newsDataPopup.moreInfoExternalLink && newsDataPopup.moreInfoExternalLink != null"
                            target="_blank" class="text-decoration-none" data-dismiss="modal">From here</a></p>
                    <div class="text-center">
                        <div class="ml-auto">

                            <!-- <a (click)="modalRef?.hide()" class="btn btn_primary_theme font-size-16 px-4">Close</a> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</ng-template>