import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { API_STATUS } from 'src/app/constant';
import { LoginService } from '../login.service';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { Patterns } from 'src/app/pattern';

@Component({
  selector: 'app-change-password-post-login',
  templateUrl: './change-password-post-login.component.html',
  styleUrls: ['./change-password-post-login.component.scss']
})
export class ChangePasswordPostLoginComponent implements OnInit {
  changePasswordForm: FormGroup;
  password;
show:any;
show2:any;
passwords;
show3:any;
passwordss;
  passwordMatched = true;
  submitted: boolean;
    constructor(private formBuilder: FormBuilder, private loginService: LoginService,
    private taxPayerDashboardService: TaxPayerDashboardService,
    private toastrService: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.initForm();
    this.password = 'password';
    this.passwords = 'password';
    this.passwordss = 'password';
    this.taxPayerDashboardService.setVisibleCommonDropDown('false');
  }
  initForm() {
    this.changePasswordForm = this.formBuilder.group({
      oldpassword: [null, [Validators.required,Validators.pattern(Patterns.passwordPattern)]],
      newpassword: new FormControl('', [Validators.required,Validators.pattern(Patterns.passwordPattern)]),
      confirmpassword: [null, [Validators.required,Validators.pattern(Patterns.passwordPattern)]]
    })


  }
  /**
  * validate password pattern
  * @param regex regex to match
  * @param error error object to add to form control
  * @returns validation function to be used in form control
  */
  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
  setPassword() {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      this.checkPasswordMatch();
      if (!this.passwordMatched) return;
      this.loginService.resetPasswordAfterLogin(this.submitObject()).subscribe(res => {
        if (res['status'] === API_STATUS.error) {
          let passwordErr = '';
          if (res['fieldErrors']) {
            res['fieldErrors'].forEach(
              (err: any) => (passwordErr += err.message + '</br>')
            );
          }
          this.toastrService.error(passwordErr, res['message'], {
            enableHtml: true,
          });
          return;
        } else {
          this.toastrService.success(res['message'], '', { enableHtml: true })
          this.router.navigate(['/login']);
        }
      })
    }

  }
  submitObject() {
    return {
      oldPassword: this.changePasswordForm.get('oldpassword').value,
      newPassword: this.changePasswordForm.get('newpassword').value,
      confirmPassword: this.changePasswordForm.get('confirmpassword').value
    }
  }
  checkPasswordMatch() {
    if (this.changePasswordForm.value.newpassword && this.changePasswordForm.value.confirmpassword) {
      this.passwordMatched = this.changePasswordForm.value.newpassword === this.changePasswordForm.value.confirmpassword;
    }
    return this.passwordMatched;
  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  onClickNew() {
    if (this.passwords === 'password') {
      this.passwords = 'text';
      this.show2 = true;
    } else {
      this.passwords = 'password';
      this.show2 = false;
    }
  }
  onClick3(){
    if (this.passwordss === 'password') {
      this.passwordss = 'text';
      this.show3 = true;
    } else {
      this.passwordss = 'password';
      this.show3 = false;
    }
  }
}
