import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-greivance-status',
  templateUrl: './greivance-status.component.html',
  styleUrls: ['./greivance-status.component.scss']
})
export class GreivanceStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
