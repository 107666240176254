import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiSandboxService {

  private credentials = new BehaviorSubject<{ CLIENT_ID: string, CLIENT_SECRET: string }>({CLIENT_ID: "", CLIENT_SECRET: ""});
  credentialData = this.credentials.asObservable();
  constructor(private http: HttpClient, private sharedService: SharedService) { }

  sendSandboxCredentials(cred: { CLIENT_ID: string, CLIENT_SECRET: string }) {
    this.credentials.next(cred);
  }

  getSandboxGstin(gstin: any)
  {
    return this.http
      .get(`${environment.apiUrl}/gstin/clientId/clientsecret?gstin=${gstin}`);
  }
  generateOtp(client:any)
  {
    // console.log("API",client);
    
    return this.http
      .post(`${environment.apiUrl}/regenerate/clientsecret/generate/otp`,client);
  }
  verifyOtp(clientDetails:any)
  {
    return this.http
      .post(`${environment.apiUrl}/regenerate/clientsecret/verify/otp`,clientDetails);
  }
}
