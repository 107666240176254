<div class="custom_container">
  <div class="pt-2 m-1">
    <div class="custom_tab">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="not-generated" role="tabpanel" aria-labelledby="not-generated-tab">
          <div class="col-12 py-2">
            <div class="row g-3 align-items-end">
              <div class="col-md-4">
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Enter HSN or SAC</label>
                  <input type="text" minlength="2" maxlength="8" (keypress)="keyPressNumbers($event)"
                    [disabled]="(hsnDescValue && hsnDescValue.length >0) ? true : false" class="form-control"
                    id="exampleFormControlInput1" [(ngModel)]="hsnCodeValue"
                    placeholder="2 digit, 4 digit, 6 digit or 8 digit">
                </div>
              </div>
              <div class="col text-center mb-4">
                OR
              </div>
              <div class="col-md-4">
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput2" class="form-label">Enter Description</label>
                  <input type="text" minlength="3" class="form-control"
                    [(ngModel)]="hsnDescValue" [disabled]="(hsnCodeValue && hsnCodeValue.length > 0) ? true :false" id="exampleFormControlInput2"
                    placeholder="Min: 3 characters">
                </div>

              </div>

              <div class="col-md-3 text-end w100">
                <button class="btn btn-dark px-3 me-3 mb-3" (click)="searchHSN($event)">SEARCH</button>
                <button class="btn btn-outline-dark px-3 mb-3" (click)="clear()">CLEAR</button>
              </div>

            </div>
          </div>
          <div class="iris-dashboard-cards iris-news-updates mt15"  *ngIf="isAPIError">
            <div class="visibleGstin" id="visibleGstin">
              <h4 class="mb-4 error-field text-center">Error Details</h4>
          <ng-container >
            
            <div class="row mt-3 text-center">
              <div class="col-md-12">
                <img src="../../../../../assets/images/No-Data-found.png"  height="200"/>
                <p>{{apiErrorMsg}}</p>
                </div>
              
            </div>

          </ng-container>
        </div>
      </div>
      <div class="iris-dashboard-cards iris-news-updates mt15" *ngIf="hsncodeListWide">
        <div class="visibleGstin" id="visibleGstin">
          <h4 class="mb-4 success-field">HSN/SAC Details fetched successfully</h4>
          <ng-container *ngIf="hsncodeListWide">
            
            <div class="col-12 mt-2">
              <div class="table-responsive data_table mt-60">
              <table class="table">
                <thead class="bg-dark text-white">
                  <tr>
                    <th class="tHead width">HSN/SAC Code</th>
                    <th class="tHead">Description</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let hsnList of hsncodeListWide">
                    <td>{{hsnList.hsnCode}}</td>
                    <td>{{hsnList.description}}</td>
                  </tr>

                </tbody>
              </table>
              </div>
            </div>
          </ng-container>
          <p class="mb-0">Source: <br><a href="https://einvoice1.gst.gov.in/Others/MasterCodes" target="_blank"
              title="This is an external link that opens in new tab. You will be moving out of einvoice6.gst.gov.in website">MasterCodes - nic.einvoice.web</a></p>
          <p>Last Update date :<br> 03-03-2023</p>
          </div>
          </div>
          <!-- <div class="row align-items-center mt-3">
            <div class="col-auto">
              <label for="exampleFormControlInput1" class="form-label">Enter HSN or SAC</label>
            </div>

            <div class="col-auto">
              <input type="text" minlength="2" maxlength="8" (input)="gethsnCodeData($event)" class="form-control" />
            </div> OR
            <div class="col-auto">
              <label class="form-label font-size-14"
                >Description<span style="color: red">*</span></label
              >
            </div>
            <div class="col-auto">
              <input type="text" (input)="getHsnDesc($event)" class="form-control" />
            </div>
            <div class="col-auto">
              <button type="button" (click)="searchHSN($event)"   class="btn btn_primary_theme font-size-16 text-capitalize">
                Search
              </button>&nbsp;
              <button type="button" (click)="clear()"   class="btn btn_primary_theme font-size-16 text-capitalize">
                Clear
              </button>
            </div>
          </div>
           -->

          <!-- <div class="table-responsive mt-60">
            <table class="data_table hsn_data" style="width: 100%;">
              <thead class="font-size-14">
                <tr>
                  <th></th>
                  <th>HSN Code.</th>
                  <th></th>
                  <th></th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="font-size-14">
                <ng-container *ngIf="hsncodeListWide">
                  <tr *ngFor="let hsnList of hsncodeListWide">
                    <td></td>
                    <td>{{hsnList.hsnCode}}</td>
                    <td></td>
                    <td>{{statePin.name}}</td> 
                    <td></td>
                    <td>{{hsnList.description}}</td>
                    <td></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div> -->
        </div>
        <!-- <div
          class="tab-pane fade"
          id="generated"
          role="tabpanel"
          aria-labelledby="generated-tab"
        >
          <div class="row align-items-center mt-3">
            <div class="col-auto">
              <label class="form-label font-size-14"
                >GSTIN<span style="color: red">*</span></label
              >
            </div>
            <div class="col-auto">
              <input type="text" class="form-control" />
            </div>
            <div class="col-auto">
              <button type="button"    class="btn btn_primary_theme font-size-16 text-capitalize">
                Search
              </button>
              <button type="button" class="btn btn_gray font-size-16 m-1 ms-3">
                Clear
              </button>
            </div>
          </div>
          <div class="row align-items-end mb-4">
            <div class="col-sm-5 col-12 m-1">
              <div class="row align-items-center mt-3">
                <div
                  class="
                    col-sm-3 col-12
                    text-start text-sm-end
                    pe-3
                    mb-sm-0 mb-2
                  "
                >
                  <label class="form-label font-size-14"
                    >GSTIN<span style="color: red">*</span></label
                  >
                </div>
                <div class="col-sm-7 col-12">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-12 m-1">
              <div class="row d-flex justify-content-around">
                <div class="col-6 text-end">
                  <button
                    type="button"
                    class="btn btn_gradient font-size-16 m-1"
                  >
                    Search
                  </button>
                </div>
                <div class="col-6">
                  <button type="button" class="btn btn_gray font-size-16 m-1">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div> 
          <div class="mt-5">
            <label>GSTIN Details</label>
          </div>
          <div class="table-responsive mt-60">
            <table class="data_table gstin_data">
              <thead class="font-size-14">
                <tr>
                  <th>GSTIN</th>
                  <th>Trade Name</th>
                  <th>Legal Name</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>Pincode</th>
                  <th>Type</th>
                  <th class="text-end">Status</th>
                </tr>
              </thead>
              <tbody class="font-size-14">
                <tr>
                  <td>27AIUPD5546Q1ZD</td>
                  <td>Simply Shilp</td>
                  <td>Simply Shilp</td>
                  <td>D 404 Silicon Tower, 4th Floor, Vashi, Navi Mumbai</td>
                  <td>MAHARASHTRA</td>
                  <td>400705</td>
                  <td>Regular</td>
                  <td class="text-end">ACTIVE</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>