import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiProductionService {
  private credentials = new BehaviorSubject<{ CLIENT_ID: string, CLIENT_SECRET: string }>({CLIENT_ID: "", CLIENT_SECRET: ""});
  credentialData = this.credentials.asObservable();

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  sendCredentials(cred: { CLIENT_ID: string, CLIENT_SECRET: string }) {
    this.credentials.next(cred);
  }
  getGstin(gstin: any)
  {
    return this.http
      .get(`${environment.baseUrl}/gstin/clientId/clientsecret?gstin=${gstin}`);
  }
  verifyGstin(gstin: string): any {
    return this.http
      .get(`${environment.baseUrl}/gstin/verify?gstin=${gstin}`)
      ;
  }
  generateEncryptedPhone(value) {
    return value.replace(/\d(?=\d{4})/g, "*");
  }
  generateEncryptedEmail(value) {
    return value.replace(/^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
  }
  gstinValidate(event)
  {
    var inp = String.fromCharCode(event.keyCode);


    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  generateCredOtp(client:any)
  {
    return this.http
    .post(`${environment.baseUrl}/regenerate/clientsecret/generate/otp`,client);
  }
  verifyCredOtp(clientDetails:any)
  {
    return this.http
      .post(`${environment.baseUrl}/regenerate/clientsecret/verify/otp`,clientDetails);
  }
  generateOtp(userDetails: any) {
    return this.http
      .post(`${environment.baseUrl}/generate/prod/application/otp`, userDetails)
  }
  verifyOtp(otp) {
    return this.http
      .post(`${environment.baseUrl}/api/prod/application/verify/otp`, otp);
  }

  getProdGstin(gstin)
  {
    return this.http
      .get(`${environment.baseUrl}/api/prod/application?gstin=${gstin}`)
  }

  generateOtpForSignatory(gstinDetails){
    return this.http
    .post(`${environment.baseUrl}/api/integrated/generate/as/otp`, gstinDetails);
  }
  verifyOtpForSignatory(otp){
    return this.http
    .post(`${environment.baseUrl}/api/integrated/verify/as/otp`, otp);
  }
  updateFromCommonPortalProfile(gstin){
    return this.http.post(`${environment.baseUrl}/gstin/update/from/common/portal?gstin=${gstin}`,{})

  }
}
