import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-greivance',
  templateUrl: './greivance.component.html',
  styleUrls: ['./greivance.component.scss'],
})
export class GreivanceComponent implements OnInit {
  categoryType: any = [
    { label: 'eInvoice - Testing/ Trail/Sandbox APIs' },
    { label: 'eInvoice - Production Portal' },
    { label: 'eInvoice - Law /Procedure/ Enablement' },
  ];
  subCategoryType1: any = [
    { label: 'eInvoice Sandbox APIs : Request and Responses for all' },
    { label: 'eInvoice Sandbox APIs : Registration and Authentication ' },
    { label: 'eInvoice Sandbox APIs : Integration and development' },
    { label: 'eInvoice Trail Web: Bulk Tool/ Offline Utility' },
    { label: 'eInvoice Trail Web: Generation of IRN' },
    { label: 'eInvoice Trail Web: Other Issues / Miscellaneous' },
  ];
  subCategoryType2: any = [
    { label: 'eInvoice IRP  Production Portal  : GSTIN not active/ invalid' },
    {
      label:
        'eInvoice IRP  Production Portal : Unable to login  into portal (error)',
    },
    { label: 'eInvoice IRP  Production Portal : Generation of IRN' },
    { label: 'eInvoice IRP  Production Portal : Printing of IRN ' },
    { label: 'eInvoice IRP  Production Portal : Bulk Tool/ Offline Utility' },
  ];
  subCategoryType3: any = [{ label: 'eInvoice : Law Procedure Related Query' }];
  category: any;
  subCategoryType: any;
  states: any = [
    { code: 1, name: 'JAMMU AND KASHMIR' },
    { code: 2, name: 'HIMACHAL PRADESH' },
    { code: 3, name: 'PUNJAB' },
    { code: 4, name: 'CHANDIGARH' },
    { code: 5, name: 'UTTARAKHAND' },
    { code: 6, name: 'HARYANA' },
    { code: 7, name: 'DELHI' },
    { code: 8, name: 'RAJASTHAN' },
    { code: 9, name: 'UTTAR PRADESH' },
    { code: 10, name: 'BIHAR' },
    { code: 11, name: 'SIKKIM' },
    { code: 12, name: 'ARUNACHAL PRADESH' },
    { code: 13, name: 'NAGALAND' },
    { code: 14, name: 'MANIPUR' },
    { code: 15, name: 'MIZORAM' },
    { code: 16, name: 'TRIPURA' },
    { code: 17, name: 'MEGHALAYA' },
    { code: 18, name: 'ASSAM' },
    { code: 19, name: 'WEST BENGAL' },
    { code: 20, name: 'JHARKHAND' },
    { code: 21, name: 'ODISHA' },
    { code: 22, name: 'CHHATTISGARH' },
    { code: 23, name: 'MADHYA PRADESH' },
    { code: 24, name: 'GUJARAT' },
    { code: 25, name: 'DAMAN AND DIU' },
    { code: 26, name: 'DADRA AND NAGAR HAVELI' },
    { code: 27, name: 'MAHARASHTRA' },
    { code: 29, name: 'KARNATAKA' },
    { code: 30, name: 'GOA' },
    { code: 31, name: 'LAKSHADWEEP' },
    { code: 32, name: 'KERALA' },
    { code: 33, name: 'TAMIL NADU' },
    { code: 34, name: 'PUDUCHERRY' },
    { code: 35, name: 'ANDAMAN AND NICOBAR' },
    { code: 36, name: 'TELANGANA' },
    { code: 37, name: 'ANDHRA PRADESH' },
    { code: 38, name: 'LADAKH' },
    { code: 97, name: 'OTHER TERRITORY' },
    { code: 96, name: 'OTHER COUNTRY' },
  ];
  userType: string;

  constructor(private sharedService: SharedService, private router: Router) {}

  ngOnInit() {
    this.getUserType();
  }

  updateCategory() {
    if (this.category === 'eInvoice - Testing/ Trail/Sandbox APIs')
      this.subCategoryType = this.subCategoryType1;
    else if (this.category === 'eInvoice - Production Portal')
      this.subCategoryType = this.subCategoryType2;
    else this.subCategoryType = this.subCategoryType3;
  }

  getUserType() {
    const userDetails = JSON.parse(this.sharedService.getCookie('userDetails'));
    this.userType = userDetails.userRole;
  }

  navigateDashboard() {
    if (this.userType === 'TAXPAYER') {
      this.router.navigate(['/irp/gstin/dashboard']);
    } else if (this.userType === 'API_INTEGRATOR') {
      this.router.navigate(['/irp/api/dashboard']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
