
<app-auth-header></app-auth-header>
<div class="container-fluid">
    <div class="row backgroundBlock">
        <div class="col-12">
            <h1 class="h1Title">E-Invoice APIs for <span class="highlight-heading h1TitleSpan">Solution<br> Providers</span></h1>
            <span class="text-center d-block mx-auto h1TitleSpanContent">Invoice
                Registration APIs to make your applications e-invoice ready.</span>
        </div>
    </div>
    <div class="container">
        <div class="row mt-5">
            <div class="col-12 text-center">
                <div class="divCont">
                    <h6 class="h6Content"> API Integration</h6>
                    <h3 class="h3Content black">We believe E-Invoicing is not a<br /> compliance
                        mandate but a </h3>
                    <h3 style="text-align: center;"><span style="color: #5dab00;"
                            class="highlight-heading h3Content">technological
                            revolution</span></h3>
                </div>
            </div>
            <div class="col-12 mt-5 px-5">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <img src="../../assets/images/Cloud.png" width="150">
                            </div>
                            <div class="col">
                                <h5 style="font-size: 18px;
                            line-height: 28px;
                            color: #252525;
                            font-weight: 600;
                            letter-spacing: 0px;">With a robust network of able system integrators, e-invoice mandate
                                    can be a feather-touch to businesses.</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" style="padding-top: 50px;
                padding-right: 50px;
                padding-bottom: 50px;
                padding-left: 50px;
                align-items: center;
                background-color: #f8f9fa;
                border-radius: 15px 15px 15px 15px;">
                        <h4 style="font-size: 26px;
                        line-height: 38px;
                        font-weight: 500;
                        color: #252525;
                        letter-spacing: 0px;">IRIS IRP calls all ERP providers and system integrators to explore
                            endless possibilities we can build together.</h4>
                        <!-- <button class="btn btn-lg btn-dark px-3 mt-3">Contact Sales</button> -->
                    </div>
                </div>
            </div>
            <div class="col-12 mt-5 px-5">
                <div class="row align-items-center mt-5">
                    <div class="col-md-6">
                        <div class="image_wrapper"><img class="scale-with-grid imgClass"
                                style="-webkit-mask-size:-webkit-mask-position;"
                                src="../../assets/images/apiIntegrate_apis.png" alt="Home" title="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- <h6 style="padding: 10px 20px;
                        background-color: #f2f3fe;
                        border-radius: 13px;
                        display: inline-block;
                        color: #5dab00;"> Multiple UI</h6> -->
                        <h3 style="color: #252525;font-size: 35px;
                        line-height: 45px;
                        font-weight: 700;
                        letter-spacing: 0px;" class="mt-4"> Seamless Data Exchange between your Clients' systems and
                            IRP</h3>
                        <h5 style="color: black;font-weight: 600;font-size: 18px;">
                            <p style="color: black;font-weight: 600;font-size: 18px;">Design a seamless user journey for
                                your customers using IRIS IRP to build a robust and error-proof e-invoice compliance.
                            </p>
                        </h5>
                        <h5 style="color: black;font-weight: 600;font-size: 18px;">
                            <p style="color: black;font-weight: 600;font-size: 18px;"> Sign up and get access to
                                E-invoice sandbox.</p>
                        </h5>
                        <button type="submit" style="width: 290px;" routerLink="/signup"
                            class="btn btn_primary_theme font-size-16 mt-3">Explore the APIs now</button>
                    </div>

                </div>

            </div>
            <div class="col-12 mt-5 px-5">
                <div class="row align-items-center mt-5">
                    <div class="col-md-8">
                        <h6 style="padding: 10px 20px;
                        background-color: #f2f3fe;
                        border-radius: 13px;
                        display: inline-block;
                        color: #5dab00;"> Core E-Invoice Operations</h6>
                        <h4 style="color: #252525;font-size: 35px;
                        line-height: 45px;
                        letter-spacing: 0px;" class="mt-4"> If you are a GSP, ERP, billing software provider or even a
                            system integrator looking to simplify invoicing for your customers, you have come to the
                            right place. IRIS IRP offers Core and Enhanced APIs for e-invoice generation.</h4>
                    </div>
                    <div class="col-md-4">
                        <div class="column_attr mfn-inline-editor clearfix mobile_align_center" style="">
                            <p></p>
                            <div class="mcb-wrap-inner mcb-wrap-inner-b93604f49"><div class="column mcb-column mcb-item-894df7711 one-third tablet-one-third mobile-one column_image" style=""><div class="mcb-column-inner mcb-column-inner-894df7711 mcb-item-image-inner"><div class="image_frame element_classes image_item no_link scale-with-grid aligncenter no_border mfn-mask-shape "><div class="image_wrapper"><img class="scale-with-grid" style="-webkit-mask-size:-webkit-mask-position;" src="https://irisirp.com/wp-content/uploads/2022/09/upward-arrow.png" alt="E-Invoice APIs for Solution Providers" title=""></div></div>
                        </div></div><div class="column mcb-column mcb-item-39cbd8102 two-third tablet-two-third mobile-one column_column" style=""><div class="mcb-column-inner mcb-column-inner-39cbd8102 mcb-item-column-inner"><div class="column_attr mfn-inline-editor clearfix mobile_align_center" style=""><p></p><div class="google_font" style="font-family:'Poppins',Arial,Tahoma,sans-serif;font-size:70px;line-height:70px;font-weight:600;letter-spacing:0px;color:#252525;"><span class="counter-inline animate-math"><span style="color:#252525;" class="number" data-to="99">99</span></span>.9%</div>
                        <p></p><h4>Up-time</h4></div></div></div><div class="column mcb-column mcb-item-d286faf16 one tablet-one mobile-one column_divider" style=""><div class="mcb-column-inner mcb-column-inner-d286faf16 mcb-item-divider-inner">
                        </div></div><div class="column mcb-column mcb-item-ebb27b4d0 one-third tablet-one-third mobile-one column_image" style=""><div class="mcb-column-inner mcb-column-inner-ebb27b4d0 mcb-item-image-inner"><div class="image_frame element_classes image_item no_link scale-with-grid aligncenter no_border mfn-mask-shape "><div class="image_wrapper"><img class="scale-with-grid" style="-webkit-mask-size:-webkit-mask-position;" src="https://irisirp.com/wp-content/uploads/2022/09/three-o-clock-clock.png" alt="E-Invoice APIs for Solution Providers" title=""></div></div>
                        </div></div><div class="column mcb-column mcb-item-660a20505 two-third tablet-two-third mobile-one column_column" style=""><div class="mcb-column-inner mcb-column-inner-660a20505 mcb-item-column-inner"><div class="column_attr mfn-inline-editor clearfix mobile_align_center" style=""><p></p><div class="google_font" style="font-family:'Poppins',Arial,Tahoma,sans-serif;font-size:70px;line-height:70px;font-weight:600;letter-spacing:0px;color:#252525;"><span style="color:#252525;" class="counter-inline animate-math"><span style="color:#252525;" class="number" data-to="300">300</span></span>ms</div>
                        <p></p>
                        <h4><span class="black">Average throughput time</span></h4></div></div></div></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 mt-5 px-5">
                <div class="row align-items-center mt-5">
                    <div class="col-md-8">
                        <h6 style="padding: 10px 20px;
                        background-color: #f2f3fe;
                        border-radius: 13px;
                        display: inline-block;
                        color: #5dab00;"> Beginner</h6>
                        <h3 style="color: #252525;font-size: 35px;
                        line-height: 45px;
                        font-weight: 700;
                        letter-spacing: 0px;" class="mt-4"> Let's build together. Grow together.</h3>
                        <h5>For a successful and seamless e-invoice generation via APIs, your systems need to follow
                            authentication protocols, gracefully handle errors, and provide options to taxpayers to
                            retrieve information as and when demanded.</h5>
                    </div>
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6 mt-5 mb-5 p-3">
                        <div class="row align-items-center">
                            <div class="col-4 my-2">
                                <img src="https://irisirp.com/wp-content/uploads/2022/07/Bulk-generation-cancel-1.png">
                            </div>
                            <div class="col-8 my-2">
                                <h5><span style="color: #252525">Core APIs</span><span style="color: #5dab00;"></span>
                                </h5>
                            </div>
                            <!-- <div class="col-4 my-2">
                                <img
                                    src="https://irisirp.com/wp-content/uploads/2022/07/multiple-gstn-single-login.png">
                            </div> -->
                            <!-- <div class="col-8 my-2">
                                <h5><span style="color: #252525">Multiple GSTIN Single Login</span><span
                                        style="color: #5dab00;"></span></h5>
                            </div> -->
                            <div class="col-4 my-2">
                                <img src="https://irisirp.com/wp-content/uploads/2022/07/Role-based-user-access.png">
                            </div>
                            <div class="col-8 my-2">
                                <h5><span style="color: #252525">Full-fledged Sandbox</span><span
                                        style="color: #5dab00;"></span></h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-5 mb-5 p-3">
                        <div class=" row align-items-center">
                            <div class="col-4 my-2">
                                <img src="https://irisirp.com/wp-content/uploads/2022/07/Download-1.png">
                            </div>
                            <div class="col-8 my-2">
                                <h5><span class="black">Extensive Dashboards</span><span
                                        style="color: #5dab00;"></span></h5>
                            </div>
                           
                            <div class="col-4 my-2">
                                <img src="https://irisirp.com/wp-content/uploads/2022/07/Print-1.png">
                            </div>
                            <div class="col-8 my-2">
                                <h5><span class="black">Comprehensive API Documentation</span><span
                                        style="color: #5dab00;"></span></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <h6 style="padding: 10px 20px;
            background-color: #f2f3fe;
            border-radius: 13px;
            display: inline-block;
            color: #5dab00;"> Beginner</h6> -->
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-5 px-5" style="background: #333333; padding-bottom: 60px;">
            <div class="container">
                <div class="row align-items-center mt-5">
                    <div class="col-md-7">
                        <h6 style="padding: 10px 20px;
                        background-color: #f2f3fe;
                        border-radius: 13px;
                        display: inline-block;
                        color: #5dab00;"> Pro</h6>
                        <h3 style="color: #FFF;font-size: 35px;
                        line-height: 45px;
                        font-weight: 700;
                        letter-spacing: 0px;" class="mt-4">Enhance your system for better customer experience with IRIS
                            IRP Pro APIs</h3>
                        <p style="color: #FFF;font-size: 25px;">Integrate enhanced and value-added APIs to provide
                            innovative solutions to your customers</p>
                    </div>
                    <div class="col-md-5">

                    </div>
                    <div class="col-md-12 mt-5 mb-5 p-3">
                        <div class=" row align-items-center">
                            <div class="col-6">
                                <div class="row align-items-center">
                                    <div class="col-4 my-2">
                                        <img src="https://irisirp.com/wp-content/uploads/2022/08/Enhanced-API.png">
                                    </div>
                                    <div class="col-8 my-2">
                                        <h5 style="font-size: 26px;
line-height: 38px;
font-weight: 500;
letter-spacing: 0px;"><span style="color: #FFF;">Enhanced APIs</span>
                                            <p style="color: #FFF;font-size: 20px;">To help you design a seamless user
                                                journey in your applications</p><span style="color: #5dab00;"></span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row align-items-center">
                                    <div class="col-4 my-2">
                                        <img src="https://irisirp.com/wp-content/uploads/2022/08/Collaboration.png">
                                    </div>
                                    <div class="col-8 my-2">
                                        <h5 style="font-size: 26px;
line-height: 38px;
font-weight: 500;
letter-spacing: 0px;"><span style="color: #FFF;">Collaboration</span>
                                            <p style="color: #FFF;font-size: 20px;">Invite your team members and work
                                                collaboratively</p><span style="color: #5dab00;"></span>
                                        </h5>
                                    </div>
                                </div>
                            </div>


                            <div class="col-6">
                                <div class="row align-items-center">
                                    <div class="col-4 my-2">
                                        <img src="https://irisirp.com/wp-content/uploads/2022/08/Reports.png">
                                    </div>
                                    <div class="col-8 my-2">
                                        <h5 style="font-size: 26px;
line-height: 38px;
font-weight: 500;
letter-spacing: 0px;"><span style="color: #FFF;">Performance Reports</span>
                                            <p style="color: #FFF;font-size: 20px;">Monitor your usage and keep errors
                                                and failures under check</p><span style="color: #5dab00;"></span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row align-items-center">
                                    <div class="col-4 my-2">
                                        <img src="https://irisirp.com/wp-content/uploads/2022/08/Support.png">
                                    </div>
                                    <div class="col-8 my-2">
                                        <h5 style="font-size: 26px;
line-height: 38px;
font-weight: 500;
letter-spacing: 0px;"><span style="color: #FFF;">Extended Support</span>
                                            <p style="color: #FFF;font-size: 20px;">Get support beyond business hours
                                                and ensure uninterrupted operations</p><span
                                                style="color: #5dab00;"></span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-8 px-5">
                            <!-- <h4 style="color: #fff; font-weight: 600;font-size: 26px;
                    line-height: 38px;">Excited to start your Journey with us?</h4> -->
                        </div>
                        <div class="col-4">
                            <!-- <button class="btn btn-lg btn-light px-3 mx-2" style="font-weight: 600;">Contact
                                Sales</button> -->
                        </div>
                    </div>
                </div>
                <div class="container">
                </div>
            </div>
        </div>
    </div>
    <div style="padding: 70px 30px 10px;;background-color: #fafafa;">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h4>Our products</h4>
                    <ul style="list-style: none; padding-left: 10px;">
                        <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                            <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                            margin-right: 5px;"></i> E-Invoicing for
                            Taxpayers
                        </li>
                        <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                            <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                            margin-right: 5px;"></i> E-Invoice APIs
                            for
                            Solution
                            Providers
                        </li>
                    </ul>
                    <h4>Find us here</h4>
                    <i class="bi bi-twitter"></i>
                    <i class="mx-2 bi bi-linkedin"></i>
                </div>
                <div class="col-md-4">
                    <h4>Our products</h4>
                    <ul style="list-style: none; padding-left: 10px;">
                        <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                            <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                            margin-right: 5px;"></i>Notifications
                        </li>
                        <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                            <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                            margin-right: 5px;"></i> Blog
                        </li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <h4>Our products</h4>
                    <ul style="list-style: none; padding-left: 0px;">
                        <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                            T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                            Maharashtra, India
                        </li>
                        <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                            <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                            <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 mt-4">
                    <p>© 2022 IRIS Business Services Ltd. All Rights Reserved. | <a (click)="redirectToPrivacy()" href="javascript:;">Privacy Policy</a> | <a (click)="redirectToTerms()" href="javascript:;">Terms &
                        Conditions</a></p>
                </div>
            </div>
        </div>
    </div>
</div>