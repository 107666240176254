<div class="gradient_wrapper">
  <!--header-->
  <header>
    <div class="custom_container"></div>
    <div class="logo"><img src="assets/images/logo.png" /></div>
    <div class="greivance">
      <h5>GRIEVANCES</h5>
    </div>
  </header>
  <!--header-END-->
  <div class="container wrapper_container">
    <div class="row justify-content-between mb-5">
      <div class="col-sm-4 col-12">
        <button type="button" class="btn btn_gradient font-size-16 ms-5">
          Submit Grievance
        </button>
      </div>
      <div class="col-sm-4 col-12">
        <button type="button" class="btn btn_gradient font-size-16 ms-5">
          View Status of Grievance
        </button>
      </div>
    </div>
    <div class="row align-items-center m-3">
      <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
        <label class="form-label font-size-14"
          >Ticket Reference Number<span style="color: red">*</span></label
        >
      </div>
      <div class="col-sm-8 pe-3 mb-sm-0 mb-2"></div>
      <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
        <input type="text" class="form-control" />
      </div>
    </div>
    <div class="row align-items-center m-3">
      <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
        <div class="row justify-content-between m-3">
          <div class="col-sm-6 col-12 pe-3 mb-sm-0 mb-2">
            <button type="button" class="btn btn_gradient font-size-16 ms-5">
              Search
            </button>
          </div>
          <div class="col-sm-6 col-12 pe-3 mb-sm-0 mb-2">
            <button type="button" class="btn btn_gray font-size-16 ms-5">
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-60">
      <table class="data_table" style="white-space: nowrap;">
        <thead class="font-size-14">
          <tr>
            <th>Ticket Number</th>
            <th>Date of creation</th>
            <th>GSTIN/PAN</th>
            <th>Issue Category</th>
            <th>Resolution comments</th>
            <th class="text-end">Status</th>
          </tr>
        </thead>
        <tbody class="font-size-14">
          <tr>
            <td>G-202008241745711</td>
            <td>04/11/2021</td>
            <td>27AADCB2923M1ZL</td>
            <td>eInvoice - Testing/ Trail/Sandbox APIs</td>
            <td></td>
            <td class="text-end">Ticket Status – Resolved</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="clearfix"></div>
  <!--footer-->
  <footer class="font-size-10">
    <p>Copyright © 2020 iris e-invoicesystem.com. All rights reserved.</p>
  </footer>
  <!--footer-END-->
</div>
