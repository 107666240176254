<div class="gradient_wrapper">
  <!--header-->
  <header>
    <div class="logo"><img src="assets/images/logo.png" /></div>
    <div class="greivance"></div>
  </header>
  <!--header-END-->
  <div class="custom_container">
    <div class="wrapper_container">
      <div class="container pt-5">
        <div class="row">
          <div class="col-12">
            <button
              type="button"
              class="btn btn_gray font-size-15"
              (click)="navigateDashboard()"
            >
              <i class="bi bi-chevron-left"></i> BACK
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12"><h4 class="mt-4">GRIEVANCES</h4>
          <hr/></div>
        </div>
        <div class="m-1">
          <div>
            <div class="custom_tab">
              <ul class="nav nav-tabs" id="myTab">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="submit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#submit"
                    role="tab"
                    type="button"
                    aria-controls="submit"
                    aria-selected="true"
                    >Submit Grievances</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="status-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#status"
                    role="tab"
                    type="button"
                    aria-controls="status"
                    aria-selected="false"
                    >View Status of Grievance</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active py-3"
                  id="submit"
                  role="tabpanel"
                  aria-labelledby="submit-tab"
                >
                  <div class="row align-items-center">
                    <label>Type of Issue/Concern</label>
                    <div class="col-sm-5 col-12 pe-3 mb-sm-0 mb-2">
                      <ng-select
                        [items]="categoryType"
                        bindLabel="label"
                        bindValue="label"
                        [(ngModel)]="category"
                        placeholder="Category*"
                        [clearable]="false"
                        (change)="updateCategory()"
                      ></ng-select>
                    </div>
                    <div class="col-sm-7 col-12">
                      <ng-select
                        [items]="subCategoryType"
                        bindLabel="label"
                        bindValue="label"
                        placeholder="Sub Category*"
                        [clearable]="false"
                      ></ng-select>
                    </div>
                  </div>
                  <div class="mt-5">
                    <p class="font-size-12 m-0">
                      Ticket Details Kindly Provide the following information to
                      report your issue/concern to GST Helpdesk.
                    </p>
                    <p class="font-size-12">
                      Attributes marked with asterisk (*) are mandatory to fill.
                    </p>
                  </div>
                  <div
                    class="row align-items-center"
                    *ngIf="userType !== 'TAXPAYER'"
                  >
                    <div class="col-12 pe-3 mb-sm-0 mb-2">
                      <label class="form-label font-size-14"
                        >Is this complaint a registered Taxpayer?</label
                      >
                    </div>
                    <div class="col-4 pe-3 mb-sm-0 mb-2">
                      <label class="custom_radio font-size-16 text_light_blue"
                        >YES
                        <input
                          type="radio"
                          checked="checked"
                          name="isTaxpayer"
                          value="Y"
                        />
                        <span class="checkmark"></span>
                      </label>

                      <label
                        class="custom_radio ml-3 font-size-16 text_light_blue"
                        >NO
                        <input type="radio" name="isTaxpayer" value="N" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
                      <label class="form-label font-size-14"
                        >GSTIN<span style="color: red">*</span></label
                      >
                      <input type="text" class="form-control" />
                    </div>
                    <div class="col-sm-4 col-12">
                      <label class="form-label font-size-14"
                        >First Name<span style="color: red">*</span></label
                      >
                      <input type="text" class="form-control" />
                    </div>
                    <div class="col-sm-4 col-12">
                      <label class="form-label font-size-14"
                        >Last Name<span style="color: red">*</span></label
                      >
                      <input type="text" class="form-control" />
                    </div>
                  </div>

                  <div class="row align-items-center">
                    <div
                      class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2"
                      *ngIf="userType !== 'TAXPAYER'"
                    >
                      <label class="form-label font-size-14"
                        >State Name<span style="color: red">*</span></label
                      >
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        bindValue="code"
                        [clearable]="false"
                      ></ng-select>
                    </div>
                    <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
                      <label class="form-label font-size-14"
                        >Mobile No<span style="color: red">*</span></label
                      >
                      <input type="text" class="form-control" />
                    </div>
                    <div class="col-sm-4 col-12">
                      <label class="form-label font-size-14"
                        >Email ID<span style="color: red">*</span></label
                      >
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-sm-7 col-12 pe-3 mb-sm-0 my-2">
                      <p class="font-size-12">
                        Request to upload relevant JSON file where you are
                        getting error along with screen-shots to help us
                        investigate the error.
                      </p>
                      <div class="row mb-3">
                        <div class="col-12">
                          <label class="form-label font-size-14"
                            >Attachment<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-12"><input type="file" /></div>
                      </div>
                      <ul class="font-size-12 ms-3 ps-0">
                        <li>
                          Please provide the screenshorts of the error
                          encountered.
                        </li>
                        <li>
                          Only PNG, JPG, JPEG, PDF and JSON files are allowed as
                          attachment.
                        </li>
                        <li>
                          The allowed size limit for attachments is 5MB, all
                          attachment combined.
                        </li>
                      </ul>
                      <!-- <p class="font-size-12">
                    Please provide the screenshorts of the error encountered.
                  </p>
                  <p class="font-size-12">
                    Only PNG, JPG, JPEG, PDF and JSON files are allowed as
                    attachment.
                  </p>
                  <p class="font-size-12">
                    The allowed size limit for attachments is 5MB, all
                    attachment combined.
                  </p> -->
                    </div>
                    <div class="col-12">
                      <label class="form-label font-size-14"
                        >Issue Description<span style="color: red"
                          >*</span
                        ></label
                      >
                      <textarea
                        class="form-control custom_textarea"
                        rows="4"
                        style="height: 200px"
                      ></textarea>
                    </div>
                  </div>
                  <div class="text-end">
                    <button
                      type="button"
                      class="btn btn_gradient font-size-16 mt-3"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div
                  class="tab-pane fade py-3"
                  id="status"
                  role="tabpanel"
                  aria-labelledby="status-tab"
                >
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <label class="form-label font-size-14"
                        >Ticket Reference Number<span style="color: red"
                          >*</span
                        ></label
                      >
                    </div>
                    <div class="col-auto">
                      <input type="text" class="form-control" />
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn_gradient font-size-16"
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        class="btn btn_gray font-size-16 ms-3"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <!-- <div class="row align-items-center">
                <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
                  <label class="form-label font-size-14"
                    >Ticket Reference Number<span style="color: red"
                      >*</span
                    ></label
                  >
                </div>
                <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
                  <input type="text" class="form-control" />
                </div>
              </div> -->
                  <!-- <div class="row align-items-center">
                <div class="col-sm-4 col-12 pe-3 mb-sm-0 mb-2">
                  <div class="row justify-content-between m-3">
                    <div class="col-sm-6 col-12 pe-3 mb-sm-0 mb-2">
                      <button
                        type="button"
                        class="btn btn_gradient font-size-16 ms-5"
                      >
                        Search
                      </button>
                    </div>
                    <div class="col-sm-6 col-12 pe-3 mb-sm-0 mb-2">
                      <button
                        type="button"
                        class="btn btn_gray font-size-16 ms-5"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
                  <div class="table-responsive mt-4">
                    <table
                      class="data_table m-0"
                      style="white-space: nowrap; width: 100% !important"
                    >
                      <thead class="font-size-14">
                        <tr>
                          <th>Ticket Number</th>
                          <th>Date of creation</th>
                          <th>GSTIN/PAN</th>
                          <th>Issue Category</th>
                          <th>Resolution comments</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody class="font-size-14">
                        <tr>
                          <td>G-202008241745711</td>
                          <td>04/11/2021</td>
                          <td>27AADCB2923M1ZL</td>
                          <td>eInvoice - Testing/ Trial/Sandbox APIs</td>
                          <td></td>
                          <td>Ticket Status – Resolved</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
