<p>api-integration-dashboard works!</p>
<div class="gradient_wrapper position-relative">
    <!--header-->
    <header>
        <div class="custom-header_container">
            <div class="logo"><img src="../../../assets/images/logo.png" /></div>
            <div class="user_admin">
                <div class="dropdown">
                    <div class="d-flex align-items-center cursor_pointer" data-bs-toggle="dropdown">
                        <span class="user_pic"><img src="../../../assets/images/admin_user.png" /></span>
                        <span class="font-size-14">Shilpa <i class="bi bi-chevron-down ps-1"></i></span>
                    </div>
                    <ul class="dropdown-menu font-size-13" aria-labelledby="dropdownMenuButton1">
                        <li><a href="#">Text-1</a></li>
                        <li><a href="#">Text-2</a></li>
                        <li><a href="#">Text-3</a></li>
                    </ul>
                </div>
            </div>
            <div class="row text-center align-items-center" style="position: relative; top: 10px">
                <div class="container">
                    <div class="col-12 col-md-4 mb-2 mx-auto">
                        <select class="form-select">
                            <option>Coconuttree_Maharashtra</option>
                        </select>
                    </div>
                    <div class="col-12 font-size-14">
                        <marquee>
                            Einvoicing mandate to be applicable to entites having turnover
                            above Rs. 10 Crs for any FY from FY 2017-18.</marquee>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!--header-END-->

    <!--nav-->
    <nav>
        <div class="custom_container ">
            <span class="menu-open-icon" onclick="openNav()"><i class="bi bi-list font-size-30"></i></span>
            <div id="mySidenav" class="sidenav">
                <span class="closebtn" onclick="closeNav()"><i class="bi bi-x font-size-30"></i></span>
                <ul class="font-size-14">
                    <li>
                        <a href="#"> <i class="bi bi-speedometer2"></i> HOME</a>
                    </li>

                    <li class="dropdown">
                        <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-upload"></i> SANDBOX
                            <i class="bi bi-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                        </ul>
                    </li>
                    <li class="dropdown">
                        <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-card-list"></i> PRODUCTION SETTINGS
                            <i class="bi bi-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                        </ul>
                    </li>

                    <li class="dropdown">
                        <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            REPORTS <i class="bi bi-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                        </ul>
                    </li>
                    <li class="dropdown">
                        <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-journal-text"></i> RESOURCES
                            <i class="bi bi-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                            <li><a class="dropdown-item" href="#">TEXT</a></li>
                        </ul>
                    </li>
                    <li><i class="bi bi-gear"></i> SETTINGS</li>
                </ul>
            </div>
        </div>
    </nav>
    <!--nav-END-->

    <!--breadcrumb-->
    <div class="breadcrumb">
        <div class="custom_container">
            <ul class="font-size-14">

                <li><a href="#">Dashboard</a></li>
            </ul>
        </div>
    </div>
    <!--breadcrumb-END-->

    <div class="custom_container mt-4 px-3">
        <div class="row mb-2">
            <div class="col-12 text-end">
                <button class="btn btn-success contextual-button d-flex align-items-center float-end me-2 green-400">
                    <i class="bi bi-arrow-clockwise text-white font-size-20" style="line-height: 0"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                <div class="iris-dashboard-cards card-blue">
                    <h4>GSTINS associated</h4>
                    <hr />
                    <div class="row">
                        <div class="col card-label">Today</div>
                        <div class="col card-value text-end">6500</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col card-label">Yesterday</div>
                        <div class="col card-value text-end">5620</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                <div class="iris-dashboard-cards card-red">
                    <h4>Auth calls</h4>
                    <hr />
                    <div class="row">
                        <div class="col card-label">Today</div>
                        <div class="col card-value text-end">682</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col card-label">Yesterday</div>
                        <div class="col card-value text-end">759</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                <div class="iris-dashboard-cards card-green">
                    <h4>IRNS Generated</h4>
                    <hr />
                    <div class="row">
                        <div class="col card-label">Today</div>
                        <div class="col card-value text-end">1560</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col card-label">Yesterday</div>
                        <div class="col card-value text-end">570</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                <div class="iris-dashboard-cards card-red last-card">
                    <h4>IRNS Canceled</h4>
                    <hr />
                    <div class="row">
                        <div class="col card-label">Today</div>
                        <div class="col card-value text-end">6500</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col card-label">Yesterday</div>
                        <div class="col card-value text-end">5620</div>
                    </div>
                </div>
            </div>
        </div>
        <!--alert-->
        <div class="row my-4">
            <div class="col-12">
                <div class="alert alert-danger opt-for-storage-alert mb-0" role="alert">
                    <img src="../../../assets/images/alert-icon.png" />
                    <span class="fw-bold font-size-20 me-2">7480</span>
                    Files will be Deleted today
                    <button class="btn opt-for-storage-alert-view-btn text-uppercase ms-2 fw-bold">
                        View
                    </button>

                    <button
                        class="btn opt-for-storage-alert-btn ms-2 ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                        <i class="bi bi-check-circle-fill font-size-20 me-1"></i>
                        Opt for more storage
                    </button>
                </div>
            </div>
        </div>
        <!--//alert-->
        <div class="row">
            <div class="col-lg-6">
                <div class="iris-dashboard-cards">
                    <div class="row align-items-center">
                        <div class="col-md-10">
                            <h4 class="mb-4">Common Errors in Past 7 Days</h4>
                        </div>
                        <div class="col-md-2"> <button
                                class="btn btn-success contextual-button d-flex align-items-center float-end me-2 green-400">
                                <i class="bi bi-download text-white font-size-20" style="line-height: 0"></i>
                            </button></div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <div>
                                <canvas baseChart
                                [datasets]="barChartData1"
                                  [labels]="barChartLabels1"
                                  [options]="barChartOptions1"
                                  [plugins]="barChartPlugins1"
                                  [legend]="barChartLegend1"
                                  [chartType]="barChartType1">
                                </canvas>
                              </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-6">
                <div class="iris-dashboard-cards">
                    <div class="row align-items-center">
                        <div class="col-md-10">
                           <!--  -->
                           <h4 class="mb-4">GSTINS with highest Errors in last 7 days.</h4>
                        </div>
                        <div class="col-md-2"> <button
                                class="btn btn-success contextual-button d-flex align-items-center float-end me-2 green-400">
                                <i class="bi bi-download text-white font-size-20" style="line-height: 0"></i>
                            </button></div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <div>
                                <canvas baseChart
                                  [datasets]="barChartData"
                                  [labels]="barChartLabels"
                                  [options]="barChartOptions"
                                  [plugins]="barChartPlugins"
                                  [legend]="barChartLegend"
                                  [chartType]="barChartType">
                                </canvas>
                              </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mb-5 mt-4">
            <div class="col-lg-7">
                <div class="iris-dashboard-cards">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <h4 class="mb-4">Useful Videos</h4>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 col-md-10 mx-auto">
                            <div class="iris-video-container">
                                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="0" class="active" aria-current="true"
                                            aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src="../../../assets/images/video-placeholder.png"
                                                class="d-block w-100 img-fluid" alt="...">
                                        </div>
                                        <div class="carousel-item">
                                            <img src="../../../assets/images/video-placeholder.png"
                                                class="d-block w-100 img-fluid" alt="...">
                                        </div>
                                        <div class="carousel-item">
                                            <img src="../../../assets/images/video-placeholder.png"
                                                class="d-block w-100 img-fluid" alt="...">
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button"
                                        data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                        <i class="bi bi-arrow-left-circle font-size-40"></i>

                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button"
                                        data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                        <i class="bi bi-arrow-right-circle font-size-40"></i>

                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-5">
                <div class="iris-dashboard-cards iris-news-updates">
                    <h4 class="mb-4">News & Updates</h4>
                    <div style="max-height: 530px; overflow: auto;">
                        <div class="">
                            <h6>Notification 14/2022 issued</h6>
                            <p class="font-size-14 mb-3">Lorem Ipsum some description about the generate quickly action
                                about may be of two three
                                lines about how it works or what user can do Lorem Ipsum some description about
                                the generate quickly action</p>
                        </div>
                        <hr />
                        <div class="">
                            <h6>Notification 14/2022 issued</h6>
                            <p class="font-size-14 mb-3">Lorem Ipsum some description about the generate quickly action
                                about may be of two three
                                lines about how it works or what user can do Lorem Ipsum some description about
                                the generate quickly action</p>
                        </div>
                        <hr />
                        <div class="">
                            <h6>Notification 14/2022 issued</h6>
                            <p class="font-size-14 mb-3">Lorem Ipsum some description about the generate quickly action
                                about may be of two three
                                lines about how it works or what user can do Lorem Ipsum some description about
                                the generate quickly action</p>
                        </div>
                        <hr />
                        <div class="">
                            <h6>Notification 14/2022 issued</h6>
                            <p class="font-size-14 mb-3">Lorem Ipsum some description about the generate quickly action
                                about may be of two three
                                lines about how it works or what user can do Lorem Ipsum some description about
                                the generate quickly action</p>
                        </div>
                    </div>
                    <div class=" mt-3">
                        <div class="col-12 text-end">
                            <a href="#" class="font-size-14">View all</a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <!--footer-->
        <footer class="font-size-10">
            <p>Copyright © 2020 iris e-invoicesystem.com. All rights reserved.</p>
        </footer>
    </div>
    <!--footer-END-->
</div>