import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  openCollapsibleBar: boolean;
  modalRef: BsModalRef;

  constructor(    
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    
  }
  openModalWhatsnew(whtsnew) {
    this.modalRef = this.modalService.show(
      whtsnew,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }
  barChartOptions1: ChartOptions = {
    responsive: true,
  };
  public barChartLabels1: Label[] = ['Jan', 'Feb', 'March', 'April', 'May'];
  barChartType1: ChartType = 'bar';
  barChartLegend1 = false;

  public barChartData1: ChartDataSets[] = [
    { data: [5, 5, 2, 3, 8, 1] },

  ];
  generateQuickly(){
    this.openCollapsibleBar = !this.openCollapsibleBar;
    var generateBtnElement =document.querySelector('.generate-btn') as HTMLElement;
    var generateBtnInnerElement =document.querySelector('.generate-btn-inner') as HTMLElement;
    var siedmenuwidth =generateBtnInnerElement;
    if(this.openCollapsibleBar){
      generateBtnInnerElement.style.width = '265px';
      generateBtnElement.style.right = '255px';
    }else{
      generateBtnInnerElement.style.width = '0px';
      generateBtnElement.style.right = '0px';
    }
   


  }

}
