import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiProfileService } from 'src/app/apiIntegration/api-profile/api-profile.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-api-integration-header',
  templateUrl: './api-integration-header.component.html',
  styleUrls: ['./api-integration-header.component.scss'],
})
export class ApiIntegrationHeaderComponent implements OnInit {
  @ViewChild('mySidenav', { static: false }) mySidenav!: ElementRef;
  userName: string;
  userPan: string;
  businessName: string;
  userType: string;
  status: string;
  statusData: any = {
    PENDING_FOR_APPROVAL: 'Pending for Approval',
    APPROVED: 'Approved',
  };

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private profileService: ApiProfileService
  ) {}

  async ngOnInit() {
    this.getUserName();
    const res = await this.profileService.getUserBusiness();
    this.status = res.response?.approveStatus;
  }

  /**
   * Fetches username
   */
  getUserName() {
    const userDetails = JSON.parse(this.sharedService.getCookie('userDetails'));
    this.userName = userDetails?.userName;
    this.userPan = userDetails?.userPan;
    this.businessName = userDetails?.businessName;
    this.userType = userDetails?.businessType;
  }

  /**
   * Logout user from app
   */
  logout() {
    this.sharedService.logout();
    this.router.navigate(['/login']);
  }

  openNav() {
    this.mySidenav.nativeElement.style.width = '250px';
  }

  closeNav() {
    this.mySidenav.nativeElement.style.width = '0';
  }
}
