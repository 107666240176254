import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { LocalStorageKey, roleAccessMap, userType } from 'src/app/app.config';
import { LoginService } from 'src/app/loginSignup/login.service';
import { ApiIntegratorDashboardService } from '../../services/api-integrator-dashboard.service';
import { ApiSandboxService } from '../../services/api-sandbox.service';
import { ToastrService } from 'ngx-toastr';
import { ApiProductionService } from '../../services/api-production.service';

@Component({
  selector: 'app-api-header',
  templateUrl: './api-header.component.html',
  styleUrls: ['./api-header.component.scss']
})
export class ApiHeaderComponent implements OnInit {
  prodStatus: any;
  mouseOveredSandbox;
  mouseOveredReport;
  mouseOveredProductionSetting;
  mouseOvered;
  mouseOveredTab;
  clientData: any;
  userName: any;
  userId: any;
  Data: any = [];
  gstin: any;
  activeMenu: string;
  subscription: any;
  overParam: any;
  isTestGstinAdded: boolean = false;
  userDetails: any;
  detailsLoginData: any;
  constructor(private router: Router,
    private sharedService: SharedService, private loginService: LoginService, private toastrService: ToastrService, private apiDashboardService: ApiIntegratorDashboardService, private sandboxService: ApiSandboxService, private productionService: ApiProductionService) { }

  ngOnInit(): void {
    this.getUserName();
    this.gstin = localStorage.getItem('GSTIN');


    // if(this.mouseOvered)
    // {
    //   this.mouseOver('mouseOveredProductionSetting')
    //     {
    //      this.mouseOvered =true;
    //      this.resetFlags();
    //      this['mouseOveredProductionSetting'] = true;
    //     }
    // }
    // this.resetFlags();
    // this['mouseOveredProductionSetting'] = true;
    this.userDetails = localStorage.getItem('logged-in-userinfo');
    this.detailsLoginData = JSON.parse(this.userDetails)


  }
  getUserName() {
    const userDetails = JSON.parse(this.sharedService.getCookie('userDetails'));
    this.userName = userDetails.userName;
    this.userId = userDetails.userId;
    this.Data = userDetails;

  }
  redirectToPage(feature) {
    this.resetFlags();
    switch (feature) {
      case 'home':
        this.navigationToast('home')
        this.redirectToLandingPage();
        break;
      case 'testGstin':
        this.router.navigate(['/irp/api/sandbox/sandbox-testGstin'])
        this.activeMenu = 'Sandbox';

        break;
      case 'settings':
        this.router.navigate(['irp/api/sandbox/sandbox-settings'])
        this.activeMenu = 'Sandbox';
        this.sandboxService.getSandboxGstin(this.gstin).subscribe(res => {
          this.clientData = res;

          if (this.clientData.hasOwnProperty('response')) {
            this.sandboxService.sendSandboxCredentials(this.clientData.response);
            localStorage.setItem('CLIENT_ID', this.clientData.response.CLIENT_ID);
            localStorage.setItem('CLIENT_SECRET', this.clientData.response.CLIENT_SECRET);
          }
          else {
            // this.toastrService.error(this.clientData.message);
          }

        })
        break;


      case 'prod-app':
        if (this.Data.intermediary_status === "PROD_APPLICATION_SUBMITTED" || this.prodStatus === true) {
          this.router.navigate(['/irp/api/production/prod-status']);
          this.activeMenu = 'Production';

        }
        else {
          this.router.navigate(['irp/api/production/prod-App'])
          this.activeMenu = 'Production';
        }

        break;
      case 'prod-setting':
        this.router.navigate(['irp/api/production/production-setting'])
        this.activeMenu = 'Production';
        this.productionService.getGstin(this.gstin).subscribe(res => {
          this.clientData = res;

          if (this.clientData.hasOwnProperty('response')) {
            this.productionService.sendCredentials(this.clientData.response);
            localStorage.setItem('PROD_CLIENT_ID', this.clientData.response.CLIENT_ID);
            localStorage.setItem('PROD_CLIENT_SECRET', this.clientData.response.CLIENT_SECRET);
          }
          else {
            // this.toastrService.error(this.clientData.message);
          }

        })
        break;

      case 'gstin-list':
        this.router.navigate(['irp/api/production/gstins'])
        this.activeMenu = 'Production';
        break;

      case 'report':
        this.router.navigate(['/irp/api/dashboard/reports'])
        this.activeMenu = 'Sandbox';
        break;

    }
  }
  redirectToLandingPage() {

    this.userDetails = localStorage.getItem('logged-in-userinfo');
    this.detailsLoginData = JSON.parse(this.userDetails)

    const loggedInUserInfo = JSON.parse(localStorage.getItem(LocalStorageKey.LoggedInUserInfo));
    if (loggedInUserInfo) {
      const userTypeInfo = loggedInUserInfo['userType'];
      const mouseOverProd: string = localStorage.getItem('mouseOvered');
      this.mouseOvered = (mouseOverProd && mouseOverProd === "true") ? true : false;
      if (this.mouseOvered) {
        this.prodStatus = true;
      }
      if (this.isTestGstinAdded === true || this.detailsLoginData.intermediary_status === 'TEST_GSTIN_ADDED') {
        this.mouseOvered = true;
        this.router.navigate(['/irp/api/dashboard/production-access']);
        this.resetFlags();
      } else {
        this.mouseOvered = false;

      }

      if (this.detailsLoginData.intermediary_status === 'PROD_APPLICATION_SUBMITTED') {
        this.mouseOvered = true;
        this.router.navigate(['/irp/api/production/prod-status']);
        // this.router.navigate(['/irp/api/dashboard']);
        this.resetFlags();
        // this.router.navigate(['/irp/api/production/prod-App']);
      }
      else {
        this.mouseOvered = false;
      }

      if (this.detailsLoginData.intermediary_status === 'PROD_APPLICATION_APPROVED') {
        this.mouseOvered = true;
        this.router.navigate(['/irp/api/dashboard']);
        this.resetFlags();
        // this.router.navigate(['/irp/api/production/prod-App']);
      }
      else {
        this.mouseOvered = false;
      }

      if (this.detailsLoginData.intermediary_status == 'SANDBOX_GRANTED') {
        this.router.navigate(['/irp/api/dashboard/addtest-gstin']);
      }

      console.log(this.detailsLoginData.intermediary_status)
    }
  }
  navigationToast(route) {
    this.userDetails = localStorage.getItem('logged-in-userinfo');
    this.detailsLoginData = JSON.parse(this.userDetails)
    var role = this.detailsLoginData.intermediary_status;
    const access = roleAccessMap[role];
    if (access.cannotView.includes(route)) {
      // if user does not have access to the route, show toast message
      this.toastrService.warning(access.toastMsg)
    }

  }
  mouseOverTab(param) {
    this.mouseOveredTab = true;
    this.resetFlags()
    this[param] = true;
  }
  mouseOver(param) {
    this.userDetails = localStorage.getItem('logged-in-userinfo');
    this.detailsLoginData = JSON.parse(this.userDetails)
    if (param == 'mouseOveredSandbox') {
      this.apiDashboardService.getFlagRegisterGstin().subscribe((isGstin: string) => {
        if (!this.apiDashboardService.isEmpty(isGstin)) {
          if (isGstin === 'true') {
            this.mouseOvered = true;
            this.resetFlags();
            this[param] = true;
          } else {
            this.mouseOvered = false;
          }
        }
      })
      this.apiDashboardService.getFlagTestGstin().subscribe((res: string) => {

        if (!this.apiDashboardService.isEmpty(res)) {
          if (res === 'true') {
            this.isTestGstinAdded = true;
            localStorage.setItem('testGstinAdded', 'true');
          }
        }
      })

      if (this.detailsLoginData.intermediary_status === 'NOT_REGISTERED') {

        this.mouseOvered = false;
      } else {
        this.mouseOvered = true;
        this.resetFlags();
        this[param] = true;
      }
      // this.resetFlags();
      // this[param] = true;

    }
    if (param == 'mouseOveredProductionSetting') {

      if (this.detailsLoginData.intermediary_status === 'PROD_APPLICATION_SUBMITTED' || this.detailsLoginData.intermediary_status === 'PROD_APPLICATION_APPROVED') {
        this.mouseOvered = true;
        this.resetFlags();
        this[param] = true;
        // this.router.navigate(['/irp/api/production/prod-App']);
      }
      else {
        this.mouseOvered = false;
      }

      const mouseOverProd: string = localStorage.getItem('mouseOvered');
      this.mouseOvered = (mouseOverProd && mouseOverProd === "true") ? true : false;
      if (this.mouseOvered) {
        this[param] = true;
        this.prodStatus = true;
      }
    }
    if (param == 'mouseOveredReport') {

      if (this.detailsLoginData.intermediary_status === 'PROD_APPLICATION_SUBMITTED' || this.detailsLoginData.intermediary_status === 'PROD_APPLICATION_APPROVED') {
        this.mouseOvered = true;
        this.resetFlags();
        this[param] = true;
        // this.router.navigate(['/irp/api/production/prod-App']);
      }
      else {
        this.mouseOvered = false;
      }

      const mouseOverProd: string = localStorage.getItem('mouseOvered');
      this.mouseOvered = (mouseOverProd && mouseOverProd === "true") ? true : false;
      if (this.mouseOvered) {
        this[param] = true;
        this.prodStatus = true;
      }
    }
  }
  resetFlags() {
    this.mouseOveredSandbox = false;
    this.mouseOveredProductionSetting = false;
  }
  mouseOut(param) {
    this.mouseOvered = false;
    this.mouseOveredTab = false;
    this[param] = false;
  }
  formatProfileName(name) {
    if (name) {
      return name.length < 40 ? name : name.substr(0, 40) + "...";
    }
    return null;
  }
  logout() {
    this.sharedService.logout();
    this.router.navigate(['/login']);
  }
  resetPassword() {
    this.router.navigate(['/irp/api/change-password']);
  }

  naviagteToReport() {
    const mouseOverProd: string = localStorage.getItem('mouseOvered');
    this.mouseOvered = (mouseOverProd && mouseOverProd === "true") ? true : false;
    if (this.detailsLoginData.intermediary_status === "NOT_REGISTERED") {
      this.toastrService.warning("Kindly register your GSTIN")
    }

    if (this.detailsLoginData.intermediary_status === "SANDBOX_GRANTED") {
      this.toastrService.warning("Kindly add Test GSTINs")
    }

    if (this.detailsLoginData.intermediary_status === "TEST_GSTIN_ADDED") {
      this.toastrService.warning("Kindly complete your Production Application");
    }


    if (this.detailsLoginData.intermediary_status === "PROD_APPLICATION_SUBMITTED" || this.detailsLoginData.intermediary_status === 'PROD_APPLICATION_APPROVED' || this.mouseOvered) {
      this.router.navigate(['/irp/api/dashboard/reports']);
      this.activeMenu = 'Reports';

    }
  }
}
