import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MasterCodeTabs,PreMasterCodeTabs } from 'src/app/app.config';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MastersService } from 'src/app/taxPayer/utilities/masters.service';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';
@Component({
  selector: 'app-generic-masters-code',
  templateUrl: './generic-masters-code.component.html',
  styleUrls: ['./generic-masters-code.component.scss']
})
export class GenericMastersCodeComponent implements OnInit {
  activeTab = 'stateCode';
  preActiveTab = 'stateCode';
  stateDataForm: FormGroup;
  countryForm: FormGroup;
  portForm: FormGroup;
  currencyForm: FormGroup;
  uqcForm: FormGroup;
  taxForm: FormGroup;
  pincodeForm: FormGroup;
  pinStateForm: FormGroup;
  errorForm: FormGroup;
  stateData: any;
  countryData: any;
  portData: any;
  currencyData: any;
  uqcData: any;
  taxData: any;
  pinStateData: any;
  errData: any;
   stateName;
   setCaptch:any;
  pinState: any = [
      { code: '01', name: 'JAMMU AND KASHMIR', pincode: "180-194" },
      { code: '02', name: 'HIMACHAL PRADESH', pincode: "171-177" },
      { code: '03', name: 'PUNJAB', pincode: "140-160" },
      { code: '04', name: 'CHANDIGARH', pincode: "160-160" },
      { code: '05', name: 'UTTARAKHAND', pincode: "244-263" },
      { code: '06', name: 'HARYANA', pincode: "121-136" },
      { code: '07', name: 'DELHI', pincode: "110-110" },
      { code: '08', name: 'RAJASTHAN', pincode: "301-345" },
      { code: '09', name: 'UTTAR PRADESH', pincode: "201-285" },
      { code: 10, name: 'BIHAR', pincode: "800-855" },
      { code: 11, name: 'SIKKIM', pincode: "737-737" },
      { code: 12, name: 'ARUNACHAL PRADESH', pincode: "790-792" },
      { code: 13, name: 'NAGALAND', pincode: "797-798" },
      { code: 14, name: 'MANIPUR', pincode: "795-795" },
      { code: 15, name: 'MIZORAM', pincode: "796-796" },
      { code: 16, name: 'TRIPURA', pincode: "799-799" },
      { code: 17, name: 'MEGHALAYA', pincode: "793-794" },
      { code: 18, name: 'ASSAM', pincode: "781-788" },
      { code: 19, name: 'WEST BENGAL', pincode: "700-743" },
      { code: 20, name: 'JHARKHAND', pincode: "813-835" },
      { code: 21, name: 'ODISHA', pincode: "751-770" },
      { code: 22, name: 'CHHATTISGARH', pincode: "490-497" },
      { code: 23, name: 'MADHYA PRADESH', pincode: "450-488" },
      { code: 24, name: 'GUJARAT', pincode: "360-396" },
      { code: 25, name: 'DAMAN AND DIU', pincode: "362-362" },
      { code: 26, name: 'DADRA AND NAGAR HAVELI', pincode: "396-396" },
      { code: 27, name: 'MAHARASHTRA', pincode: "400-445" },
      { code: 29, name: 'KARNATAKA', pincode: "560-591" },
      { code: 30, name: 'GOA', pincode: "403-403" },
      { code: 31, name: 'LAKSHADWEEP', pincode: "682-682" },
      { code: 32, name: 'KERELA', pincode: "670-695" },
      { code: 33, name: 'TAMIL NADU', pincode: "600-643" },
      { code: 34, name: 'PUDUCHERRY', pincode: "533-533" },
      { code: 35, name: 'ANDAMAN AND NICOBAR', pincode: "744-744" },
      { code: 36, name: 'TELANGANA' },
      { code: 37, name: 'ANDHRA PRADESH', pincode: "500-535" },
      { code: 38, name: 'LADAKH' }
  ];

  confirmOptions: any = [
      { label: 'Yes', code: 'Y' },
      { label: 'No', code: 'N' },
  ];

  pincodes: any = [];
  constructor(private form: FormBuilder, private googleAnalyticsService: GoogleAnalyticsService, private masterServices: MastersService, private toastrService: ToastrService, private invoiceService: EInvoiceService) { }

  ngOnInit(): void {
    this.initStForm();
    this.initCountry();
    this.initPortForm();
    this.initCurrencyForm();
    this.initUqcForm();
    this.initTaxForm();
    this.initPinStateForm();
    this.initErrorForm();
    this.initPincodeForm();
    this.getStates('State_Code');
    this.getCountries('Country_Code');
    this.getPorts('Port_Code');
    this.getCurrency('Currency_Code');
    this.getUqc('Uqc_Code');
    this.getTaxes('Tax_Rate');
    this.getPinStates('PinCode_State_Mapping');
    this.getErrors('Error_List')

    this.setCaptch = localStorage.getItem('token');
  }
 //^[a-zA-Z0-9_.-]*$
 initStForm() {
  this.stateDataForm = new FormGroup({
      state: new FormControl('')
  });
}
initCountry() {
  this.countryForm = new FormGroup({
      countryName: new FormControl('')
  })
}
initPortForm() {
  this.portForm = new FormGroup({
      port: new FormControl('')
  })
}
initCurrencyForm() {
  this.currencyForm = new FormGroup({
      currency: new FormControl('')
  })
}
initUqcForm() {
  this.uqcForm = new FormGroup({
      uqcName: new FormControl('')
  })
}
initTaxForm() {
  this.taxForm = new FormGroup({
      taxName: new FormControl('')
  })
}
initPincodeForm() {
  this.pincodeForm = new FormGroup({
      pincode: new FormControl('')
  })
}
initPinStateForm() {
  this.pinStateForm = new FormGroup({
      pinState: new FormControl('')
  })
}
initErrorForm() {
  this.errorForm = new FormGroup({
      error: new FormControl('')
  })
}
getStates(State_Code) {

  this.masterServices.getAllData(State_Code).subscribe(res => {
      this.stateData = res['response'];

  })
}
getStateValue(State_Code) {
  var stateName = this.stateDataForm.controls['state'].value;

  if (this.invoiceService.isEmpty(stateName)) {
      // stateName = 'M';
      this.masterServices.getAllData(State_Code).subscribe(res => {
          this.stateData = res['response'];
      })
  }


}
SearchStateValue(State_Code) {
  var stateName = this.stateDataForm.controls['state'].value;

      // stateName = 'M';
      this.masterServices.getMasterData(State_Code, stateName).subscribe(res => {
          this.stateData = res['response'];
      })
}
SearchCountryValue(Country_Code) {
  var countryName = this.countryForm.controls['countryName'].value;

      // stateName = 'M';
      this.masterServices.getMasterData(Country_Code, countryName).subscribe(res => {
          this.countryData = res['response'];
      })
}
getCountryValue(Country_Code) {

  var countryName = this.countryForm.controls['countryName'].value;
  if (this.invoiceService.isEmpty(countryName)) {
      this.masterServices.getAllData(Country_Code).subscribe(res => {
          this.countryData = res['response'];
      })
  }
}
downloadMaster(type) {
  var stateName = this.stateDataForm.controls['state'].value;
  if(stateName){
      try {
          this.masterServices.downloadMasterData(type, stateName).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }

}

getCountries(Country_Code) {

  this.masterServices.getAllData(Country_Code).subscribe(res => {
      this.countryData = res['response'];

  })
}
downloadCountry(type) {
  const countryName = this.countryForm.controls['countryName'].value;

  if(countryName){
      try {
          this.masterServices.downloadMasterData(type, countryName).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }
}
getPorts(Port_Code) {

  this.masterServices.getAllData(Port_Code).subscribe(res => {
      this.portData = res['response'];

  })
}

getPortValue(Port_Code) {
  var portName = this.portForm.controls['port'].value;        ;
  if (this.invoiceService.isEmpty(portName)) {
      this.masterServices.getAllData(Port_Code).subscribe(res => {
          this.portData = res['response'];
      })
  }
}
searchPort(Port_Code){
  var portName = this.portForm.controls['port'].value;

      // stateName = 'M';
      this.masterServices.getMasterData(Port_Code, portName).subscribe(res => {
          this.portData = res['response'];
      })
}
downloadPort(type) {
  const portName = this.portForm.controls['port'].value;
  if(portName){
      try {
          this.masterServices.downloadMasterData(type, portName).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }
}
getCurrency(Currency_Code) {

  this.masterServices.getAllData(Currency_Code).subscribe(res => {
      this.currencyData = res['response'];

  })
}
getCurrencyValue(Currency_Code) {

  var currencyName = this.currencyForm.controls['currency'].value;
  if (this.invoiceService.isEmpty(currencyName)) {
      this.masterServices.getAllData(Currency_Code).subscribe(res => {
          this.currencyData = res['response'];
      })
  }
}
searchCurrency(Currency_Code){
  var currencyName = this.currencyForm.controls['currency'].value;

      // stateName = 'M';
      this.masterServices.getMasterData(Currency_Code, currencyName).subscribe(res => {
          this.currencyData = res['response'];
      })
}
downloadCurrency(type) {
  const currencyName = this.currencyForm.controls['currency'].value;
  if(currencyName){
      try {
          this.masterServices.downloadMasterData(type, currencyName).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }
}
getUqc(Uqc_Code) {

  this.masterServices.getAllData(Uqc_Code).subscribe(res => {
      this.uqcData = res['response'];

  })
}
getUqcValue(Uqc_Code) {

  var uqcName;
  if (this.invoiceService.isEmpty(uqcName)) {
      this.masterServices.getAllData(Uqc_Code).subscribe(res => {
          this.uqcData = res['response'];
      })
  }
}
downloadUqc(type) {
  const uqcName = this.uqcForm.controls['uqcName'].value;
  if(uqcName){
      try {
          this.masterServices.downloadMasterData(type, uqcName).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }
}
searchUqcValue(Uqc_Code){
  const uqcName = this.uqcForm.controls['uqcName'].value;

  // stateName = 'M';
  this.masterServices.getMasterData(Uqc_Code, uqcName).subscribe(res => {
      this.uqcData = res['response'];
  })
}
getTaxes(Tax_Rate) {

  this.masterServices.getAllData(Tax_Rate).subscribe(res => {
      this.taxData = res['response'];

  })
}
getTaxValue(Tax_Rate) {

  var taxName;

  if (this.invoiceService.isEmpty(taxName)) {
      this.masterServices.getAllData(Tax_Rate).subscribe(res => {
          this.taxData = res['response'];
      })
  }
}
searchTaxValue(Tax_Rate){
  const taxName = this.taxForm.controls['taxName'].value;

  // stateName = 'M';
  this.masterServices.getMasterData(Tax_Rate, taxName).subscribe(res => {
      this.taxData = res['response'];
  })
}
downloadTax(type) {
  const taxName = this.taxForm.controls['taxName'].value;
  if(taxName){
      try {
          this.masterServices.downloadMasterData(type, taxName).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }
}
downloadPinCode(type) {
  const state = this.pincodeForm.controls.pincode.value;

  try {
      this.masterServices.downloadPincode(type, state).subscribe();
  }
  catch (e) {
      console.log(e);
  }
}
getPinStates(PinCode_State_Mapping) {

  this.masterServices.getAllData(PinCode_State_Mapping).subscribe(res => {
      this.pinStateData = res['response'];

  })
}
getPinStateValue(PinCode_State_Mapping) {

  var pinStName;
  if (this.invoiceService.isEmpty(pinStName)) {
      this.masterServices.getAllData(PinCode_State_Mapping).subscribe(res => {
          this.pinStateData = res['response'];
      })
  }
}
searchPinState(PinCode_State_Mapping){
  const pinStName = this.pinStateForm.controls['pinState'].value

  // stateName = 'M';
  this.masterServices.getMasterData(PinCode_State_Mapping, pinStName).subscribe(res => {
      this.pinStateData = res['response'];
  })
}
downloadPinStateList(type) {
  const pinStName = this.pinStateForm.controls['pinState'].value;
  if(pinStName){
      try {
          this.masterServices.downloadMasterData(type, pinStName).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }
}
getErrors(Error_List) {

  this.masterServices.getAllData(Error_List).subscribe(res => {
      this.errData = res['response'];

  })
}
getErrorValue(Error_List) {

  var err;
  if (this.invoiceService.isEmpty(err)) {
      err ='M';
      this.masterServices.getAllData(Error_List).subscribe(res => {
          this.errData = res['response'];
      })
  }
}
searchError(Error_List){

   const err = this.errorForm.controls['error'].value;


  this.masterServices.getMasterData(Error_List, err).subscribe(res => {
      this.errData = res['response'];

  })
}
downloadErrorList(type) {
  const err = this.errorForm.controls['error'].value;
  if(err){
      try {
          this.masterServices.downloadMasterData(type, err).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }else{
      try {
          this.masterServices.downloadAllMasterData(type).subscribe();
      }
      catch (e) {
          console.log(e);
      }
  }
}
tabClicked(tabName) {
  this.activeTab = tabName;
  // this.resetFlags();
  if (tabName && MasterCodeTabs.hasOwnProperty(tabName)) {
      this.trackAnalytics(MasterCodeTabs[tabName])
  }
}
pretabClicked(tabName) {
    this.activeTab = tabName;
    // this.resetFlags();
    if (tabName && PreMasterCodeTabs.hasOwnProperty(tabName)) {
        this.trackAnalytics(PreMasterCodeTabs[tabName])
    }
  }
  
pincodeListStateWide: any
getPincodeData(value) {
  if (value.code === 25) {
      this.toastrService.error("No records available");
  }
  const state = value.code
  this.pincodes = this.masterServices.getPincode(state).subscribe(res => {
      if (res["status"] == 1) {
          this.pincodeListStateWide = res["response"]
      }

  })

}
clear() {
  if (this.activeTab === 'stateCode') {
      let stateName = this.stateDataForm.controls['state'].value;
      let stCode = 'State_Code';
      if (stateName) {
          stateName = '';
          if (this.invoiceService.isEmpty(stateName)) {
              stateName = 'M';
              this.masterServices.getAllData(stCode).subscribe(res => {
                  this.stateData = res['response'];
                  this.stateDataForm.controls['state']?.reset();
              })
          }
      }
  }

  if (this.activeTab === 'countryCode') {
      let countryName = this.countryForm.controls['countryName'].value;
      let ctCode = 'Country_Code';
      if (countryName) {
          countryName = '';
          if (this.invoiceService.isEmpty(countryName)) {
              countryName = 'M';
              this.masterServices.getAllData(ctCode).subscribe(res => {
                  this.countryData = res['response'];
                  this.countryForm.controls['countryName']?.reset();
              })
          }
      }
  }

  if (this.activeTab === 'portCode') {
      let portName = this.portForm.controls['port'].value;
      let ptCode = 'Port_Code';
      if (portName) {
          portName = '';
          if (this.invoiceService.isEmpty(portName)) {
          portName = 'M';
              this.masterServices.getAllData(ptCode).subscribe(res => {
                  this.portData = res['response'];
                  this.portForm.controls['port']?.reset();
              })
          }
      }
  }

  if (this.activeTab === 'currencyUqcCode') {
      let currencyName = this.currencyForm.controls['currency'].value;
      let currencyCode = 'Currency_Code';
      if (currencyName) {
          currencyName = '';
          if (this.invoiceService.isEmpty(currencyName)) {
              this.masterServices.getAllData(currencyCode).subscribe(res => {
                  this.currencyData = res['response'];
                  this.currencyForm.controls['currency']?.reset();
              })
          }
      }
  }
  if (this.activeTab === 'uqcCode') {
      let uqcName = this.uqcForm.controls['uqcName'].value;
      let uqcCode = 'Uqc_Code';
      if (uqcName) {
          uqcName = '';
          if (this.invoiceService.isEmpty(uqcName)) {
              this.masterServices.getAllData(uqcCode).subscribe(res => {
                  this.uqcData = res['response'];
                  this.uqcForm.controls['uqcName']?.reset();
              })
          }
      }
  }
  if (this.activeTab === 'taxRate') {
      let taxName = this.taxForm.controls['taxName'].value;
      let taxCode = 'Tax_Rate';
      if (taxName) {
          taxName = '';
          if (this.invoiceService.isEmpty(taxName)) {
              this.masterServices.getAllData(taxCode).subscribe(res => {
                  this.taxData = res['response'];
                  this.taxForm.controls['taxName']?.reset();
              })
          }
      }
  }
  if (this.activeTab === 'pincodeStateMap') {
      let pinName = this.pinStateForm.controls['pinState'].value;
      let pinCode = 'PinCode_State_Mapping';
      if (pinName) {
          pinName = '';
          if (this.invoiceService.isEmpty(pinName)) {
              this.masterServices.getAllData(pinCode).subscribe(res => {
                  this.pinStateData = res['response'];
                  this.pinStateForm.controls['pinState']?.reset();
              })
          }
      }
  }
  if (this.activeTab === 'errorList') {
      let errName = this.errorForm.controls['error'].value;
      let errCode = 'Error_List';
      if (errName) {
          errName = '';
          if (this.invoiceService.isEmpty(errName)) {
              this.masterServices.getAllData(errCode).subscribe(res => {
                  this.errData = res['response'];
                  this.errorForm.controls['error']?.reset();
              })
          }
      }
  }

}
trackAnalytics(btnName) {
  this.googleAnalyticsService.trackAnalytics(btnName);
}
}
