import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from "@techiediaries/ngx-qrcode";

export const userType = {
    Taxpayer: "TAXPAYER",
    APIIntegrator: "APIINTEGRATOR"
}
export const transactionType =
{
    Regular: "Regular",
    BillDispatch: "Bill From - Dispatch From",
    BillShip: "Bill To - Ship To",
    Combination: "Combination of 2 & 3"
}
export const globalRates = {
    val0: "0",
    val01: "0.1",
    val025: "0.25",
    val1: "1",
    val15: "1.5",
    val3: "3",
    val5: "5",
    val6: "6",
    val75: "7.5",
    val12: "12",
    val18: "18",
    val28: "28"
}
export const suplyType = {
    B2B: "B2B Invoices of registered counterparty",
    SEZWP: "SEZ with Payment",
    SEZWOP: "SEZ without Payment",
    EXPWP: "Export with payment of duty",
    EXPWOP: "Export without payment of duty",
    DEXP: "Deemed Export"
}
export const documentType = {
    INV: 'Tax Invoice',
    DBN: 'Debit Note',
    CRN: 'Credit Note'
}
export const gstinUserTypeOperator = {
    Dummy: "DUMMY",
    Valid: "TEST"
}
export const gstinuserType = {
    Taxpayer: "TAXPAYER",
    Intermediary: "INTERMEDIARY",
    Ecommerce: "E_COMMERCE"
}
export const Status = {
    Success: 'S',
    Cancelled: 'C',
    Error: 'E'
}
export const userTypeForAPI = {
    Taxpayer: "gstin",
    APIIntegrator: "intd"
}
export const addGSTIN = {
    tradeName: 'tradeName'
}
export const GoogleAnalyticsMeasurementIdForPROD = 'G-KD65P5VKYS';
export const GoogleAnalyticsMeasurementIdForDEV = 'G-50KZFDZH5H';

export const LocalStorageKey = {
    LoggedInUserInfo: 'logged-in-userinfo',
    GSTINAvailable: 'isGSTInAvailable',
    SelectedGSTIN: 'SELECTED_GSTIN',
    GSTINNumber: 'GSTIN',
    IS_GSTIN_AVAILABLE: 'IS_GSTIN_AVAILABLE',
    NEWS: 'News',
    VERIFIED_GSTIN: 'verifiedGstin',
    REQUEST_ID_BULK_UPLOAD: 'request-id-bulk-upload',
    REQUEST_ID_BULK_UPLOAD_CANCEL: 'request-id-bulk-upload-cancel',
    REQUEST_ID_BULK_UPLOAD_EWB: 'request-id-bulk-upload-ewb',
}
export const GSTINMessage = {
    'Verify': 'Gstn verified.',
    'Added': 'GSTN Details added successfully...',
    'ProcessCompleted': 'Process completed'
}
export const videoLinks = {
    'gstInfo': 'https://www.youtube.com/embed/ypQp_B5R7ow?enablejsapi=1&origin=http://localhost:4200'
}
export const Patterns = {
    Email: '/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/',
    Phone: '[789][0-9]{9}',
    gstinTCS: '[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[C]{1}[0-9a-zA-Z]',
    vehicleNoPattern : /^[A-Z]{2}\d{2}\s[A-Z]{2}\s\d{4}$/,
    distancePattern : /^\d+(\.\d{0,2})?$/,
    transporterIdPattern:/^[A-Z]{2}\d{2}\d{2}\d{2}\d{2}\d{5}$/
}
export const TaxPayerType = {
    TCS: "Tax Collector (Electronic Commerce Operator)",
    Regular: "Regular",
    SEZDeveloper: "SEZ Developer"
}
export const InvoiceStatus = {
    "No": "No"
}
export const AnnualTurnover = {
    below5CR: "below5CR",
    below10CR: "below10CR"
}
export const badgeMapInvoice = {
    "buyerName": "Buyer Name",
    "ctin": "CTIN",
    "type": "Doc Type",
    "invType": "Inv Type",
    "no": "Doc Number",
    "st": "Status",
    "irn": "IRN",
    "ackNo": "Ack No",
    "sellerName": "Supplier Name",
    "sgstin": "Supplier GSTIN",
    "ewbNo": "EWB Number",
    "ewbStatus": "EWB Status",
    "invoiceStatus": "Invoice Status"
}
export const downloadType = {
    'JSON': 'VIEW_JSON',
    'SUMMARY': 'VIEW_SUMMARY_CSV',
    'DETAILED': 'VIEW_DETAIL_CSV',
    'CSV': 'VIEW_CSV',
    'BULK_PRINT': 'BULK_PRINT'
}
export const bulkDownloadType = {
    'JSON': 'JSON',
    'CSV': 'CSV',
    'RESPONSE': 'ERROR_CSV',
    'CANCELCSV': 'CANCEL_CSV',
    'CANCELERRCSV': 'CANCEL_ERROR_CSV',
    'EWBJSON':'EWB_JSON',
    'EWBCSV': 'EWB_CSV',
    'EWBERRCSV':'EWB_ERROR_JSON'
}

export const states: any = {
    '01': 'JAMMU AND KASHMIR',
    '02': 'HIMACHAL PRADESH',
    '03': 'PUNJAB',
    '04': 'CHANDIGARH',
    '05': 'UTTARAKHAND',
    '06': 'HARYANA',
    '07': 'DELHI',
    '08': 'RAJASTHAN',
    '09': 'UTTAR PRADESH',
    10: 'BIHAR',
    11: 'SIKKIM',
    12: 'ARUNACHAL PRADESH',
    13: 'NAGALAND',
    14: 'MANIPUR',
    15: 'MIZORAM',
    16: 'TRIPURA',
    17: 'MEGHALAYA',
    18: 'ASSAM',
    19: 'WEST BENGAL',
    20: 'JHARKHAND',
    21: 'ODISHA',
    22: 'CHHATTISGARH',
    23: 'MADHYA PRADESH',
    24: 'GUJARAT',
    25: 'DAMAN AND DIU',
    26: 'DADRA AND NAGAR HAVELI',
    27: 'MAHARASHTRA',
    29: 'KARNATAKA',
    30: 'GOA',
    31: 'LAKSHADWEEP',
    32: 'KERALA',
    33: 'TAMIL NADU',
    34: 'PUDUCHERRY',
    35: 'ANDAMAN AND NICOBAR',
    36: 'TELANGANA',
    37: 'ANDHRA PRADESH',
    38: 'LADAKH',
    97: 'OTHER TERRITORY',
    96: 'OTHER COUNTRY'
}
export const invoiceTypeMapping = {
    "INV": "Invoice",
    "DBN": "Debit Note",
    "CRN": "Credit Note"
}
export const statusMap = {
    "ACT": "Active",
    "CNL": "Cancelled"
}
export const globalTypeList = [
    { label: 'Invoice', value: 'INV' },
    { label: 'Credit Note', value: 'CRN' },
    { label: 'Debit Note', value: 'DBN' },
];
export const globalTypeListForPrint = [
    { label: 'Tax Invoice', value: 'INV' },
    { label: 'Credit Note', value: 'CRN' },
    { label: 'Debit Note', value: 'DBN' },
];
export const Pagination = {
    rows: 10,
    pageStart: 0
}
export const QRCodeSettings = {
    ELEMENT_TYPE: NgxQrcodeElementTypes.IMG,
    CORRECTION_LEVEL: NgxQrcodeErrorCorrectionLevels.HIGH,
    WIDTH: 300,
    smWIDTH:200
}
export const globalInvoiceTypes = [
    { label: 'B2B - Regular B2B Invoices of registered counterparty', value: 'B2B' },
    { label: 'SEZWP - SEZ with payment', value: 'SEZWP' },
    { label: 'SEZWOP - SEZ without payment', value: 'SEZWOP' },
    { label: 'EXPWP - Export with payment of duty', value: 'EXPWP' },
    { label: 'EXPWOP - Export without payment of duty', value: 'EXPWOP' },
    { label: 'DEXP - Deemed Export', value: 'DEXP' },
];

export const globalSupply = [
    { code: 'Business to Business', desc: 'B2B' },
    { code: 'SEZ with payment', desc: 'SEZWP' },
    { code: 'SEZ without payment', desc: 'SEZWOP' },
    { code: 'Export with Payment', desc: 'EXPWP' },
    { code: 'Export without Payment', desc: 'EXPWOP' },
    { code: 'Deemed Export', desc: 'DEXP' },
];

export const globalstateWebForm = [
    { code: "01", name: 'JAMMU AND KASHMIR', pincode: "180-194" },
    { code: "02", name: 'HIMACHAL PRADESH', pincode: "171-177" },
    { code: "03", name: 'PUNJAB', pincode: "140-160" },
    { code: "04", name: 'CHANDIGARH', pincode: "160-160" },
    { code: "05", name: 'UTTARAKHAND', pincode: "244-263" },
    { code: "06", name: 'HARYANA', pincode: "121-136" },
    { code: "07", name: 'DELHI', pincode: "110-110" },
    { code: "08", name: 'RAJASTHAN', pincode: "301-345" },
    { code: "09", name: 'UTTAR PRADESH', pincode: "201-285" },
    { code: 10, name: 'BIHAR', pincode: "800-855" },
    { code: 11, name: 'SIKKIM', pincode: "737-737" },
    { code: 12, name: 'ARUNACHAL PRADESH', pincode: "790-792" },
    { code: 13, name: 'NAGALAND', pincode: "797-798" },
    { code: 14, name: 'MANIPUR', pincode: "795-795" },
    { code: 15, name: 'MIZORAM', pincode: "796-796" },
    { code: 16, name: 'TRIPURA', pincode: "799-799" },
    { code: 17, name: 'MEGHALAYA', pincode: "793-794" },
    { code: 18, name: 'ASSAM', pincode: "781-788" },
    { code: 19, name: 'WEST BENGAL', pincode: "700-743" },
    { code: 20, name: 'JHARKHAND', pincode: "813-835" },
    { code: 21, name: 'ODISHA', pincode: "751-770" },
    { code: 22, name: 'CHHATTISGARH', pincode: "490-497" },
    { code: 23, name: 'MADHYA PRADESH', pincode: "450-488" },
    { code: 24, name: 'GUJARAT', pincode: "360-396" },
    { code: 25, name: 'DAMAN AND DIU', pincode: "362-362" },
    { code: 26, name: 'DADRA AND NAGAR HAVELI', pincode: "396-396" },
    { code: 27, name: 'MAHARASHTRA', pincode: "400-445" },
    { code: 29, name: 'KARNATAKA', pincode: "560-591" },
    { code: 30, name: 'GOA', pincode: "403-403" },
    { code: 31, name: 'LAKSHADWEEP', pincode: "682-682" },
    { code: 32, name: 'KERALA', pincode: "670-695" },
    { code: 33, name: 'TAMIL NADU', pincode: "600-643" },
    { code: 34, name: 'PUDUCHERRY', pincode: "533-533" },
    { code: 35, name: 'ANDAMAN AND NICOBAR', pincode: "744-744" },
    { code: 36, name: 'TELANGANA' },
    { code: 37, name: 'ANDHRA PRADESH', pincode: "500-535" },
    { code: 38, name: 'LADAKH' },
    { code: 97, name: 'OTHER TERRITORY' },
    { code: 96, name: 'OTHER COUNTRY' },
];
export const globalUnits = [
    {
        code: 'BAG',
        desc: 'BAGS',
    },
    {
        code: 'BAL',
        desc: 'BALE',
    },
    {
        code: 'BDL',
        desc: 'BUNDLES',
    },
    {
        code: 'BKL',
        desc: 'BUCKLES',
    },
    {
        code: 'BOU',
        desc: 'BILLION OF UNITS',
    },
    {
        code: 'BOX',
        desc: 'BOX',
    },
    {
        code: 'BTL',
        desc: 'BOTTLES',
    },
    {
        code: 'BUN',
        desc: 'BUNCHES',
    },
    {
        code: 'CAN',
        desc: 'CANS',
    },
    {
        code: 'CCM',
        desc: 'CUBIC CENTIMETERS',
    },
    {
        code: 'CMS',
        desc: 'CENTIMETERS',
    },
    {
        code: 'CBM',
        desc: 'CUBIC METERS',
    },
    {
        code: 'CTN',
        desc: 'CARTONS',
    },
    {
        code: 'DOZ',
        desc: 'DOZENS',
    },
    {
        code: 'DRM',
        desc: 'DRUMS',
    },
    {
        code: 'GGK',
        desc: 'GREAT GROSS',
    },
    {
        code: 'GMS',
        desc: 'GRAMMES',
    },
    {
        code: 'GRS',
        desc: 'GROSS',
    },
    {
        code: 'GYD',
        desc: 'GROSS YARDS',
    },
    {
        code: 'KGS',
        desc: 'KILOGRAMS',
    },
    {
        code: 'KLR',
        desc: 'KILOLITRE',
    },
    {
        code: 'KME',
        desc: 'KILOMETRE',
    },
    {
        code: 'LTR',
        desc: 'LITRES',
    },
    {
        code: 'MLS',
        desc: 'MILLI LITRES',
    },
    {
        code: 'MLT',
        desc: 'MILILITRE',
    },
    {
        code: 'MTR',
        desc: 'METERS',
    },
    {
        code: 'MTS',
        desc: 'METRIC TON',
    },
    {
        code: 'NOS',
        desc: 'NUMBERS',
    },
    {
        code: 'OTH',
        desc: 'OTHERS',
    },
    {
        code: 'PAC',
        desc: 'PACKS',
    },
    {
        code: 'PCS',
        desc: 'PIECES',
    },
    {
        code: 'PRS',
        desc: 'PAIRS',
    },
    {
        code: 'QTL',
        desc: 'QUINTAL',
    },
    {
        code: 'ROL',
        desc: 'ROLLS',
    },
    {
        code: 'SET',
        desc: 'SETS',
    },
    {
        code: 'SQF',
        desc: 'SQUARE FEET',
    },
    {
        code: 'SQM',
        desc: 'SQUARE METERS',
    },
    {
        code: 'SQY',
        desc: 'SQUARE YARDS',
    },
    {
        code: 'TBS',
        desc: 'TABLETS',
    },
    {
        code: 'TGM',
        desc: 'TEN GROSS',
    },
    {
        code: 'THD',
        desc: 'THOUSANDS',
    },
    {
        code: 'TON',
        desc: 'TONNES',
    },
    {
        code: 'TUB',
        desc: 'TUBES',
    },
    {
        code: 'UGS',
        desc: 'US GALLONS',
    },
    {
        code: 'UNT',
        desc: 'UNITS',
    },
    {
        code: 'YDS',
        desc: 'YARDS',
    },
];
export const globalDocumentType = [
    { code: 'INV', desc: 'Invoice' },
    { code: 'DBN', desc: 'Debit Note' },
    { code: 'CRN', desc: 'Credit Note' },
];
export const globalconfirmOptions = [
    { label: 'Yes', code: 'Y' },
    { label: 'No', code: 'N' },
  ];
export const cancelReasonList = [
    { value: 1, label: 'Duplicate' },
    { value: 2, label: 'Data Entry Mistake' },
    { value: 3, label: 'Order Cancelled' },
    { value: 4, label: 'Others' },
];
export const fileDownloadStatusList = {
    'Complete': "COMPLETE",
    'InProgress': "INPROGRESS"
}
export const statusMappingList = [{ label: 'Active', value: 'ACT' }, { label: 'Cancelled', value: 'CNL' },{label:'Rejected',value:'REJ'}]
export const ewbStatusMappingList = [{ label: 'Active', value: 'ACT' }, { label: 'Cancelled', value: 'CNL' }]
export const invoiceMappingList = [{label: 'IRN Generated', value: 'IRN_GENERATED'},{ label: 'EWB Generated', value: 'IRN_EWB_GENERATED' },{ label: 'EWB Pending', value: 'IRN_GENERATED_EWB_PENDING' }]

export const captchaKey = {
    'ROBOT': '6LdafCUkAAAAADgIkTpIE2TOaxcMGfwwzZKkWQy_',
    'INVISIBLE': '6LfhVSEkAAAAAGmw982zTMoXMrzYzGbvb2qgVkOs'
}
export const salesOverview = {
    NoDataFound: 'No data found'
}
export const gstinMsg ={
    NoDataFound: 'No Data Found'
}
export const GAEventsMap = {
    "buyerName": "View_Filter_CounterpartyName",
    "ctin": "View_Filter_CounterpartyGSTIN",
    "type": "View_Filter_DocType",
    "invType": "View_Filter_InvoiceType",
    "no": "View_Filter_DocNo",
    "st": "View_Filter_IRNStatus",
    "irn": "View_Filter_IRNNo",
    "ackNo": "View_Filter_ACKNo",
    "docDtf": "View_Filter_DocDate",
    "docDtt": "View_Filter_DocDate"
}
export const GAEventsViewSharedPurchaseMap = {
    "sellerName": "MyPurchases_Filter_CounterpartyName",
    "ctin": "MyPurchases_Filter_CounterpartyGSTIN",
    "type": "MyPurchases_Filter_DocType",
    "invType": "MyPurchases_Filter_InvoiceType",
    "no": "MyPurchases_Filter_DocNo",
    "st": "MyPurchases_Filter_IRNStatus",
    "irn": "MyPurchases_Filter_IRNNo",
    "ackNo": "MyPurchases_Filter_ACKNo",
    "docDtf": "MyPurchases_Filter_DocDate",
    "docDtt": "MyPurchases_Filter_DocDate"
}
export const MasterCodeTabs = {
    "stateCode": "StateCode",
    "countryCode": "CountryCode",
    "portCode": "PortCode",
    "currencyUqcCode": "CurrencyCode",
    "uqcCode": "UQCCode",
    "taxRate": "Taxrate",
    "pinCode": "Pincode",
    "pincodeStateMap": "PincodeStatemapping",
    "errorList": "ErrorList"
}
export const PreMasterCodeTabs = {
    "stateCode": "Pre_Login_StateCode",
    "countryCode": "Pre_Login_CountryCode",
    "portCode": "Pre_Login_PortCode",
    "currencyUqcCode": "Pre_Login_CurrencyCode",
    "uqcCode": "Pre_Login_UQCCode",
    "taxRate": "Pre_Login_Taxrate",
    "pinCode": "Pre_Login_Pincode",
    "pincodeStateMap": "Pre_Login_PincodeStatemapping",
    "errorList": "Pre_Login_ErrorList"
}
export const GAEventsConsentCheckbox = {
    "share": "Consent_Share",
    "store": "Consent_Store",
    "view": "Consent_View"
}
export const irnSection = {
    "step1" : "Transactional Details",
    "step2" : "Supplier Details",
    "step3" : "Recipient Details",
    "step4" : "Item Details",
    "step5" : "Total Values",
    "step6" : "EWB Detail",
    "step23" : "Supplier Details / Recipient Details"

}
export const errorMessage = [
    {
        "SN": 1,
        "ErrorCode": 2150,
        "Error Description": "Duplicate IRN",
        "FieldName": "Document No",
        "RespectiveSection": "Transactional Details",
        "Reason": "Attempting to register a document again which is already registered and IRN is generated"
    },
    {
        "SN": 2,
        "ErrorCode": 2163,
        "Error Description": "The document date should not be future date .",
        "FieldName": "Document Date",
        "RespectiveSection": "Transactional Details",
        "Reason": "Attempting to set future date for the document date"
    },
    {
        "SN": 3,
        "ErrorCode": 2173,
        "Error Description": "You have exceeded the limit of number of items",
        "FieldName": "Sr.No",
        "RespectiveSection": "Item Details",
        "Reason": "User has passed the requests payload with more number of li"
    },
    {
        "SN": 4,
        "ErrorCode": 2176,
        "Error Description": "HSN code(s)-{0} is invalid",
        "FieldName": "HSN",
        "RespectiveSection": "Item Details",
        "Reason": "Wrong HSN code is being passed"
    },
    {
        "SN": 5,
        "ErrorCode": 2195,
        "Error Description": "e-Commerce GSTIN is not registered as TCS-GSTIN",
        "FieldName": "GSTIN",
        "RespectiveSection": "Supplier Details",
        "Reason": "The eCommerce GSTIN passed does not belong to the type Tax Collector"
    },
    {
        "SN": 6,
        "ErrorCode": 2233,
        "Error Description": "Duplicate SI numbers are not allowed in line items",
        "FieldName": "Sr.No",
        "RespectiveSection": "Item Details",
        "Reason": "Duplicate serial number for the items has been sent in the item list."
    },
    {
        "SN": 7,
        "ErrorCode": 2240,
        "Error Description": "For Sl. No {0}, GST rate of tax is incorrect or not as notified",
        "FieldName": "GST Rate",
        "RespectiveSection": "Item Details",
        "Reason": "Wrong GST rate is passed for the specified item in the item list."
    },
    {
        "SN": 8,
        "ErrorCode": 2258,
        "Error Description": "Supplier GSTIN state code does not match with the state code passed in supplier details",
        "FieldName": "State",
        "RespectiveSection": "Supplier Details",
        "Reason": "The state code passed and first two digits of the GSTIN passed in the Supplier details do not match"
    },
    {
        "SN": 9,
        "ErrorCode": 2265,
        "Error Description": "Recipient GSTIN state code does not match with the state code passed in recipient details",
        "FieldName": "State",
        "RespectiveSection": "Recipient Details",
        "Reason": "The state code passed and first two digits of the GSTIN passed in the Recipient details do not match."
    },
    {
        "SN": 10,
        "ErrorCode": 2271,
        "Error Description": "SHIP TO - state code is not valid statecode for B2B, SEZ and Deemed Export transactions",
        "FieldName": "State",
        "RespectiveSection": "Recipient Details > Ship To",
        "Reason": "Invalid State code is passed under Ship To details for B2B, SEZ and Deemed export transaction"
    },
    {
        "SN": 11,
        "ErrorCode": 2272,
        "Error Description": "SHIP TO - PIN code cannot be 999999 for B2B, SEZ and Deemed Export transactions",
        "FieldName": "Pincode",
        "RespectiveSection": "Recipient Details > Ship To",
        "Reason": "PIN code under ShipTo Details has been passed as 999999 for B2B, SEZ and Deemed Export transactions"
    },
    {
        "SN": 12,
        "ErrorCode": 2278,
        "Error Description": "IRN is already generated and is cancelled for this Document number. The duplicate IRN cannot be generated for the same number.",
        "Reason": "    - ",
        "RespectiveSection": "Transactional Details",
        "FieldName": "Document No",
    },
    {        "SN": 13,
        "ErrorCode": 2284,
        "Error Description": "IRN cannot be generated for the document date which is prior to {0}",
        "FieldName": "Document Date",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
        "SN": 14,
        "ErrorCode": 2295,
        "Error Description": "IRN is already generated and registered with GSTN Lookup Portal by other IRP",
        "FieldName": "Document No",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
        "SN": 15,
        "ErrorCode": 2297,
        "Error Description": "Reverse charge supply is not allowed for ISD recipients",
        "FieldName": "Reverse Charge",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
        "SN": 16,
        "ErrorCode": 2298,
        "Error Description": "Recipient cannot be composition\/UIN taxpayer for Deemed Exports",
        "FieldName": "GSTIN",
        "RespectiveSection": "Recipient Details",
        "Reason": "    -"
    },
    {
        "SN": 17,
        "ErrorCode": 2299,
        "Error Description": "E-Commerce Operator GSTIN field should be empty when Tax Payer generates e-Invoice",
        "FieldName": "GSTIN",
        "RespectiveSection": "Supplier Details",
        "Reason": "    -"
    },
    {
        "SN": 18,
        "ErrorCode": 2301,
        "Error Description": "E-Commerce Operator GSTIN field should not be empty when e-Commerce operator generates e-Invoice",
        "FieldName": "GSTIN",
        "RespectiveSection": "Supplier Details",
        "Reason": "    -"
    },
    {
        "SN": 19,
        "ErrorCode": 3038,
        "Error Description": "{0} details Details:Pincode-{1} does not exists",
        "FieldName": "Pincode",
        "RespectiveSection": "Supplier Details / Recipient Details",
        "Reason": "    -"
    },
    {
        "SN": 20,
        "ErrorCode": 3039,
        "Error Description": "Pincode -{1} of {0} does not belong to his\/her State - {2}",
        "FieldName": "Pincode",
        "RespectiveSection": "Supplier Details / Recipient Details",
        "Reason": "    -"
    },
    {
        "SN": 21,
        "ErrorCode": 3047,
        "Error Description": "The HSN - {0} does not belong to Goods",
        "FieldName": "HSN",
        "RespectiveSection": "Item Details",
        "Reason": "    -"
    },
    {
        "SN": 22,
        "ErrorCode": 3048,
        "Error Description": "The HSN - {0} does not belong to Service",
        "FieldName": "HSN",
        "RespectiveSection": "Item Details",
        "Reason": "    -"
    },
    {
        "SN": 23,
        "ErrorCode": 3074,
        "Error Description": "GSTIN - {0} is cancelled and document date - {1} is later than de-registration date",
        "FieldName": "Document Date",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
        "SN": 24,
        "ErrorCode": 3075,
        "Error Description": "GSTIN- {0} is cancelled and de-registration date is not available",
        "FieldName": "Document Date",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
        "SN": 25,
        "ErrorCode": 3077,
        "Error Description": "GSTIN - {0} is cancelled and document date - {1} is earlier than registration date",
        "FieldName": "Document Date",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
        "SN": 26,
        "ErrorCode": 3078,
        "Error Description": "GSTIN- {0} is cancelled and registration date is not available",
        "FieldName": "GSTIN",
        "RespectiveSection": "Recipient Details",
        "Reason": "    -"
    },
    {
        "SN": 27,
        "ErrorCode": 3079,
        "Error Description": "For active GSTINs the document date should not be earlier than registration date",
        "FieldName": "Document Date",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
        "SN": 28,
        "ErrorCode": 5002,
        "Error Description": "The field e-Commerce GSTIN must be a string with a minimum length of 15 and a maximum length of 15.",
        "FieldName": "GSTIN",
        "RespectiveSection": "Supplier Details"
    },
    {
        "SN": 29,
        "ErrorCode": 5002,
        "Error Description": "The field e-Commerce GSTIN must match the regular expression ^([0-9]{2}[0-9A-Z]{13})$.",
        "FieldName": "GSTIN",
        "RespectiveSection": "Supplier Details"
    },
    {
        "SN": 30,
        "ErrorCode": 5002,
        "Error Description": "The field Phone Number must match the regular expression '^([0-9]{6,12})$'.",
        "FieldName": "Phone No",
        "RespectiveSection": "Supplier Details / Recipient Details"
    },
    {
        "SN": 31,
        "ErrorCode": 5002,
        "Error Description": "The field e-Mail must match the regular expression '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'.",
        "FieldName": "Email Id",
        "RespectiveSection": "Supplier Details / Recipient Details"
    },
    {
        "SN": 32,
        "ErrorCode": 5002,
        "Error Description": "The field Phone Number must match the regular expression '^([0-9]{6,12})$'.",
        "FieldName": "Phone No",
        "RespectiveSection": "Supplier Details / Recipient Details"
    },
    {
        "SN": 33,
        "ErrorCode": 5002,
        "Error Description": "The field e-Mail must match the regular expression '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'.",
        "FieldName": "Email Id",
        "RespectiveSection": "Supplier Details / Recipient Details"
    },
    {
        "SN": 34,
        "ErrorCode": 5002,
        "Error Description": "The field Sl No. must match the regular expression '^[0-9]*$'.",
        "FieldName": "Sr.No",
        "RespectiveSection": "Item Details"
    },
    {
        "SN": 35,
        "ErrorCode": 2279,
        "Error Description": "Reverse charge supply is not allowed for ISD recipients",
        "FieldName": "Reverse Charge",
        "RespectiveSection": "Transactional Details",
        "Reason": "    -"
    },
    {
      "SN": 36,
      "ErrorCode": 2174,
      "Error Description": "For inter-state transaction, CGST and SGST amounts are not applicable; only IGST amount is applicable",
      "FieldName": "State",
      "RespectiveSection": "Supplier Details / Recipient Details",
      "Reason": "CGST and SGST taxes are being passed for inter state transactions"
    },
    {
      "SN": 37,
      "ErrorCode": 2172,
      "Error Description": "For Intra-state transaction, IGST amount is not applicable; only CGST and SGST amounts are applicable",
      "FieldName": "State",
      "RespectiveSection": "Supplier Details / Recipient Details",
      "Reason": "IGST amount is being passed for Intra State transaction where as CGST and SGST are applicable."
    }
]
export const EInvoiceEnable = {
    "notEnabled": "E-Invoice is NOT ENABLED for this Taxpayer."
}
export const roleAccessMap = {
    NOT_REGISTERED: {
      cannotView: ['sandbox', 'production', 'home','report'],
      toastMsg: 'Kindly Register your GSTIN',
    },
    SANDBOX_GRANTED: {
      cannotView: ['production', 'home','report'],
      toastMsg: 'Kindly add Test GSTINs',
    },
    TEST_GSTIN_ADDED: {
      cannotView: ['production', 'home','report'],
      toastMsg: 'Kindly complete your Production Application',
    },
    PROD_APPLICATION_SUBMITTED: {
      cannotView: ['home'],
      toastMsg: 'Your Production application is yet to be approved',
    },
    PROD_APPLICATION_APPROVED: {
      cannotView: [],
      toastMsg: '',
    },
  };
export const mastersTypesMap = {
    recipient: "RECIPIENT",
    transporter: "TRANSPORTER",
    productAndService: "PRODUCT_SERVICE"
}
export const tokenErrorMessages = [
    'Token has been expired',
    'Invalid Authentication Token.',
    'Token expired.'
]
