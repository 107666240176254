<div class="col-12 font-size-14 top-space-55">
    <div *ngFor="let news of news">
            <marquee class="marqueeClass">
                {{news.newsBody}} <a style="color: #007ad9; font-size: 14px"
                *ngIf="news.moreInfoExternalLink" [href]="news.moreInfoExternalLink" target="_blank"
               >from here</a>
            </marquee>
    </div>
    <div *ngIf="news.length === 0">
        <div class="marqueeCl">
        </div>
    </div>
  </div>