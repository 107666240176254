<!-- <nav class=" navbar-expand-lg">
  <div class="custom_container">
    <img src="../../../../assets/images/iris-reverse-white-logo.png" class="logo" />
  </div>
</nav> -->

<div class="">
  <div  *ngIf="isNavbarLoginVisible">
    <app-auth-header></app-auth-header>
  </div>
  <div *ngIf="!isNavbarLoginVisible">
    <app-auth-login></app-auth-login>
  </div>
  <!-- <app-auth-login></app-auth-login> -->
  <!-- Marquee moved to header-->
  <!-- <div class="col-12 font-size-12 top-space-55">
    <marquee class="marquee-body" *ngIf="marquee && marquee.newsBody">
      {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}}
    </marquee>
  </div> -->
  <!-- <div class="custom_container mt-3 px-3">
    <div [class.marquee-space]="!marquee ">
       <app-common-gstin-dropdown></app-common-gstin-dropdown>

    </div>

  </div> -->

  <router-outlet></router-outlet>
</div>

<div class="container-fluid" id="increaseFont" style="background-color: #faf9fa;"
  *ngIf="!formList.showResetPasswordForm">
  <div class="row align-items-center banner-sidebar customVh">
    <!-- <div class="col-lg-1"></div> -->
    <div class="col-lg-5  text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12 px-lg-5 text-lg-start">
          <h6>IRIS IRP for an easy, efficient and enhanced E-invoicing journey</h6>
          <ul>
            <li>Manage e-invoicing for multiple GSTINs from single login</li>
            <li>Bulk IRN generation as well as cancellation</li>
            <li>Multiple modes for generation – API, Web and Desktop Utility</li>
            <li>Simple and intuitive UI designed for business teams</li>
          </ul>
          <h6>Sign-up and explore the value-added services, extending beyond e-invoicing</h6>
        </div>
      </div>
    </div>
    <!--login Form-->
    <div class="col-lg-7">
      <div class="signup_form">
        <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
          <div class="card-body" *ngIf="!formList.showForgotPassword && formList.showLogin">
            <form [formGroup]="loginForm">
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">Log In</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <b>Welcome to IRIS E-Invoicing Portal</b>
                </div>
                <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" [badge]="'bottomleft'" (reset)="handleReset()"
                  (success)="handleSuccess($event)" (expire)="handleExpire()" (error)="handleError()"
                  [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }" (ready)="handleReady()"
                  (load)="handleLoad()">
                </ngx-invisible-recaptcha>
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput2" class="form-label"><b>Email id / Mobile</b></label>
                  <input type="text" class="form-control" id="exampleFormControlInput2" formControlName="userId"
                    placeholder="Email Id / Mobile" required>
                  <div class="error"
                    *ngIf="loginForm.controls['userId'].hasError('required') && (loginForm.controls['userId'].dirty || loginForm.controls['userId'].touched)">
                    Email id / Mobile is required</div>
                  <div class="error"
                    *ngIf="loginForm.controls['userId'].hasError('pattern') && (loginForm.controls['userId'].dirty || loginForm.controls['userId'].touched)">
                    Please enter valid email address or phone number</div>
                </div>
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput1" class="form-label"><b>Password</b></label>
                  <div class="input-icons">
                    <i class="bi bi-eye icon" *ngIf="show" (click)="onClick()"></i>
                    <i class="bi bi-eye-slash icon" *ngIf="!show" (click)="onClick()"></i>
                    <input type="password"  (input)="checkCaptcha()" class="form-control" id="exampleFormControlInput1" [type]="passwords"
                      placeholder="Password" formControlName="password" required>
                  </div>
                  <div *ngIf="!password?.valid && (password?.dirty ||password?.touched)">
                    <div class="error" [hidden]="!password?.errors?.required">
                      Password is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="custom_checkbox form-check-label">Remember me
                    <input type="checkbox" name="rememberMe" formControlName="rememberMe" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-md-6 mb-3 text-end">
                  <span class="text-primary"><a href="javascript:;" (click)="forgotPasswordFormShow()"
                      class="text-decoration-none custom-text anchorLink">Forgot
                      Password?</a></span>
                </div>
                <div class="mb-3 row" *ngIf="captchaVisible1">
                  <!-- <label for="gstin" class="col-sm-3 col-form-label"></label> -->
                  <label for="gstin" class="col-sm-5 text-center font-size-30 captchaTxt">{{captchString}}</label>
                  <!-- <label *ngIf="!setCaptch">{{this.captchString}}</label> -->
                  <div class="col-sm-1">
                    <button title="Refresh Data" class="btn btn-sm custom-icon-button p12"
                      (click)="generateRandomString(5)">
                      <!-- <i class="bi bi-arrow-clockwise "></i> -->
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                          fill="#252525" />
                      </svg>
  
                    </button>
                  </div>
                  <div class="col-sm-5" *ngIf="captchaVisible1">
                    <input type="text" class="form-control ml15" id="gstin" name="gstin"
                    formControlName="captcha" placeholder="Enter Captcha">
                  </div>
  
  
  
                </div>
                <div class="col-12 my-3 text-center">
                  <button class="btn btn-dark px-5 py-2" (click)="captchaElem?.execute();login('login')">Login</button>
                </div>
                <div class="col-12 text-center">
                  <p>Not a member? <span class="text-primary"><a routerLink="/signup"
                        class="text-decoration-none custom-text anchorLink">Sign up now!</a></span></p>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body" *ngIf="formList.showForgotPassword">

            <form [formGroup]="forgotPasswordForm">
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">Forgot Password</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <b>Share your Email ID/Mobile registered with IRIS IRP to reset password</b>
                </div>
                <div class="col-12 mb-3">
                  <label for="email" class="form-label"><b>Email Id/ Mobile</b></label>
                  <input placeholder="Email Id / Mobile" type="email"  (input)="checkCaptchaForgotPassword()" name="email" class="form-control"
                    formControlName="userId" />
                  <div class="error"
                    *ngIf="forgotPasswordForm.controls['userId'].hasError('required') && (forgotPasswordForm.controls['userId'].dirty || forgotPasswordForm.controls['userId'].touched)">
                    Email id / Mobile is required</div>
                  <div class="error"
                    *ngIf="forgotPasswordForm.controls['userId'].hasError('pattern') && (forgotPasswordForm.controls['userId'].dirty || forgotPasswordForm.controls['userId'].touched)">
                    Please enter valid email address or phone number</div>
                </div>
                <div class="mb-3 row" *ngIf="captchaVisibleForgotPas">
                  <!-- <label for="gstin" class="col-sm-3 col-form-label"></label> -->
                  <label for="gstin" class="col-sm-5 text-center font-size-30 captchaTxt">{{captchStringForgetPass}}</label>
                  <!-- <label *ngIf="!setCaptch">{{this.captchString}}</label> -->
                  <div class="col-sm-1">
                    <button title="Refresh Data" class="btn btn-sm custom-icon-button p12"
                      (click)="generateRandomStringForgetPass(5)">
                      <!-- <i class="bi bi-arrow-clockwise "></i> -->
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                          fill="#252525" />
                      </svg>
  
                    </button>
                  </div>
                  <div class="col-sm-5" *ngIf="captchaVisibleForgotPas">
                    <input type="text" class="form-control ml15" id="gstin" name="gstin"
                    formControlName="captcha" placeholder="Enter Captcha">
                  </div>
  
  
  
                </div>
                <div class="col-12 my-3 text-center">
                  <button type="submit" class="btn btn-dark px-5 py-2" [disabled]="!forgotPasswordForm.controls['userId'].valid" (click)="generateForgotPasswordOTP()" >Generate
                    OTP</button>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body" *ngIf="formList.showOTPVerificatonForm">

            <form [formGroup]="OTPForm" (ngSubmit)="onSubmit()">
              <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">OTP Verification</h2>
              <div class="row">
                <div class="col-12 mb-5">
                  <b>We've sent OTP on :{{forgotPasswordForm.value.userId}}</b>
                </div>
                <div class="col-12 mb-3">
                  <label for="exampleFormControlInput2" class="form-label"><b>OTP</b></label>
                  <input placeholder="OTP" type="otp" class="form-control" name="otp" formControlName="otp"
                    minLength="6" onKeyPress="if(this.value.length==6) return false;">
                  <small class="text-muted">OTP will remain valid for 10 mins</small>
                </div>
                <div class="col-12 my-3 text-center">
                  <button type="submit" [disabled]="!OTPForm.controls['otp'].valid" class="btn btn-dark px-5 py-2">Validate OTP</button>
                </div>
                <div class="col-12 text-center">
                  <p class="mb-0">Didn't receive an OTP? </p>
                  <span class="text-primary"><a (click)="resendOTP()"
                      class="text-decoration-none custom-text anchorLink mt-2 cursor-pointer">Resend
                      OTP</a></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>
<div class="container-fluid" style="background-color: #faf9fa;" *ngIf="formList.showResetPasswordForm">
  <div class="row align-items-center banner-sidebar customVh">

    <app-auth-header></app-auth-header>
    <div class="col-lg-5  text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12 px-lg-5 text-lg-start ">
          <h4>IRIS IRP for an easy, efficient and enhanced E-invoicing journey</h4>
          <ul>
            <li>Manage e-invoicing for multiple GSTINs from single login</li>
            <li>Bulk IRN generation as well as cancellation</li>
            <li>Multiple modes for generation – API, Web and Desktop Utility</li>
            <li>Simple and intuitive UI designed for business teams</li>
          </ul>
          <h5>Sign-up and explore the value-added services, extending beyond e-invoicing</h5>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="signup_form">
        <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
          <div class="card-body">
            <div class="content-center">Your Password has expired. Reset your password from here</div>
            <form [formGroup]="setPasswordForm">

              <h2 class="card-title mb-5" style="font-weight: 700 !important; color: #fff;">Reset Password</h2>
              <div class="row">
                <div class="col-12 small text-muted mb-3">
                  <i class="bi bi-info-circle-fill"></i> Password shall have:
                  <ul>
                    <li>Minimum 8 and Maximum 16 characters required</li>
                    <li>Atleast one character in uppercase and lowercase required</li>
                    <li>Atleast one special character required</li>
                    <li>Atleast one numeric value required</li>
                  </ul>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Old Password <span style="color: red">*</span></label>
                  <input type="password" class="form-control" name="oldPassword" formControlName="oldPassword"
                    placeholder="">
                  <div
                    *ngIf="!setPasswordForm.get('oldPassword')?.valid && (setPasswordForm.get('oldPassword')?.dirty ||setPasswordForm.get('oldPassword')?.touched)">
                    <div class="error" [hidden]="!setPasswordForm.get('oldPassword')?.errors?.required">
                      Old Password is required
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">New Password <span style="color: red">*</span></label>
                  <!-- <input type="password" class="form-control" id="exampleFormControlInput2" placeholder="" required> -->
                  <div class="col-12 position-relative">
                    <div class="custom_input">
                      <input type="password" class="form-control" name="password" formControlName="password"
                        (ngModelChange)="passwordMatched = true" placeholder="********">
                      <div *ngIf="setPasswordForm.get('password')!.invalid" class="text-danger m-1">
                        <p class="m-0 text-danger font-size-12" *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value === ''
                                            ">Password is required
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['minlength']
                                            " class="m-0 text-danger font-size-12">* Your password must have minimum 8
                          characters
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['maxlength']
                                        " class="m-0 text-danger font-size-12">* Your password must have maximum 16
                          characters long
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasCapitalCase']
                                            " class="m-0 text-danger font-size-12">* Your password should contain at
                          least 1 capital letter
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasSpecialCharacters']
                                            " class="m-0 text-danger font-size-12">* Your password should contain at
                          least 1 special character
                        </p>
                        <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasNumber']
                                            " class="m-0 text-danger font-size-12">* Your password should contain at
                          least 1 number
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="col-12 mb-3">
                      <label class="form-label">Confirm Password <span style="color: red">*</span></label>
                    </div>
                    <div class="col-12 position-relative">
                      <div class="custom_input">
                        <input type="password" class="form-control" name="confirmPassword"
                          formControlName="confirmPassword" (ngModelChange)="passwordMatched = true"
                          placeholder="********">
                      </div>
                      <div *ngIf="!passwordMatched">
                        <span class="font-size-12 text-danger">Password doesn't Match.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-3 text-center">
                  <button type="submit" class="btn btn-dark px-5 py-2 mouse-pointer"
                    [disabled]="!setPasswordForm.value.password || !setPasswordForm.value.confirmPassword"
                    (click)="resetPassword()">Reset Password</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div style="padding: 70px 30px 10px;;background-color: #fafafa;">
  <hr/>
  <div class="container">
      <div class="row">
          <div class="col-md-4">
              <h6>Our products</h6>
              <ul style="list-style: none; padding-left: 10px;">
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                      margin-right: 5px;"></i> E-Invoicing for
                      Taxpayers
                  </li>
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                      margin-right: 5px;"></i> E-Invoice APIs
                      for
                      Solution
                      Providers
                  </li>
              </ul>
              <h6>Find us here</h6>
              <i class="bi bi-twitter"></i>
              <i class="mx-2 bi bi-linkedin"></i>
          </div>
          <div class="col-md-4">
              <h6>Resources</h6>
              <ul style="list-style: none; padding-left: 10px;">
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                      margin-right: 5px;"></i>Notifications
                  </li>
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                      margin-right: 5px;"></i> Blog
                  </li>
              </ul>
          </div>
          <div class="col-md-4">
              <h6>Contact us</h6>
              <ul style="list-style: none; padding-left: 0px;">
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                      Maharashtra, India
                  </li>
                  <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                      <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                      <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                  </li>
              </ul>
          </div>
          <div class="col-12 mt-4">
              <h6>© 2022 IRIS Business Services Ltd. All Rights Reserved. | <a (click)="redirectToPrivacy()"  href="javascript:;">Privacy Policy</a> |  <a (click)="redirectToTerms()" href="javascript:;">Terms &
                  Conditions</a></h6>
          </div>
      </div>
  </div>
</div> -->

<div class="container-fluid footer">
  <div class="row footerDiv">
    <div class="col-md-9">
      <h3 class="display-4 text-white">IRIS IRP is govt authorised E-Invoice Registration Portal</h3>
    </div>
    <div class="col-md-3">
      <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank"
        class="btn btn-primary rounded mb-0 text-nowrap">Contact Us</a>
    </div>
    <hr class="hrdiv">
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">Important links</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.gstn.org.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://cbic-gst.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">CBIC</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/notifications/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GST
            notifications</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.india.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">National
            Portal</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf"
            target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN
            Authorization of IRIS IRP</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            IRP Sandbox</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">IRIS IRP Policies</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">Terms and Condition</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/privacy/">Privacy Policy</a>
        </li>
        <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Disclaimer
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Grievance Redressal
        </li> -->
      </ul>

    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">About IRIS</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            IRP</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            GST</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisbusiness.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            Business</a>
        </li>

      </ul>
      <h4 class="widget-title text-white mb-3">Need Help?</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="mailto:support@irisirp.com">Write to: support@irisirp.com</a>
        </li>
        <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Feedback
        </li> -->
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">Other Products</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a
            href="https://irisgst.com/irissapphire/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            GST Software</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-onyx/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            E-Invoicing</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/gst-litigation-management-system/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            Litigation Management</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iristopaz/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            E-Way Bill</a>
        </li>

        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-lookup/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            LookUp</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/irisperidot/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            Peridot</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://easywaybill.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">EWB
            app for transporters</a>
        </li>
      </ul>
    </div>
    <hr class="hrdiv mb10">
    <div class="row">
      <div class="col-md-3">
        <div class="social social-white social-center">
          <span><a href="https://twitter.com/IRIS_IRP" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-twitter"></i></a></span>
          <span><a href="https://www.linkedin.com/showcase/82795027/admin/" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-linkedin"></i></a></span>
          <span><a href="https://www.youtube.com/@irisirp" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-youtube"></i></a></span>
        </div>
      </div>
      <div class="col-md-6">
        <p class="text-center text-white">© 2023 IRIS Business Services Ltd. All rights reserved.</p>
      </div>
      <div class="col-md-3 social-center">
        <span><img class="responsive" style="height: 40px;width: 100px;"
            src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png"
            alt=""></span>

      </div>
    </div>
  </div>
</div>