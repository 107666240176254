import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient, private sharedService: SharedService) { }

  /**
   * Fetch added gstins list
   * @returns gstins List
   */
  getGstinsList(): any {
    return this.http
      .get(`${environment.baseUrl}/gstin/detail`)
      .toPromise();
  }
  getGSTINDetail(gstin) {
    return this.http
      .get(`${environment.baseUrl}/gstin/detail?gstin=${gstin}`)
  }

  /**
   * Fetch registered gstins
   * @returns gstin number list
   */
  getGstinsNumberList(): any {
    return this.http
      .get(`${environment.baseUrl}/gstin/list`)
  }
  getGSTINListForLogo(): any {
    return this.http
      .get(`${environment.baseUrl}/gstin/detail`)
  }

  /**
   * Change Irp password for that gstin
   * @param passwordDetails old and new password
   * @returns change password response
   */
  changeIrpPassword(passwordDetails: any): any {
    return this.http
      .post(
        `${environment.baseUrl}/gstin/change/irp/password`,
        passwordDetails,
        {

        }
      )
      .toPromise();
  }

  /**
   * manage api access for selected gstins
   * @param apiDetails intermediary details for gstins
   * @returns api access response
   */
  manageApiAccess(apiDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/gstin/manage/api/access`, apiDetails)
      .toPromise();
  }

  /**
   * Fetches Registered gstins details
   * @returns registered GSTIN details
   */
  getRegisteredGstinDetails(): any {
    return this.http
      .get(`${environment.baseUrl}/gstin/registered/detail`)
      .toPromise();
  }

  /**
   * Freezez the gsp for gstin from api access
   * @param gspDataModel gsp data to be freeze
   * @returns freeze response
   */
  freezeGspApiAccess(gspDataModel: any): any {

    return this.http
      .post(`${environment.baseUrl}/gstin/freeze/gsp`, gspDataModel)
      .toPromise();
  }

  /**
   * Invite user to manage gstin
   * @param invite user invitation data
   * @returns invite response
   */
  inviteUser(invite: any): any {
    return this.http
      .post(`${environment.baseUrl}/user/invite`, invite)
      .toPromise();
  }

  /**
   * Fetch invited users list
   * @returns invited user list
   */
  getInvitedUserList(): any {
    return this.http
      .get(`${environment.baseUrl}/user/invite/list`)
      .toPromise();
  }

  /**
   * delete invited user role
   * @param userEmail user email
   * @returns remove response
   */
  deleteUserRole(userEmail: any): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .delete(`${environment.baseUrl}/user/invite/role/delete?userId=${userEmail.userId}&gstin=${userEmail.gstin}`,
        {
          headers: headers
        })
      .toPromise();
  }

  /**
   * Update the user role
   * @param roleDetail updated role and email
   * @returns updated response
   */
  updateUserRole(roleDetail: any): any {
    return this.http
      .post(`${environment.baseUrl}/user/invite/change/role`, roleDetail)
      .toPromise();
  }
  uploadLogo(gstin, formData) {
    return this.http
      .post(`${environment.baseUrl}/gstin/add/logo?gstin=${gstin}`, formData)
  }
  getLogo(gstin) {
    return this.http
      .get(`${environment.baseUrl}/gstin/logo?gstin=${gstin}`)
  }
}
