import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-component',
  templateUrl: './content-component.component.html',
  styleUrls: ['./content-component.component.scss']
})
export class ContentComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href = 'https://einvoice6.gst.gov.in/content/';
  }

}
