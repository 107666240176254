import { Component, OnInit } from '@angular/core';
import { ApiIntegratorDashboardService } from '../../services/api-integrator-dashboard.service';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrls: ['./marquee.component.scss']
})
export class MarqueeComponent implements OnInit {
  newsList: any = [];
  newsData = [];
  news = [];
  constructor(private apiDashboardService: ApiIntegratorDashboardService) { }

  ngOnInit(): void {
    this.getNewsData();
  }
  getNewsData() {
    const newsResponse = this.apiDashboardService.getNews().subscribe(res => {
      this.newsList = res;

      const newsMarquee = this.newsList.response.marquee;
      this.newsData = this.newsList.response.marquee;
      for (let i = 0; i < this.newsData.length; i++) {
        if(this.newsData[i].userType === 'API_INTEGRATOR'){
          this.news.push(this.newsData[i]);
          break;

        }
      }
    })

  }

}
