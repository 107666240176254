import { Component, OnInit } from '@angular/core';
import { globalInvoiceTypes, globalTypeListForPrint, invoiceTypeMapping, QRCodeSettings } from 'src/app/app.config';
import { API_STATUS } from 'src/app/constant';
import * as moment from 'moment';
import { states } from 'src/app/app.config';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';


@Component({
  selector: 'app-generic-verify-einvoice',
  templateUrl: './generic-verify-einvoice.component.html',
  styleUrls: ['./generic-verify-einvoice.component.scss']
})
export class GenericVerifyEinvoiceComponent implements OnInit {

  supplyTypesMap = globalInvoiceTypes;
  typeList = globalTypeListForPrint;

  optionValues = {
    'Y': 'Yes',
    'N': 'No'
  }
  activeTab = 'verifySignedEInvoice';
  logoImg: any;
  isSubmit = false;
  showResults;
  qrCode
  qrcodeData: any;
  isErrorPresent: boolean;
  errorMessage: any;
  invTypeMap = invoiceTypeMapping;
  file: any;
  selectedFile: any;
  printDetailResponse: any;
  currentDate: string;
  statesMap = states;
  isDispatchEmpty: any;
  showDispatchDetails: boolean;
  isShipEmpty: any;
  showShipDetails: boolean;
  elementType = QRCodeSettings.ELEMENT_TYPE;
  correctionLevel = QRCodeSettings.CORRECTION_LEVEL;
  qrWidth = QRCodeSettings.WIDTH;
  constructor(private eInvoiceService: EInvoiceService,private googleAnalyticsService:GoogleAnalyticsService) { }
  setCaptch:any;
  ngOnInit(): void {
    this.getCurrentDate();
    this.setCaptch = localStorage.getItem('token');
  }


  uploadFile() {
    let data = new FormData();
    data.append('file', this.selectedFile);
    this.eInvoiceService.verifySignedInvoice(data).subscribe(res => {
      this.showResults = true;
      if (res && res['status'] == API_STATUS.error) {
        this.isErrorPresent = true;
        this.errorMessage = res['message'];
      } else {
        this.isErrorPresent = false;
        const printResponse = res;
        
        if (printResponse && printResponse['response']) {
          this.printDetailResponse = printResponse['response'];
          this.trackAnalytics("VerifySignedInvoice_Submit")
          if (this.printDetailResponse && this.printDetailResponse.DispDtls) {
            this.isDispatchEmpty = this.isWholeJSONObjectEmpty(this.printDetailResponse.DispDtls);
            if (this.isDispatchEmpty == true) {
              this.showDispatchDetails = false;
            } else {
              this.showDispatchDetails = true;
            }
          } else {
            this.showDispatchDetails = false;
          }

          // Check for Ship Details
          if (this.printDetailResponse && this.printDetailResponse.ShipDtls) {
            this.isShipEmpty = this.isWholeJSONObjectEmpty(this.printDetailResponse.ShipDtls);
            if (this.isShipEmpty == true) {
              this.showShipDetails = false;
            } else {
              this.showShipDetails = true;
            }
          } else {
            this.showShipDetails = false;
          }


        }
      }

    })
  }
  isWholeJSONObjectEmpty(jsonObj) {

    const allValsEmpty = !Object.keys(jsonObj).some(k => jsonObj[k] !== "");

    return allValsEmpty;

  }
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
 

  formatDate(inputDate, inputFormat, outputFormat) {
    if (inputDate) {
      var date = moment(inputDate, inputFormat)
      return date.format(outputFormat)
    }
    return null;

  }
 
  resetEinvoice()
  {
    this.file = null;
    this.showResults = false;
  }
  getCurrentDate() {
    this.currentDate = moment().format('DD-MM-YYYY');
  }
  getSupplyTypeName(val) {
    let matchSupply = this.supplyTypesMap.find(supply => supply.value == val);
    if (matchSupply) {
      return matchSupply.label.split('-')[1].trim();
    }
    return null;
  }
  getDocumentType(docType) {
    if (docType) {
      const matchingType = this.typeList.find(type => type.value == docType);
      if (matchingType) {
        return matchingType.label;
      }

    }
    return null;
  }
  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
  
}