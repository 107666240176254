<app-auth-header></app-auth-header>


<div class="container-fluid">
    <div class="row backgroudBox">
        <div class="col-12">
            <h1 class="h1Heading">Have a query? <span class="highlight-heading spanHighlight"><br>We are just a mail
                    away! </span></h1>
        </div>
    </div>
    <div class="container">
        <div class="row mt-3">
            <div class="col-12 text-center">
                <h5 class="h5Content">If you have any questions regarding IRIS
                    IRP
                    or
                    would want to request a free consultation, please contact us,<span class="highlight-heading h5span"
                   > and we’ll respond as soon as we can.</span>
                </h5>
            </div>
            <div class="col-12 mt-5 px-5">
                <div class="row align-items-center">
                    <div class="row align-items-center">
                        <div class="col-md-3">

                        </div>
                        <div class="col-md-7">
                            <div class="input-fields inputArea mb-3"
                                >
                                <input type="text" id="First_Name" name="First Name" maxlength="40"
                                    placeholder="First Name"
                                    class="inputText">
                            </div>
                            <div class="input-fields inputArea mb-3">
                                <input type="text" id="Last_Name" name="Last Name" maxlength="40"
                                    placeholder="Last Name" class="inputText">
                            </div>
                            <div class="input-fields inputArea mb-3">
                                <input type="text" id="Email" name="Email" maxlength="40" placeholder="Email"
                                class="inputText">
                            </div>
                            <div class="input-fields inputArea mb-3">
                                <input type="text" id="Phone" name="Phone" maxlength="40" placeholder="Phone"
                                class="inputText">
                            </div>
                            <div class="input-fields inputArea mb-3">
                                <input type="text" id="Company" name="Company" maxlength="40" placeholder="Company"
                                class="inputText">
                            </div>
                            <!-- <div class="g-recaptcha" data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ"></div> -->


                            <div class="input-fields inputTextArea mb-3">
                                <input type="text" id="Comments" name="Comments" placeholder="Comments"
                                    class="textAreaBox">
                            </div>

                        </div>
                        <div class="col-md-2">

                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <div  class="captcha captchBlock">
                                <div class="spinner">
                                    <label>
                                        <input type="checkbox" onclick="$(this).attr('disabled','disabled');">
                                        <span class="checkmark"><span>&nbsp;</span></span>
                                    </label>
                                </div>
                                <div class="text">
                                    I'm not a robot
                                </div>
                                <div class="logo">
                                    <img src="https://www.gstatic.com/recaptcha/api2/logo_48.png" />
                                    <p>reCAPTCHA</p>
                                    <small>Privacy - Terms</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6"></div>

                    </div>
                    <div class="row mt-3 align-items-center">
                        <div class="col-md-6"></div>
                        <div class="col-md-4">
                            <div class="input-fieldss btnSubmit mb-3">
                                <button class="btnClass">Submit</button>
                            </div>
                        </div>
                        <div class="col-md-2"></div>

                    </div>
                </div>
            </div>
            <div class="col-12 mt-5 px-5">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="row align-items-center">
                            <h4 class="mt-4 h4Heading"> Address</h4>
                            <h5><span class="h5DetailContent"> T231, Tower 2, Third floor, International Infotech Part
                                    Vashi Navi Mumbai 400703
                                    Maharashtra, India.</span>
                            </h5>


                            <h4  class="mt-4 h4Heading"> Phone</h4>
                            <h5><span class="h5DetailContent"> +91 22 6723 1000</span>
                            </h5>
                            <h4 class="mt-4 h4Heading"> Email</h4>
                            <h5><span class="h5DetailContent"> hello@irisirp.com</span>
                            </h5>
                            <h4  class="mt-4 h4Heading"> Corporate Website</h4>
                            <h5><span class="h5DetailContent"> https://irisirp.com </span>
                            </h5>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.0123412608405!2d72.99413452695313!3d19.063194999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14dedbc52b9%3A0x4c80eb1fdf166556!2sIRIS%20Business%20Services%20Limited!5e0!3m2!1sen!2sin!4v1675744404883!5m2!1sen!2sin"
                        width="100%" height="390" class="mapContent" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="padding: 70px 30px 10px;;background-color: #fafafa;">
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h6>Our products</h6>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoicing for
                        Taxpayers
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoice APIs
                        for
                        Solution
                        Providers
                    </li>
                </ul>
                <h6>Find us here</h6>
                <i class="bi bi-twitter"></i>
                <i class="mx-2 bi bi-linkedin"></i>
            </div>
            <div class="col-md-4">
                <h6>Resources</h6>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                        margin-right: 5px;"></i>Notifications
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> Blog
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <h6>Contact us</h6>
                <ul style="list-style: none; padding-left: 0px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                        Maharashtra, India
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                        <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-4">
                <h6>© 2022 IRIS Business Services Ltd. All Rights Reserved. | <a routerLink="/privacy">Privacy
                        Policy</a> | <a routerLink="/terms-conditions">Terms &
                        Conditions</a></h6>
            </div>
        </div>
    </div>
</div>