<div class="card custom-card border-0 shadow mb-4">
    <div class="card-body">
        <h4 class="mb-4 news-subscription">News &amp; Updates </h4>
        <div style="overflow: auto;">
            <div *ngFor="let news of newsData">
                <div class="" *ngIf="news.userType == 'API_INTEGRATOR'">
                    <div class="col-6 " *ngIf="news.moreInfoImageLink">
                        <img [src]="news.moreInfoImageLink" class="news-img" />
                      </div>
                    <div>
                        <div>
                            <h6><b>{{news.newsHeader}}</b>
                                <span *ngIf="news.newsType == 'IMPORTANT'"
                                  class="vertical-center-badge badge bg-warning custom-badge"> New </span>
              
                              </h6>
                            <!-- <h6>{{news.newsHeader}}</h6><span *ngIf="news.newsType == 'IMPORTANT'"
                                class="vertical-center-badge badge bg-warning custom-badge ">
                                New </span> -->

                        </div>
                        <div id="newsTbl">

                            </div>
                        <span class="mb-3 text-break sub-text" [innerHTML]="news.newsBody">
                            
                            {{news.newsBody}}
                        </span>
                        <div class="col-12 " *ngIf="news.moreInfoExternalLink !== null"><a target="_blank" class="font-size-14"
                                 href="{{news.moreInfoExternalLink}}">Know more</a>
                        </div>
                        
                        <!-- <div class="col-6 " *ngIf="news.moreInfoImageLink"><img class="news-img" 
                                [src]="news.moreInfoImageLink">
                        </div> -->
                        <!-- <div class="col-6 "><img class="news-img" *ngIf="news.newsType == 'IMPORTANT'"
                                src="https://irisirp.com/wp-content/uploads/2022/06/IRISIRP-Logo-1.png">
                        </div> -->
                    </div>
                    <hr>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="card border-0 custom-card shadow mb-4">
    <div class="card-body">
        <h4 class="mb-4 news-subscription">Videos & Tutorials</h4>
        <iframe width="100%" height="200" src="https://www.youtube.com/embed/ypQp_B5R7ow">
        </iframe>
    </div>
</div>