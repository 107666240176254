<app-auth-header></app-auth-header>
<div class="container-fluid">
    <div class="row backgroudBox">
        <div class="col-12">
            <h1 class="h1Heading">Terms & Conditions</h1>
        </div>
    </div>
</div>
<div class="container">
    <div id="section1">
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">Our Customer Terms of Service govern our customers’ use of the IRIS
                        IRP
                        Solutions, Website, Content, Product Subscriptions, and Consulting Services (“Services”). By
                        downloading Content or using the Website or by using the Product or Applications or Services,
                        these
                        terms will automatically apply to you– you should make sure therefore you read them carefully
                        before
                        using the “Services” offered by IRIS IRP, a GSTN authorised IRP (Invoice Registration Portal) to
                        generate e-invoices under GST.</span>
                </h5>
                <h5 class="mt-3">
                    <span class="h5DetailContent">
                        We update these terms from time to time. If you have an active subscription of any of the
                        Services,
                        we will try to let you know when we update the terms via in-app notification or by email (if you
                        subscribe to receive email updates) or by any other means as available at the time.
                    </span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">1. Definitions</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“IRIS/ IRIS Business”</b> here means the parent
                        company,
                        IRIS Business
                        Services Limited.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">IRIS IRP –</b> The government authorised Invoice
                        Registration Portal to
                        help taxpayers, GSPs, and solution providers generate IRN (Invoice Reference Number) under
                        e-invoicing, GST. IRIS has been appointed as the official IRP by the government of India in June
                        2022.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Affiliate”</b> means any entity which directly or
                        indirectly controls,
                        is controlled by, or is under common control with a party to this Agreement. For purposes of
                        this
                        definition, control means direct or indirect ownership.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Agreement” or “Customer Terms of Service”</b> means
                        these Terms and
                        all materials referred or linked to in here.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Billing Period”</b> means the period for which you
                        agree to prepay
                        fees under an Order Form. This may be the same length as the Subscription Term specified in the
                        Order Form, or it may be shorter. For example, if you subscribe to the Product Subscription for
                        a
                        one (1) year Subscription Term, with a twelve (12) month upfront payment, the Billing Period
                        will be
                        twelve (12) months.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">“Contact” means a single individual (other than a User) whose Contact
                        Information is stored by you in the Product Subscription.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Contact Information”</b> means the name, email
                        address,
                        phone number,
                        online user name(s), telephone number, GSTIN, invoice details, IRN number, e-way bill details
                        and
                        similar information submitted by User while using IRIS IRP Products/Services.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Consulting Services”</b> means the professional
                        services provided to
                        you by us, which may include responses to blog queries, chat queries, training services,
                        installation, integration or other consulting services.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Free Services”</b> means the Product Subscription
                        or
                        other products or
                        features made available by us to you on an unpaid trial or free basis.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“IRIS IRP Content”</b> means all information, data,
                        text, messages,
                        software, sound, music, video, photographs, graphics, images, and tags that we incorporate on
                        the
                        website, social media and into the Product Subscription or Consulting Services</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Order” or “Order Form”</b> means the online
                        subscription process by
                        which you agree to subscribe to the Product Subscriptions.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Personal Data”</b> means any information relating
                        to an
                        identified or
                        identifiable individual where such information is contained within Customer Data and is
                        protected
                        similarly as personal data or personally identifiable information</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Sensitive Information”</b>means credit or debit
                        card
                        numbers;
                        financial account numbers or wire instructions; biometric information, personal health
                        information
                        (or other information protected under any applicable health data protection laws), personal
                        information of children protected under any child data protection laws, and any other
                        information or
                        combinations of information that falls within the definition of “special categories of data”
                        under
                        GDPR or any other applicable law relating to privacy and data protection.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Subscription Fee”</b>means the amount you pay for
                        Product
                        Subscriptions.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Product Subscriptions”</b> means all of our
                        web-based
                        and mobile-based
                        applications, tools and platforms that you have subscribed to or that we otherwise make
                        available to
                        you, and are developed, operated, and maintained by us, accessible via https://einvoice6.gst.gov.in or
                        another designated URL, and any ancillary products and services that we provide to you.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Services”</b> means any platform offered by IRIS
                        IRP
                        like website,
                        content, Product Subscriptions, Mobile Applications, Consulting Services, Knowledge transfers
                        etc.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Subscription Term”</b> means the initial term of
                        your
                        subscription to
                        the applicable Product Subscription, as specified on your Order, and each subsequent renewal
                        term
                        (if any). For Free Services, the Subscription Term will be the period during which you have an
                        account to access the Free Services.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Third-Party Products”</b> means non-embedded
                        products
                        and professional
                        services that are provided by third parties which interoperate with or are used in connection
                        with
                        the Product Subscription. These products and services include non-IRIS IRP products or apps and
                        links made available through the Product Subscription.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Third-Party Sites”</b> means third-party websites
                        linked to from
                        within the Product Subscription, including Communications Services.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“Users”</b> means your employees, representatives,
                        consultants,
                        contractors or agents who are authorized to use the Product Subscription for your benefit and
                        have
                        unique user identifications and passwords for the Product Subscription.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“IRIS IRP”, “we”, “us” or “our”</b> means the
                        applicable
                        business line
                        of IRIS Business Services Limited.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent"><b class="bolder">“You”, “your” or “Customer”</b> means the person or
                        entity using the Product Subscription or receiving the Consulting Services and identified in the
                        applicable account record, billing statement, online subscription process, or Order Form as the
                        customer.</span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section2">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">2. Terms for Use</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">2.1 Access
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">During the Subscription Term, we will provide you and your Users
                        access to use the Product Subscriptions as described in this Agreement and/or the applicable
                        Order. You must ensure that all access, use and receipt by your Users are subject to and in
                        compliance with this Agreement. We may also provide your Users access to use our Free Services
                        at any time by activating them in your IRIS IRP account. We might provide some or all elements
                        of the Product Subscriptions through third party service providers. You may provide access and
                        use of the Product Subscription to your Users or allow them to receive the Services purchased;
                        provided that, all such access, use and receipt by your Users is subject to and in compliance
                        with the Agreement.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">2.2 Additional Features
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">You may subscribe to additional features of the Product Subscription
                        by placing an additional Order. This Agreement will apply to all additional Order(s) and all
                        additional features that you activate from within your account.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">2.3 User Eligibility
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">The Services are provided by “IRIS IRP” which means IRIS IRP, business
                        line of IRIS Business Services Limited and all group companies (including parent company &
                        subsidiaries, including those which may get created in future). The use of the Services is
                        available only to entities and persons over the age of majority and who can form legally binding
                        agreement(s) under applicable law. If you do not qualify, you are not permitted to use the
                        Services.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">2.4 Prohibited and Unauthorized Use
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">You will not use the Services in any way that violates the terms of
                        use or for any purpose or in any manner that is unlawful or prohibited by this Agreement.
                    </span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">You may not use the Services if you are legally prohibited from
                        receiving or using the Services under the laws of the country in which you are resident or from
                        which you access or use the Services.
                    </span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">2.5 Free Trial
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">If you register for a free trial, we will make the applicable Product
                        Subscription available to you on a trial basis free of charge until the earlier of (a) the end
                        of the free trial period (if not terminated earlier) or (b) the start date of your paid
                        subscription. Unless you purchase a subscription to the applicable Product Subscription before
                        the end of the free trial, all of your data in the Product Subscription may be permanently
                        deleted at the end of the trial, and we will not recover it. If we include additional terms and
                        conditions on the trial registration web page, those will apply as well.
                        You will notify us promptly of any unauthorized use of your Users’ identifications and passwords
                        or your account
                    </span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">2.6 Modifications
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">IRIS IRP may revise and update these Terms of Use at any time. Your
                        continued usage of the Services after any changes to these Terms of Use will mean you accept
                        those changes. Any aspect of the Website or Application or Product or Service may be changed,
                        supplemented, deleted or updated without notice at the sole discretion of IRIS IRP. We may also
                        change or impose fees for products and services provided through the Website or Application at
                        any time in its sole discretion.
                    </span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section3">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">3. Fees</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">3.1 Subscription Fees
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">The Subscription Fee will remain fixed during the initial term of your
                        subscription unless (i) you exceed your Maximum Contacts, Email Send Limit, User or other
                        applicable limits (see the ‘Limits’ section above), (ii) you upgrade products or base packages,
                        (iii) you subscribe to additional features or products, including additional Contacts, or (iv)
                        otherwise agreed to in your Order. We may also choose to decrease your fees upon written notice
                        to you. You can find all the information about how your fees may be otherwise adjusted in
                        Product Specific Terms.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">3.2 Fee Adjustments at Renewal
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">Upon renewal, we may increase your fees up to our then-current list
                        price. If this increase applies to you, we will notify you at least thirty (30) days in advance
                        of your renewal and the increased fees will apply at the start of the next renewal term. If you
                        do not agree to this increase, either party can choose to terminate your subscription at the end
                        of your then-current term</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">3.3 Payment by credit card
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">If you are paying by credit card, you authorize us to charge your
                        credit card or bank account for all fees payable during the Subscription Term. You further
                        authorize us to use a third party to process payments, and consent to the disclosure of your
                        payment information to such third party.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">3.3.1 Payment against invoice
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">If you are paying by invoice, we will invoice you no more than
                        forty-five (45) days before the beginning of the Subscription Term and each subsequent Billing
                        Period, and other times during the Subscription Term when fees are payable. All amounts invoiced
                        are due and payable within thirty (30) days from the date of the invoice, unless otherwise
                        specified in the Order Form.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">3.3.2 Payment Information
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">You will keep your contact information, billing information and credit
                        card information (where applicable) up to date. Changes may be made on your Billing Page within
                        your IRIS IRP account. All payment obligations are non-cancelable and all amounts paid are
                        non-refundable, except as specifically provided for in this Agreement. All fees are due and
                        payable in advance throughout the Subscription Term.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">3.3.3 Tax
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">All fees are exclusive of taxes, which we will charge as applicable.
                        You agree to pay any taxes applicable to your use of the Services. You shall have no liability
                        for any taxes based upon our gross revenues or net income.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">3.3.4 Withholding Tax
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">If you are required to deduct or withhold tax from payment of your
                        IRIS IRP invoice, you may deduct this amount from the applicable Subscription Fee due to the
                        extent it is due and payable as assessed withholding tax required under laws that apply to you
                        (the “Deduction Amount”).</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">You will not be required to repay the Deduction Amount to us, provided
                        that you present us with a valid tax receipt verifying payment of the Deduction Amount to the
                        relevant tax authority within ninety (90) days from the date of the invoice. If you do not
                        provide this tax receipt within the specified time period, then all fees, inclusive of the
                        Deduction Amount, will be immediately due and payable, and failure to pay these fees may result
                        in your account being suspended or terminated for non-payment.</span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section4">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">4. Term and Termination</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">4.1 Term and Renewal
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">Your initial subscription term will be specified in your Order, and,
                        unless otherwise specified in your Order, your subscription will automatically renew for the
                        shorter of the subscription term, or one year.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Content">4.2 Notice of Non-Renewal
                </h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">Unless otherwise specified in your Order, to prevent renewal of your
                        subscription, you or we must give written notice of non-renewal. The deadline for sending this
                        notice varies depending on the IRIS IRP product and version you have subscribed to.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">If you decide not to renew, you may send the non-renewal notice to
                        us.</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="h3Title">
                    <h3 class="h3Content">4.2.1 Early Cancellation
                    </h3>
                    <h5 class="mt-3"><span class="h5DetailContent">You may choose to cancel your subscription early at
                            your convenience provided that we will not provide any refunds of prepaid fees or unused
                            Subscription Fees, and you will promptly pay all unpaid fees due through the end of the
                            Subscription Term.</span>
                    </h5>
                </div>
                <div class="h3Title mt-3">
                    <h3 class="h3Content">4.2.2 Termination for Cause
                    </h3>
                    <h5 class="mt-3"><span class="h5DetailContent">Either party may terminate this Agreement for cause,
                            as to any or all Subscription Services: (i) upon thirty (30) days’ notice to the other party
                            of a material breach if such breach remains uncured at the expiration of such period, or
                            (ii) immediately, if the other party becomes the subject of a petition in bankruptcy or any
                            other proceeding relating to insolvency, cessation of business, liquidation or assignment
                            for the benefit of creditors.</span>
                    </h5>
                    <h5 class="mt-3"><span class="h5DetailContent">We may also terminate this Agreement for cause on
                            thirty (30) days’ notice if we determine that you are acting, or have acted, in a way that
                            has or may negatively reflect on or affect us, our prospects, or our customers.</span>
                    </h5>
                    <h5 class="mt-3"><span class="h5DetailContent">This Agreement may not otherwise be terminated prior
                            to the end of the Subscription Term.</span>
                    </h5>
                </div>
            </div>
        </div>
    </div>
    <div id="section5">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">5. License and Ownership</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">Any and all intellectual property rights (“Intellectual Property”)
                        associated with the Services and its contents are the sole property of IRIS IRP, business line
                        of IRIS Business Services Limited. The Content is protected by copyright and other laws in
                        India. Elements of the Website or Application are also protected by trade secret, unfair
                        competition, and other laws and shall not be copied or imitated in whole or in part.</span>
                </h5>
                <h5 class="mt-3">
                    <span class="h5DetailContent">
                        Unless otherwise indicated or anything contained to the contrary or any proprietary material
                        owned by a third party and so expressly mentioned, IRIS IRP owns all intellectual property
                        rights to and into the trademarks including, without limitation, any and all rights, title, and
                        interest in and to copyright, related rights, patents, utility models, designs, know-how, trade
                        secrets and inventions (patent pending), goodwill, source code, meta tags, databases, text,
                        content, graphics, icons, and hyperlinks.
                    </span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section6">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">6. Restrictions on Use of the Services</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">In addition to other restrictions set forth in these Terms of Use, you
                        agree that:</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="h3Title mt-3">
                    <ul>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">You shall not disguise the origin of
                                    information
                                    transmitted through any of the Services.</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">You will not place false or misleading
                                    information on
                                    the Website or Application or any other Services.</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">You will not use or access any service or
                                    information
                                    available via the Services in a manner not expressly permitted by IRIS IRP</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">You will not input or upload any information
                                    which
                                    contains viruses, or other computer programming routines that are intended to
                                    damage,
                                    interfere with, intercept or expropriate any system that infringes the Intellectual
                                    Property
                                    rights of another.</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">Certain areas of the Website or Application
                                    are
                                    restricted to customers of IRIS IRP only.</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">You shall not use or access the Website or
                                    Application in any way that, in IRIS IRP’s judgment, adversely affects the
                                    performance or
                                    function of the IRIS IRP Systems, Services or the Website or Application.</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">You shall not frame or utilize framing
                                    techniques to
                                    enclose any portion or aspect of the Content or the Information, without the express
                                    written
                                    consent of IRIS IRP team.</span>
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div id="section7">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">7. Limitation of Liability</h2>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">This Website/Application is not affiliated to the Government of India.
                    IRIS IRP has been appointed as an official Invoice Registration Portal by the Government of India in
                    June 2022. With the help of IRIS IRP, taxpayers, GSPs and solution providers can generate IRN
                    (Invoice Reference Number) under E-invoicing, GST. It is a connecting platform allowing users to
                    interact with the government system / databases in an easier manner. In No Event Shall IRIS IRP, Its
                    Affiliates, Its Licensors, Its Suppliers or any Third Parties mentioned at the Web Site or
                    Application be liable for any Financial, Incidental, Indirect, Exemplary, Punitive and Consequential
                    Damages, Lost Profits, Lost Reputation, Lost Goodwill or Damages Resulting from Lost Data or
                    Business Interruption resulting from the use of or inability to use or incorrect utilization of the
                    Services. Any ambiguous language in this terms and conditions shall be interpreted according to its
                    fair meaning and shall not be construed for or against any party.</span>
            </h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h5><span class="h5DetailContent">Further, you expressly understand and agree that IRIS IRP, Its Affiliates,
                    Its Licensors, Its Suppliers or any Third Parties mentioned at the Services shall not be liable to
                    you for any loss or damage which may be incurred by you, including but not limited to loss or damage
                    as a result of:</span>
            </h5>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="h3Title mt-3">
                <ul>
                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">Any reliance placed by you on the completeness,
                                accuracy
                                or existence of any advertising, or as a result of any relationship or transaction
                                between you
                                and any advertiser or sponsor whose advertising appears on the services;</span>
                        </h5>
                    </li>
                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">Any changes which IRIS IRP may make to the
                                services, or
                                for any permanent or temporary cessation in the provision of the services (or any
                                features
                                within the services);</span>
                        </h5>
                    </li>
                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">The deletion of, corruption of, or failure to
                                store, any
                                content and other communications data maintained or transmitted by or through your use
                                of the
                                services;</span>
                        </h5>
                    </li>
                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">Your failure to provide IRIS IRP with accurate
                                account
                                information;</span>
                        </h5>
                    </li>
                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">Your failure to keep your password or account
                                details
                                secure and confidential;</span>
                        </h5>
                    </li>
                    <li>
                        <h5 class="mt-3"><span class="h5DetailContent">The limitations on IRIS IRP’s liability to you
                                shall
                                apply whether or not IRP has been advised of or should have been aware of the
                                possibility of any
                                such losses arising.</span>
                        </h5>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div id="section8">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">8. Warranties</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">You expressly understand and agree that your use of the Services is at
                        your sole risk and that the services are provided “as is” and “as available”. In particular,
                        IRIS IRP, its subsidiaries and affiliates, and its licensors do not represent or warrant to you
                        that:</span>
                </h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="h3Title mt-3">
                    <ul>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">Your use of the Services will meet your
                                    requirements,
                                    Your use of the services will be uninterrupted, timely, secure or free from
                                    error,</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">Any information obtained by you as a result
                                    of your
                                    use of the Services will be accurate or reliable, and
                                    That defects in the operation or functionality of any software provided to you as
                                    part of
                                    the Services will be corrected.</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">Any material downloaded or otherwise obtained
                                    through
                                    the use of the Services is done at your own discretion and risk and that you will be
                                    solely
                                    responsible for any damage to your computer system or other device or loss of data
                                    that
                                    results from the download of any such material.</span>
                            </h5>
                        </li>
                        <li>
                            <h5 class="mt-3"><span class="h5DetailContent">No advice or information, whether oral or
                                    written,
                                    obtained by you from IRIS IRP or through or from the Services shall create any
                                    warranty not
                                    expressly stated in the terms. IRIS IRP further expressly disclaims all warranties
                                    and
                                    conditions of any kind, whether express or implied, including, but not limited to
                                    the
                                    implied warranties and conditions of merchantability, fitness for a particular
                                    purpose and
                                    non-infringement.</span>
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div id="section9">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">9. Indemnity</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">You agree to defend, indemnify and hold harmless IRIS IRP and its
                        respective officers, directors, employees, agents and representatives from any and all claims
                        (i) arising out of your breach of any of these terms and conditions, and any of your activities
                        conducted in connection with these Services.</span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section10">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">10. Jurisdiction</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">To the fullest extent permitted by Law, you hereby expressly agree
                        that any proceeding arising out of or relating to your use of the Services shall be instituted
                        in courts of Mumbai.</span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section11">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">11. Severability</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">If any provision of these Terms of Use is found to be invalid by any
                        court having competent jurisdiction, the invalidity of such provision shall not affect the
                        validity of the remaining provisions of these Terms of Use, which shall remain in full force and
                        effect.</span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section12">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">12. Complete Agreement</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">Except as expressly provided in other written agreements between you
                        and IRIS IRP these terms of use constitute the entire agreement between you and IRIS IRP (Parent
                        Company – IRIS BUSINESS SERVICES LIMITED) with respect to the use of the Services</span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section13">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">13 Changes to This Terms and Conditions</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">We may update our Terms and Conditions from time to time. Thus, you
                        are advised to review this page periodically for any changes. We will notify you of any changes
                        by posting the new Terms and Conditions on this page. These terms and conditions are effective
                        as of 01-Oct-2022</span>
                </h5>
            </div>
        </div>
    </div>
    <div id="section14">
        <div class="row mt-3">
            <div class="col-12">
                <h2 class="h3Head">14 Contact Us</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h5><span class="h5DetailContent">If you have any questions or suggestions about our Terms and
                        Conditions, do not hesitate to contact us at hello@irisirp.com</span>
                </h5>
            </div>
        </div>
    </div>

</div>
<div style="padding: 70px 30px 10px;;background-color: #fafafa;">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h4>Our products</h4>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoicing for
                        Taxpayers
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoice APIs
                        for
                        Solution
                        Providers
                    </li>
                </ul>
                <h4>Find us here</h4>
                <i class="bi bi-twitter"></i>
                <i class="mx-2 bi bi-linkedin"></i>
            </div>
            <div class="col-md-4">
                <h4>Resources</h4>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                        margin-right: 5px;"></i>Notifications
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> Blog
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <h4>Contact us</h4>
                <ul style="list-style: none; padding-left: 0px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                        Maharashtra, India
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                        <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-4">
                <p>© 2022 IRIS Business Services Ltd. All Rights Reserved. | <a (click)="redirectToPrivacy()" href="javascript:;">Privacy Policy</a> | <a (click)="redirectToTerms()" href="javascript:;">Terms &
                    Conditions</a></p>
            </div>
        </div>
    </div>
</div>