import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiIntegratorDashboardService {
  public testgstinDetail : any;
  setFlagForTestGstin = new BehaviorSubject<string>("");
  setFlagForSandboxRegistration = new BehaviorSubject<string>("");

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  setFlagTestGstin(value: string){
    this.setFlagForTestGstin.next(value);
  }

  getFlagTestGstin(): Observable<string>{
    return this.setFlagForTestGstin.asObservable();
  }

  setFlagRegisterGstin(value: string){
    this.setFlagForSandboxRegistration.next(value);
  }

  getFlagRegisterGstin(): Observable<string>{
    return this.setFlagForSandboxRegistration.asObservable();
  }
  
  getIRNCount()
  {
    return this.http
      .get(`${environment.baseUrl}/gstin/api/registration`)
  }
  getDataCount()
  {
    return this.http
      .get(`${environment.baseUrl}/dbd/intd/apis`)
  }

  getError(dt)
  {
    return this.http
      .get(`${environment.baseUrl}/dbd/intd/errors?dt=${dt}`);
  }
  verifyGstin(gstin: string,type:string): any {
    return this.http
      .get(`${environment.baseUrl}/api/integrate/validate/gstin?gstin=${gstin}&type=${type}`)
      ;
  }
  verifyDataGstin(gstin:string):any{
    return this.http
      .get(`${environment.baseUrl}/gstin/verify?gstin=${gstin}`)
  }
  generateOtp(gstinDetails:any)
  {
    return this.http
      .post(`${environment.baseUrl}/api/integrate/gstin/generate/otp`, gstinDetails)
  }
  generateOtpTaxpayer(gstinDetails:any)
  {
    return this.http
      .post(`${environment.baseUrl}/api/integrate/gstin/generate/otp`, gstinDetails)
  }
  generateOtpEcomm(gstinDetails:any)
  {
    return this.http
      .post(`${environment.baseUrl}/api/integrate/gstin/generate/otp`, gstinDetails)
  }

  verifyOtp(otp)
  {
    return this.http
    .post(`${environment.baseUrl}/api/integrate/gstin/verify/otp`, otp);
  }
  getNews()
  {
    return this.http
    .get(`${environment.baseUrl}/view/news`)
    ;
  }
  getState(statecode)
  {
    return this.http
      .get(`${environment.apiUrl}/state/gstin/?state=${statecode}`)
  }

  addTestGstin(gstin)
  {
    return this.http
      .post(`${environment.apiUrl}/dummy/gstin`,gstin)
  }
  getTestGstin()
  {
    return this.http
      .get(`${environment.apiUrl}/dummy/gstin`)
  }
  addValidGstin(gstin,type,gstinType)
  {
    return this.http
      .get(`${environment.apiUrl}/api/integrate/validate/gstin?gstin=${gstin}&type=${type}&gstinType=${gstinType}`)
  }
  genOtpValidGstin(gstinDetails:any)
  {
    return this.http
      .post(`${environment.apiUrl}/test/gstin/generate/otp`,gstinDetails)
  }
  verifyOtpTestGstin(otpDetail)
  {
    return this.http
      .post(`${environment.apiUrl}/test/gstin/verify/otp`,otpDetail)
  }
  genIRPDtls(gstinDetails:any)
  {
    return this.http
      .post(`${environment.apiUrl}/test/gstin/irp/details`,gstinDetails)
  }
  gstinValidate(event)
  {

    var inp = String.fromCharCode(event.keyCode);


    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  generateEncryptedPhone(value) {
    return value.replace(/\d(?=\d{4})/g, "*");
  }
  generateEncryptedEmail(value) {
    return value.replace(/^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
  }
  getErrorData(date:any)
  {
    return this.http
      .get(`${environment.baseUrl}/dbd/intd/errors?dt=${date}`)
  }
  getGstinDetail(){
    return this.http
      .get(`${environment.baseUrl}/api/registration/detail`)
  }
  isEmpty(data: any): boolean {
    return data === undefined || data === null || data === '';
  }
}
