import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  redirectToPrivacy() {
    window.scrollTo(0, 0);
    this.router.navigate([`/privacy`]);
  }
  redirectToTerms() {
    window.scrollTo(0, 0);
    this.router.navigate([`/terms-conditions`]);
  }
}
