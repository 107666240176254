import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CallTrackerReportInformation } from 'assert';
import { LocalStorageKey } from 'src/app/app.config';
import { GSTDropdownService } from 'src/app/shared/services/gstDropdown.service';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ProfileService } from '../../profile/profile.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationExtras, Router } from '@angular/router';
import { EInvoiceService } from '../../e-invoice/e-invoice.service';

@Component({
  selector: 'app-common-gstin-dropdown',
  templateUrl: './common-gstin-dropdown.component.html',
  styleUrls: ['./common-gstin-dropdown.component.scss'],
})
export class CommonGstinDropdownComponent implements OnInit {
  isGSTInAvailable: boolean;
  gstinList = [];
  gstinFilterData: any;
  selectedGstin: any;
  @Output() outputEmitterEvent = new EventEmitter<Object>();
  @Input() gstInDropdownList;
  visibleDropDown: any;
  userType:any
  constructor(
    private profileService: ProfileService,
    private toastrService: ToastrService,
    private router: Router,
    private sharedService: SharedService,
    private gstDropdownService: GSTDropdownService,
    private taxPayerDashboardService: TaxPayerDashboardService,
    private invoiceService: EInvoiceService
  ) {}

  ngOnInit(): void {
    // console.log("COMMON")
    this.getGSTIN();
    this.taxPayerDashboardService
      .getGstinFromList()
      .subscribe((gstin: string) => {
        if (!this.invoiceService.isEmpty(gstin)) {
          this.selectedGstin = gstin;
        }
      });

    this.taxPayerDashboardService
      .getaddGstinToList()
      .subscribe((gstin: any) => {
        if (!this.invoiceService.isEmpty(gstin)) {
          this.gstinList = gstin;
          this.isGSTInAvailable = true;
          this.selectedGstin = this.gstinList[0]?.gstin;
          this.storeInLS();
          this.generateAndSendSharedData();
        }
      });

      this.taxPayerDashboardService.getVisibleCommonDropDown().subscribe((visible:any)=>{
        if(!this.invoiceService.isEmpty(visible)){
          this.visibleDropDown = visible;
        }
      })

  }
  getGSTIN() {
    this.profileService.getGstinsNumberList().subscribe((res) => {
      if (res && res.response) {
        this.isGSTInAvailable = true;
        res.response.map((gstin) => {
          this.gstinList.push({
            gstin: gstin.gstin,
            taxpayerName: gstin.taxpayerName,
            userType: gstin.userType,
            userRole: gstin.userRole,
          });
        });
        this.selectedGstin = this.gstinList[0].gstin;
        this.storeInLS();
        this.generateAndSendSharedData();
      } else {
        this.isGSTInAvailable = false;
      }
    });
  }

  generateAndSendSharedData() {
    // this.taxPayerDashboardService.setShowPopup("false");
    const matchingList = this.gstinList.find(
      (gst) => gst.gstin == this.selectedGstin
    );
    const sharedData = {
      selectedGSTIn: this.selectedGstin,
      isGSTInAvailable: this.isGSTInAvailable,
      userType: matchingList.userType,
      taxpayerName: matchingList.taxpayerName,
      userRole: matchingList.userRole,
    };
    this.gstDropdownService.gstSharingData.next(sharedData);
    this.taxPayerDashboardService.setChangeUpdatedData('true');
  }

  updateGstin() {
    const data = this.profileService.getGstinsNumberList().subscribe((res) => {
      this.gstinList = res['response'];
      for (let i = 0; i < this.gstinList.length; i++) {
        var Selectgstin = localStorage.getItem('GSTIN');
        const filterGstin = this.gstinList.filter(
          (gstNo: any) => gstNo.gstin === Selectgstin
        )[0];
        this.gstinFilterData = this.gstinList.filter(
          (gstNo: any) => gstNo.gstin === Selectgstin
        )[0];
      }
      if (
        this.gstinFilterData.userRole === 'GENERATOR' ||
        this.gstinFilterData.userRole === 'REVIEWER'
      ) {
        // this.toastrService.error("Only Admin/Authorised Signatory can perform this action")
        localStorage.setItem('UserRole', 'true');
      } else {
        localStorage.setItem('UserRole', 'false');
      }
    });
    this.storeInLS();
    // Send all the listener the updated data
    this.generateAndSendSharedData();
    const gstinDetails = localStorage.getItem(LocalStorageKey.SelectedGSTIN);
    if (gstinDetails != null) {
      this.userType = JSON.parse(gstinDetails || '').userType;
      console.log(this.userType)
      if (this.userType === 'BASIC') {
        this.taxPayerDashboardService.setFromDate('true');
      } else {
        this.taxPayerDashboardService.setFromDate('false');
      }
    }
    // this.redirectToPlansPage('planType');
    // this.taxPayerDashboardService.setShowPopup("false");

  }
  redirectToPlansPage(planType: string) {
    const navigationExtras: NavigationExtras = {
      state: {
        planType: planType,
      },
    };
    this.router.navigate(['irp/gstin/dashboard/plans'], navigationExtras);
  }
  storeInLS() {
    // this.taxPayerDashboardService.setShowPopup("false");
    localStorage.setItem(
      LocalStorageKey.IS_GSTIN_AVAILABLE,
      JSON.stringify(this.isGSTInAvailable)
    );
    localStorage.setItem(LocalStorageKey.GSTINNumber, this.selectedGstin);
    localStorage.setItem(
      LocalStorageKey.SelectedGSTIN,
      JSON.stringify(
        this.gstinList.find((g: any) => g.gstin === this.selectedGstin)
      )
    );
  }
}
