import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TaxPayerDashboardService {

    endpoint: string;
    irnEndpoint: string;

    changeUpdatedDate = new BehaviorSubject<string>("");
    changeGStin = new BehaviorSubject<string>("");
    addedGstin = new BehaviorSubject<any>('');
    forgetTologin = new BehaviorSubject<any>("");
    marqueePre = new BehaviorSubject<any>("");
    showPopup = new BehaviorSubject<string>("");
    visibleCommonGstin = new BehaviorSubject<string>("");
    showPopUpNoGstin = new BehaviorSubject<string>("");
    isviewAllFilter = new BehaviorSubject<string>("");
    isFromDateFilter = new BehaviorSubject<string>("");
    constructor(private http: HttpClient, private sharedService: SharedService) {
        this.endpoint = `${environment.baseUrl}`;
    }

    getChangeUpdatedData(): Observable<string> {
        return this.changeUpdatedDate.asObservable();
    }

    setChangeUpdatedData(value: string) {
        this.changeUpdatedDate.next(value);
    }

    setGstinfromList(value: string) {
        this.changeGStin.next(value);
    }
    getGstinFromList(): Observable<string> {
        return this.changeGStin.asObservable();
    }
    getisForgetData(): Observable<string> {
        return this.forgetTologin.asObservable();
    }
    setisForgetData(value: string) {
        this.forgetTologin.next(value);
    }
    setaddGstinToList(value: any) {
        this.addedGstin.next(value);
    }
    getaddGstinToList(): Observable<any> {
        return this.addedGstin.asObservable();
    }
    setMarqueeForPre(value: any) {
        this.marqueePre.next(value);
    }
    getMarqueeForPre(): Observable<any> {
        return this.marqueePre.asObservable();
    }
    //FOR DASHBOARD
    getShowPopup(): Observable<string> {
        return this.showPopup.asObservable();
    }

    setShowPopup(value: string) {
        this.showPopup.next(value);
    }
    //FOR NO_GSTIN
    setPopUpNoGstin(value:string){
        this.showPopUpNoGstin.next(value);
    }
    getPopUpNoGstin():Observable<string>{
        return this.showPopUpNoGstin.asObservable();
    }
    setVisibleCommonDropDown(value:string){
        this.visibleCommonGstin.next(value);
    }
    getVisibleCommonDropDown():Observable<string>{
        return this.visibleCommonGstin.asObservable();
    }
    setViewAllFilter(value: string) {
        this.isviewAllFilter.next(value);
    }
    getViewAllFilter(): Observable<string>{
        return this.isviewAllFilter.asObservable();
    }
    setFromDate(value: string) {
        this.isFromDateFilter.next(value);
    }
    getFromDate() {
        return this.isFromDateFilter.asObservable();
    }
    createHeaderObject() {
        // HTTP request header does not accept integer value
        return {
            headers: new HttpHeaders({
                'X-Frame-Options': 'deny',
                'X-Content-Type-Options': 'nosniff',
                'X-XSS-Protection': '1',
            }),
        };
    }
    getIRNCount(userType, gstin = null) {
        //27BCKPS4201C1ZV
        this.irnEndpoint = `${this.endpoint}/dbd/${userType}?gstin=${gstin}`
        return this.http.get(this.irnEndpoint,
            {
                headers: this.createHeaderObject().headers
            })

    }
    getEwbPendingCount(gstin = null) {
        this.irnEndpoint = `${this.endpoint}/dbd/gstin/invoice/count?gstin=${gstin}`
        return this.http.get(this.irnEndpoint,
            {
                headers: this.createHeaderObject().headers
            })
    }
    getErrors(userType, gstin = null) {
        this.irnEndpoint = `${this.endpoint}/dbd/${userType}/errors?gstin=${gstin}`
        return this.http.get(this.irnEndpoint,
            {
                headers: this.createHeaderObject().headers
            })

    }
    getNews() {
        this.irnEndpoint = `${this.endpoint}/view/news`
        return this.http.get(this.irnEndpoint,
            {
                headers: this.createHeaderObject().headers
            })
    }


}
