import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { API_STATUS } from 'src/app/constant';
import { UtilityService } from 'src/app/taxPayer/utilities/utilities.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { TaxpayerDashboardService } from 'src/app/taxPayer/taxpayer-dashboard/taxpayer-dashboard.service';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { captchaKey } from 'src/app/app.config';

@Component({
  selector: 'app-generic-gstin-status',
  templateUrl: './generic-gstin-status.component.html',
  styleUrls: ['./generic-gstin-status.component.scss']
})
export class GenericGstinStatusComponent implements OnInit {


  gstin;
  gstinDetail: any;
  isErrorPresent: boolean;
  errorMessage: any;
  showResults: boolean = false;
  siteKey = captchaKey.INVISIBLE;
  captchSuccessfulToken: any;
  private captchaChange = new BehaviorSubject<number>(0);
  recaptcha;
  setCaptch: any;
  isNotGstin: boolean;
  captcha: any;
  captchString: any;
  captchaVisible1: boolean = false;
  constructor(private utilityService: UtilityService, private invoiceService: EInvoiceService, private toasterService: ToastrService, private googleAnalyticsService: GoogleAnalyticsService, private dashboardService: TaxpayerDashboardService) { }

  ngOnInit(): void {
    this.setCaptch = localStorage.getItem('token');
    this.generateRandomString(5);

  }
  checkCaptcha() {
    if (this.gstin) {
      this.captchaVisible1 = true;
    } else {
      this.generateRandomString(5);
      this.captchaVisible1 = false;
    }
  }
  searchGstin() {
    // if (this.captchaVerified == true) {
    this.utilityService.getGSTINStatus(this.gstin.toUpperCase()).subscribe(res => {
      this.showResults = true;
      if (res && res['status'] == API_STATUS.error) {
        this.isErrorPresent = true;
        this.errorMessage = res['message'];


      } else {
        this.isErrorPresent = false;
        if (res && res['response']) {
          this.gstinDetail = res['response'];
          if (this.invoiceService.isEmpty(this.gstinDetail.einvoiceEnable)) {
            if (this.gstinDetail.einvoiceStatus === 'Yes') {
              this.gstinDetail.einvoiceEnable = 'E-Invoice is ENABLED for this Taxpayer.';
            }
            else if (this.gstinDetail.einvoiceStatus === 'No') {
              this.gstinDetail.einvoiceEnable = 'E-Invoice is NOT ENABLED for this Taxpayer.';
            }
            else if (this.invoiceService.isEmpty(this.gstinDetail.einvoiceStatus)) {
              this.gstinDetail.einvoiceEnable = '';
            }
          } else {
            this.gstinDetail.einvoiceEnable = this.gstinDetail.einvoiceEnable;
          }

          this.trackAnalytics("GSTINStatus_Search")
        }
      }

    })
    // } else {
    //   this.toasterService.error('Please select captcha before proceeding.');
    // }



  }
  searchGst() {
    if (this.gstin && !this.captcha) {
      this.toasterService.warning("Please Enter Captcha")
    } else {
      if (this.gstin) {
        if (this.captchString === this.captcha) {
          this.utilityService.getGSTINStatus(this.gstin.toUpperCase()).subscribe(res => {
            this.showResults = true;
            if (this.gstin && this.showResults) {
              this.captchaVisible1 = false;
            }
            if (res && res['status'] == API_STATUS.error) {
              this.isErrorPresent = true;
              this.errorMessage = res['message'];


            } else {
              this.isErrorPresent = false;
              if (res && res['response']) {
                this.gstinDetail = res['response'];
                if (this.invoiceService.isEmpty(this.gstinDetail.einvoiceEnable)) {
                  if (this.gstinDetail.einvoiceStatus === 'Yes') {
                    this.gstinDetail.einvoiceEnable = 'E-Invoice is ENABLED for this Taxpayer.';
                  }
                  else if (this.gstinDetail.einvoiceStatus === 'No') {
                    this.gstinDetail.einvoiceEnable = 'E-Invoice is NOT ENABLED for this Taxpayer.';
                  }
                  else if (this.invoiceService.isEmpty(this.gstinDetail.einvoiceStatus)) {
                    this.gstinDetail.einvoiceEnable = '';
                  }
                } else {
                  this.gstinDetail.einvoiceEnable = this.gstinDetail.einvoiceEnable;
                }

                this.trackAnalytics("Pre_GSTINStatus_Search")
                this.generateRandomString(5);
              }
            }

          })
        } else {
          this.toasterService.error("Please Enter Valid Captcha")

        }
      } else {
        this.utilityService.getGSTINStatus(this.gstin.toUpperCase()).subscribe(res => {
          this.showResults = true;
          if (res && res['status'] == API_STATUS.error) {
            console.log(res['message']);
            this.isErrorPresent = true;
            this.errorMessage = res['message'];
          }
        })
      }
    }

  }
  getUpdatedData() {
    this.dashboardService
      .updateFromCommonPortal(this.gstin.toUpperCase())
      .subscribe((res) => {
        if (res['status'] !== API_STATUS.success) {
          //  this.isErrorPresent = true;
          // this.errorMessage = res['message'];
          this.toasterService.error(res['message']);

        } else {
          this.toasterService.success(res['message']);
          this.utilityService.getGSTINStatus(this.gstin.toUpperCase()).subscribe(res => {
            if (res && res['status'] !== API_STATUS.error) {
              if (res && res['response']) {
                this.gstinDetail = res['response'];
              }
            }

          })

          this.trackAnalytics("GSTINStatus_Search")
          // this.isErrorPresent = false;


        }
        this.trackAnalytics("Update_From_Common_Portal");
      });
    // this.utilityService.getUpdatedStatus(this.gstin).subscribe(res => {
    //   console.log(res)
    //   this.showResults = true;
    //   if (res && res['status'] == API_STATUS.error) {
    //     // this.isErrorPresent = true;
    //     this.errorMessage = res['message'];


    //   } else {
    //     // this.isErrorPresent = false;
    //     if (res && res['response']) {
    //       console.log(res);
    //       // this.gstinDetail = res['response'];
    //       this.trackAnalytics("GSTINStatus_Search")
    //     }
    //   }

    // })

  }
  generateAddress() {
    let addr = '';
    if (this.gstinDetail && this.gstinDetail.address && this.gstinDetail?.address?.addr) {
      let tempAddr = this.gstinDetail?.address?.addr;
      if (tempAddr != null) {
        if (tempAddr.bno != null) {
          addr = addr.concat(tempAddr.bno);
        }


        if (tempAddr?.flno) {
          addr = addr.concat(',' + tempAddr?.flno);
        }
        if (tempAddr?.bnm) {
          addr = addr.concat(',' + tempAddr?.bnm);
        }

        if (tempAddr?.st) {
          addr = addr.concat(',' + tempAddr?.st);
        }
        if (tempAddr?.dst) {
          addr = addr.concat(',' + tempAddr?.dst);
        }
        if (tempAddr?.loc) {
          addr = addr.concat(',' + tempAddr?.loc);
        }
      }

    }
    // console.log("adddress", addr)
    return addr;


  }
  formatDate(inputDate, inputFormat, outputFormat) {
    if (inputDate) {
      var date = moment(inputDate, inputFormat)
      return date.format(outputFormat)
    }
    return null;

  }


  cancel() {
    this.gstin = null;
    this.captcha = null;
    this.gstinDetail = [];
    this.showResults = false;
    this.generateRandomString(5);
    this.captchaVisible1 = false;
  }

  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
  generateRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    this.captchString = result;
    this.captcha = null;
    return result;


  }
}
