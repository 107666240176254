import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gstin-status-prelogin',
  templateUrl: './gstin-status-prelogin.component.html',
  styleUrls: ['./gstin-status-prelogin.component.scss']
})
export class GstinStatusPreloginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
