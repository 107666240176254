import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-api-integrator-whome-content',
  templateUrl: './api-integrator-whome-content.component.html',
  styleUrls: ['./api-integrator-whome-content.component.scss']
})
export class ApiIntegratorWhomeContentComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  redirectToPrivacy(){
    window.scrollTo(0,0);
    this.router.navigate([`/privacy`]);
  }
  redirectToTerms(){
    window.scrollTo(0,0);
    this.router.navigate([`/terms-conditions`]);
  }

}
