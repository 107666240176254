<div class="">
  <!-- <app-auth-header></app-auth-header> -->
  <!-- Marquee moved to header-->
  <!-- <div class="col-12 font-size-12 top-space-55">
    <marquee class="marquee-body" *ngIf="marquee && marquee.newsBody">
      {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}}
    </marquee>
  </div> -->
  <!-- <div class="custom_container mt-3 px-3">
    <div [class.marquee-space]="!marquee ">
       <app-common-gstin-dropdown></app-common-gstin-dropdown>

    </div>

  </div> -->
  <div class="container-fluid custom_container_fluid cursor-pointer landingHeader" (click)="redirectToLogin()">
    <div class="row align-items-center headerDiv">
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/gstnlogo.png" alt="..." class="imgClass">
      </div>
      <div class="col-sm-6 text-center">
        <p class="text-center my-0 font-size-28">E-Invoice Registration Portal - IRP 6</p>
        <span class="text-center my-0 highDiv powerTxt"> Powered by IRIS Business Services Ltd. for Goods and Services
          Tax Network </span>
        <span class="text-center my-0 smallDiv powerTxt"> Powered by IRIS for GSTN </span>
      </div>
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/IRISIRP-Logo-1.png" class="imgClass">
      </div>
    </div>

  </div>
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent custom_navbar">
    <div class="container-fluid px-5">
      <!-- <a class="navbar-brand" href="#"><img src="../../../../assets/images/IRISIRP-Logo-1.png" height="60"></a> -->
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class=""><i class="bi bi-list"></i></span>
      </button>
      <span class="mr10 smallDiv mt-10">
        <a [class.active]="isMenuSelected == 'Sandbox'" class="btn btn-primary rounded p13 btnContact1"
          href="https://sandbox.einvoice6.gst.gov.in/signup" target="_blank">Sandbox</a>
      </span>
      <span class="mr10 smallDiv mt-10">
        <a [class.active]="isMenuSelected == 'Login'" class="btn btn-primary rounded p13 btnContact"
          (click)="redirectTOLoginPage()">Login</a>
      </span>
      <span class="nav-item dropdown pl0 smallDiv mr10 mt-10">
        <a class="btnLang " href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
          aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg"> </a>
        <ul class="dropdown-menu t60 left-3" aria-labelledby="navbarDropdown02">
          <li><a href="https://einvoice6.gst.gov.in/content/hindi/" class="dropdown-item"><img
                src="../../../../assets/images/landingpage/hindi.png" class="langImg"> हिन्दी </a></li>
          <li><a href="https://einvoice6.gst.gov.in/content/tamil/" class="dropdown-item"><img
                src="../../../../assets/images/landingpage/tamil.png" class="langImg"> தமிழ் </a></li>
          <li><a href="https://einvoice6.gst.gov.in/content/marathi/" class="dropdown-item"><img
                src="../../../../assets/images/landingpage/marathi.png" class="langImg"> मराठी </a></li>
          <li><a href="https://einvoice6.gst.gov.in/content/gujrati/" class="dropdown-item"><img
                src="../../../../assets/images/landingpage/gujrati.png" class="langImg"> ગુજરાતી</a></li>
        </ul>
      </span>

      <!-- <img src="../../../../assets/images/IRISIRP-Logo-1.png" class="imgClass smallDiv"> -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent" style="">
        <ul class="mb-2 mb-lg-0 ms-auto navbar-nav custom_navbar_nav">
          <li class="nav-item dropdown">
            <a class="nav-link " (mouseenter)="isMenuSelected = 'Taxpayers'"
              [class.active]="isMenuSelected == 'Taxpayers'" href="javascript:;" id="navbarDropdown0" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Taxpayers <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
              <li><a class="dropdown-item"
                  href="https://einvoice6.gst.gov.in/content/e-invoicing-for-taxpayers/">E-invoicing for Taxpayers</a>
              </li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kb/registration/">Registering with
                  IRIS IRP</a></li>
              <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101"
                  role="button" (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
                  aria-expanded="false">Solutions <span class="">></span></a>
                <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
                  <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irp-web-portal/">Web
                      Portal</a></li>
                  <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/excel-utility/">Excel
                      Utility</a></li>
                  <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">API
                      Integrators</a></li>
                  <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/erp-billing-systems/">ERP /
                      Billing Systems</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/mobile-web-application/">Mobile Web Application</a></li>
                </ul>
              </li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">Product
                  Guide</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/pricing-plan/">Pricing Plan</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Developers'"
              [class.active]="isMenuSelected == 'Developers'" href="javascript:;" id="navbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              API Developers <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item"
                  href="https://einvoice6.gst.gov.in/content/kb/api-integrator-registration/">Registering with IRIS
                  IRP</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">API
                  Documentation</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link " (mouseenter)="isMenuSelected = 'Help'" [class.active]="isMenuSelected == 'Help'"
              href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Help <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/faq-powered-by-irisirp/">FAQ</a>
              </li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/public-keys/">Public Keys <span
                    class="blink_me">Updated</span></a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/validation-rules/">Validation
                  Rules</a></li>
              <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101"
                  role="button" (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
                  aria-expanded="false">E-invoice Schema <span class="">></span></a>
                <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
                  <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/general-master/">General
                      Master</a></li>
                  <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irn-2/">IRN</a></li>
                </ul>
              </li>
              <li><a class="dropdown-item"
                  href="https://einvoice6.gst.gov.in/content/kb/troubleshooting-common-errors/">Resolving Common
                  Errors</a></li>
              <li><a class="dropdown-item" href="https://selfservice.gstsystem.in/ReportIssue.aspx">Report Issue</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Search'" [class.active]="isMenuSelected == 'Search'"
              href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Search <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/masters/" >Masters</a></li> -->
              <li><a class="dropdown-item" (click)="redirectTo('master-search')">Masters</a></li>
              <li><a class="dropdown-item" (click)="redirectTo('enablement-status')">EInvoice Enablement Status</a></li>
              <li><a class="dropdown-item" (click)="redirectTo('verify-qrcode')">Verify QR Code</a></li>
              <li><a class="dropdown-item" (click)="redirectTo('verify-signedInvoice')">Verify Signed E-Invoice</a></li>
              <li><a class="dropdown-item" (click)="redirectTo('gstin-status')">Gstin Status</a></li>


            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Resources'"
              [class.active]="isMenuSelected == 'Resources'" href="javascript:;" id="navbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Resources <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/einvoice-mandate/">E-Invoice
                  Mandate</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irp-for-e-invoicing/">IRIS IRP for
                  E-Invoicing</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/notifications/">GST
                  Notifications</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/blog/">Blog</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/news/">E-invoice and GST in
                  News</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'About'" [class.active]="isMenuSelected == 'About'"
              href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              About Us <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="https://irisirp.com/">About IRIS IRP</a></li>
              <li><a class="dropdown-item" href="https://irisgst.com/">About IRIS GST</a></li>
              <li><a class="dropdown-item" href="https://www.irisbusiness.com/">About IRIS Business</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Contact Us'"
              [class.active]="isMenuSelected == 'Contact Us'" href="https://einvoice6.gst.gov.in/content/contact-us/"
              id="navbarDropdown" role="button" aria-expanded="false">
              Contact Us
            </a>
          </li>

















          <!-- <li class="nav-item dropdown">
            <a class="nav-link " (mouseenter)="isMenuSelected = 'ForWhom'" [class.active]="isMenuSelected == 'ForWhom'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Getting Started <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/e-invoice-mandate/">E-invoice Mandate</a></li>
              <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/iris-irp-for-e-invoicing/">IRIS IRP for E-invoicing</a></li>
              <li><a class="dropdown-item"  target="new" title= "This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website" href="https://einvoice.gst.gov.in/einvoice/check-enablement">E-invoice enablement</a></li>
              <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
                aria-expanded="false">Registering with IRIS IRP <span class="">></span></a>
              <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kb/registration/">Taxpayers</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kb/api-integrator-registration/">API Integrators</a></li>

              </ul>
            </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Generate'" [class.active]="isMenuSelected == 'Generate'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              How to Generate <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
                aria-expanded="false">For Taxpayers <span class="">></span></a>
              <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/web-portal/">Web Portal</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/excel-utility/">Excel Utility</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/mobile-app/">Mobile App</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">API Integration</a></li>
                <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/erp-billing-systems/" >ERP/Billing Systems</a></li>



              </ul>
            </li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/api-flow/" >For Solution Providers</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Documentation'" [class.active]="isMenuSelected == 'Documentation'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Documentation <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" adropendria-labelledby="navbarDropdown">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/blog/kbtopic/developerconsole/" >API Integration</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/public-keys/" >Public keys</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/validation-rules/" >Validation Rules</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/masters/" >Masters</a></li>
            <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
              aria-expanded="false">E-invoice Standard <span class="">></span></a>
            <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/notified-e-invoice-schema/">Notified E-invoice Schema</a></li>
              <li><a class="dropdown-item" href="https://einv-apisandbox.nic.in/downloads/Regular%20Expression.pdf">Field specifications</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/general-master/">General Master</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irn-2/">IRN</a></li>



            </ul>
          </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Tools'" [class.active]="isMenuSelected == 'Tools'" href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Tools <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item dropend"  (click)="redirectTo('enablement-status')" >EInvoice Enablement Status</a></li>
            <li><a class="dropdown-item dropend"  (click)="redirectTo('gstin-status')" >GSTIN Status</a></li>
            <li><a class="dropdown-item dropend"  (click)="redirectTo('verify-signedInvoice')" >Verify Signed E-Invoice</a></li>
            <li><a class="dropdown-item dropend"  (click)="redirectTo('verify-qrcode')" >Verify QR Code</a></li>
            <li><a class="dropdown-item dropend"  (click)="redirectTo('hsn-search')" >HSN Search</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Resources'" [class.active]="isMenuSelected == 'Resources'" href="javascript:;" id="navbarDropdown2" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Resources <span><i class="bi bi-chevron-down"></i></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/news/">E-invoice and GST in News</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/notifications/">GST Notifications</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/blog/" >Blog</a></li>


            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'Help'" [class.active]="isMenuSelected == 'Help'" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">Help <span><i class="bi bi-chevron-down"></i></span></a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown02">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/faq-powered-by-irisirp/">FAQs</a></li>
              <li><a class="dropdown-item" href="https://selfservice.gstsystem.in/ReportIssue.aspx">Report Issue</a></li>
              </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (mouseenter)="isMenuSelected = 'About Us'" [class.active]="isMenuSelected == 'About Us'" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">About Us <span><i class="bi bi-chevron-down"></i></span></a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown02">
              <li><a class="dropdown-item" href="https://irisirp.com/" target="new" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">About IRIS IRP</a></li>
              <li><a class="dropdown-item" href="https://irisgst.com/" target="new" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">About IRIS GST</a></li>
              <li><a class="dropdown-item" href="https://irisbusiness.com/" target="new" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">About IRIS Business</a></li>
              </ul>
          </li> -->
          <li class="nav-item highDiv pl0">
            <a class="nav-link" [class.active]="isMenuSelected == 'Sandbox'"
              class="btn btn-primary rounded p13 btnContact1" href="https://sandbox.einvoice6.gst.gov.in/signup"
              target="_blank">Sandbox</a>
          </li>
          <li class="nav-item highDiv pl0">
            <a class="nav-link" [class.active]="isMenuSelected == 'Login'"
              class="btn btn-primary rounded p13 btnContact" (click)="redirectTOLoginPage()">Login</a>
          </li>
          <li class="nav-item dropdown pl0">
            <a class="btnFont" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">A </a>
            <ul class="dropdown-menu t60" aria-labelledby="navbarDropdown02">
              <li><a (click)="changeSizeByBtn('20px')" class="mr dropdown-item text-center"
                  href="javascript:;">A<sup>+</sup></a></li>
              <li><a href="javascript:;" class="dropdown-item text-center"
                  (click)="changeSizeByBtn('unset')">A<sup>-</sup></a></li>
            </ul>
          </li>
          <li class="nav-item dropdown pl0">
            <a class="btnLang " href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg">
            </a>
            <ul class="dropdown-menu t60 left-3" aria-labelledby="navbarDropdown02">
              <li><a href="https://einvoice6.gst.gov.in/content/hindi/" class="dropdown-item"><img
                    src="../../../../assets/images/landingpage/hindi.png" class="langImg"> हिन्दी </a></li>
              <li><a href="https://einvoice6.gst.gov.in/content/tamil/" class="dropdown-item"><img
                    src="../../../../assets/images/landingpage/tamil.png" class="langImg"> தமிழ் </a></li>
              <li><a href="https://einvoice6.gst.gov.in/content/marathi/" class="dropdown-item"><img
                    src="../../../../assets/images/landingpage/marathi.png" class="langImg"> मराठी </a></li>
              <li><a href="https://einvoice6.gst.gov.in/content/gujrati/" class="dropdown-item"><img
                    src="../../../../assets/images/landingpage/gujrati.png" class="langImg"> ગુજરાતી</a></li>
            </ul>
          </li>


        </ul>

      </div>
    </div>
  </nav>
  <!-- <div class="flasingInfo">
    <p>Generate e-invoices, send payment links and reminders, and streamline cashflow with <a class="blink_me" style="color: #319645;font-size: 18px;font-weight: 500;" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website" href="https://irisperidot.com/login" target="_blank" rel="noopener" (click)="generateEvent()">IRIS Peridot Plus</a></p>
  </div> -->
  <div class="container-fluid px-0" style="position: relative;">
    <marquee class="marque mt-0 font-size-14" *ngIf="marquee && marquee.newsBody">
      {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}} <a
        style="color: #007ad9; font-size: 14px; font-weight:500;" *ngIf="marquee.moreInfoExternalLink"
        [href]="marquee.moreInfoExternalLink" target="_blank" class="font-size-14">from here</a>
    </marquee>
  </div>

  <ng-template #template>
    <div class="modal-body">
      <p>The link shall take you to a page outside the einvoice6.gst.gov.in. For any query regarding the contents of the
        linked page, please contact concerned website</p>
    </div>
    <div class="modal-footer">
      <a class="text_black text-decoration-none" (click)="modalRef?.hide()">Skip</a>

      <button type="button" class="btn btn_primary_theme font-size-14 ms-2" (click)="redirectToPage()">
        OK
      </button>

    </div>
  </ng-template>
  <router-outlet></router-outlet>
</div>
<div class="container-fluid" id="increaseFont" style="background-color: #faf9fa;">
  <div class="row vh-100 align-items-center banner-sidebar customVh">
    <div class="col-lg-5 text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12  px-lg-5 text-lg-start">
          <h6>IRIS IRP for an easy, efficient and enhanced E-invoicing journey</h6>
          <ul>
            <li>Manage e-invoicing for multiple GSTINs from single login</li>
            <li>Bulk IRN generation as well as cancellation</li>
            <li>Multiple modes for generation – API, Web and Desktop Utility</li>
            <li>Simple and intuitive UI designed for business teams</li>
          </ul>
          <h6>Sign-up and explore the value-added services, extending beyond e-invoicing</h6>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
        <div class="card-body">
          <form [formGroup]="resetPasswordForm">
            <h2 class="card-title mb-5" style="font-weight: 700 !important; color: #252525;">Reset your
              password</h2>
            <div class="row">
              <div class="col-12 small text-muted mb-3">
                <i class="bi bi-info-circle-fill"></i> Password shall have:
                <ul>
                  <li>Minimum 8 and Maximum 16 characters</li>
                  <li>Atleast one character in uppercase and lowercase
                  </li>
                  <li>Atleast one special character
                  </li>
                  <li>Atleast one numeric value
                  </li>
                </ul>
              </div>
              <div class="col-12 mb-3">
                <label for="exampleFormControlInput2" class="form-label"><b>New Password</b><span
                    style="color: red">*</span></label>
                <div class="col-12 position-relative">
                  <div class="custom_input">
                    <div class="input-icons">

                      <i class="bi bi-eye icon" *ngIf="show" (click)="onClick()"></i>
                      <i class="bi bi-eye-slash icon" *ngIf="!show" (click)="onClick()"></i>
                      <input [type]="password" class="form-control" name="password" formControlName="password"
                        (ngModelChange)="checkPasswordMatch()" placeholder="********">
                    </div>
                    <div *ngIf="resetPasswordForm.get('password')!.invalid" class="text-danger m-1">
                      <p class="m-0 text-danger font-size-12" *ngIf="resetPasswordForm.get('password')!.touched && resetPasswordForm.get('password')!.value === ''
                                          ">* Password is required
                      </p>
                      <p *ngIf="resetPasswordForm.get('password')!.touched && resetPasswordForm.get('password')!.value !== '' && resetPasswordForm.get('password')!.errors!['minlength']
                                          " class="m-0 text-danger font-size-12">* Your password must have minimum 8
                        characters
                      </p>
                      <p *ngIf="resetPasswordForm.get('password')!.touched && resetPasswordForm.get('password')!.value !== '' && resetPasswordForm.get('password')!.errors!['maxlength']
                                      " class="m-0 text-danger font-size-12">* Your password must have maximum 16
                        characters long
                      </p>
                      <p *ngIf="resetPasswordForm.get('password')!.touched && resetPasswordForm.get('password')!.value !== '' && resetPasswordForm.get('password')!.errors!['hasCapitalCase']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                        least 1 capital letter
                      </p>
                      <p *ngIf="resetPasswordForm.get('password')!.touched && resetPasswordForm.get('password')!.value !== '' && resetPasswordForm.get('password')!.errors!['hasSpecialCharacters']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                        least 1 special character
                      </p>
                      <p *ngIf="resetPasswordForm.get('password')!.touched && resetPasswordForm.get('password')!.value !== '' && resetPasswordForm.get('password')!.errors!['hasNumber']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                        least 1 number
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="exampleFormControlInput2" class="form-label"><b>Confirm Password</b> <span
                    style="color: red">*</span></label>
                <div class="col-12 position-relative">
                  <div class="custom_input">
                    <div class="input-icons">

                      <i class="bi bi-eye icon" *ngIf="showNew" (click)="onClickNew()"></i>
                      <i class="bi bi-eye-slash icon" *ngIf="!showNew" (click)="onClickNew()"></i>
                      <input type="password" class="form-control" name="confirmPassword" [type]="passwords"
                        formControlName="confirmPassword" (ngModelChange)="checkPasswordMatch()" placeholder="********">
                    </div>
                  </div>
                  <div *ngIf="!passwordMatched">
                    <span class="font-size-12 text-danger">New Password and Confirm Password
                      should
                      match</span>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3 text-center">
                <button type="submit" class="btn btn-dark py-2"
                  [disabled]="(!resetPasswordForm.value.password ||  !resetPasswordForm.value.confirmPassword) || !passwordMatched"
                  (click)="resetPassword()">Reset Password</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


</div>
<div class="container-fluid footer">
  <div class="row footerDiv">
    <div class="col-md-9">
      <h3 class="display-4 text-white">IRIS IRP is govt authorised E-Invoice Registration Portal</h3>
    </div>
    <div class="col-md-3">
      <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank"
        class="btn btn-primary rounded mb-0 text-nowrap">Contact Us</a>
    </div>
    <hr class="hrdiv">
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">Important links</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.gstn.org.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://cbic-gst.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">CBIC</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/notifications/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GST
            notifications</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.india.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">National
            Portal</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf"
            target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN
            Authorization of IRIS IRP</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            IRP Sandbox</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">IRIS IRP Policies</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">Terms and Condition</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/privacy/">Privacy Policy</a>
        </li>
        <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Disclaimer
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Grievance Redressal
        </li> -->
      </ul>

    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">About IRIS</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            IRP</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            GST</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisbusiness.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            Business</a>
        </li>

      </ul>
      <h4 class="widget-title text-white mb-3">Need Help?</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="mailto:support@irisirp.com">Write to: support@irisirp.com</a>
        </li>
        <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          Feedback
        </li> -->
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">Other Products</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a
            href="https://irisgst.com/irissapphire/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            GST Software</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-onyx/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            E-Invoicing</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/gst-litigation-management-system/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            Litigation Management</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iristopaz/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            E-Way Bill</a>
        </li>

        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-lookup/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            LookUp</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/irisperidot/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS
            Peridot</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://easywaybill.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">EWB
            app for transporters</a>
        </li>
      </ul>
    </div>
    <hr class="hrdiv mb10">
    <div class="row">
      <div class="col-md-3">
        <div class="social social-white social-center">
          <span><a href="https://twitter.com/IRIS_IRP" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-twitter"></i></a></span>
          <span><a href="https://www.linkedin.com/showcase/82795027/admin/" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-linkedin"></i></a></span>
          <span><a href="https://www.youtube.com/@irisirp" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-youtube"></i></a></span>
        </div>
      </div>
      <div class="col-md-6">
        <p class="text-center text-white">© 2023 IRIS Business Services Ltd. All rights reserved.</p>
      </div>
      <div class="col-md-3 social-center">
        <span><img class="responsive" style="height: 40px;width: 100px;"
            src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png"
            alt=""></span>

      </div>
    </div>
  </div>
</div>