import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ApiHeaderComponent } from '../apiIntegration/api-dashboard/api-header/api-header.component';
import { ChangePasswordPostLoginComponent } from '../loginSignup/change-password-post-login/change-password-post-login.component';
import { HeaderComponent } from '../taxPayer/taxpayer-dashboard/header/header.component';
import { TaxpayerHomeDashboardComponent } from '../taxPayer/taxpayer-dashboard/taxpayer-home-dashboard/taxpayer-home-dashboard.component';
import { ApiIntegrationHeaderComponent } from './api-integration-header/api-integration-header.component';
import { GreivanceStatusComponent } from './greivance-status/greivance-status.component';
import { GreivanceComponent } from './greivance/greivance.component';
import { TestComponent } from './test/test.component';
import { NoGstinScreenComponent } from '../taxPayer/taxpayer-dashboard/no-gstin-screen/no-gstin-screen.component';

const routes: Routes = [
  {
    path: 'gstin',
    component: HeaderComponent,
    canActivate: [AuthGuard],
    data: { roles: ['TAXPAYER'] },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import(
            '../taxPayer/taxpayer-dashboard/taxpayer-dashboard.module'
          ).then((m) => m.TaxpayerDashboardModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../taxPayer/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'bulk-operation',
        loadChildren: () =>
          import('../taxPayer/bulk-operation/bulk-operation.module').then(
            (m) => m.BulkOperationModule
          ),
      },
      {
        path: 'e-invoice',
        loadChildren: () =>
          import('../taxPayer/e-invoice/e-invoice.module').then(
            (m) => m.EInvoiceModule
          ),
      },
      {
        path: 'analytics',
        loadChildren: () =>
          import('../taxPayer/analytics/analytics.module').then(
            (m) => m.AnalyticsModule
          ),
      },
      {
        path: 'utilities',
        loadChildren: () =>
          import('../taxPayer/utilities/utilities.module').then(
            (m) => m.UtilitiesModule
          ),
      },
      {
        path: 'change-password',
        component: ChangePasswordPostLoginComponent,
      },
      {
        path:'no-gstin',
        component:NoGstinScreenComponent
      }
    ],
  },
  {
    path: 'api',
    component: ApiHeaderComponent,
    canActivate: [AuthGuard],
    data: { roles: ['API_INTEGRATOR'] },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('../apiIntegration/api-dashboard/api-dashboard.module').then(
            (m) => m.ApiDashboardModule
          ),
      },
      {
        path: 'sandbox',
        loadChildren: () => 
          import('../apiIntegration/sandbox/sandbox.module').then(
            (m) => m.SandboxModule
          ),
      },
      {
        path: 'production',
        loadChildren: () =>
          import('../apiIntegration/production/production.module').then(
            (m) => m.ProductionModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../apiIntegration/api-profile/api-profile.module').then(
            (m) => m.ApiProfileModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import(
            '../apiIntegration/manage-ip-clientsecret/manage-ip-clientsecret.module'
          ).then((m) => m.ManageIpClientsecretModule),
      },
      {
        path: 'change-password',
        component: ChangePasswordPostLoginComponent,
      },
    ],
  },
  {
    path: 'greivance',
    component: GreivanceComponent,
  },
  {
    path: 'greivance-status',
    component: GreivanceStatusComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
