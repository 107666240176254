export const Patterns = {
    emailPattern : /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/,
    gstinPattern : /^(([0-9]{2}[0-9A-Z]{13})|URP)$/,
    documentNoPattern : /^([a-zA-Z1-9]{1}[a-zA-Z0-9\/-]{0,15})$/,
    twoDigitDecimalPattern : /^\d+.?\d{0,2}$/,
    threeDigitDecimalPattern : /^\d+.?\d{0,3}$/,
    numberPattern : /^[0-9]*$/,
    HsnnumberPattern : /^(?:\d{4}|\d{6}|\d{8})$/,
    SrnumberPattern : '^[1-9]{1}[0-9]{0,5}$',
    gstAlphanumericPattern : /^[a-zA-Z0-9]{15}|URP$/,
    lglNamePattern : /^[a-zA-Z0-9!@#$%^&*()-_=+:;,./\s]{3,100}$/,
    addressPattern : /^[a-zA-Z0-9-,./\s]{1,100}$/,
    locPattern:/^[a-zA-Z0-9!@#$%^&*()-_=+:;,./\s]{3,50}$/,
    pincodePattern:/^[0-9]{6}$/,
    rndOffPattern: /^-?\d*(\.\d{0,2})?$/,
    discountPattern: /^\\d+.?\\d{0,2}$/,
    gstValPattern: /^\\d+.?\\d{0,2}$/,
    assValPattern: /^\\d+.?\\d{0,2}$/,
    quantityPattern : '^[0-9][0-9]*[.]?[0-9]{0,3}$',
    twoDigitNewPattern : '^[0-9][0-9]*[.]?[0-9]{0,2}$',
    threeDigitNewPattern : '^[0-9][0-9]*[.]?[0-9]{0,3}$',
    newNumberPattern: '/^[0-9]*$/',
    usernamePattern : /^[A-Za-z0-9_@$-]{4,19}$/,
    quantityLengthPattern : /^(?!$)\d{0,10}(?:\.\d{1,3})?$/,
    unitPriceLengthPattern : /^(?!$)\d{0,12}(?:\.\d{1,3})?$/,
    passwordPattern : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    vehicleNoPattern : /^[A-Z0-9]{4,20}$/,
    distancePattern : /^\d+(\.\d{0,2})?$/,
    transporterIdPattern : /^[A-Z]{2}\d{2}\d{2}\d{2}\d{2}\d{5}$/
}
