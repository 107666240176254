<div class="custom_container">
  <div class="row">
    <div class="col-md-12" *ngIf="!setCaptch">
      <div class="iris-dashboard-cards2">
        <div class="bannerImg highDiv">
          <img src="../../../../../assets/images/landingpage/bannerImg.png" height="200" />
          <a class="btn btn-primary rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">Try
            Sandbox today</a>
        </div>
        <div class="smallDiv">
          <div class="bannerDiv">
            <p>E-invoicing mandatory for taxpayers with AATO of Rs.5cr or more than 1<sup>st</sup> August</p>
            <p>(GST Notification No. 10/2023)</p>
            <p><a class="btn rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">Try
              Sandbox today</a></p>
          </div>
          <!-- <a href="https://sandbox.einvoice6.gst.gov.in/login"><img
              src="../../../../../assets/images/landingpage/sandboximg.png" height="250" width="100%" /></a> -->
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-9 mt15">
          <div class="row">
            <div class="col-md-12">
              <div class="iris-dashboard-cards iris-news-updates h265">
                <h4 class="mb-4">Verify e-invoice by uploading QR Code</h4>
                <div class="content">
                  <div class="mb-3 row">
                    <label for="gstin" class="col-sm-3 col-form-label">QR Code:</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" [(ngModel)]="qrCode" />&nbsp;
                    </div>
                    <div class="mb-3 row ">
                      <div class="col-md-12 text-end">
                        <button class="btn btn-outline-dark px-4 me-2" (click)="resetQR()">CLEAR</button>

                        <button class="btn btn-dark px-4" (click)="verifyQR()">VERIFY</button>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3" *ngIf="!setCaptch">
              <div class="iris-dashboard-cards2">
                <img src="../../../../../assets/images/landingpage/sandboximg.png" height="200" />
              </div>
            </div> -->
          </div>
          <div class="iris-dashboard-cards iris-news-updates mt15" *ngIf="showResultsQR">
            <div class="visibleGstin" id="qrVisible">
              <h4 *ngIf="!isErrorPresent" class="mb-4 success-field"> Digitally signed E-Invoice</h4>
              <h4 *ngIf="isErrorPresent" class="mb-4 error-field text-center">Error Details</h4>

              <div class="content text-start ms-0">
                <div class="mb-3 row">
                  <div class="col-12">
                    <ng-container *ngIf="isErrorPresent">
                      <div class="card-body "><br />
                        <div class="row text-center">
                          <div class="col-md-12">
                            <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                            <p>{{errorMessage}}</p>
                          </div>

                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!isErrorPresent">
                      <div class="card-body" style=" min-height: 200px;"><br />
                        <div class="row">
                          <div class="col-md-12 col-12 font-size-14">
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Supplier GSTIN : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.SellerGstin}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Recipient GSTIN : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.BuyerGstin}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Document Number : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.DocNo}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Document Type : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{invTypeMap && qrcodeData &&
                                  qrcodeData.DocTyp?invTypeMap[qrcodeData?.DocTyp]:''}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Document Date : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span
                                  class="textBreakAll">{{formatDate(qrcodeData?.DocDt,'DD/MM/YYYY','DD-MM-YYYY')}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Total Invoice value : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.TotInvVal.toLocaleString('en-IN',{
                                  minimumFractionDigits: 2 })}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">No of line items : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.ItemCnt}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Main HSN Code : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.MainHsnCode}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">IRN : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.Irn}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">IRN Date : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{formatDate(qrcodeData?.IrnDt,'YYYY-MM-DD
                                  HH:mm:ss','DD-MM-YYYY HH:mm:ss')}}</span>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3 col-12">
                                <label class="static-label lbl">Issued by : </label>
                              </div>
                              <div class="col-md-9 col-12">
                                <span class="textBreakAll">{{qrcodeData?.iss}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt15">
          <div class="iris-dashboard-cards iris-news-updates">
            <h4 class="mb-4">Verify QR Code</h4>
            <div class="content">
              <p>IRIS IRP’s verify QR Code functionality allows you to ascertain if the invoice is registered and
                digitally
                signed by the IRP itself. Also, Basic invoice details available in QR Code such as the Supplier GSTIN,
                Buyer
                GSTIN, Document number, Document type, Document date, Invoice value, HSN code, IRN, IRN date and digital
                signature of IRP can be decoded using this functionality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>