<div class="">
  <app-auth-header></app-auth-header>
  <!-- Marquee moved to header-->
  <!-- <div class="col-12 font-size-12 top-space-55">
    <marquee class="marquee-body" *ngIf="marquee && marquee.newsBody">
      {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}}
    </marquee>
  </div> -->
  <!-- <div class="custom_container mt-3 px-3">
    <div [class.marquee-space]="!marquee ">
       <app-common-gstin-dropdown></app-common-gstin-dropdown>

    </div>

  </div> -->

  <router-outlet></router-outlet>
</div>
<div class="container-fluid">
    <div class="row vh-100 align-items-center banner-sidebar customVh">
        <div class="col-lg-5 text-center diplayFooter ">
            <img src="assets/images/Infographic.png" class="img-fluid" />
            <div class="row">
                <div class="col-12 px-lg-5 text-lg-start">
                    <h4>IRIS IRP for an easy, efficient and enhanced E-invoicing journey</h4>
                    <ul>
                        <li>Manage e-invoicing for multiple GSTINs from single login</li>
                        <li>Bulk IRN generation as well as cancellation</li>
                        <li>Multiple modes for generation – API, Web and Desktop Utility</li>
                        <li>Simple and intuitive UI designed for business teams</li>
                    </ul>
                    <h5>Sign-up and explore the value-added services, extending beyond e-invoicing</h5>
                </div>
            </div>
        </div>
        <div class="col-lg-7">
            <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
                <div class="card-body">
                    <form [formGroup]="setPasswordForm">
                        <h2 class="card-title mb-5" style="font-weight: 700 !important; color: #252525;">Reset your
                            password</h2>
                        <div class="row">
                            <div class="col-12 small text-muted mb-3">
                                <i class="bi bi-info-circle-fill"></i> Password shall have:
                                <ul>
                                    <li>Minimum 8 and Maximum 16 characters</li>
                                    <li>Atleast one character in uppercase and lowercase
                                    </li>
                                    <li>Atleast one special character
                                    </li>
                                    <li>Atleast one numeric value
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 mb-3">
                                <label for="exampleFormControlInput2" class="form-label"><b>New Password</b><span
                                        style="color: red">*</span></label>
                                <div class="col-12 position-relative">
                                    <div class="custom_input">
                                        <input type="password" class="form-control" name="password"
                                            formControlName="password" (ngModelChange)="checkPasswordMatch()"
                                            placeholder="********">
                                        <div *ngIf="setPasswordForm.get('password')!.invalid" class="text-danger m-1">
                                            <p class="m-0 text-danger font-size-12" *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value === ''
                                          ">* Password is required
                                            </p>
                                            <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['minlength']
                                          " class="m-0 text-danger font-size-12">* Your password must have minimum 8
                                                characters
                                            </p>
                                            <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['maxlength']
                                      " class="m-0 text-danger font-size-12">* Your password must have maximum 16
                                                characters long
                                            </p>
                                            <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasCapitalCase']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                                                least 1 capital letter
                                            </p>
                                            <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasSpecialCharacters']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                                                least 1 special character
                                            </p>
                                            <p *ngIf="setPasswordForm.get('password')!.touched && setPasswordForm.get('password')!.value !== '' && setPasswordForm.get('password')!.errors!['hasNumber']
                                          " class="m-0 text-danger font-size-12">* Your password should contain at
                                                least 1 number
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <label for="exampleFormControlInput2" class="form-label"><b>Confirm Password</b> <span
                                        style="color: red">*</span></label>
                                <div class="col-12 position-relative">
                                    <div class="custom_input">
                                        <input type="password" class="form-control" name="confirmPassword"
                                            formControlName="confirmPassword" (ngModelChange)="checkPasswordMatch()"
                                            placeholder="********">
                                    </div>
                                    <div *ngIf="!passwordMatched">
                                        <span class="font-size-12 text-danger">New Password and Confirm Password
                                            should
                                            match</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 my-3 text-center">
                                <button type="submit" class="btn btn-dark px-5 py-2"
                                    [disabled]="(!setPasswordForm.value.password ||  !setPasswordForm.value.confirmPassword) || !passwordMatched"
                                    (click)="setPassword()">SET PASSWORD</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="clearfix"></div>
<!--footer-->
<div class="container-fluid footer">
    <div class="row footerDiv">
      <div class="col-md-9">
        <h3 class="display-4 text-white">IRIS IRP is govt authorised E-Invoice Registration Portal</h3>
      </div>
      <div class="col-md-3">
        <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank" class="btn btn-primary rounded mb-0 text-nowrap">Contact Us</a>
      </div>
      <hr class="hrdiv">
      <div class="col-md-3">
        <h4 class="widget-title text-white mb-3">Important links</h4>
        <ul class="text-white" style="list-style: none; padding-left: 0px;">
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://www.gstn.org.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://cbic-gst.gov.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">CBIC</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisirp.com/notifications/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GST notifications</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://www.india.gov.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">National Portal</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">GSTN Authorization of IRIS IRP</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS IRP Sandbox</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <h4 class="widget-title text-white mb-3">IRIS IRP Policies</h4>
        <ul class="text-white" style="list-style: none; padding-left: 0px;">
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">Terms and Condition</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://einvoice6.gst.gov.in/content/privacy/" >Privacy Policy</a>
          </li>
          <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            Disclaimer
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            Grievance Redressal
          </li> -->
        </ul>

      </div>
      <div class="col-md-3">
        <h4 class="widget-title text-white mb-3">About IRIS</h4>
        <ul class="text-white" style="list-style: none; padding-left: 0px;">
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisirp.com/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS IRP</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisgst.com/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS GST</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisbusiness.com/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS Business</a>
          </li>

        </ul>
        <h4 class="widget-title text-white mb-3">Need Help?</h4>
        <ul class="text-white" style="list-style: none; padding-left: 0px;">
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="mailto:support@irisirp.com">Write to: support@irisirp.com</a>
          </li>
          <!-- <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            Feedback
          </li> -->
        </ul>
      </div>
      <div class="col-md-3">
        <h4 class="widget-title text-white mb-3">Other Products</h4>
        <ul class="text-white" style="list-style: none; padding-left: 0px;">
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a href="https://irisgst.com/irissapphire/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS GST Software</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisgst.com/iris-onyx/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS E-Invoicing</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisgst.com/gst-litigation-management-system/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS Litigation Management</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisgst.com/iristopaz/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS E-Way Bill</a>
          </li>

          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisgst.com/iris-lookup/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS LookUp</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://irisgst.com/irisperidot/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">IRIS Peridot</a>
          </li>
          <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
            <a href="https://easywaybill.in/" target="blank" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">EWB app for transporters</a>
          </li>
        </ul>
      </div>
      <hr class="hrdiv mb10">
      <div class="row">
        <div class="col-md-3">
          <div class="social social-white social-center">
            <span><a href="https://twitter.com/IRIS_IRP"  target="blank"><i
                  style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                  class="bi bi-twitter"></i></a></span>
            <span><a href="https://www.linkedin.com/showcase/82795027/admin/"  target="blank"><i
                  style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                  class="bi bi-linkedin"></i></a></span>
            <span><a href="https://www.youtube.com/@irisirp"  target="blank"><i
                  style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                  class="bi bi-youtube"></i></a></span>
          </div>
        </div>2023 IRIS Business Services
        <div class="col-md-6">
          <p class="text-center text-white">© 2023 IRIS Business Services Ltd. All rights reserved.</p>
        </div>
        <div class="col-md-3 social-center">
          <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
          <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png" alt=""></span>

        </div>
      </div>
    </div>
  </div>
