import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GSTDropdownService {
   
    gstSharingData = new  BehaviorSubject(null) ; 
    marqueeAndNewsSharingData= new BehaviorSubject(null);

    constructor() {
    }
   

}
