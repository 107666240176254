import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { API_STATUS } from 'src/app/constant';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  token = '';
  passwordMatched = true;

  setPasswordForm: FormGroup;
  userId: any;
  userName: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private toastrService: ToastrService
  ) {}

  /**
   * Initialize component
   */
  async ngOnInit() {
    this.userId =  this.route.snapshot.paramMap.get('userId');
    this.userName = this.route.snapshot.paramMap.get('userName');
    this.initForm();
    this.route.queryParams.subscribe((routeParams) => {
      this.token = routeParams.token;
    });
  }

  // Initialize set password form
  initForm(){
    this.setPasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        this.patternValidator(/['/;\[\]\\!@#+_\-=$%^&*(),~`.?":{}|<>]/, {
          hasSpecialCharacters: true,
        }),
      ]),
      confirmPassword: new FormControl()
    });
  }
  
    /**
   * validate password pattern
   * @param regex regex to match
   * @param error error object to add to form control
   * @returns validation function to be used in form control
   */
     patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
      return (control: AbstractControl): any => {
        if (!control.value) {
          return null;
        }
        const valid = regex.test(control.value);
        return valid ? null : error;
      };
    }


  /**
   * set password for user if passwords matches
   * @returns void
   */
  async setPassword() {
    this.checkPasswordMatch();
    if (!this.passwordMatched) return;
    let res;
    let passwordDetails = {
      confirmPassword: this.setPasswordForm.controls.confirmPassword.value,
      newPassword: this.setPasswordForm.controls.password.value,

      userId: this.userId,
    };
    try {
      res = await this.loginService.setPassword(passwordDetails);
      if (res.status === API_STATUS.error) {
        let passwordErr = '';
        if (res.fieldErrors) {
          res.fieldErrors.forEach(
            (err: any) => (passwordErr += err.message + '</br>')
          );
        }
        this.toastrService.error(passwordErr, res.message, {
          enableHtml: true,
        });
        return;
      }
      this.toastrService.success(res.message, '', { enableHtml: true })
      this.router.navigate(['/login']);
    } catch (e) {
      this.toastrService.error('Password not created');
    }
  }

  /**
   * check whether password and confirm password both are same or not
   * @returns boolean
   */
  checkPasswordMatch() {
    if (this.setPasswordForm.value.password && this.setPasswordForm.value.confirmPassword) {
      this.passwordMatched = this.setPasswordForm.value.password === this.setPasswordForm.value.confirmPassword;
    }
    return this.passwordMatched;
  }
}
