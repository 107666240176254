<div class="custom_container">
  <div class="row">
    <div class="col-md-12" *ngIf="!setCaptch">
      <div class="iris-dashboard-cards2">
        <div class="bannerImg highDiv">
          <img src="../../../../../assets/images/landingpage/bannerImg.png" height="200" />
          <a class="btn btn-primary rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">Try
            Sandbox today</a>
        </div>
        <div class="smallDiv">
          <div class="bannerDiv">
            <p>E-invoicing mandatory for taxpayers with AATO of Rs.5cr or more than 1<sup>st</sup> August</p>
            <p>(GST Notification No. 10/2023)</p>
            <p><a class="btn rounded p13 btnContact" href="https://sandbox.einvoice6.gst.gov.in/login">Try
              Sandbox today</a></p>
          </div>
          <!-- <a href="https://sandbox.einvoice6.gst.gov.in/login"><img
              src="../../../../../assets/images/landingpage/sandboximg.png" height="250" width="100%" /></a> -->
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-9 mt15">
          <div class="row">
            <div class="col-md-12">
              <div class="iris-dashboard-cards iris-news-updates h265">
                <div class="col-12 text-end">

                </div>
                <h4 class="mb-4">Search Einvoice enablement status</h4>

                <div class="content">
                  <div class="mb-3 row">
                    <label for="gstin" class="col-sm-3 col-form-label">GSTIN: </label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="gstin" name="gstin" [(ngModel)]="gstin"
                        (input)="checkCaptcha()" placeholder="GSTIN">
                    </div>

                  </div>
                  <div class="mb-3 row ww" *ngIf="!setCaptch && captchaVisible1">
                    <label for="gstin" class="col-sm-3 col-form-label"></label>
                    <label for="gstin" *ngIf="!setCaptch"
                      class="col-sm-3 text-center font-size-30 captchaTxt">{{this.captchString}}</label>
                    <!-- <label *ngIf="!setCaptch">{{this.captchString}}</label> -->
                    <div class="col-sm-3">
                      <button title="Refresh Data" *ngIf="!setCaptch" class="btn btn-sm custom-icon-button"
                        (click)="generateRandomString(5)">
                        <!-- <i class="bi bi-arrow-clockwise "></i> -->
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.0625 3.3457C15.25 1.5332 12.7625 0.408203 9.99995 0.408203C4.47495 0.408203 0.0124512 4.8832 0.0124512 10.4082C0.0124512 15.9332 4.47495 20.4082 9.99995 20.4082C14.6625 20.4082 18.5499 17.2207 19.6625 12.9082H17.0625C16.0375 15.8207 13.2625 17.9082 9.99995 17.9082C5.86245 17.9082 2.49995 14.5457 2.49995 10.4082C2.49995 6.2707 5.86245 2.9082 9.99995 2.9082C12.075 2.9082 13.9249 3.7707 15.275 5.1332L11.25 9.1582H20V0.408203L17.0625 3.3457Z"
                            fill="#252525" />
                        </svg>

                      </button>
                    </div>
                    <label for="gstin" class="col-sm-3 col-form-label"></label>



                  </div>
                  <div class="mb-3 row" >
                    <label for="gstin" class="col-sm-3 col-form-label hideDiv"></label>
                    <div class="col-sm-4" *ngIf="captchaVisible1">
                      <input type="text" class="form-control" id="gstin" *ngIf="!setCaptch" name="gstin"
                        [(ngModel)]="captcha" placeholder="Enter Captcha">
                    </div>
                    <div class="text-end" [ngClass]="captchaVisible1 ? 'col-sm-5' : 'col-md-9'">

                      <button class="btn btn-outline-dark px-4 me-2" (click)="clear()">CLEAR</button>
                      <button class="btn btn-dark px-4" *ngIf="setCaptch" (click)="searchEinvoice()">SEARCH</button>
                      <button class="btn btn-dark px-4" *ngIf="!setCaptch" (click)="searchEinv()">SEARCH</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3" *ngIf="!setCaptch">
          <div class="iris-dashboard-cards2">
            <img src="../../../../../assets/images/landingpage/sandboximg.png" height="200" />
          </div>
        </div> -->
          </div>
          <div class="iris-dashboard-cards iris-news-updates mt15" *ngIf="showResults">
            <div class="visibleGstin" id="visibleGstin">
              <h4 *ngIf="!isErrorPresent" class="mb-4 success-field ">Search Einvoice enablement status</h4>
              <h4 *ngIf="isErrorPresent" class="mb-4 error-field text-center">Error Details</h4>

              <div class="content text-start ms-0">
                <div class="mb-3 row">
                  <div class="col-12">
                    <ng-container *ngIf="isErrorPresent">
                      <div class="row mt-3 text-center">
                        <div class="col-md-12">
                          <img src="../../../../../assets/images/No-Data-found.png" height="200" />
                          <p>{{errorMessage}}</p>
                        </div>

                      </div>
                      <div class="col-12 text-end">
                        <button class="btn btn-dark px-4 mb-3" (click)="getUpdatedData()">Update From Common
                          Portal</button>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!isErrorPresent">
                      <div class="row mt-3">
                        <div class="col-md-12 col-12 font-size-14">

                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">GSTIN : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.gstin}}</span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">Trade Name : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.tradeNm}}</span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">Legal Name : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.legalNm}}</span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">Enablement Status : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.einvoiceEnable}}
                              </span>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">Type : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.type}}</span>
                            </div>
                          </div>

                          <div class="row mb-3" *ngIf="!showReason">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">Enablement Date : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{invDetail?.einvoiceDate}}</span>
                            </div>
                          </div>
                          <div class="row mb-3" *ngIf="showReason">
                            <div class="col-md-4 col-12">
                              <label class="static-label lbl">Reason : </label>
                            </div>
                            <div class="col-md-8 col-12">
                              <span class="textBreakAll">{{reasonValue}} <span *ngIf="showDisclaimer"
                                  style="color: red;">*</span></span>
                            </div>
                          </div>
                          <div class="col-12 text-end">
                            <button class="btn btn-dark px-4 mb-3" (click)="getUpdatedData()">Update From Common
                              Portal</button>
                          </div>
                          <div *ngIf="showDisclaimer" class="col-12 mt-2">
                            <span style="color: red;">*</span>TCS GSTINs are not allowed to issue e-Invoices for the
                            supplies made by them. If the
                            TCS GSTINs intend to take registration for issuing e-Invoices on behalf of their suppliers,
                            they
                            can do so by
                            registering themselves in any notified Invoice Registration Portal (IRP).

                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt15">
          <div class="iris-dashboard-cards iris-news-updates ">
            <h4 class="mb-4">IRIS IRP’s e-invoicing enablement</h4>
            <div class="content">
              <div class="font-size-14 text-start mt-3 text-muted"><i class="bi bi-info-circle-fill"></i>
                This option to search taxpayers enabled on IRP is only for facilitation purpose. This listing is shared
                by
                NIC with IRIS IRP and is based on aggregate annual turnover as per GST System derived from GSTR-3B
                filing.
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
              <div class="font-size-14 text-start mt-3 text-muted">
                Where status is enabled doesn’t mean that the GSTIN is supposed to do e-invoicing. If a taxpayer is
                exempt
                from e-invoicing or e-invoicing is not applicable to a taxpayer for any other reason, they need not be
                concerned about the enablement status and may please ignore the same.
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
              <div class="font-size-14 text-start mt-3 text-muted">
                If your PAN has not exceeded the notified Aggregate Annual Turnover(AATO) then raise a ticket at GST
                Common
                Portal : <a href="https://selfservice.gstsystem.in/"
                  title="This is an external link that opens in new tab. You will be moving out of einvoice6.gst.gov.in website"
                  target="_blank">Goods and Services Tax</a>
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
              <div class="font-size-14 text-start mt-3 text-muted">
                In case any registered person, is required to prepare invoice in terms of Rule 48(4) but not enabled on
                this
                portal, he/she may go on GSTN Portal and click on ‘Registration -> e-Invoice Enablement’ on the GST
                portal.
                <!-- <a href="#"
            class="footer-links anchor">Archived Data</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>