<div class="gradient_wrapper position-relative">

  <!--nav-->
  <nav class=" navbar-expand-lg">
    <div class="custom_container">
      <span class="menu-open-icon"><i class="bi bi-list font-size-30"></i></span>
      <div id="mySidenav" class="sidenav d-flex">
        <span class="closebtn"><i class="bi bi-x font-size-30"></i></span>
        <ul #test class="font-size-16" class="navbar-nav me-auto ps-lg-0" style="padding-left: 0.15rem"
          style="font-weight: 700; display: flex; align-items: center">
          <li (click)="redirectToPage('home')">
            <img src="../../../../assets/images/iris-reverse-white-logo.png" class="logo" />
          </li>
          <!-- NEW MENU  -->
          <li class="nav-item dropdown dropdown-mega position-static"  (mouseover)="mouseOver('mouseOveredSandbox')"
          (mouseout)="mouseOut('mouseOveredSandbox')">
            <a class="nav-link dropdown-toggle " (click)="navigationToast('sandbox')"    [class.display]="mouseOveredSandbox"  href="javascript:;"  [class.highlight]="activeMenu == 'Sandbox'"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true">Sandbox</a>
            <div class="dropdown-menu2 shadow  " [class.display]="mouseOveredSandbox" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">

                        <img src="../../../../assets/images/TestGstin.png" class="img-fluid shadow-1-strong rounded"
                          alt="Invoice" />

                        <div (click)="redirectToPage('testGstin')">
                          <h6 class="">
                           Test GSTIN
                          </h6>
                          <p class="wrap">View all the dummy and valid GSTINs added for testing</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/settingIcon.png" class="img-fluid shadow-1-strong rounded"
                          alt="Procurement" />

                        <div (click)="redirectToPage('settings')">
                          <h6 class="">
                           Sandbox Settings
                            <!-- <span class="badge bg-warning custom-badge"> Upcoming </span>  -->
                          </h6>
                          <p class="wrap">Manage Sandbox Credentials and View Sandbox Endpoints</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- Manage -->
          <li class="nav-item dropdown dropdown-mega position-static" (mouseover)="mouseOver('mouseOveredProductionSetting')"
          (mouseout)="mouseOut('mouseOveredProductionSetting')" >
            <a (click)="navigationToast('production')" class="nav-link dropdown-toggle " [class.display]="mouseOveredProductionSetting" href="javascript:;"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" [class.highlight]="activeMenu == 'Production'" aria-expanded="true">Production</a>
            <div class="dropdown-menu2 shadow" [class.display]="mouseOveredProductionSetting" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">

                        <img src="../../../../assets/images/appIcon.png" class="img-fluid shadow-1-strong rounded"
                          alt="Invoice" />
                          <div (click)="redirectToPage('gstin-list')">
                            <h6 class="">
                              Production GSTIN Details
                              <!-- <span class="badge bg-warning custom-badge"> Upcoming </span>  -->
                            </h6>
                            <p class="wrap"> View and Edit GSTIN added for Production Access</p>
                          </div>
                        <!-- <div (click)="redirectToPage('prod-app')">
                          <h6 class="">
                           Production Application
                          </h6>
                          <p class="wrap">View the Production application submitted</p>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/settingIcon.png" class="img-fluid shadow-1-strong rounded"
                          alt="Procurement" />

                        <div (click)="redirectToPage('prod-setting')">
                          <h6 class="">
                            Production Settings
                            <!-- <span class="badge bg-warning custom-badge"> Upcoming </span>  -->
                          </h6>
                          <p class="wrap">Manage Production Credentials and View Production Endpoints</p>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/settingIcon.png" class="img-fluid shadow-1-strong rounded"
                          alt="Procurement" />

                        <div (click)="redirectToPage('gstin-list')">
                          <h6 class="">
                            Production GSTIN Details
                          </h6>
                          <p class="wrap"> View and Edit GSTIN added for Production Access</p>
                        </div>
                      </div>
                    </div> -->
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/file.png" class="img-fluid shadow-1-strong rounded"
                          alt="File" />
                        <div (click)="redirectToPage('view-invoice')">
                          <h6 class="">
                            View Invoices
                          </h6>
                          <p class="wrap">And take actions such as Cancel, Print, Generate eway bill and more</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/loupe.png" class="img-fluid shadow-1-strong rounded"
                          alt="Loupe" />
                        <div  (click)="redirectToPage('search-invoice')">
                          <h6 class="">
                            Search
                          </h6>
                          <p class="wrap">Get or search your e-invoices and eway bill by IRN</p>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/verify.png" class="img-fluid shadow-1-strong rounded"
                          alt="Verify" />
                        <div (click)="redirectToPage('verify-invoice')">
                          <h6 class="">
                            Verify
                          </h6>
                          <p class="wrap">Check the authenticity of IRN, QR code or a signed e-invoice
                          </p>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">

                        <img src="../../../../assets/images/folder.png" class="img-fluid shadow-1-strong rounded"
                          alt="Archived Invoice" />
                        <div>
                          <h6 class="">

                            Archived Invoices
                            <span class="badge bg-success custom-badge"> VAS </span>

                          </h6>
                          <p class="wrap">View and access historical einvoices and eway bills
                          </p>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </li>


          <!-- Analytics-->

          <!-- <li class="nav-item dropdown dropdown-mega position-static" (mouseover)="mouseOver('mouseOveredAnalytics')"
          (mouseout)="mouseOut('mouseOveredAnalytics')">
            <a class="nav-link dropdown-toggle "  [class.display]="" href="javascript:;"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true">Reports</a>
            <div class="dropdown-menu2 shadow purchase-mega-menu " [class.display]="" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <h5>Explicabo voluptas</h5>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/sales.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div (click)="redirectToPage('sales-overview')">
                          <h6 class="">
                            Sales Overview
                          </h6>
                          <p class="wrap">Stay on top of business performance </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/purchase-order.png"
                          class="img-fluid shadow-1-strong rounded" alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Purchase Snapshot
                            <span class="badge bg-warning custom-badge"> Upcoming </span>

                          </h6>
                          <p class="wrap">Get insights from your B2B purchases and maximise ITC</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/warning.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Errors and discrepancies
                            <span class="badge bg-warning custom-badge"> Upcoming </span>

                          </h6>
                          <p class="wrap">Keep a tab on errors and lapses
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </li> -->


          <!-- My Purchases-->
          <li class="nav-item dropdown dropdown-mega position-static"  (mouseover)="mouseOver('mouseOveredReport')"
          (mouseout)="mouseOut('mouseOveredReport')">
            <a class="nav-link dropdown-toggle " (click)="navigationToast('report')"    [class.display]="mouseOveredReport"  href="javascript:;"  [class.highlight]="activeMenu == 'Sandbox'"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true">Reports</a>
            <div class="dropdown-menu2 shadow  " [class.display]="mouseOveredReport" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">

                        <img src="../../../../assets/images/TestGstin.png" class="img-fluid shadow-1-strong rounded"
                          alt="Invoice" />

                        <div (click)="redirectToPage('report')">
                          <h6 class="">
                            List of GSTINs associated
                          </h6>
                          <p class="wrap">View complete list of GSTINs associated with your GSTIN</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="nav-item dropdown dropdown-mega position-static">
            <a class="nav-link dropdown-toggle" target="_blank" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/"
               data-bs-auto-close="outside" aria-expanded="true">Resources</a>
            <div class="dropdown-menu2 shadow  purchase-mega-menu"  data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/data-transfer.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div (click)="redirectToPage('viewShared-invoice')">
                          <h6 class="">
                            View Shared Invoices
                          </h6>
                          <p class="wrap">Get access to your purchase invoices where your vendor has given consent to
                            share
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/report.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Get GSTR 2B from GSTN
                            <span class="badge bg-warning custom-badge"> Upcoming </span>
                          </h6>
                          <p class="wrap">Fetch data from GST portal and monitor your ITC

                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </li>



          <!-- Tools-->


          <!-- Resources (On Web also)-->

          <!-- <li class="nav-item dropdown dropdown-mega position-static">
            <a class="nav-link dropdown-toggle " (mouseover)="mouseOver('mouseOveredRes')" (mouseout)="mouseOut('mouseOveredRes')"
              [class.display]="mouseOveredRes" href="javascript:;" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="true">Resources</a>
            <div class="dropdown-menu2 shadow  " [class.display]="mouseOveredRes" data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                     <h5>Explicabo voluptas</h5>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/041.webp"
                          class="img-fluid shadow-1-strong rounded" alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            GST Law Updates
                          </h6>
                          <p class="wrap">Quick Excel for IRN Generation
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp"
                          class="img-fluid shadow-1-strong rounded" alt="Palm Springs Road" />
                        <div>
                          <h6 class="">
                            User Manual

                          </h6>
                          <p class="wrap">Look for any State, Country, Pincode, Port and more

                          </p>
                        </div>
                      </div>
                    </div>


                    <div class="col-12 col-sm-4 col-md-6 py-3">
                      <div class="header-menu-list">
                        <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp"
                          class="img-fluid shadow-1-strong rounded" alt="Palm Springs Road" />
                        <div>
                          <h6 class="">
                            FAQs
                          </h6>
                          <p class="wrap">Compute PIN to PIN distance

                          </p>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </li>  -->
        </ul>
        <ul class="d-flex justify-content-end align-items-center">




          <!-- <li class="nav-item dropdown dropdown-mega  position-static">
            <a class="nav-link" (mouseover)="mouseOver('mouseOveredNotifications')"
              (mouseout)="mouseOut('mouseOveredNotifications')" [class.display]="mouseOveredNotifications"
              href="javascript:;" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true">
              <i class="font-size-24 bi bi-bell badge-wrapper">

              </i>
            </a>
            <div class="dropdown-menu2 shadow  notifications-mega-menu" [class.display]="mouseOveredNotifications"
              data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12  notification-body">
                      <div class="header-menu-list">




                        <div (click)="redirectToPage('einvoice')">
                          <h6 class="">
                            Bulk Upload EInvoice > Download CSV
                          </h6>
                          <div>
                            <p class=""> <i class="icon-notification bi bi-check-circle-fill font-size-24"></i>
                              Download:28-10-2022 17:50:49</p>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 py-3">
                      <div class="header-menu-list">

                        <div (click)="redirectToPage('einvoice')">
                          <h6 class="">
                            Bulk Upload EInvoice > Download CSV
                          </h6>
                          <div>
                            <p class="">

                              <img src="../../../../assets/images/processing-time.png" class="notification-img" />


                              InProgress:28-10-2022 17:50:49</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </li> -->


          <!-- Settings starts here -->
          <li class="nav-item dropdown dropdown-mega  position-static"  (mouseover)="mouseOver('mouseOveredSettings')"
          (mouseout)="mouseOut('mouseOveredSettings')">
            <a class="nav-link"  href="javascript:;"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true"> <i
                class="bi bi-bell-fill font-size-24"></i></a>
            <div class="dropdown-menu2 shadow  settings-mega-menu"
              data-bs-popper="static">
              <div class="mega-content p-4">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <h5>Explicabo voluptas</h5> -->
                    <!-- <div class="col-12 col-sm-3 col-md-3 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/user.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            General
                          </h6>
                          <div>

                            <p class="wrap">Personal Settings
                            </p>
                            <p class="wrap">Business Profile and Catalog

                            </p>
                            <p>Reset Password

                            </p>
                            <p>Delete my Account

                            </p>
                            <p>Audit Log

                            </p>
                          </div>

                        </div>
                      </div>
                    </div> -->

                    <div class="col-12 col-sm-4 col-md-4 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/relation.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Organisation
                          </h6>
                          <div>

                            <p (click)="redirectToPage('my-gstin')">My GSTINs </p>
                            <p (click)="redirectToPage('manage-api-access')" class="wrap">Manage API Access </p>
                            <p (click)="redirectToPage('manage-users')">Manage Users </p>

                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-4 col-md-4 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/template.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Template
                          </h6>
                          <div>

                            <p class="wrap">Invoice Template </p>
                            <p class="wrap">Email Template </p>

                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-4 col-md-4 py-3">
                      <div class="header-menu-list">
                        <img src="../../../../assets/images/clipboard.png" class="img-fluid shadow-1-strong rounded"
                          alt="Hollywood Sign on The Hill" />
                        <div>
                          <h6 class="">
                            Masters
                          </h6>
                          <div>

                            <!-- <p>Supplier </p> -->
                            <p>Customer </p>
                            <p>Transporter </p>
                            <p>Product and Service </p>

                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- Settings ends here -->
          <li class="d-flex align-items-center">
            <!-- <div class="d-inline-block px-3">
              <i class="bi bi-gear font-size-24"></i>
            </div> -->

            <div class="btn-group profile-btn-group">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {{ formatProfileName(userName) }}
              </button>
              <ul class="dropdown-menu drop-end profile-dropdown">
                <li class="bg-white text-center p-3 pb-0 d-flex align-items-center">
                  <div class="circle rounded-pill bg-light logo-background">
                    <img src="../../../../assets/images/logo.png" class="" width="80">
                  </div>
                  <div class="text-start ps-3">

                    <h6 class="text-dark"> {{ formatProfileName(userName) }}
                    </h6>

                    <span class="text-dark me-2 d-block w-100"><a href="#" style="color: #007ad9"> {{ formatProfileName(userId) }}</a>
                    </span>
                    <span class="text-dark me-2"><i class="bi bi-file-lock2-fill" style="color: #f7b92f;"></i><a  (click)="resetPassword()">Change
                        Password</a>
                    </span>
                    <!-- <span class="text-dark me-2"><a   (click)="resetPassword()">Change
                                      Password</a>
                              </span><span class="text-dark me-2"><a href="#">Settings</a></span> -->
                  </div>
                </li>
                <li class="bg-white px-3" style="height: 0.5px;">
                  <hr class="dropdown-divider m-0">
                </li>
                <li class="bg-white p-2">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 ps-3">
                        <h6 class="text-dark"><b>Need Help?</b></h6>
                        <span class="content py-2 me-3"><i class="bi bi-info-circle-fill" style="color:#5dab00;"></i> <a target="_blank" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">Product
                          Help</a></span>
                        <span class="content py-2" title="This link shall take you to a page outside the einvoice6.gst.gov.in"><i class="bi bi-youtube me-1" style="vertical-align: bottom;color: red;"></i><a href="https://www.youtube.com/channel/UCcn-R6DrQwhyKJBZosmkCnw"  target="_blank"> Help
                          Videos</a></span>

                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-white px-3">
                  <hr class="dropdown-divider m-0 h-auto">
                </li>
                <li class="bg-white p-2">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 ps-3 pe-0">
                        <h6 class="text-dark"><b>Contact Us</b></h6>
                        <ul class="normal-list">
                          <li class="py-2"><img src="../../../../assets/images/feedback.png" class="mailIcon"><a  title="This link shall take you to a page outside the einvoice6.gst.gov.in"  href="https://forms.irisirp.com/IRISBusiness/form/ProductFeedbackAPIIntegratorPortal/formperma/FrKOzdf5LOGSHblYgrki9Sit_C3fnxeJ6vsfztSNS9I" style="left: 2px;" target="_blank">Feedback &
                            Suggestions</a></li>
                          <li class="py-2"><i class="bi bi-envelope-fill" style="color:#007ad9;"></i>
                            Write to us at <a class="d-inline-block mail-anchor p-0"
                              href="mailto: support@irisirp.com" style="color:#007ad9;">support@irisirp.com</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-white">
                  <div class="container-fluid">
                    <div class="row">
                      <!-- <div class="col-6 px-0">
                        <a class="btn btn-dark w-100 rounded-0"
                          style="border-bottom-left-radius: 5px !important;color: white;">My Subscription</a>
                      </div> -->
                      <!-- <div class="col-6 px-0"> -->
                        <button class="btn btn-dark w-100 rounded-0"
                          style="border-bottom-right-radius: 5px !important;color: white;" (click)="logout()">Sign Out</button>
                      <!-- </div> -->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav><br/>
  <!--nav-END-->
  <router-outlet></router-outlet>

  <footer>

      <div>
          <!-- <a href="#"> Help</a>
          <a href="#"> Feedback</a>
          <a href="#"> Contact us</a> -->
          <p class="mt-5 font-size-10">Copyright © 2020 iris e-invoicesystem.com. All rights reserved.</p>
      </div>
  </footer>
</div>
