import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-einvoice-prelogin',
  templateUrl: './verify-einvoice-prelogin.component.html',
  styleUrls: ['./verify-einvoice-prelogin.component.scss']
})
export class VerifyEinvoicePreloginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
