<div class="gradient_wrapper position-relative">
  <!--nav-->
  <nav>
    <div class="custom_container">
      <span class="menu-open-icon"><i class="bi bi-list font-size-30"></i></span>
      <div id="mySidenav" class="sidenav d-flex">
        <span class="closebtn"><i class="bi bi-x font-size-30"></i></span>
        <ul class="font-size-16" style="font-weight: 700; display: flex; align-items: center">
          <li>
            <img src="https://irisirp.com/wp-content/uploads/2022/06/IRISIRP-Logo-1.png" class="logo" />
          </li>

          <li class="dropdown">
            <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <!-- <i class="bi bi-upload"></i>  -->
              Generate
              <i class="bi bi-chevron-down"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">TEXT</a></li>
              <li><a class="dropdown-item" href="#">TEXT</a></li>
            </ul>
          </li>
          <li class="dropdown">
            <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <!-- <i class="bi bi-card-list"></i>  -->
              Manage
              <!-- <i class="bi bi-chevron-down"></i> -->
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">TEXT</a></li>
              <li><a class="dropdown-item" href="#">TEXT</a></li>
            </ul>
          </li>

          <li class="dropdown">
            <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Reports
              <!-- <i class="bi bi-chevron-down"></i> -->
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">TEXT</a></li>
              <li><a class="dropdown-item" href="#">TEXT</a></li>
            </ul>
          </li>
          <li class="dropdown">
            <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <!-- <i class="bi bi-journal-text"></i>  -->
              Utilities
              <!-- <i class="bi bi-chevron-down"></i> -->
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">TEXT</a></li>
              <li><a class="dropdown-item" href="#">TEXT</a></li>
            </ul>
          </li>
          <li class="dropdown">
            <a href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <!-- <i class="bi bi-journal-text"></i> -->
              VAS
              <!-- <i class="bi bi-chevron-down"></i> -->
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">TEXT</a></li>
              <li><a class="dropdown-item" href="#">TEXT</a></li>
            </ul>
          </li>
        </ul>
        <ul class="d-flex justify-content-end align-items-center">
          <li>
            <div class="dropdown">
              <button class="generate-btn" data-bs-toggle="dropdown" id="dropdownMenuButton1">
                Generate Quickly
              </button>

              <ul class="dropdown-menu generate-btn-inner flip-in-hor-bottom" aria-labelledby="dropdownMenuButton1">
                <li>
                  <button class="btn btn-success iris-quick-generate-btn yellow py-2">
                    Single IRN
                  </button>
                  <button class="btn btn-success iris-quick-generate-btn green py-2">
                    Bulk IRN
                  </button>
                </li>
              </ul>
            </div>
          </li>
          <li class="d-flex align-items-center">
            <div class="d-inline-block px-3">
              <i class="bi bi-gear font-size-24"></i>
            </div>

            <div class="user_admin">
              <div class="dropdown">
                <div class="d-flex align-items-center cursor_pointer" data-bs-toggle="dropdown">
                  <span class="user_pic"><img src="../../../assets/images/admin_user.png" /></span>
                  <span class="font-size-14">
                    <!-- Shilpa  -->
                    <i class="bi bi-chevron-down ps-1"></i>
                  </span>
                </div>
                <ul class="dropdown-menu font-size-12" aria-labelledby="dropdownMenuButton1">
                  <li><a href="#">Text-1</a></li>
                  <li><a href="#">Text-2</a></li>
                  <li><a href="#">Text-3</a></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--nav-END-->

  <div class="col-12 font-size-12 top-space-55">
    <marquee style="
        color: #252525;
        background-color: #f1f1f0;
        padding: 6px 0px;
        margin-top: 6px;
      ">
      eInvoicing mandate to be applicable to entites having turnover above Rs.
      10 Crs for any FY from FY 2017-18.
    </marquee>
  </div>
  <div class="custom_container">
    <!-- <div class="col-12 col-md-3 mb-2 mx-auto mt-1">
            <select class="form-select">
                <option>Coconuttree_Maharashtra</option>
            </select>
        </div> -->
  </div>

  <div class="custom_container mt-3 px-3">
    <div class="row mb-1 align-items-center">
      <div class="">
        <!--breadcrumb-->
        <!-- <div class="breadcrumb">
                    <div class="custom_container">
                        <ul class="font-size-14 p-0" style="color:#3565b9">

                            <li><a href="#">My Profile</a></li>
                            <li><a href="#">My GSTIN</a></li>
                            <li><a href="#">GSTIN</a></li>
                        </ul>
                    </div>
                </div> -->
        <!--breadcrumb-END-->
      </div>
      <div class="col-md-3">
        <select class="form-select" style="font-weight: 400; font-size: 20px">
          <option>Coconuttree_Maharashtra</option>
        </select>
      </div>
    </div>
    <div class="row mb-3 align-items-center">
      <div class="col-md-6">
        <!--breadcrumb-->
        <!-- <div class="breadcrumb">
                    <div class="custom_container">
                        <ul class="font-size-14 p-0" style="color:#3565b9">

                            <li><a href="#">My Profile</a></li>
                            <li><a href="#">My GSTIN</a></li>
                            <li><a href="#">GSTIN</a></li>
                        </ul>
                    </div>
                </div> -->
        <!--breadcrumb-END-->
      </div>
      <div class="col-md-6 text-end">
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
          ">
          <span class="card-label font-size-10" style="padding-right: 8px">API Last updated on :22.07.2022</span>
          <button class="btn contextual-button d-flex align-items-center me-2 green-400">
            <i class="bi bi-arrow-clockwise text-white font-size-18" style="line-height: 0"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="iris-dashboard-cards card-green">
          <h4>IRN Generated</h4>
          <hr />
          <div class="row">
            <div class="col card-label">Today</div>
            <div class="col card-value text-end">682</div>
          </div>
          <div class="row mt-3">
            <div class="col card-label">Yesterday</div>
            <div class="col card-value text-end">759</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="iris-dashboard-cards card-yellow last-card">
          <h4>IRN Canceled</h4>
          <hr />
          <div class="row">
            <div class="col card-label">Today</div>
            <div class="col card-value text-end">682</div>
          </div>
          <div class="row mt-3">
            <div class="col card-label">Yesterday</div>
            <div class="col card-value text-end">759</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="iris-dashboard-cards card-green">
          <h4>EWB Generated</h4>
          <hr />
          <div class="row">
            <div class="col card-label">Today</div>
            <div class="col card-value text-end">1560</div>
          </div>
          <div class="row mt-3">
            <div class="col card-label">Yesterday</div>
            <div class="col card-value text-end">570</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="iris-dashboard-cards card-yellow last-card">
          <h4>EWB Canceled</h4>
          <hr />
          <div class="row">
            <div class="col card-label">Today</div>
            <div class="col card-value text-end">6500</div>
          </div>
          <div class="row mt-3">
            <div class="col card-label">Yesterday</div>
            <div class="col card-value text-end">5620</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-12">
        <div class="alert alert-announcement mb-0" role="alert">
          <img src="../../assets/images/bulb-icon.svg" class="announcement-icon" />
          <h4 class="mb-0" style="color: #252525; font-size: 18px">
            Important Announcements
          </h4>
          <div style="color: #252525; font-size: 14px">
            Content goes here Content announcement
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-7">
        <!--alert-->
        <!-- <div class="row ">
                    <div class="col-12">
                        <div class="alert  opt-for-storage-alert mb-0" style="background: white!important;
                        border-color: #fa645c !important" role="alert">
                            <img src="../../../assets/images/alert-icon.png" />
                            <span class="fw-bold font-size-20 me-2">7480</span>
                            Files will be Deleted today

                            <a href="javascript:; " style="padding-left:8px;"> View</a>

                            <button
                                class="btn opt-for-storage-alert-btn ms-2 ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                                <i class="bi bi-check-circle-fill font-size-20 me-1"></i>
                                Opt for more storage
                            </button>
                        </div>
                    </div>
                </div> -->
        <!--//alert-->

        <div class="iris-dashboard-cards mb-4" style="height: 400px">
          <div class="row align-items-center">
            <div class="col-md-12" style="position: relative">
              <h4 class="mb-0">Common Errors in Past 7 Days</h4>

              <button class="btn contextual-button d-flex align-items-center float-end me-2"
                style="position: absolute; right: 0px; top: 0px"  (click)="downloadCanvas($event)">
                <i class="bi bi-download text-white font-size-20" style="line-height: 0"></i>
              </button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 text-center">
              <!-- <img src="../../../assets/images/graph.png" class="img-fluid" /> -->

              <canvas *ngIf="loadChart" baseChart [datasets]="barChartData1" [labels]="labelsArray" [options]="barChartOptions1"
                [legend]="barChartLegend1" [chartType]="barChartType1" >
              </canvas>

            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="my-4">
          <div class="iris-dashboard-cards">
            <div class="row align-items-center">
              <div class="col-12">
                <h4 class="mb-4">Useful Videos</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-10 mx-auto">
                <div class="iris-video-container">
                  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
                    <div class="carousel-indicators">
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                        class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="../../../assets/images/video-placeholder.png" class="d-block w-100 img-fluid"
                          alt="..." />
                      </div>
                      <div class="carousel-item">
                        <img src="../../../assets/images/video-placeholder.png" class="d-block w-100 img-fluid"
                          alt="..." />
                      </div>
                      <div class="carousel-item">
                        <img src="../../../assets/images/video-placeholder.png" class="d-block w-100 img-fluid"
                          alt="..." />
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev">
                      <i class="bi bi-arrow-left-circle font-size-40"></i>

                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next">
                      <i class="bi bi-arrow-right-circle font-size-40"></i>

                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-5">
                    <div class="iris-dashboard-cards">
                        <h4 class="mb-4">Generate Quickly</h4>
                        <p class="font-size-14 my-3">Lorem Ipsum some description about the generate quickly action about
                            may be of two three
                            lines about how it works or what user can do Lorem Ipsum some description about
                            the generate quickly action</p>
                        <hr />
                        <div class="row text-center">
                            <div class="col-md-6 mx-auto  ">
                                <div class="d-grid gap-2 mt-3">
                                    <button class="btn btn-success iris-quick-generate-btn blue py-2">Single IRN</button>
                                    <button class="btn btn-success iris-quick-generate-btn green py-2">Bulk IRN</button>
                                    <button class="btn btn-success iris-quick-generate-btn red py-2">Cancel IRN</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

      <div class="col-lg-5">
        <div class="iris-dashboard-cards iris-news-updates" style="height: 936px">
          <h4 class="mb-4">News & Updates</h4>
          <div style="max-height: 810px; overflow: auto">

<div *ngFor="let news of newsList">
  <div class="">
    <h6>{{news.newsHeader}}</h6>
    <span class="font-size-14 mb-3 text-break">
     {{news.newsBody}}
    </span>
    sadsd
    <div class="col-12 " *ngIf="news.moreInfoExternalLink">
      <a [href]="news.moreInfoExternalLink" target="_blank" class="font-size-14">Know more</a>
    </div>
    <div class="col-6 " *ngIf="news.moreInfoImageLink">
      <img [src]="news.moreInfoImageLink" class="news-img" />
    </div>
  </div>
  <hr   />
</div>




            <!-- <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>
            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>
            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>
            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>
            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>
            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>
            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>
            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div>

            <hr />
            <div class="">
              <h6>Notification 14/2022 issued</h6>
              <p class="font-size-14 mb-3">
                Lorem Ipsum some description about the generate quickly action
                about may be of two three lines about how it works or what user
                can do Lorem Ipsum some description about the generate quickly
                action
              </p>
            </div> -->
          </div>
          <div class="mt-3">
            <div class="col-12 text-end">
              <a href="#" class="font-size-14">View all</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5"></div>
    <div class="clearfix"></div>

    <!-- alert-->
    <div class="row my-4">
      <div class="col-12">
        <div class="alert opt-for-storage-alert mb-0" role="alert">
          <img src="../../../assets/images/alert-icon.png" />
          <span class="fw-bold font-size-20 me-2">7480</span>
          Files will be deleted today
          <button class="btn opt-for-storage-alert-view-btn text-uppercase ms-2 fw-bold">
            View
          </button>

          <button class="btn opt-for-storage-alert-btn ms-2 ms-md-auto me-4 mt-3 mt-md-0 d-flex align-items-center">
            <i class="bi bi-check-circle-fill font-size-20 me-1"></i>
            Opt for more storage
          </button>
          <i class="bi bi-x-lg close-opt-icon"></i>
        </div>
      </div>
    </div>
    <!--//alert -->

    <!--footer-->
    <footer>
      <div>
        <a href="#"> Help</a>
        <a href="#"> Feedback</a>
        <a href="#"> Contact us</a>
        <p class="mt-5 font-size-10">
          Copyright © 2020 iris e-invoicesystem.com. All rights reserved.
        </p>
      </div>
    </footer>
  </div>
  <!--footer-END-->
</div>

<!--generate-side-btn-->
<!--generate-side-btn-->
<!-- <div id="generate" class="generate-side-btn">
    <button class="generate-btn" (click)="generateQuickly()"><span>Generate Quickly</span></button>
    <div class="generate-btn-inner">
        <div>
            <button class="btn btn-success iris-quick-generate-btn blue py-2 ">Single IRN</button>
            <button class="btn btn-success iris-quick-generate-btn green py-2">Bulk IRN</button>
            <button class="btn btn-success iris-quick-generate-btn red py-2 mb-0">Cancel IRN</button>
        </div>
    </div>
</div> -->
<!--generate-side-btn-->