import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GSTDropdownService } from 'src/app/shared/services/gstDropdown.service';
import { ProfileService } from '../profile/profile.service';
import { ToastrService } from 'ngx-toastr';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { EInvoiceService } from '../e-invoice/e-invoice.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';

@Component({
  selector: 'app-plan-subscription',
  templateUrl: './plan-subscription.component.html',
  styleUrls: ['./plan-subscription.component.scss'],
})
export class PlanSubscriptionComponent implements OnInit {
  gstinList = [];
  gstinFilterData: any;

  subscription: any;
  isGSTInAvailable: any;
  selectedGstin: any;
  userType: any;
  modalRef: BsModalRef;
  userTypeOriginal: any;
  lastPlan: any;
  incomingPlanType: string;

  constructor(
    private gstDropdownService: GSTDropdownService,
    private invoiceService: EInvoiceService,
    private taxPayerDashboardService: TaxPayerDashboardService,
    private profileService: ProfileService,
    private toastrService: ToastrService,
    private router: Router,
    private modalService: BsModalService,
    private googleAnalyticsService:GoogleAnalyticsService
  ) {
    // only available when redirect coming from My GSTINs page
    this.incomingPlanType =
      router.getCurrentNavigation().extras?.state?.planType;
  }

  ngOnInit(): void {
    this.subscription = this.gstDropdownService.gstSharingData.subscribe(
      (res: any) => {
        // Get the updated value
        if (res) {
          this.isGSTInAvailable = res.isGSTInAvailable;
          this.selectedGstin = res.selectedGSTIn;
          this.userType = res.userType;
          this.userTypeOriginal = res.userType;
        } else {
        }

      }

    );
        // handles redirects from My GSTINs page, set userType accordingly
    if (this.incomingPlanType) {
      this.userType = this.incomingPlanType;
      this.userTypeOriginal = this.incomingPlanType;
    }
    this.taxPayerDashboardService
      .getGstinFromList()
      .subscribe((gstin: string) => {
        if (!this.invoiceService.isEmpty(gstin)) {
          this.selectedGstin = gstin;
        }
      });
    this.taxPayerDashboardService.setVisibleCommonDropDown('true');
    this.trackAnalytics("Plans_Subscription");

  }
  changePlan(selectedPlan, template) {
    this.userType = selectedPlan;
    if (this.userType == this.userTypeOriginal) {
      console.log('No need to change, this is your current plan!');
    } else if (this.userType == 'VAS') {
      this.openModal(template);
    } else if (this.userType == 'BASIC') {
      this.openModal(template);
    } else if (this.userType == 'VAS_PREMIUM') {
      // this.router.navigate([`/irp/gstin/dashboard/peridot`]);
    }
  }
  redirectToConsent() {
    const data = this.profileService.getGstinsNumberList().subscribe((res) => {
      this.gstinList = res['response'];
      for (let i = 0; i < this.gstinList.length; i++) {
        var Selectgstin = this.selectedGstin;
        const filterGstin = this.gstinList.filter(
          (gstNo: any) => gstNo.gstin === Selectgstin
        )[0];
        this.gstinFilterData = this.gstinList.filter(
          (gstNo: any) => gstNo.gstin === Selectgstin
        )[0];
      }
      if (
        this.gstinFilterData.userRole === 'GENERATOR' ||
        this.gstinFilterData.userRole === 'REVIEWER'
      ) {
        this.toastrService.error(
          'Only Admin/Authorised Signatory can perform this action'
        );
      } else {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.router.navigate([`/irp/gstin/profile/gstins/details`], {
          queryParams: { gstin: this.selectedGstin, consent: true },
        });
      }
    });
    // if (this.modalRef) {
    //   this.modalRef.hide();
    // }
    // this.router.navigate([`/irp/gstin/profile/gstins/details`], {
    //   queryParams: { gstin: this.selectedGstin, consent: true },
    // });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  cancel() {
    this.userType = this.userTypeOriginal;
  }
  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
}
