import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { LoginService } from '../login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { API_STATUS } from 'src/app/constant';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { captchaKey, LocalStorageKey, userType } from 'src/app/app.config';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { GSTDropdownService } from 'src/app/shared/services/gstDropdown.service';
import { Patterns } from 'src/app/pattern';
import { ProfileService } from 'src/app/taxPayer/profile/profile.service';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';
import * as moment from 'moment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  passwords;
  show: boolean;
  // resetPasswordField: string;
  OTPForm: FormGroup;
  forgotPasswordForm: FormGroup;
  apiIntegratedGstin: any;
  apiIntegratedGstinType: any;
  isValidFormSubmitted = null;
  recaptcha;
  siteKey = captchaKey.INVISIBLE;
  marquee: any;
  gstinuserRole: any;
  gstinuserPlan: any;
  mouseOvered: any;
  private captchaChange = new BehaviorSubject<number>(0);

  formList = {
    showLogin: true,
    showForgotPassword: false,
    showResetPasswordForm: false,
    showOTPVerificatonForm: false,
  };
  setPasswordForm: FormGroup;
  passwordMatched = true;
  captchSuccessfulToken: any;
  submittedForgotPassword: boolean;
  popupShown: any;
  isNavbarLoginVisible: boolean;
  captchString: any;
  captchStringForgetPass: any;
  captchaVisible1: boolean = false;
  captchaVisibleForgotPas: boolean = false;
  constructor(
    private formGroup: FormBuilder,
    private sharedService: SharedService,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private profileService: ProfileService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private taxPayerDashboardService: TaxPayerDashboardService,
    private gstDropdownService: GSTDropdownService,
    private invoiceService: EInvoiceService
  ) {
    this.loginForm = this.formGroup.group({
      // userId: [null, [Validators.required]],
      userId: [
        null,
        [Validators.required, Validators.pattern(Patterns.emailPattern)],
      ],
      password: [null, Validators.required],
      captcha:[null,[Validators.required]],
      rememberMe: [null],
    });

    this.OTPForm = this.formGroup.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.forgotPasswordForm = this.formGroup.group({
      userId: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(Patterns.emailPattern),
        ]),
      ],
      captcha:[null,[Validators.required]],
    });
  }

  /**
   * Initialize component
   */
  ngOnInit() {
    this.checkLoginDetails();
    this.initResetPasswordForm();
    this.clearStorage();
    this.captchaChange.asObservable().subscribe((captchaValue) => {
      if (captchaValue != null) {
        this.login('ngOnInit');
      }
    });
    // this.show = true;
    this.passwords = 'password';

    this.taxPayerDashboardService.getisForgetData().subscribe((emited: string) => {
      if (!this.invoiceService.isEmpty(emited) && emited === "true") {
        this.formList.showForgotPassword = false;
        this.formList.showLogin = true;
        this.isNavbarLoginVisible = false;
      }
    })
    this.generateRandomString(5);
    this.generateRandomStringForgetPass(5);
  }
  clearStorage() {
    let isTokenModified = JSON.parse(localStorage.getItem('tk_'));
    if (isTokenModified) {
      // isTokenModified is TRUE only when another user logs in a new tab, in that case,
      // we don't clear the storage because doing so would remove the localStorage and cookies for
      // the newly logged in user in the new tab
      return;
    } else {
      // localStorage.clear();
      localStorage.removeItem(LocalStorageKey.IS_GSTIN_AVAILABLE);
      localStorage.removeItem(LocalStorageKey.SelectedGSTIN);
      localStorage.removeItem(LocalStorageKey.NEWS);
      localStorage.removeItem(LocalStorageKey.GSTINNumber);
      localStorage.removeItem('token');
      localStorage.removeItem('IRN_GEN_YESTRDAY_COUNT');
      localStorage.removeItem('IRN_CNL_COUNT');
      localStorage.removeItem('Y_ERR_COUNT');
      localStorage.removeItem('IRN_GEN_COUNT');
      localStorage.removeItem('T_ERR_COUNT');
      localStorage.removeItem('IRN_CNL_YESTERDAY_COUNT');
      localStorage.removeItem('filters');
      localStorage.removeItem('purchase-filters');
      localStorage.removeItem('verifiedGstin');
      localStorage.removeItem('verified-gstin-userinfo');
      localStorage.removeItem('logged-in-userinfo');
      localStorage.removeItem('UserRole');
      localStorage.removeItem('Intermediary Status');
      localStorage.removeItem('PROD_GSTIN');
      localStorage.removeItem('mouseOvered');
      localStorage.removeItem('type');
      this.sharedService.deleteLoginDetails();
      this.sharedService.deleteUserDetails();
    }
  }

  get userId() {
    return this.loginForm.get('userId');
  }
  get password() {
    return this.loginForm.get('password');
  }
  //  OTP API integartion
  async onSubmit() {
    if (this.OTPForm.invalid) {
      return;
    }
    let dataOTP = {
      userId: this.forgotPasswordForm.value.userId,
      otp: this.OTPForm.value.otp.trim(),
    };
    let response;
    try {
      response = await this.loginService.forgotPasswordOtpVerify(dataOTP);
      if (response.status === API_STATUS.error) {
        let OTPVerifyErr = '';
        if (response.fieldErrors) {
          response.fieldErrors.forEach(
            (err: any) => (OTPVerifyErr += err.message + '</br>')
          );
        }
        this.toastrService.error(OTPVerifyErr, response.message, {
          enableHtml: true,
        });
        return;
      } else if (response.status === API_STATUS.success) {
        this.toastrService.success(response.message, ' ', { enableHtml: true });
        // Redirect User to new forget password page
        this.router.navigate([
          `reset-forget-password/${this.forgotPasswordForm.value.userId}`,
        ]);
      }
    } catch (e) {
      this.toastrService.error('User Not found');
    }
  }

  checkLoginDetails() {
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe === 'true') {
      try {
        const loginDetail = this.sharedService.getLoginDetail();
        if (!loginDetail) {
          return;
        }
        this.loginForm.controls.userId.setValue(loginDetail.email);
        this.loginForm.controls.password.setValue(loginDetail.value);
        this.loginForm.controls.rememberMe.setValue(true);
      } catch (error) {
        this.resetLoginForm();
      }
    } else {
      this.resetLoginForm();
    }
  }

  // Initialize set password form
  initResetPasswordForm() {
    this.setPasswordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        this.patternValidator(/['/;\[\]\\!@#+_\-=$%^&*(),~`.?":{}|<>]/, {
          hasSpecialCharacters: true,
        }),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
  }

  /**
   * validate password pattern
   * @param regex regex to match
   * @param error error object to add to form control
   * @returns validation function to be used in form control
   */
  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  /**
   * Login the user with given credentials
   */
  async login(callThrough: string) {
    if (callThrough && callThrough === 'login') {
      if (
        this.loginForm.controls['userId'].invalid &&
        this.loginForm.controls['userId'].hasError('required')
      ) {
        this.loginForm.controls['userId'].markAsTouched();
      }

      if (
        this.loginForm.controls['password'].invalid &&
        this.loginForm.controls['password'].hasError('required')
      ) {
        this.loginForm.controls['password'].markAsTouched();
      }
      if (this.loginForm.controls.userId.value && this.loginForm.controls.password.value) {
        if (!this.loginForm.controls.captcha.value) { 
          this.toastrService.warning("Please Enter Captcha")
        }
      }
      if (this.loginForm.controls.captcha.value) {    
        if (this.captchString != this.loginForm.controls.captcha.value) { 
          this.toastrService.error("Please Enter Valid Captcha");
        }
      }
    }
    if (this.loginForm.controls.captcha.value) {
      if (this.captchString == this.loginForm.controls.captcha.value) { 
        if (this.loginForm.valid && this.captchSuccessfulToken != null) {
          const data = {
            userId: this.loginForm.value.userId.toLowerCase(),
            password: this.loginForm.value.password,
          };
          let login;
          try {
            login = await this.loginService.login(data);
            if (login && login.response && login.response.apiIntegratedGstinType) {
              this.apiIntegratedGstinType = login.response.apiIntegratedGstinType;
              localStorage.setItem('type', this.apiIntegratedGstinType);
            }
            if (login && login.response && login.response.apiIntegratedGstin) {
              this.apiIntegratedGstin = login.response.apiIntegratedGstin;
              localStorage.setItem('GSTIN', this.apiIntegratedGstin);
            }
    
            // if (login && login.response && login.response.apiIntegratedGstin) {
            //   this.apiIntegratedGstin = login.response.apiIntegratedGstin;
            //   localStorage.setItem('GSTIN', this.apiIntegratedGstin);
            // }
          } catch (error) {
            //remove user from local storage and cookie
            this.removeLoginDetails();
            this.toastrService.error('Login fails');
            return;
          }
          if (login.status == API_STATUS.error) {
            //remove user from local storage and cookie
            if (login && login.response && login.response.signupStatus == '2') {
              this.router.navigateByUrl(
                `/set-password-post-signup/${this.loginForm.value.userId}/${this.loginForm.value.userId}`
              );
            }
            this.removeLoginDetails();
            let loginErr = '';
            if (login.fieldErrors) {
              login.fieldErrors.forEach(
                (err: any) => (loginErr += err.message + '</br>')
              );
            }
            this.toastrService.error(login.message);
            return;
          } else if (login.status === API_STATUS.success) {
            if (
              login.response.passwordExpired === true ||
              login.response.passwordExpired === 'true'
            ) {
              // this.formList.showResetPasswordForm = true;
              this.router.navigate([
                `/reset-expired-password/${login.response.userId}`,
              ]);
            } else if (
              login.response.passwordExpired === false ||
              login.response.passwordExpired === 'false'
            ) {
              this.sharedService.setUserDetails(login.response);
    
              this.sharedService.setCookie('token', login.response.token);
              localStorage.setItem('token', login.response.token);
              localStorage.setItem(
                LocalStorageKey.LoggedInUserInfo,
                JSON.stringify(login.response)
              );
              //set loginDetails into cookie
              const loginDetail = {
                email: this.loginForm.value.email,
                value: this.loginForm.value.password,
              };
              this.sharedService.setLoginDetails(loginDetail);
              if (this.loginForm.value.rememberMe) {
                // add logic to store in local storage
                localStorage.setItem('rememberMe', this.loginForm.value.rememberMe);
              } else {
                // remove if user detail already stored
                this.removeLoginDetails();
              }
              if (login.response.userType === userType.Taxpayer) {
    
                this.profileService.getGstinsNumberList().subscribe((res) => {
                  if (res && res['status'] !== 0) {
                    this.taxPayerDashboardService.setShowPopup("true");
                    this.router.navigate(['/irp/gstin/dashboard']);
                    this.gstinuserRole = res['response'][0]?.userRole;
                    localStorage.setItem('SelectedGstinRole', this.gstinuserRole)
                    this.gstinuserPlan = res['response'][0]?.userType;
                    localStorage.setItem('selectedGstinPlan', this.gstinuserPlan);
                    // this.gstinuserRole = localStorage.setItem('UserRole',res['response'][0].userRole);
                    if (
                      this.gstinuserRole === 'GENERATOR' ||
                      this.gstinuserRole === 'REVIEWER'
                    ) {
                      localStorage.setItem('UserRole', 'true');
                    } else {
                      localStorage.setItem('UserRole', 'false');
                    }
                  } else {
                    this.taxPayerDashboardService.setPopUpNoGstin("true");
                    this.router.navigate(['/irp/gstin/no-gstin']);
                  }
                });
              } else {
                const intermediaryStatus = login.response.intermediary_status;
                // localStorage.setItem('Intermediary Status',intermediaryStatus);
                if (intermediaryStatus === 'SANDBOX_GRANTED') {
                  this.router.navigate(['/irp/api/dashboard/addtest-gstin']);
                } else if (intermediaryStatus === 'NOT_REGISTERED') {
                  this.mouseOvered = localStorage.setItem("mouseOvered", 'false');
                  this.router.navigate(['/irp/api/dashboard/api-no-gstin']);
                }
                // else {
                //   if (login.response.intermediary_status !== "NOT_REGISTERED") {
                //     this.router.navigate(['/irp/api/dashboard']);
    
                //   }
                //   else {
                //     this.router.navigate(['/irp/api/dashboard/api-no-gstin']);
                //   }
                else if (intermediaryStatus === 'TEST_GSTIN_ADDED') {
                  this.router.navigate(['/irp/api/dashboard/production-access']);
                } else {
                  if (intermediaryStatus === 'PROD_APPLICATION_SUBMITTED') {
                    this.router.navigate(['/irp/api/production/prod-status']);
                  }
                  if (intermediaryStatus === 'PROD_APPLICATION_APPROVED') {
                    this.router.navigate(['/irp/api/dashboard']);
                  }
                }
              }
              this.ref.detectChanges();
            }
          }
          //set user details in cookie
          // after login success
    
          //set token in cookie
          localStorage.setItem('isLogin', 'true');
        }
      }else {
       
      }
    } else {
     
      
    }
    

    // this.taxPayerDashboardService.setChangeUpdatedData("true");
  }
  generateRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    this.captchString = result;
    this.loginForm.controls.captcha.setValue(null);
    return result;


  }
  generateRandomStringForgetPass(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    this.captchStringForgetPass = result;
    this.forgotPasswordForm.controls.captcha.setValue(null);
    return result;


  }
  checkCaptcha() {
    if (this.loginForm.controls.userId.value && (this.loginForm.controls.password.value && this.loginForm.controls.password.value.length >= 8)) {
      this.captchaVisible1 = true;
      // this.generateRandomString(5);
    } else {
      this.generateRandomString(5);
      this.captchaVisible1 = false;
    }
  }
  checkCaptchaForgotPassword() {
    if (this.forgotPasswordForm.controls.userId.value) {
      this.captchaVisibleForgotPas = true;
      // this.generateRandomString(5);
    } else {
      this.generateRandomStringForgetPass(5);
      this.captchaVisibleForgotPas = false;
    }
  }
  // password Expired Api integartion
  async resetPassword() {
    this.checkPasswordMatch();
    if (!this.passwordMatched) return;
    let resPassword;
    let passwordData = {
      oldPassword: this.setPasswordForm.value.oldPassword,
      newPassword: this.setPasswordForm.value.password,
      confirmPassword: this.setPasswordForm.value.confirmPassword,
    };
    try {
      resPassword = await this.loginService.changePassword(passwordData);
      if (resPassword.status === API_STATUS.error) {
        let restPasswordErr = '';
        if (resPassword.fieldErrors) {
          resPassword.fieldErrors.forEach(
            (err: any) => (restPasswordErr += err.message + '</br>')
          );
        }
        this.toastrService.error(restPasswordErr, resPassword.message, {
          enableHtml: true,
        });
        return;
      } else if (resPassword.status === API_STATUS.success) {
        this.toastrService.success(resPassword.message, ' ', {
          enableHtml: true,
        });
      }
    } catch (e) {
      this.toastrService.error('Password not created ');
    }
  }

  /**
   * check whether password and confirm password both are same or not
   */
  checkPasswordMatch() {
    if (
      this.setPasswordForm.value.password &&
      this.setPasswordForm.value.confirmPassword
    ) {
      this.passwordMatched =
        this.setPasswordForm.value.password ===
        this.setPasswordForm.value.confirmPassword;
    }
    return this.passwordMatched;
  }

  /**
   * Reset loginForm
   */
  resetLoginForm() {
    this.loginForm.controls.userId.setValue(null);
    this.loginForm.controls.password.setValue(null);
    this.loginForm.controls.rememberMe.setValue(false);
  }

  /**
   * remove details from local storage and cookies
   */
  removeLoginDetails() {
    localStorage.removeItem('rememberMe');
    this.sharedService.deleteLoginDetails();
  }

  forgotPasswordFormShow() {
    this.formList.showForgotPassword = true;
    this.formList.showLogin = false;
    this.isNavbarLoginVisible = true;
    this.generateRandomString(5);
    this.generateRandomStringForgetPass(5);
  }

  async generateForgotPasswordOTP() {
    this.submittedForgotPassword = true;
    if (this.forgotPasswordForm.controls.captcha.value) {
      if (this.captchStringForgetPass == this.forgotPasswordForm.controls.captcha.value) {   
        if (this.forgotPasswordForm.valid) {
          let res;
          let reqData = {
            userId: this.forgotPasswordForm.value.userId.toLowerCase(),
          };
          try {
            res = await this.loginService.forgotPassword(reqData);
            if (res.status === API_STATUS.error) {
              let passwordErr = '';
              if (res.fieldErrors) {
                res.fieldErrors.forEach(
                  (err: any) => (passwordErr += err.message + '</br>')
                );
              }
              this.toastrService.error(passwordErr, res.message, {
                enableHtml: true,
              });
              return;
            } else if (res.status === API_STATUS.success) {
              this.toastrService.success(res.message, ' ', { enableHtml: true });
              this.formList.showOTPVerificatonForm = true;
              this.formList.showForgotPassword = false;
              this.formList.showLogin = false;
            }
          } catch (e) {
            this.toastrService.error('User not Found');
          }
        }
      } else {
        this.toastrService.error("Please Enter Valid Captcha");
      }
    } else {
      this.toastrService.warning("Please Enter Captcha");
    }
  }

  // Resend OTP Api  integration
  async resendOTP() {
    let res;
    let reqData = {
      userId: this.forgotPasswordForm.value.userId,
    };
    res = await this.loginService.forgotPassword(reqData);
    this.toastrService.success(res.message, ' ', { enableHtml: true });
  }
  handleSuccess(event) {
    this.captchSuccessfulToken = event;

    this.changeCaptcha(this.captchSuccessfulToken);
  }
  changeCaptcha(value) {
    this.captchaChange.next(value);
  }
  handleExpire() {
    this.captchSuccessfulToken = null;
    this.changeCaptcha(this.captchSuccessfulToken);
  }
  handleError() {
    this.captchSuccessfulToken = null;
    this.changeCaptcha(this.captchSuccessfulToken);
  }
  handleReset() {
    this.captchSuccessfulToken = null;
    this.changeCaptcha(this.captchSuccessfulToken);
  }
  handleReady() {
    this.captchSuccessfulToken = null;
    this.changeCaptcha(this.captchSuccessfulToken);
  }
  handleLoad() {
    this.captchSuccessfulToken = null;
    this.changeCaptcha(this.captchSuccessfulToken);
  }
  public addTokenLog(message: string, token: string | null) { }
  getValue() { }

  onClick() {
    if (this.passwords === 'password') {
      this.passwords = 'text';
      this.show = true;
    } else {
      this.passwords = 'password';
      this.show = false;
    }
  }
  getNewsAndUpdates() {
    this.taxPayerDashboardService.getNews().subscribe((res) => {
      if (res && res['response']) {
        localStorage.setItem(
          LocalStorageKey.NEWS,
          JSON.stringify(res['response'])
        );
        this.marquee = res['response']['marquee'][0];

        this.gstDropdownService.marqueeAndNewsSharingData.next(
          'response_recieved'
        );
      }
    });
  }
  redirectToPrivacy() {
    window.scrollTo(0, 0);
    this.router.navigate([`/privacy`]);
  }
  redirectToTerms() {
    window.scrollTo(0, 0);
    this.router.navigate([`/terms-conditions`]);
  }
  changeSizeByBtn(size: any) {
    const container = document.getElementById('increaseFont');
    console.log('const', container);
    container.style.fontSize = size;
  }

}
