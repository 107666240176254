import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiIntegratorDashboardService } from '../../services/api-integrator-dashboard.service';

@Component({
  selector: 'app-api-no-gstin-screen',
  templateUrl: './api-no-gstin-screen.component.html',
  styleUrls: ['./api-no-gstin-screen.component.scss']
})
export class ApiNoGstinScreenComponent implements OnInit {
  newsList: any = [];
  newsData = [];
  news = [];
  constructor(private router: Router,private apiDashboardService: ApiIntegratorDashboardService) { }

  ngOnInit(): void {
    this.getNewsData();
  }
  registerGstin()
  {
    this.router.navigate(['/irp/api/dashboard/registerGstin']);
  }
  getNewsData() {
    const newsResponse = this.apiDashboardService.getNews().subscribe(res => {
      this.newsList = res;
      console.log("NEWS",this.newsList)
      console.log("LNGTH",this.newsList.response.marquee.length);
      // console.log((this.newsList.response.news[0].moreInfoModalBody));
      // this.newsList.response.news.moreInfoModalBody?.replace(/\r/g,'').replace(/\n/g,'');
      // var tbl = document.getElementById('newsTbl') as HTMLElement;
      // var strng = this.newsList.response.news[0].moreInfoModalBody;
      // console.log("STRING",strng);
      // var index = strng.charAt(17);
      // var abc = String.valueOf(index)
      // console.log("IND",index);
      
      
      const newsMarquee = this.newsList.response.marquee;
      console.log(newsMarquee.userType)
      this.newsData = this.newsList.response.marquee;
      for (let i = 0; i < this.newsData.length; i++) {
        console.log(this.newsData[i].userType)
        if(this.newsData[i].userType === 'API_INTEGRATOR'){
          this.news.push(this.newsData[i]);
        }
      }
    })

  }
}
