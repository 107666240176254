import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiProfileService {
 
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  getUserBusiness(): any {
    return this.http
      .get(`${environment.baseUrl}/business`)
      .toPromise();
  }
}
