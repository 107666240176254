import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageKey } from '../app.config';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  updateGstin = new BehaviorSubject('');
  isHeaderDropdown = new BehaviorSubject(true);
  constructor(private cookieService: CookieService, private router: Router) {}

  /**
   * provide cookie value
   * @param name cookie name
   * @returns value of cookie name
   */
  getCookie(name: string): any {
    return this.cookieService.get(name);
  }

  /**
   * set cookie
   * @param name cookie name
   * @param value cookie value
   */
  setCookie(name: string, value: any) {
    this.cookieService.set(name, value, 1, '/', window.location.hostname);
  }

  /**
   * Get login details from cookies
   * @returns login details
   */
  getLoginDetail() {
    try {
      return JSON.parse(this.cookieService.get('loginDetails'));
    } catch (error) {
      console.error('invalid user details in local storage!');
      return null;
    }
  }

  /**
   * Set login details in cookies
   * @param loginDetails login details
   */
  setLoginDetails(loginDetails: any) {
    this.cookieService.set(
      'loginDetails',
      JSON.stringify(loginDetails),
      365,
      '/',
      window.location.hostname
    );
  }

  /**
   * Set user details into cookies
   * @param userDetails gstin user details
   */
  setUserDetails(userDetails: any) {
    this.cookieService.set(
      'userDetails',
      JSON.stringify(userDetails),
      7,
      '/',
      window.location.hostname
    );
  }

  /**
   * Remove login details from the cookies
   */
  deleteLoginDetails() {
    this.cookieService.delete('loginDetails', '/', window.location.hostname);
  }

  /**
   * Remove user details from cookies
   */
  deleteUserDetails() {
    this.cookieService.delete('userDetails', '/', window.location.hostname);
    this.cookieService.delete('token', '/', window.location.hostname);
  }

  /**
   * Logout the user from app
   */
  logout() {
    this.deleteUserDetails();
    this.clearLocalStorage();
    localStorage.getItem('rememberMe') !== 'true' && this.deleteLoginDetails();
    this.router.navigate(['/login']);
  }
  clearLocalStorage(){
    localStorage.removeItem(LocalStorageKey.IS_GSTIN_AVAILABLE);
    localStorage.removeItem(LocalStorageKey.SelectedGSTIN);
    localStorage.removeItem(LocalStorageKey.NEWS);
    localStorage.removeItem(LocalStorageKey.GSTINNumber);
    localStorage.removeItem('token');
  }
// Not using now
  // updateSelectedGstin(gstin: any) {
  //   this.updateGstin.next(gstin);
  // }

  headerDropdown(isShown: any) {
    this.isHeaderDropdown.next(isShown);
  }
}
