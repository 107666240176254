import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-api-integration-dashboard',
  templateUrl: './api-integration-dashboard.component.html',
  styleUrls: ['./api-integration-dashboard.component.scss']
})
export class ApiIntegrationDashboardComponent implements OnInit {
 

  barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011'];
  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40]},
  ];

  barChartOptions1: ChartOptions = {
    responsive: true,
  };
  public barChartLabels1: Label[] = ['JAN', 'FEB', 'MARCH', 'APRIL', 'MAY', 'JUNE'];
  barChartType1: ChartType = 'bar';
  barChartLegend1 = false;
  barChartPlugins1 = [];
 
  public barChartData1: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40]},
    
  ];
  // public barChartColors: Color[] = [
  //   { backgroundColor: 'blue' },
  // ]
  constructor() { }

  ngOnInit(): void {
  }
  
}
