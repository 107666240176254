<!-- <nav class="navbar-expand-lg">
    <img src="../../../../assets/images/iris-reverse-white-logo.png" class="logo logoImage" />

  </nav> -->
  <div class="container-fluid custom_container_fluid cursor-pointer landingHeader" (click)="redirectToLogin()">
    <div class="row align-items-center headerDiv">
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/gstnlogo.png" alt="..." class="imgClass">
      </div>
      <div class="col-sm-6 text-center">
      <p class="text-center my-0 font-size-28" >E-Invoice Registration Portal -  IRP 6</p>
      <span class="text-center my-0 highDiv powerTxt"> Powered by IRIS Business Services Ltd. for Goods and Services Tax Network </span>
      <span class="text-center my-0 smallDiv powerTxt"> Powered by IRIS for GSTN </span>
      </div>
      <div class="col-sm-3 text-center">
        <img src="../../../../assets/images/IRISIRP-Logo-1.png" class="imgClass">
      </div>
    </div>

  </div>
  <!-- <div class="container-fluid custom_container_fluid cursor-pointer landingHeader" id="increaseFont">
    <div class="row align-items-center  bg-navy ptb20">
      <div class="col-sm-6 pl6 txt-cen">
       <span><i class="bi bi-pin-map"></i> 231, International Infotech Park, Vashi, Navi Mumbai</span>
      </div>
      <div class="col-sm-6 pr6 text-right">
      <span class="mr"><i class="bi bi-envelope"></i> hello@irisirp.com</span>
      <span class="hideonSmallDiv" ><a (click)="changeSizeByBtn('20px')" class="mr" href="javascript:;">A<sup>+</sup></a> <a href="javascript:;" (click)="changeSizeByBtn('unset')">A<sup>-</sup></a></span>
      </div>
    </div>
  </div> -->
<nav class="navbar navbar-expand-lg navbar-light bg-transparent custom_navbar">
  <div class="container-fluid px-5">
    <!-- <a class="navbar-brand" href="#"><img src="../../../../assets/images/IRISIRP-Logo-1.png" height="60"></a> -->
    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class=""><i class="bi bi-list"></i></span>
    </button>
    <span class="mr10 smallDiv mt-10">
      <a [class.active]="isMenuSelected == 'Sandbox'" class="btn btn-primary rounded p13 btnContact1"  href="https://sandbox.einvoice6.gst.gov.in/signup" target="_blank" >Sandbox</a>
      </span>
      <span class="mr10 smallDiv mt-10">
        <a [class.active]="isMenuSelected == 'Login'" class="btn btn-primary rounded p13 btnContact" (click)="redirectToSignUp()" >Sign up</a>
        </span>
        <span class="nav-item dropdown pl0 smallDiv mr10 mt-10">
          <a class="btnLang " href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg"> </a>
          <ul class="dropdown-menu t60 left-3" aria-labelledby="navbarDropdown02">
            <li><a href="https://einvoice6.gst.gov.in/content/hindi/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/hindi.png" class="langImg" > हिन्दी </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/tamil/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/tamil.png" class="langImg"> தமிழ் </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/marathi/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/marathi.png" class="langImg"> मराठी </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/gujrati/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/gujrati.png" class="langImg"> ગુજરાતી</a></li>
          </ul>
        </span>
      <!-- <span class="mr10 smallDiv mt-10">
      <a class="btnFont" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
      aria-expanded="false">A </a>
    <ul class="dropdown-menu t60" aria-labelledby="navbarDropdown02">
      <li><a (click)="changeSizeByBtn('20px')" class="mr dropdown-item text-center" href="javascript:;">A<sup>+</sup></a></li>
      <li><a href="javascript:;" class="dropdown-item text-center" (click)="changeSizeByBtn('unset')">A<sup>-</sup></a></li>
    </ul>  
    </span> -->


    <!-- <img src="../../../../assets/images/IRISIRP-Logo-1.png" class="imgClass smallDiv"> -->
    <div class="navbar-collapse collapse" id="navbarSupportedContent" style="">
      <ul class="mb-2 mb-lg-0 ms-auto navbar-nav custom_navbar_nav">
        <li class="nav-item dropdown">
          <a class="nav-link " (mouseenter)="isMenuSelected = 'Taxpayers'" [class.active]="isMenuSelected == 'Taxpayers'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Taxpayers <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/e-invoicing-for-taxpayers/">E-invoicing for Taxpayers</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/registration/">Registering with IRIS IRP</a></li>
            <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
              aria-expanded="false">Solutions <span class="">></span></a>
            <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irp-web-portal/">Web Portal</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/excel-utility/">Excel Utility</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">API Integrators</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/erp-billing-systems/">ERP / Billing Systems</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/mobile-web-application/">Mobile Web Application</a></li>
            </ul>
          </li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">Product Guide</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/pricing-plan/">Pricing Plan</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Developers'" [class.active]="isMenuSelected == 'Developers'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            API Developers <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/api-integrator-registration/" >Registering with IRIS IRP</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/" >API Documentation</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link " (mouseenter)="isMenuSelected = 'Help'" [class.active]="isMenuSelected == 'Help'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Help <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/faq-powered-by-irisirp/">FAQ</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/public-keys/">Public Keys <span class="blink_me">Updated</span></a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/validation-rules/">Validation Rules</a></li>
            <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
              aria-expanded="false">E-invoice Schema <span class="">></span></a>
            <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/general-master/">General Master</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irn-2/">IRN</a></li>
            </ul>
          </li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/troubleshooting-common-errors/">Resolving Common Errors</a></li>
            <li><a class="dropdown-item"  href="https://selfservice.gstsystem.in/ReportIssue.aspx">Report Issue</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Search'" [class.active]="isMenuSelected == 'Search'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Search <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <!-- <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/masters/" >Masters</a></li> -->
          <li><a class="dropdown-item"  (click)="redirectTo('master-search')" >Masters</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('enablement-status')"  >EInvoice Enablement Status</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('verify-qrcode')" >Verify QR Code</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('verify-signedInvoice')" >Verify Signed E-Invoice</a></li>
          <li><a class="dropdown-item" (click)="redirectTo('gstin-status')" >Gstin Status</a></li>


          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Resources'" [class.active]="isMenuSelected == 'Resources'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Resources <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/einvoice-mandate/" >E-Invoice Mandate</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/irp-for-e-invoicing/" >IRIS IRP for E-Invoicing</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/notifications/" >GST Notifications</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/blog/" >Blog</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/news/" >E-invoice and GST in News</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'About'" [class.active]="isMenuSelected == 'About'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            About Us <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  href="https://irisirp.com/" >About IRIS IRP</a></li>
          <li><a class="dropdown-item"  href="https://irisgst.com/" >About IRIS GST</a></li>
          <li><a class="dropdown-item"  href="https://www.irisbusiness.com/" >About IRIS Business</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Contact Us'" [class.active]="isMenuSelected == 'Contact Us'" href="https://einvoice6.gst.gov.in/content/contact-us/" id="navbarDropdown" role="button"
            aria-expanded="false">
            Contact Us
          </a></li>


















        <!-- <li class="nav-item dropdown">
          <a class="nav-link " (mouseenter)="isMenuSelected = 'ForWhom'" [class.active]="isMenuSelected == 'ForWhom'" href="javascript:;" id="navbarDropdown0" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Getting Started <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown0">
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/e-invoice-mandate/">E-invoice Mandate</a></li>
            <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/iris-irp-for-e-invoicing/">IRIS IRP for E-invoicing</a></li>
            <li><a class="dropdown-item"  target="new" title= "This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website" href="https://einvoice.gst.gov.in/einvoice/check-enablement">E-invoice enablement</a></li>
            <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
              aria-expanded="false">Registering with IRIS IRP <span class="">></span></a>
            <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kb/registration/">Taxpayers</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kb/api-integrator-registration/">API Integrators</a></li>

            </ul>
          </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Generate'" [class.active]="isMenuSelected == 'Generate'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            How to Generate <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
              aria-expanded="false">For Taxpayers <span class="">></span></a>
            <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/web-portal/">Web Portal</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/excel-utility/">Excel Utility</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/mobile-app/">Mobile App</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/kbtopic/developerconsole/">API Integration</a></li>
              <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/erp-billing-systems/" >ERP/Billing Systems</a></li>



            </ul>
          </li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/kb/api-flow/" >For Solution Providers</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Documentation'" [class.active]="isMenuSelected == 'Documentation'" href="javascript:;"id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Documentation <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" adropendria-labelledby="navbarDropdown">
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/blog/kbtopic/developerconsole/" >API Integration</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/public-keys/" >Public keys</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/validation-rules/" >Validation Rules</a></li>
          <li><a class="dropdown-item"  href="https://einvoice6.gst.gov.in/content/masters/" >Masters</a></li>
          <li class="dropdown dropend"><a class="dropdown-item" href="javascript:;" id="navbarDropdown101" role="button"  (mouseenter)="isMenuSelected = 'ForWhom'" data-bs-toggle="dropdown"
            aria-expanded="false">E-invoice Standard <span class="">></span></a>
          <ul class="dropdown-menu submenu" aria-labelledby="navbarDropdown101">
            <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/notified-e-invoice-schema/">Notified E-invoice Schema</a></li>
            <li><a class="dropdown-item" href="https://einv-apisandbox.nic.in/downloads/Regular%20Expression.pdf">Field specifications</a></li>
            <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/general-master/">General Master</a></li>
            <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/irn-2/">IRN</a></li>



          </ul>
        </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Tools'" [class.active]="isMenuSelected == 'Tools'" href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Tools <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item dropend"  (click)="redirectTo('enablement-status')" >EInvoice Enablement Status</a></li>
          <li><a class="dropdown-item dropend"  (click)="redirectTo('gstin-status')" >GSTIN Status</a></li>
          <li><a class="dropdown-item dropend"  (click)="redirectTo('verify-signedInvoice')" >Verify Signed E-Invoice</a></li>
          <li><a class="dropdown-item dropend"  (click)="redirectTo('verify-qrcode')" >Verify QR Code</a></li>
          <li><a class="dropdown-item dropend"  (click)="redirectTo('hsn-search')" >HSN Search</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Resources'" [class.active]="isMenuSelected == 'Resources'" href="javascript:;" id="navbarDropdown2" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Resources <span><i class="bi bi-chevron-down"></i></span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
            <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/news/">E-invoice and GST in News</a></li>
            <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/notifications/">GST Notifications</a></li>
            <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/blog/" >Blog</a></li>


          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'Help'" [class.active]="isMenuSelected == 'Help'" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">Help <span><i class="bi bi-chevron-down"></i></span></a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown02">
            <li><a class="dropdown-item" href="https://einvoice6.gst.gov.in/content/faq-powered-by-irisirp/">FAQs</a></li>
            <li><a class="dropdown-item" href="https://selfservice.gstsystem.in/ReportIssue.aspx">Report Issue</a></li>
            </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (mouseenter)="isMenuSelected = 'About Us'" [class.active]="isMenuSelected == 'About Us'" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">About Us <span><i class="bi bi-chevron-down"></i></span></a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown02">
            <li><a class="dropdown-item" href="https://irisirp.com/" target="new" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">About IRIS IRP</a></li>
            <li><a class="dropdown-item" href="https://irisgst.com/" target="new" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">About IRIS GST</a></li>
            <li><a class="dropdown-item" href="https://irisbusiness.com/" target="new" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">About IRIS Business</a></li>
            </ul>
        </li> -->
        <li class="nav-item highDiv pl0">
          <a class="nav-link" [class.active]="isMenuSelected == 'Sandbox'" class="btn btn-primary rounded p13 btnContact1"  href="https://sandbox.einvoice6.gst.gov.in/signup" target="_blank" >Sandbox</a>
        </li>
        <li class="nav-item highDiv pl0">
          <a class="nav-link" [class.active]="isMenuSelected == 'Login'" class="btn btn-primary rounded p13 btnContact"  (click)="redirectToSignUp()">Sign up</a>
        </li>
        <li class="nav-item dropdown pl0">
          <a class="btnFont" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">A </a>
          <ul class="dropdown-menu t60" aria-labelledby="navbarDropdown02">
            <li><a (click)="changeSizeByBtn('20px')" class="mr dropdown-item text-center" href="javascript:;">A<sup>+</sup></a></li>
            <li><a href="javascript:;" class="dropdown-item text-center" (click)="changeSizeByBtn('unset')">A<sup>-</sup></a></li>
          </ul>
        </li>
        <li class="nav-item dropdown pl0">
          <a class="btnLang hideBtnLang" href="javascript:;" id="navbarDropdown02" role="button" data-bs-toggle="dropdown"
            aria-expanded="false"><img src="../../../../assets/images/landingpage/default.png" class="btnLangImg"> </a>
          <ul class="dropdown-menu t60 left-3" aria-labelledby="navbarDropdown02">
            <li><a href="https://einvoice6.gst.gov.in/content/hindi/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/hindi.png" class="langImg" > हिन्दी </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/tamil/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/tamil.png" class="langImg"> தமிழ் </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/marathi/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/marathi.png" class="langImg"> मराठी </a></li>
            <li><a href="https://einvoice6.gst.gov.in/content/gujrati/" class="dropdown-item" ><img src="../../../../assets/images/landingpage/gujrati.png" class="langImg"> ગુજરાતી</a></li>
          </ul>
        </li>


      </ul>

    </div>
  </div>
</nav>
<!-- <div class="flasingInfo">
  <p>Generate e-invoices, send payment links and reminders, and streamline cashflow with <a class="blink_me" style="color: #319645;font-size: 18px;font-weight: 500;" title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website" href="https://irisperidot.com/login" target="_blank" rel="noopener" (click)="generateEvent()">IRIS Peridot Plus</a></p>
</div> -->
<div class="container-fluid px-0" style="position: relative;">
  <marquee class="marque mt-0 font-size-14" *ngIf="marquee && marquee.newsBody">
    {{marquee && marquee.newsBody ? marquee.newsBody :'N/A'}} <a style="color: #007ad9; font-size: 14px;font-weight: 500;"
    *ngIf="marquee.moreInfoExternalLink" [href]="marquee.moreInfoExternalLink" target="_blank"
   class="font-size-14">from here</a>
  </marquee>
</div>

<ng-template #template>
  <div class="modal-body">
    <p>The link shall take you to a page outside the einvoice6.gst.gov.in. For any query regarding the contents of the linked page, please contact concerned website</p>
  </div>
  <div class="modal-footer">
    <a class="text_black text-decoration-none"  (click)="modalRef?.hide()">Skip</a>

    <button
      type="button"
      class="btn btn_primary_theme font-size-14 ms-2"
      (click)="redirectToPage()"
    >
      OK
    </button>

  </div>
</ng-template>
