import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { environment } from 'src/environments/environment';
import {  GoogleAnalyticsMeasurementIdForDEV, GoogleAnalyticsMeasurementIdForPROD } from './app.config';
import { TaxPayerDashboardService } from './shared/services/taxpayer-dashboard.service';
import { LoginService } from './loginSignup/login.service';
import { NavigationStart } from '@angular/router';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'irp-ui';
  gTagId: string;
  isTokenModified: boolean;

  constructor(private router: Router, private taxPayerDashboardService: TaxPayerDashboardService, private loginService: LoginService) {

  }
  ngOnInit() {
    // Analytics only for prod environment
    // if(environment && environment.production == true){
      this.setUpAnalytics();
    // }
    // this.taxPayerDashboardService.setChangeUpdatedData("true");
    this.handleLoginNewTab();
    window.addEventListener('storage', this.loginService.logoutCurrentUserOnTokenChange)
  }
  setUpAnalytics() {
     if(environment && environment.production == true){
      this.gTagId = GoogleAnalyticsMeasurementIdForPROD
     }else{
      this.gTagId = GoogleAnalyticsMeasurementIdForDEV

     }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config',  this.gTagId,
          {
            page_path: event.urlAfterRedirects
          }
        );
      });
  }

  // router state gets modified: login.service.ts --> logoutCurrentUserOnTokenChange 
  // when new user logs in tab 2 (new tab), while a user is already logged in tab 1 
  handleLoginNewTab() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        const navigation = this.router.getCurrentNavigation();
        this.isTokenModified = navigation.extras.state ? navigation.extras.state.token_changed : false;
        localStorage.setItem('tk_', String(this.isTokenModified))
      })
  }
}