import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MastersService {
  token = this.sharedService.getCookie('token');
  headersWithContentType = new HttpHeaders({
    Authorization: `Bearer ${this.token}`,
    'Content-Type': 'application/json',
  });
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.token}`,
  });
  apiKeyHeader = new HttpHeaders({
    'apikey': '22d3c2c3-c51e-46ff-870c-a52ed8fa1b6a',
    'Access-Control-Allow-Origin': '*'
  })
  constructor(private http: HttpClient, private sharedService: SharedService) { }

 /**
   * validate if gstin is already connected with other IRP or not
   * @param gstin gstIn added by user
   * @returns gstin details of that gstin
   */
  
  getPincode(state)
  {
    return this.http.get(`${environment.baseUrl}/pincode?state=${state}`)

  }
  getHsnSearch(hsnCode)
  {
    return this.http.get(`${environment.baseUrl}/hsnByCode?hsnCode=${hsnCode}`);
  }
  getHsnSearchByDesc(hsnDesc)
  {
    return this.http.get(`${environment.baseUrl}/hsnByDescription?hsnDesc=${hsnDesc}`);
  }
  getMasterData(type,val)
  {
    return this.http.get(`${environment.baseUrl}/search/master?type=${type}&val=${val}`);
  }
  getMasterDataError(type)
  {
    return this.http.get(`${environment.baseUrl}/search/master?type=${type}`);
  }
  getMasterDataErrorReport(type)
  {
    return this.http.get(`${environment.baseUrl}/search/master?type=${type}`);
  }
  getAllData(type){
    return this.http.get(`${environment.baseUrl}/search/master?type=${type}`);
  }
  downloadMasterData(type,val)
  {
    return this.http
      .post(`${environment.baseUrl}/master/download?type=${type}&val=${val}`,{}, {
        headers: this.headersWithContentType,
        responseType: 'blob' as 'blob',
      }) .pipe(
        map((res: any) => {
          let blob = new Blob([res], {
            type: 'application/csv',
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `mastersData.csv`;
          link.click();
          window.URL.revokeObjectURL(link.href);
        })
      );
  }
  downloadAllMasterData(type)
  {
    return this.http
      .post(`${environment.baseUrl}/master/download?type=${type}`,{}, {
        headers: this.headersWithContentType,
        responseType: 'blob' as 'blob',
      }) .pipe(
        map((res: any) => {
          let blob = new Blob([res], {
            type: 'application/csv',
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `mastersData.csv`;
          link.click();
          window.URL.revokeObjectURL(link.href);
        })
      );
  }
  downloadPincode(type,state)
  {
    return this.http
    .post(`${environment.baseUrl}/master/download?type=${type}&state=${state}`,{}, {
      headers: this.headersWithContentType,
      responseType: 'blob' as 'blob',
    }) .pipe(
      map((res: any) => {
        let blob = new Blob([res], {
          type: 'application/csv',
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `mastersData.csv`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
    );
  }
  getTax(type)
  {
    return this.http.get(`${environment.baseUrl}/search/master?type=${type}`);
  }
}
