import { Component, OnInit } from '@angular/core';
import {invoiceTypeMapping, QRCodeSettings } from 'src/app/app.config';
import { API_STATUS } from 'src/app/constant';
import * as moment from 'moment';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';

@Component({
  selector: 'app-generic-verify-qr-code',
  templateUrl: './generic-verify-qr-code.component.html',
  styleUrls: ['./generic-verify-qr-code.component.scss']
})
export class GenericVerifyQrCodeComponent implements OnInit {


  elementType = QRCodeSettings.ELEMENT_TYPE;
  correctionLevel = QRCodeSettings.CORRECTION_LEVEL;
  qrWidth = QRCodeSettings.WIDTH;
  showResultsQR: boolean;
  isErrorPresent: boolean;
  errorMessage: any;
  qrcodeData: any;
  qrCode;
  currentDate: string;
  invTypeMap = invoiceTypeMapping;

  constructor(private eInvoiceService: EInvoiceService,private googleAnalyticsService:GoogleAnalyticsService) { }
  setCaptch:any;
  ngOnInit(): void {
    this.getCurrentDate();
    this.setCaptch = localStorage.getItem('token');
  }

  verifyQR() {
  
    this.eInvoiceService.verifyQRCode(this.qrCode).subscribe(res => {
      this.showResultsQR = true;
      if (res && res['status'] == API_STATUS.error) {
        this.isErrorPresent = true;
        this.errorMessage = res['message'];
      } else {
        this.isErrorPresent = false;
        if (res && res['response']) {
          this.qrcodeData = res['response'];
          this.trackAnalytics("VerifyQRCode_Submit")

        }
      }
    })
  }
 

  formatDate(inputDate, inputFormat, outputFormat) {
    if (inputDate) {
      var date = moment(inputDate, inputFormat)
      return date.format(outputFormat)
    }
    return null;

  }
  resetQR() {
    this.qrCode = null;
    this.showResultsQR = false;
  }

  getCurrentDate() {
    this.currentDate = moment().format('DD-MM-YYYY');
  }

  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
  
}