import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TaxPayerDashboardService } from 'src/app/shared/services/taxpayer-dashboard.service';
import { EInvoiceService } from 'src/app/taxPayer/e-invoice/e-invoice.service';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit {
  marquee: any;
  modalRef: any;
  currentPage: any;
  isMenuSelected = 'ForWhom';
  isVisibleNavbar : boolean;
  constructor(private taxPayerDashboardService: TaxPayerDashboardService,
    private invoiceService: EInvoiceService,
    private router: Router, private modalService: BsModalService,private googleAnalyticsService:GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.getNewsAndUpdates();

    this.taxPayerDashboardService.getisForgetData().subscribe((emited: string) => {
      if (!this.invoiceService.isEmpty(emited) && emited === "true") {
        this.isVisibleNavbar = true;

      }
    })
  }
  getNewsAndUpdates() {
    this.taxPayerDashboardService.getNews().subscribe(res => {
      if (res && res['response']) {
        this.marquee = res['response']['marquee'][0];
        this.taxPayerDashboardService.setMarqueeForPre(this.marquee);
      }
    })
  }

  redirectTo(featureName, subfeature = null) {
    switch (featureName) {
      case 'contactus':
        this.router.navigate(['/contact-us']);
        break;

      case 'plans':
        this.router.navigate([`/plans/${subfeature}`]);
        break;

      case 'masters':
        this.router.navigate([`/masters`]);
        break;

      case 'web-content':
        this.router.navigate([`/web-content/${subfeature}`]);
        break;
      case 'api-integrator':
        this.router.navigate([`/api-integrator-detail-content`]);
        break;

      case 'taxpayer':
        this.router.navigate([`/taxpayer-content`]);
        break;
      case 'enablement-status':
        this.router.navigate([`/einvoice-enablement-status`]);
        break;
      case 'gstin-status':
        this.router.navigate([`/gstin-status`]);
        break;
      case 'verify-signedInvoice':
        this.router.navigate([`/verify-signed-invoice`]);
        break;
      case 'verify-qrcode':
        this.router.navigate([`/verify-qrcode`]);
        break;
      case 'hsn-search':
        this.router.navigate([`/hsn-search`]);
        break;
      case 'master-search':
        this.router.navigate([`/masters-code`]);
        break;
    }
  }
  openModal(template: TemplateRef<any>, page) {
    this.modalRef = this.modalService.show(template);
    this.currentPage = page
  }
  redirectToPage() {
    console.log("page", this.currentPage)
    if (this.currentPage == 'news') {
      window.open("https://irisirp.com/news/", '_blank');

    }
    if (this.currentPage == 'notifications') {
      window.open("https://irisirp.com/notifications/", '_blank');
    }
    if (this.currentPage == 'blog') {
      window.open("https://irisirp.com/blog/", '_blank');
    }

  }
  redirectToLogin() {
    // this.router.navigate([`/login`]);
    window.location.href = ('https://einvoice6.gst.gov.in')
  }
  redirectTOLoginPage(){
    this.taxPayerDashboardService.setisForgetData("true");
    this.router.navigate([`/login`]);
  }
  changeSizeByBtn(size: any) {
    const container = document.getElementById('increaseFont');
    container.style.fontSize = size;
  }
  generateEvent() {
    this.trackAnalytics("Peridot_Plus")
  }
  trackAnalytics(btnName) {
    this.googleAnalyticsService.trackAnalytics(btnName);
  }
}
