

<app-auth-header></app-auth-header>

    <!-- <div class="container-fluid">
        <div class="row vh-100 align-items-center banner-sidebar">
            <div class="col-lg-5 d-none d-lg-block text-center ps-5 pe-3">
                <img src="assets/img/Infographic.png" class="img-fluid" alt="sidebar-image">
                <div class="row">
                    <div class="col-12 text-lg-start">
                        <h4>IRIS IRP for an easy, efficient and enhanced E-invoicing journey</h4>
                        <ul>
                            <li>Manage e-invoicing for multiple GSTINs from single login</li>
                            <li>Bulk IRN generation as well as cancellation</li>
                            <li>Multiple modes for generation – API, Web and Desktop Utility</li>
                            <li>Simple and intuitive UI designed for business teams</li>
                        </ul>
                        <h5>Sign-up and explore the value-added services, extending beyond e-invoicing</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 py-4">
                <div class="card border-0 shadow py-4 px-lg-3 mx-auto" style="max-width: 33.5rem;">
                    <div class="card-body">
                        <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">Log In</h2>
                        <div class="row">
                            <div class="col-12 mb-5">
                                <b>Welcome to IRIS E-Invoicing Portal</b>
                            </div>
                            <div class="col-12 mb-3">
                                <label for="exampleFormControlInput2" class="form-label"><b>Email id /
                                        Mobile</b></label>
                                <input type="text" class="form-control" id="exampleFormControlInput2"
                                    placeholder="Email Id / Mobile" required>
                            </div>
                            <div class="col-12 mb-3">
                                <label for="exampleFormControlInput1" class="form-label"><b>Password</b></label>
                                <input type="password" class="form-control" id="exampleFormControlInput1"
                                    placeholder="Password" required>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="gridCheck1" checked>
                                    <label class="form-check-label" for="gridCheck1">
                                        Remember
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 mb-3 text-end">
                                <span class="text-primary"><a href="#" class="text-decoration-none custom-text">Forgot
                                        Password?</a></span>
                            </div>
                            <div class="col-12 my-3 text-center">
                                <button class="btn btn-dark px-5 py-2">LOGIN</button>
                            </div>
                            <div class="col-12 text-center">
                                <p>Not a member? <span class="text-primary"><a href="signup.html"
                                            class="text-decoration-none custom-text">Sign up now!</a></span></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-fluid">
        <div class="row" style="background-color: #FFE8BC;padding: 120px 0px 90px 0px;">
            <div class="col-12">
                <h1 style="text-align: center;font-size: 60px;
                line-height: 70px;
                font-weight: 600;
                color: #252525;
                letter-spacing: 0px;font-family: 'Poppins';">E-Invoicing for <span class="highlight-heading"
                        style="color: #5dab00;">Taxpayers</span></h1>
                <span class="text-center d-block mx-auto"
                    style="margin: 0 0 15px; font-size: 16px; color: #252525;">Invoice Registration Platform to
                    seamlessly generate and cancel IRN or save and print e-invoices.</span>
            </div>
        </div>
        <div class="container">
            <div class="row mt-5">
                <div class="col-12 text-center">
                    <div class="" style="max-width: 600px; margin: auto;">
                        <h6 style="padding: 10px 20px;
                background-color: #f2f3fe;
                border-radius: 13px;
                font-size: 16px;
                display: inline-block;
                color: #5dab00;"> IRN generation</h6>
                        <h3 style="color: #252525;font-size: 35px;
                line-height: 45px;
                font-weight: 700;
                letter-spacing: 0px;"><span class="highlight-heading" style="color: #5dab00;">One-stop</span> solution
                            for all your e-invoicing needs.</h3>
                    </div>
                </div>
                <div class="col-12 mt-5 px-5">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <img src="https://irisirp.com/wp-content/uploads/2022/07/QR-Code.png" width="150">
                                </div>
                                <div class="col">
                                    <h5 style="font-size: 18px;
                                line-height: 28px;
                                color: #252525;
                                font-weight: 600;
                                letter-spacing: 0px;">E-invoice mandate requires suppliers to get their invoices
                                        registered with IRP and
                                        obtain a unique Invoice reference number (IRN).</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" style="padding-top: 50px;
                    padding-right: 50px;
                    padding-bottom: 50px;
                    padding-left: 50px;
                    align-items: center;
                    background-color: #f8f9fa;
                    border-radius: 15px 15px 15px 15px;">
                            <h4 style="font-size: 26px;
                            line-height: 38px;
                            font-weight: 500;
                            color: #252525;
                            letter-spacing: 0px;">IRIS IRP offers features to generate, share and store e-invoices with
                                the comfort of
                                multiple interfaces.</h4>
                            <button class="btn btn-lg btn-dark px-3 mt-3">Contact Sales</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-5 px-5">
                    <div class="row align-items-center mt-5">
                        <div class="col-md-6">
                            <h6 style="padding: 10px 20px;
                            background-color: #f2f3fe;
                            border-radius: 13px;
                            display: inline-block;
                            color: #5dab00;"> Multiple UI</h6>
                            <h3 style="color: #252525;font-size: 35px;
                            line-height: 45px;
                            font-weight: 700;
                            letter-spacing: 0px;" class="mt-4"> Stay in sync, no matter what platform you are on.</h3>
                            <p class=" mt-5" style="font-size: 16px; color: #252525;">Prepare single e-invoice via app
                                or bulk generate IRN through excel utility, pull IRN in
                                your ERP via API Integration or custom print invoices using awesome cloud platform.</p>
                        </div>
                        <div class="col-md-6">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ypQp_B5R7ow?start=5"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>

                </div>
                <div class="col-12 mt-5 px-5">
                    <div class="row align-items-center mt-5">
                        <div class="col-md-8">
                            <h6 style="padding: 10px 20px;
                            background-color: #f2f3fe;
                            border-radius: 13px;
                            display: inline-block;
                            color: #5dab00;"> Beginner</h6>
                            <h3 style="color: #252525;font-size: 35px;
                            line-height: 45px;
                            font-weight: 700;
                            letter-spacing: 0px;" class="mt-4"> Free Forever. Best suited for less daily transactions &
                                simple supply chain.</h3>
                        </div>
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-6 mt-5 mb-5 p-3" style="background: #5dab00;">
                            <div class="row align-items-center">
                                <div class="col-4 my-2">
                                    <img
                                        src="https://irisirp.com/wp-content/uploads/2022/07/Bulk-generation-cancel-1.png">
                                </div>
                                <div class="col-8 my-2">
                                    <h5><span style="color: #ffffff;">Bulk Generate or Cancel</span><span
                                            style="color: #5dab00;"></span></h5>
                                </div>
                                <div class="col-4 my-2">
                                    <img
                                        src="https://irisirp.com/wp-content/uploads/2022/07/multiple-gstn-single-login.png">
                                </div>
                                <div class="col-8 my-2">
                                    <h5><span style="color: #ffffff;">Multiple GSTIN Single Login</span><span
                                            style="color: #5dab00;"></span></h5>
                                </div>
                                <div class="col-4 my-2">
                                    <img
                                        src="https://irisirp.com/wp-content/uploads/2022/07/Role-based-user-access.png">
                                </div>
                                <div class="col-8 my-2">
                                    <h5><span style="color: #ffffff;">Role-based User Access</span><span
                                            style="color: #5dab00;"></span></h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5 mb-5 p-3">
                            <div class=" row align-items-center">
                                <div class="col-4 my-2">
                                    <img src="https://irisirp.com/wp-content/uploads/2022/07/Download-1.png">
                                </div>
                                <div class="col-8 my-2">
                                    <h5><span style="color: #252525;">Bulk Generate or Cancel</span><span
                                            style="color: #5dab00;"></span></h5>
                                </div>
                                <div class="col-4 my-2">
                                    <img src="https://irisirp.com/wp-content/uploads/2022/07/Cancel-1.png">
                                </div>
                                <div class="col-8 my-2">
                                    <h5><span style="color: #252525;">Multiple GSTIN Single Login</span><span
                                            style="color: #5dab00;"></span></h5>
                                </div>
                                <div class="col-4 my-2">
                                    <img src="https://irisirp.com/wp-content/uploads/2022/07/Print-1.png">
                                </div>
                                <div class="col-8 my-2">
                                    <h5><span style="color: #252525;">Role-based User Access</span><span
                                            style="color: #5dab00;"></span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-5 px-5" style="background: #333333; padding-bottom: 60px;">
                <div class="container">
                    <div class="row align-items-center mt-5">
                        <div class="col-md-7">
                            <h6 style="padding: 10px 20px;
                            background-color: #f2f3fe;
                            border-radius: 13px;
                            display: inline-block;
                            color: #5dab00;"> Pro</h6>
                            <h3 style="color: #FFF;font-size: 35px;
                            line-height: 45px;
                            font-weight: 700;
                            letter-spacing: 0px;" class="mt-4">Consent-based service to store e-invoice data for longer
                                duration and custom printing</h3>
                        </div>
                        <div class="col-md-5">

                        </div>
                        <div class="col-md-12 mt-5 mb-5 p-3">
                            <div class=" row align-items-center">
                                <div class="col-4">
                                    <div class="row align-items-center">
                                        <div class="col-4 my-2">
                                            <img
                                                src="https://irisirp.com/wp-content/uploads/2022/07/Custome-print-1.png">
                                        </div>
                                        <div class="col-8 my-2">
                                            <h5 style="font-size: 26px;
    line-height: 38px;
    font-weight: 500;
    letter-spacing: 0px;"><span style="color: #FFF;">Custom Invoice Print</span><span style="color: #5dab00;"></span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row align-items-center">
                                        <div class="col-4 my-2">
                                            <img src="https://irisirp.com/wp-content/uploads/2022/07/eSign-1.png">
                                        </div>
                                        <div class="col-8 my-2">
                                            <h5 style="font-size: 26px;
    line-height: 38px;
    font-weight: 500;
    letter-spacing: 0px;"><span style="color: #FFF;">E-sign invoice with your DSC</span><span
                                                    style="color: #5dab00;"></span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row align-items-center">
                                        <div class="col-4 my-2">
                                            <img
                                                src="https://irisirp.com/wp-content/uploads/2022/07/Bulk-download-1.png">
                                        </div>
                                        <div class="col-8 my-2">
                                            <h5 style="font-size: 26px;
    line-height: 38px;
    font-weight: 500;
    letter-spacing: 0px;"><span style="color: #FFF;">Download bulk PDF or Json</span><span
                                                    style="color: #5dab00;"></span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row align-items-center">
                                        <div class="col-4 my-2">
                                            <img src="https://irisirp.com/wp-content/uploads/2022/07/eMail-1.png">
                                        </div>
                                        <div class="col-8 my-2">
                                            <h5 style="font-size: 26px;
    line-height: 38px;
    font-weight: 500;
    letter-spacing: 0px;"><span style="color: #FFF;">E-mail Invoice PDF</span><span style="color: #5dab00;"></span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row align-items-center">
                                        <div class="col-4 my-2">
                                            <img src="https://irisirp.com/wp-content/uploads/2022/07/Share-2.png">
                                        </div>
                                        <div class="col-8 my-2">
                                            <h5 style="font-size: 26px;
    line-height: 38px;
    font-weight: 500;
    letter-spacing: 0px;"><span style="color: #FFF;">Share JSON with recipient</span><span
                                                    style="color: #5dab00;"></span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row align-items-center">
                                        <div class="col-4 my-2">
                                            <img src="https://irisirp.com/wp-content/uploads/2022/07/Transporter-2.png">
                                        </div>
                                        <div class="col-8 my-2">
                                            <h5 style="font-size: 26px;
    line-height: 38px;
    font-weight: 500;
    letter-spacing: 0px;"><span style="color: #FFF;">Share with transporter for EWB</span><span
                                                    style="color: #5dab00;"></span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-8 px-5">
                                <h4 style="color: #fff; font-weight: 600;font-size: 26px;
                        line-height: 38px;">Excited to start your Journey with us?</h4>
                            </div>
                            <div class="col-4">
                                <button class="btn btn-lg btn-light px-3 mx-2" style="font-weight: 600;">Contact
                                    Sales</button>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row mt-5">
                <div class="col-12 mt-5 px-5">
                    <div class="row align-items-center mt-5">
                        <div class="col-md-8">
                            <h6 style="padding: 10px 20px;
                            background-color: #f2f3fe;
                            border-radius: 13px;
                            display: inline-block;
                            color: #5dab00;"> Plans</h6>
                            <h3 style="color: #252525;font-size: 35px;
                            line-height: 45px;
                            font-weight: 700;
                            letter-spacing: 0px;" class="mt-4">Choose what best suits your needs.</h3>
                        </div>
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-6 mt-5 mb-5 p-3 ps-5">
                            <div class="row align-items-center">
                                <div class="col-12 my-1">
                                    <img class=""
                                        src="https://irisirp.com/wp-content/uploads/2022/07/agency7-icon3.webp">
                                    <h6 class="mt-5" style="color: #3d38ff;     font-size: 15px;
                                    line-height: 30px;
                                    font-weight: 500;
                                    letter-spacing: 3px;">Beginner</h6>
                                    <ul style="list-style: none; padding-left: 10px;">
                                        <li
                                            style="color:#252525; font-size: 16px; font-weight: 500;padding-bottom: 15px;">
                                            <i class="bi bi-check"
                                                style="font-size: 18px !important; color: #5dab00"></i>
                                            Everything you
                                            need to be e-invoice compliant
                                        </li>
                                        <li
                                            style="color:#252525; font-size: 16px; font-weight: 500;padding-bottom: 15px;">
                                            <i class="bi bi-check"
                                                style="font-size: 18px !important; color: #5dab00"></i>
                                            Single
                                            platform
                                            multi-user login
                                        </li>
                                        <li
                                            style="color:#252525; font-size: 16px; font-weight: 500;padding-bottom: 15px;">
                                            <i class="bi bi-check"
                                                style="font-size: 18px !important; color: #5dab00"></i>
                                            Single
                                            and bulk
                                            e-invoice generation
                                        </li>
                                        <li
                                            style="color:#252525; font-size: 16px; font-weight: 500;padding-bottom: 15px;">
                                            <i class="bi bi-check"
                                                style="font-size: 18px !important; color: #5dab00"></i>
                                            Multiple
                                            UI-
                                            Web/App or MS Excel Utility
                                        </li>
                                    </ul>
                                    <button class="btn btn-lg btn-dark px-3 mt-3" style="font-weight: 600;">Contact
                                        Sales</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5 mb-5 p-3 pe-5">
                            <div class="row align-items-center">
                                <div class="col-12 my-1">
                                    <img class=""
                                        src="https://irisirp.com/wp-content/uploads/2022/08/agency7-icon1.png">
                                    <h6 class="mt-5" style="color: #3d38ff;     font-size: 15px;
                                    line-height: 30px;
                                    font-weight: 500;
                                    letter-spacing: 3px;">Pro</h6>
                                    <ul style="list-style: none; padding-left: 10px;">
                                        <li
                                            style="color:#252525; font-size: 16px; font-weight: 500;padding-bottom: 15px;">
                                            <i class="bi bi-check"
                                                style="font-size: 18px !important; color: #5dab00"></i>
                                            Basic+
                                            Enhanced
                                            invoice processing and GST compliance
                                        </li>
                                        <li
                                            style="color:#252525; font-size: 16px; font-weight: 500;padding-bottom: 15px;">
                                            <i class="bi bi-check"
                                                style="font-size: 18px !important; color: #5dab00"></i> Save
                                            e-invoices
                                            for longer periods to be available later for reconciliation
                                        </li>
                                        <li
                                            style="color:#252525; font-size: 16px; font-weight: 500;padding-bottom: 15px;">
                                            <i class="bi bi-check"
                                                style="font-size: 18px !important; color: #5dab00"></i>
                                            Customise
                                            E-Invoices and Share with your customers from the portal
                                        </li>
                                    </ul>
                                    <button class="btn btn-lg btn-dark px-3 mt-3" style="font-weight: 600;">Contact
                                        Sales</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 mt-5 px-5">
                    <div class="row align-items-center mt-5">
                        <div class="col-md-6">
                            <h6 style="padding: 10px 20px;
                            background-color: #f2f3fe;
                            border-radius: 13px;
                            display: inline-block;
                            color: #5dab00;"> Premium</h6>
                            <h3 style="color: #252525;font-size: 35px;
                            line-height: 45px;
                            font-weight: 700;
                            letter-spacing: 0px;" class="mt-4">Leverage E-Invoice beyond Compliance
                            </h3>
                            <p style="margin: 0 0 15px">E-invoice impacts core business functions such as
                                Billing and
                                GST return filing. And the
                                standard and granular data of e-invoice opens up endless opportunities to
                                improve other
                                business functions</p>
                        </div>
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-6 mt-5 mb-5 p-3 px-5">
                            <div class="row align-items-center text-center">
                                <div class="col-12 my-5"></div>
                                <div class="col-12 my-1 card shadow-lg rounded-4 p-5 mb-5">
                                    <h6 class="mt-2 mb-5" style="color: #252525;     font-size: 26px;
                                    line-height: 38px;
                                    font-weight: 500;
                                    letter-spacing: 0px;">AR Management</h6>
                                    <p class="text-center mb-0">Get full visibility of your collections and
                                        better
                                        manage
                                        your Accounts
                                        Receivables cycles</p>
                                </div>
                                <div class="col-12 my-1 card shadow-lg rounded-4 p-5">
                                    <h6 class="mt-2 mb-5" style="color: #252525;     font-size: 26px;
                                    line-height: 38px;
                                    font-weight: 500;
                                    letter-spacing: 0px;">Automate GST Compliance</h6>
                                    <p class="text-center mb-0">Start with e-invoices, generate EWBs and
                                        finish
                                        with GST
                                        returns, all under one roof</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-5 p-3 px-5">
                            <div class="row align-items-center text-center">
                                <div class="col-12 my-1 card shadow-lg rounded-4 p-5 mb-5">
                                    <h6 class="mt-2 mb-5" style="color: #252525;     font-size: 26px;
                                    line-height: 38px;
                                    font-weight: 500;
                                    letter-spacing: 0px;">Receivable Financing</h6>
                                    <p class="text-center mb-0">Share your e-invoices with empanelled financiers and get
                                        credit lines on your receivables</p>
                                </div>
                                <div class="col-12 my-1 card shadow-lg rounded-4 p-5">
                                    <h6 class="mt-2 mb-5" style="color: #252525;     font-size: 26px;
                                    line-height: 38px;
                                    font-weight: 500;
                                    letter-spacing: 0px;">Grow your business</h6>
                                    <p class="text-center mb-0">Create your business profile and Increase your presence
                                        in IRIS network
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-5 px-5" style="background: #333333; padding-bottom: 10px;">
                <div class="row align-items-center mt-5 text-center">
                    <div class="col-md-12">
                        <h3 style="color: #FFF;font-size: 35px;
                            line-height: 45px;
                            font-weight: 700;
                            letter-spacing: 0px;" class="mt-4">Are you in for a future full of possibilities? <span
                                class="d-block mx-auto" style="color: #5dab00;">Let’s Talk!</span></h3>

                    </div>
                    <div class="col-md-12 mt-5 mb-5 p-3">
                        <div class="conainer" style="max-width: 400px;margin: auto;">
                            <form>
                                <div class="mb-3">
                                    <input type="text" class="form-control rounded-pill" id="firstName"
                                        aria-describedby="firstName" placeholder="First Name">
                                </div>
                                <div class="mb-3">
                                    <input type="text" class="form-control rounded-pill" id="lastName"
                                        aria-describedby="lastName" placeholder="Last Name">
                                </div>
                                <div class="mb-3">
                                    <input type="email" class="form-control rounded-pill" id="email"
                                        aria-describedby="email" placeholder="E-mail">
                                </div>
                                <div class="mb-3">
                                    <input type="text" class="form-control rounded-pill" id="number"
                                        aria-describedby="number" placeholder="Number">
                                </div>
                                <div class="mb-3">
                                    <input type="text" class="form-control rounded-pill" id="company"
                                        aria-describedby="company" placeholder="Company">
                                </div>
                                <div class="mb-3">
                                    <input type="text" class="form-control rounded-pill" id="comments"
                                        aria-describedby="comments" placeholder="Comments">
                                </div>

                                <button type="submit" class="btn btn-success w-100" style="color: #ffffff !important;
                                background-color: #5dab00 !important;
                                border: 3px solid #5dab00 !important;
                                border-radius: 8px !important;">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 70px 30px 10px;;background-color: #fafafa;">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h4>Our products</h4>
                        <ul style="list-style: none; padding-left: 10px;">
                            <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                                <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                                margin-right: 5px;"></i> E-Invoicing for
                                Taxpayers
                            </li>
                            <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                                <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                                margin-right: 5px;"></i> E-Invoice APIs
                                for
                                Solution
                                Providers
                            </li>
                        </ul>
                        <h4>Find us here</h4>
                        <i class="bi bi-twitter"></i>
                        <i class="mx-2 bi bi-linkedin"></i>
                    </div>
                    <div class="col-md-4">
                        <h4>Resources</h4>
                        <ul style="list-style: none; padding-left: 10px;">
                            <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                                <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                                margin-right: 5px;"></i>Notifications
                            </li>
                            <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                                <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                                margin-right: 5px;"></i> Blog
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h4>Contact us</h4>
                        <ul style="list-style: none; padding-left: 0px;">
                            <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                                T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                                Maharashtra, India
                            </li>
                            <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                                <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                                <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 mt-4">
                        <p>© 2023 IRIS Business Services Ltd. All Rights Reserved. | <a (click)="redirectToPrivacy()" href="javascript:;">Privacy Policy</a> | <a (click)="redirectToTerms()" href="javascript:;">Terms &
                            Conditions</a></p>
                    </div>
                </div>
            </div>
        </div>


