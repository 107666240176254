import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TaxpayerDashboardService {
password;
show:any;
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  /**
   * validate if gstin is already connected with other IRP or not
   * @param gstin gstIn added by user
   * @returns gstin details of that gstin
   */
  verifyGstin(gstin: string): any {
    return this.http
      .get(`${environment.baseUrl}/gstin/verify?gstin=${gstin}`)
      ;
  }
  searchGstin(gstin: string):any{
    return this.http
      .get(`${environment.baseUrl}/taxpayer/search?gstin=${gstin}`);
  }

  /**
   * add gstin details for that gstin
   * @param gstinDetails details of that gstin number
   * @returns gstin response
   */
  addGstinDetails(gstinDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/gstin/detail`, gstinDetails)
      .toPromise();
  }

  /**
   * generate otp for the mobile number registered with gstin
   * @param gstinDetails gstinDetails of gstin
   * @returns generated otp
   */
  generateOtp(gstinDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/gstin/generate/otp`, gstinDetails)
      .toPromise();
  }
  generateOTPForSignatoryVerification(gstinDetails){
    return this.http
    .post(`${environment.baseUrl}/gstin/generate/as/otp`, gstinDetails);

  }

  verifyOTPForSignatoryVerification(otp){
    return this.http
    .post(`${environment.baseUrl}/gstin/verify/as/otp`, otp);

  }
  generateOTPForIRPDetails(gstinDetails){
    return this.http
    .post(`${environment.baseUrl}/gstin/generate/irp/otp`, gstinDetails);

  }
  generateOTPForConsentDetails(gstinDetails)
  {

    return this.http.post(`${environment.baseUrl}/gstin/generate/consent/otp`,gstinDetails);

  }
  addFreeAdOnsApi(gstinDetails) {
    return this.http.post(`${environment.baseUrl}/gstin/add/consent`,gstinDetails)
  }
  verifyOTPForConsentVerification(otpDetails,gstinDetails)
  {
    return this.http.post(`${environment.baseUrl}/gstin/verify/consent/otp`, otpDetails);
  }
  verifyOTPForIRPVerification(otp){
    return this.http
    .post(`${environment.baseUrl}/gstin/verify/irp/otp`, otp);

  }


  /**
   * add gst username and registered mobile number for that gstin
   * @param userDetails gstin username and mobile
   * @returns
   */
  addGstinUserDetails(userDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/gstin/verify/otp`, userDetails)
      .toPromise();
  }

  /**
   * adds irp credentials for that gstin
   * @param userDetails irp username and password
   * @returns
   */
  addIrpUserDetails(userDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/gstin/irp/details`, userDetails)
      .toPromise();
  }

  /**
   * Fetch list os ERP or GSP
   * @param type GSP/ERP
   * @returns list of GSP/ERP
   */
  fetchErpGspList(type: string): any {
    return this.http
      .get(`${environment.baseUrl}/gsp/erp/list?type=${type}`)
      .toPromise();
  }

  /**
   * add api registration for specific gstin
   * @param apiDetails api details for gstin
   * @returns api registration response
   */
  addApiRegistration(apiDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/gstin/api/interface/register`, apiDetails)
      .toPromise();
  }

  // getDashboardData(gstin: string): any {
  //   return this.http
  //     .get(`${environment.baseUrl}/dbd/gstin?gstin=${gstin}`)
  //     .toPromise();
  // }

  updateTaxpayer(taxpayerDetails: any): any {
    return this.http
      .post(`${environment.baseUrl}/gstin/edit/taxpayer`, taxpayerDetails)
      .toPromise();
  }

  changeUserType(data: any){
    return this.http.post(`${environment.baseUrl}/gstin/change/user/type`, data, {})
    .toPromise();
  }
  updateFromCommonPortal(gstin){
    return this.http.post(`${environment.baseUrl}/gstin/update/from/common/portal?gstin=${gstin}`,{})

  }

  /**
   * Fetch list of Masters
   * @param type type of masters
   * @returns list of master entities of supplied type
   */
  fetchMasters(type: string): any {
    return this.http
      .get(`${environment.baseUrl}/master?type=${type}`)
  }


  /**
   * add masters
   * @param masterObject  obj of master details to be added
   * @returns
   */
  addMasters(masterObject: any): any {
    return this.http
      .post(`${environment.baseUrl}/masters`, masterObject)
  }

   /**
   * add masters
   * @param masterObject obj of master details to be updated, along with it's id
   * @returns
   */
   updateMasters(masterObject: any): any {
    return this.http
      .post(`${environment.baseUrl}/update/masters`, masterObject)
  }

   /**
   * delete masters
   * @param id id of master to be deleted
   * @returns
   */
   deleteMasters(id: any,type): any {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        "masterType": type
      },
    };
    return this.http
      .delete(`${environment.baseUrl}/delete/master?id=${id}`,options)
  }
  onClick(){
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
