<div class="container mt-5">
    <div class="row mb-3">
        <div class="col-lg-12 text-center mb-5">
            <h2>Explore more with IRIS IRP</h2>
            <h6>IRIS IRP offers more than just e-invoicing. Check out all the features and select a plan that suits
                your needs the best
            </h6>
            <!-- <p class="banner-text">Explore Integrated web and mobile app for all business functions - compliance, cash,
                commerce and
                credit - IRIS Peridot Plus
            </p> -->
        </div>

        <div class="col-lg-12 mt-3">
            <div class="row mx-1 text-center selection-row">
                <div class="col-6">

                </div>
                <div class="col-2 cursor-pointer" (click)="changePlan('BASIC',planDowngradeTemplate)"
                    [class.boosted]="userType == 'BASIC'"><span
                        [class.selection-button]="userType == 'BASIC'">BASIC</span>
                </div>

                <div class="col-2  cursor-pointer" (click)="changePlan('VAS',changeToVAS)" [class.boosted]="userType == 'VAS'"><span
                        [class.selection-button]="userType == 'VAS'">VAS</span></div>

                <div class="col-2  cursor-pointer" (click)="changePlan('VAS_PREMIUM',null)" [class.boosted]="userType == 'VAS_PREMIUM'">
                    <span [class.selection-button]="userType == 'VAS_PREMIUM'">VAS-PREMIUM</span>
                    <span class="ms-2 badge bg-warning custom-badge" [class.downscale-upcoming-label]="userType == 'VAS_PREMIUM'"> Upcoming </span>
                </div>
            </div>
        </div>
        <div class="col-lg-12">

            <div class="accordion accordion-flush custom-accordion" id="accordionExample">
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingThirteen" data-bs-toggle="collapse"
                        data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                        <!-- <button class="accordion-button collapsed" type="button" >
                      Accordion Item #2
                    </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Price</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span>Free</span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span>Free</span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span>To be
                                    announced</span></div>
                        </div>
                    </div>
                </div>


                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingOne" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <div class="row">
                            <div class="col-6">
                                <h6>E-invoicing Options</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span>
                            </div>
                        </div>
                    </div>
                    <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">A complete web portal to manage e-invoice generation,
                                            cancellation and other activities</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">A desktop tool using MS Excel for e-invoice management
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">APIs for Integration</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6"><p style="color:red">*</p>Integrated web and mobile app for all business functions
                                            -
                                            compliance, cash, commerce and credit</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-x lead error-icon-sign"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-x lead error-icon-sign"></i></span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span>Yes <br /><a class="" href="#">Know
                                                    More</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingTwo" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Data Storage and Access</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span>2 Days</span>
                            </div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i class="bi bi-check2 lead text-success"></i> Upto 100 Days</span>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span>6
                                    Months</span></div>
                        </div>
                    </div>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">For Minimum duration</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">For Longer duration
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-x lead error-icon-sign"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i>Upto 100 Days</span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i>Upto 6 months</span>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingThree" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                        <div class="row">
                            <div class="col-6">
                                <h6>Invoice Printing and download</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                        class="bi bi-check2 lead text-success">+</i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                <span><i class="bi bi-check2 lead text-success">+ +</i></span>
                            </div>
                        </div>
                    </div>
                    <div id="collapseThree" class="accordion-collapse collapse " aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Print in Standard format (with Logo and without Logo)
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Customise Invoice Print templates
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-x lead error-icon-sign"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-x lead error-icon-sign"></i></span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i> Upto 10.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Email Invoice in PDF or CSV</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span>PDF</span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span>PDF,
                                                JSON, CSV</span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span>PDF, JSON, CSV</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Download Invoice</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span>PDF
                                                or JSON</span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span>PDF,
                                                JSON, CSV</span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span>PDF, JSON, CSV</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingFour" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>E-way Bill Management</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                        class="bi bi-check2 lead text-success">+</i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                <span><i class="bi bi-check2 lead text-success">+ +</i></span>
                            </div>
                        </div>
                    </div>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Generate E-way bill along with e-invoice </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i> </span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Generate E-way bill later using IRN
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'">
                                            <span><i class="bi bi-check2 lead text-success"></i>Only for 2 days</span>
                                        </div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'">
                                            <span><i class="bi bi-check2 lead text-success"></i>Upto 100 Days</span>
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Other E-way bill tasks (Update vehicle, Extend etc.) </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingFive" data-bs-toggle="collapse"
                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Bulk operations</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                        class="bi bi-check2 lead text-success">+</i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span><i
                                        class="bi bi-check2 lead text-success">+ +</i></span></div>
                        </div>
                    </div>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Bulk generation and cancellation</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Bulk invoice download
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i>Only for 2 days</span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i>As per subscription</span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i>As per subscription</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingSix" data-bs-toggle="collapse"
                        data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>User and Access Management</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span>Upto 10
                                    Users</span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span>Upto 10
                                    Users</span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span>Upto 20
                                    Users</span></div>
                        </div>
                    </div>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Single login for multiple GSTINs</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Multi-users and access roles for GSTINs
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i>Upto 10 Users</span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i>Upto 10 Users</span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i>Upto 20 Users</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Manage data sharing with recipients</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingSeven" data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Utilities</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                        </div>
                    </div>
                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Verify E-invoice and QR code</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Get GSTIN details
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Get Master Info (State, HSN, Tax rate etc.)</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingEight" data-bs-toggle="collapse"
                        data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Business Intelligence</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span>Basic</span>
                            </div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span>Basic</span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span> Analytics
                                    and Insights</span></div>
                        </div>
                    </div>
                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Basic MIS reports</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Analytics and Insights
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"><span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                            class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingNine" data-bs-toggle="collapse"
                        data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Accounts Payable and Receivables</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                        class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span><i
                                        class="bi bi-check2 lead text-success"> +</i></span></div>
                        </div>
                    </div>
                    <div id="collapseNine" class="accordion-collapse collapse " aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Get e-invoices shared by supplier</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                            class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Reports on vendor payment release
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Initiate collections with customers</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Virtual Ledgers for receivable and payables</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingTen" data-bs-toggle="collapse"
                        data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>GST Compliance</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                        class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                        class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                        </div>
                    </div>
                    <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">GST Returns preparation</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                            class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">ITC Reconciliation
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">CFO dashboard</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Virtual Ledgers for receivable and payables</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingEleven" data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Marketplace</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                        class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span><i
                                        class="bi bi-check2 lead text-success"> +</i></span></div>
                        </div>
                    </div>
                    <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Create your public business profile</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                            class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Search suppliers based on location, HSN and more
                                        </div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                            class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Share PO and Inventory availability</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Explore invoice financing options</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"> <span><i
                                                    class="bi bi-check2 lead text-success"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div class="accordion-header px-3" id="headingTwelve" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                        <!-- <button class="accordion-button collapsed" type="button" >
                          Accordion Item #2
                        </button> -->
                        <div class="row">
                            <div class="col-6">
                                <h6>Monitoring</h6>
                            </div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                        class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                class="bi bi-x lead error-icon-sign"></i></span></div>
                            <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'"><span><i
                                        class="bi bi-check2 lead text-success"></i></span></div>
                        </div>
                    </div>
                    <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body py-0 px-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">Audit Trail</div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'BASIC'"> <span><i
                                                    class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center " [class.boosted]="userType == 'VAS'"><span><i
                                            class="bi bi-x lead error-icon-sign"></i></span></div>
                                        <div class="col-2 text-center" [class.boosted]="userType == 'VAS_PREMIUM'">
                                            <span><i class="bi bi-check2 lead text-success"></i></span>
                                        </div>
                                    </div>
                                </div>
                          
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <span><b>Notes:</b>
                    <ol>
                        <li>Minimum duration is set by GSTN for all IRPs as 2 days</li>
                        <li>Eway Bill integration is available</li>
                        <!-- <li>Will be available after integration with IRP</li> -->
                    </ol>
                </span>
            </div>
        </div>
    </div>

    <ng-template #planDowngradeTemplate>
        <div class="modal-xl">
            <div>
                <div class="modal-content ">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Downgrade Plan</h1>
                    </div>
                    <div class="modal-body">
                        <div class="row mx-2">
                            To downgrade from VAS to Basic plan type kindly revoke your consent to Store, Share and View
                        </div>
                        <div class="modal-footer pb-0 border-top-0">
                            <a class="btn text-decoration-none px-4 me-2"
                           (click)="modalRef?.hide();cancel()">Cancel</a>
                            <button type="button" class="btn btn-dark" data-bs-toggle="modal"
                                data-bs-target="#exampleModalEmailTemplate" (click)="redirectToConsent()">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #changeToVAS>
        <div class="modal-xl">
            <div>
                <div class="modal-content ">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Upgrade Plan</h1>
                    </div>
                    <div class="modal-body">
                        <div class="row mx-2">
                            To upgrade from Basic to VAS plan type. Kindly provide consent to Store data for extended period, Share data with recipients and View data shared by suppliers
                        </div>
                        <div class="modal-footer pb-0 border-top-0">
                            <a class="btn text-decoration-none px-4 me-2"
                           (click)="modalRef?.hide();cancel()">Cancel</a>
                            <button type="button" class="btn btn-dark" data-bs-toggle="modal"
                                data-bs-target="#exampleModalEmailTemplate" (click)="redirectToConsent()">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>