import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { userTypeForAPI } from '../app.config';
import { TaxPayerDashboardService } from '../shared/services/taxpayer-dashboard.service';
@Component({
  selector: 'app-taxpayer-dashboard',
  templateUrl: './taxpayer-dashboard.component.html',
  styleUrls: ['./taxpayer-dashboard.component.scss']
})
export class TaxpayerDashboardComponent implements OnInit {
  chartResponse: any;
  labelsArray = [];
  dataArray = [];
  tooltipArray = [];
  loadChart: boolean;
  newsList=[];
  constructor(private taxPayerDashboardService: TaxPayerDashboardService) { }
  barChartOptions1: ChartOptions = {
    responsive: true,

    scales: {
      xAxes: [{

        gridLines: {
          drawOnChartArea: false,
          // tickMarkLength: 0,
        },

        ticks: {
          autoSkip: false,
          // maxRotation: 45,
          // minRotation: 45
        }
      }],
      yAxes: [{
        gridLines: {
          drawOnChartArea: false,
          // tickMarkLength: 0,
        }
      }]
    },
    tooltips: {
      caretPadding: 10,
      displayColors: false,
      enabled: true,
      intersect: true,
      mode: 'x',
      titleFontColor: '#fff',
      titleMarginBottom: 10,
      xPadding: 20,
      yPadding: 15,
      // If you want to custom the value
      // callbacks: {
      //   label: function (tooltipItem, data) {
      //     console.log(tooltipItem)

      //     return ': $' + tooltipItem.yLabel;
      //   }
      // }
    },

  };
  barChartType1: ChartType = 'bar';
  barChartLegend1 = false;

  public barChartData1: ChartDataSets[] = [{
    // barPercentage: 0.5,
    // barThickness: 6,
    // maxBarThickness: 8,
    // minBarLength: 1,
    data: [5, 1, 2, 4, 3, 3, 1, 1, 1, 15, 21, 4, 1, 1, 3, 7, 54]
  }]
  ngOnInit(): void {
    this.getIRN();
    this.getErrors();
    this.getNewsAndUpdates();

  }
  getIRN() {
    this.taxPayerDashboardService.getIRNCount(userTypeForAPI.Taxpayer).subscribe(res => {
    })
   
  }
  getNewsAndUpdates(){
    this.taxPayerDashboardService.getNews().subscribe(res=>{
      if(res && res['response'] && res['response']['news']){

      //  this.newsList = res['response']['news'];
      const test =  [
        {
          "id": null,
          "newsType": "REGULAR",
          "newsHeader": "REGULAR : IRP testing  1",
          "newsBody": "Its for demo",
          "moreInfoType": null,
          "moreInfoModalHeader": null,
          "moreInfoModalBody": null,
          "moreInfoExternalLink": null,
          "moreInfoImageLink": null,
          "newsOrder": 1,
          "isActive": 1,
          "userType": "TAXPAYER",
          "onDate": "2022-09-15T00:00:00.000+00:00",
          "offDate": "2022-09-30T00:00:00.000+00:00",
          "newsDetailsId": "1",
          "creationDate": "2022-09-23T08:29:28.580+00:00"
        },
        {
          "id": null,
          "newsType": "REGULAR",
          "newsHeader": "Test case 1",
          "newsBody": "Test case body 1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
          
          "moreInfoExternalLink": "https://developer.mozilla.org/en-US/docs/Web/CSS/text-overflow",
          "moreInfoImageLink": "https://images.freeimages.com/images/large-previews/e04/yellow-frontal-with-ivy-1228121.jpg",
          "newsOrder": 2,
          "isActive": 1,
          "userType": "TAXPAYER",
          "onDate": "2022-09-22T00:00:00.000+00:00",
          "offDate": "2022-09-23T00:00:00.000+00:00",
          "newsDetailsId": "5",
          "creationDate": "2022-09-23T05:36:58.309+00:00"
        }
      ]
this.newsList = test;
      }
    })
  }
  getErrors() {
    this.taxPayerDashboardService.getErrors(userTypeForAPI.Taxpayer).subscribe(res => {
      if (res && res['response']) {
        const errorList = res['response'];

        const sortedList = errorList.sort((a, b) => a.count - b.count).reverse().slice(0, 5);
        if (sortedList) {
          sortedList.map(list => {
            this.labelsArray.push(list.error);
            this.dataArray.push(list.count);
            this.tooltipArray.push(list.message);
          });
          this.loadChart = true;

        }
        this.barChartData1 = [{

          data: this.dataArray
        }];
        let self = this;
        this.barChartOptions1.tooltips.callbacks = {

          label: function (tooltipItem, data) {
            return self.tooltipArray[tooltipItem.index];
          }

        }
        //       let elements = Object.keys(errorList)
        // .sort((a, b) => errorList[b] - errorList[a])
        // .slice(0, 5);
      }

    })

  }
  downloadCanvas(event) {

    // get the `<a>` element from click event
    var anchor = event.target;
    // get the canvas, I'm getting it by tag name, you can do by id
    // and set the href of the anchor to the canvas dataUrl
    let canvasData = document.getElementsByTagName('canvas')[0].toDataURL();
    // set the anchors 'download' attibute (name of the file to be downloaded)
    var a = document.createElement("a");
    a.download = "chart.png";
    a.href = canvasData;
    a.click();
    // anchor.download = "test.png";
  }

}
