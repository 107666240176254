<div class="gradient_wrapper">
  <!--header-->
  <header>
    <div class="custom_container">
      <div class="row">
        <div class="col-2">
          <div class="logo"><img src="assets/images/logo.png" /></div>
        </div>
        <div class="col-7 text-center">
          <div class="gstin-dropdown" *ngIf="!userPan">
            <p>User Type: API</p>
          </div>
          <div class="gstin-dropdown" *ngIf="userPan">
            <div class="user-pan-wrapper t">
              <label class="mx-1 fw-light"
                >PAN: <span class="value">{{ userPan }}</span></label
              >
              <label class="mx-2 fw-light"
                >Name: <span class="value">{{ businessName }}</span></label
              >
              <label class="mx-2 fw-light"
                >User: <span class="value">{{ userType }}</span></label
              >
              <label class="mx-1 fw-light"
                >Status:
                <span class="value">{{ statusData[status] }}</span></label
              >
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="user_admin">
            <div class="dropdown">
              <div
                class="d-flex align-items-center cursor_pointer"
                data-bs-toggle="dropdown"
              >
                <span class="user_pic"
                  ><img src="assets/images/admin_user.png"
                /></span>
                <span class="font-size-14"
                  >{{ userName }}<i class="bi bi-chevron-down ps-1"></i
                ></span>
              </div>
              <ul
                class="dropdown-menu font-size-13"
                aria-labelledby="dropdownMenuButton1"
              >
                <li><a>My Profile</a></li>
                <li><a [routerLink]="['/irp/api/profile/pan']">My PAN</a></li>
                <li><a href="#">Change Password</a></li>
                <li><a (click)="logout()">Logout</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!--header-END-->

  <!--nav-->
  <nav>
    <div class="custom_container">
      <span class="menu-open-icon" (click)="openNav()"
        ><i class="bi bi-list font-size-30"></i
      ></span>
      <div id="mySidenav" #mySidenav class="sidenav">
        <span class="closebtn" (click)="closeNav()"
          ><i class="bi bi-x font-size-30"></i
        ></span>
        <ul class="font-size-14">
          <li>
            <a [routerLink]="['/irp/api/dashboard']">
              <i class="bi bi-speedometer2"></i> DASHBOARD</a
            >
          </li>

          <li class="dropdown">
            <a
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-journal-text"></i> URL Whitelisting
              <i class="bi bi-chevron-down"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="['/irp/api/ip-whitelist']"
                  >Application</a
                >
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/irp/api/ip-status']"
                  >Status</a
                >
              </li>
            </ul>
          </li>
          <li class="dropdown">
            <a
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              [routerLink]="['/irp/api/public-key']"
            >
              <i class="bi bi-card-list"></i> Get Public Key
            </a>
          </li>

          <li class="dropdown">
            <a
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Manage Client Credentials
              <i class="bi bi-chevron-down"></i>&nbsp;&nbsp;&nbsp;
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="['/irp/api/generate-secret']"
                  >Regenerate API Client Secret</a
                >
              </li>
            </ul>
          </li>

          <li class="dropdown">
            <a
              id="navbarDropdown"
              role="button"
              [routerLink]="['/irp/greivance']"
            >
              Grievance
            </a>
          </li>
          <li class="dropdown">
            <a
              class="border-none"
              [routerLink]="['/irp/api/usage']"
              id="navbarDropdown"
            >
              Usage
            </a>
          </li>
          <li><i class="bi bi-gear"></i> Settings</li>
        </ul>
      </div>
    </div>
  </nav>
  <!--nav-END-->
  <router-outlet></router-outlet>
  <div class="clearfix"></div>
  <!--footer-->
  <footer class="font-size-10">
    <p>Copyright 2021 IRIS IRP. All rights reserved.</p>
  </footer>
  <!--footer-END-->
</div>
