import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-generate-content',
  templateUrl: './generate-content.component.html',
  styleUrls: ['./generate-content.component.scss']
})
export class GenerateContentComponent implements OnInit {
  subfeature: string;

  constructor(private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.subfeature =  this.route.snapshot.paramMap.get('subfeature');
    console.log("this.subfeature",this.subfeature)
    // if(this.subfeature != 'home'){
    //   this.scrollToDiv();
    // }
 
  }
  scrollToDiv() {
    if(this.subfeature == 'APIFEATURE'){
      console.log("going hereeee")
      window.scrollTo(0, 0)

      document.getElementById('api-content').scrollIntoView();

    }
  }

}
