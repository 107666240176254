
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-masters-pre-login',
  templateUrl: './masters-pre-login.component.html',
  styleUrls: ['./masters-pre-login.component.scss']
})
export class MastersPreLoginComponent implements OnInit {
  constructor(private toastrService: ToastrService,  private router: Router) {}

  ngOnInit(): void {}
  activeTab = 'stateCode';
  states: any = [
    { code:'01', name: 'JAMMU AND KASHMIR',pincode :"180-194" },
    { code: '02', name: 'HIMACHAL PRADESH' ,pincode:"171-177"},
    { code: '03', name: 'PUNJAB',pincode : "140-160"},
    { code: '04', name: 'CHANDIGARH',pincode : "160-160"},
    { code: '05', name: 'UTTARAKHAND',pincode : "244-263"},
    { code: '06', name: 'HARYANA',pincode :"121-136" },
    { code: '07', name: 'DELHI',pincode : "110-110"},
    { code: '08', name: 'RAJASTHAN',pincode :"301-345" },
    { code: '09', name: 'UTTAR PRADESH',pincode : "201-285"},
    { code: 10, name: 'BIHAR',pincode : "800-855"},
    { code: 11, name: 'SIKKIM',pincode : "737-737"},
    { code: 12, name: 'ARUNACHAL PRADESH',pincode : "790-792"},
    { code: 13, name: 'NAGALAND',pincode : "797-798"},
    { code: 14, name: 'MANIPUR',pincode : "795-795"},
    { code: 15, name: 'MIZORAM',pincode :"796-796" },
    { code: 16, name: 'TRIPURA',pincode :"799-799" },
    { code: 17, name: 'MEGHALAYA',pincode : "793-794"},
    { code: 18, name: 'ASSAM',pincode : "781-788"},
    { code: 19, name: 'WEST BENGAL',pincode : "700-743"},
    { code: 20, name: 'JHARKHAND',pincode : "813-835"},
    { code: 21, name: 'ODISHA',pincode : "751-770"},
    { code: 22, name: 'CHHATTISGARH',pincode : "490-497"},
    { code: 23, name: 'MADHYA PRADESH',pincode : "450-488"},
    { code: 24, name: 'GUJARAT',pincode :"360-396" },
    { code: 25, name: 'DAMAN AND DIU',pincode : "362-362"},
    { code: 26, name: 'DADRA AND NAGAR HAVELI',pincode :"396-396" },
    { code: 27, name: 'MAHARASHTRA',pincode :"400-445" },
    { code: 29, name: 'KARNATAKA',pincode : "560-591"},
    { code: 30, name: 'GOA',pincode :"403-403" },
    { code: 31, name: 'LAKSHADWEEP',pincode : "682-682"},
    { code: 32, name: 'KERALA',pincode : "670-695"},
    { code: 33, name: 'TAMIL NADU',pincode : "600-643"},
    { code: 34, name: 'PUDUCHERRY',pincode : "533-533"},
    { code: 35, name: 'ANDAMAN AND NICOBAR',pincode : "744-744"},
    { code: 36, name: 'TELANGANA'},
    { code: 37, name: 'ANDHRA PRADESH',pincode : "500-535"},
    { code: 38, name: 'LADAKH'},
    { code: 97, name: 'OTHER TERRITORY'},
    { code: 96, name: 'OTHER COUNTRY'},
  ];
  pinCodeMap: any = [
    { code:'01', name: 'JAMMU AND KASHMIR',pincode :"180-194" },
    { code: '02', name: 'HIMACHAL PRADESH' ,pincode:"171-177"},
    { code: '03', name: 'PUNJAB',pincode : "140-160"},
    { code: '04', name: 'CHANDIGARH',pincode : "160-160"},
    { code: '05', name: 'UTTARAKHAND',pincode : "244-263"},
    { code: '06', name: 'HARYANA',pincode :"121-136" },
    { code: '07', name: 'DELHI',pincode : "110-110"},
    { code: '08', name: 'RAJASTHAN',pincode :"301-345" },
    { code: '09', name: 'UTTAR PRADESH',pincode : "201-285"},
    { code: 10, name: 'BIHAR',pincode : "800-855"},
    { code: 11, name: 'SIKKIM',pincode : "737-737"},
    { code: 12, name: 'ARUNACHAL PRADESH',pincode : "790-792"},
    { code: 13, name: 'NAGALAND',pincode : "797-798"},
    { code: 14, name: 'MANIPUR',pincode : "795-795"},
    { code: 15, name: 'MIZORAM',pincode :"796-796" },
    { code: 16, name: 'TRIPURA',pincode :"799-799" },
    { code: 17, name: 'MEGHALAYA',pincode : "793-794"},
    { code: 18, name: 'ASSAM',pincode : "781-788"},
    { code: 19, name: 'WEST BENGAL',pincode : "700-743"},
    { code: 20, name: 'JHARKHAND',pincode : "813-835"},
    { code: 21, name: 'ODISHA',pincode : "751-770"},
    { code: 22, name: 'CHHATTISGARH',pincode : "490-497"},
    { code: 23, name: 'MADHYA PRADESH',pincode : "450-488"},
    { code: 24, name: 'GUJARAT',pincode :"360-396" },
    { code: 25, name: 'DAMAN AND DIU',pincode : "362-362"},
    { code: 26, name: 'DADRA AND NAGAR HAVELI',pincode :"396-396" },
    { code: 27, name: 'MAHARASHTRA',pincode :"400-445" },
    { code: 29, name: 'KARNATAKA',pincode : "560-591"},
    { code: 30, name: 'GOA',pincode :"403-403" },
    { code: 31, name: 'LAKSHADWEEP',pincode : "682-682"},
    { code: 32, name: 'KERALA',pincode : "670-695"},
    { code: 33, name: 'TAMIL NADU',pincode : "600-643"},
    { code: 34, name: 'PUDUCHERRY',pincode : "533-533"},
    { code: 34, name: 'PUDUCHERRY', pincode: "605-605" },
    { code: 34, name: 'PUDUCHERRY', pincode: "607-607" },
    { code: 34, name: 'PUDUCHERRY', pincode: "609-609" },
    { code: 35, name: 'ANDAMAN AND NICOBAR',pincode : "744-744"},
    { code: 37, name: 'ANDHRA PRADESH',pincode : "500-535"},
  ];
  pinState:any = [
    { code:'01', name: 'JAMMU AND KASHMIR',pincode :"180-194" },
    { code: '02', name: 'HIMACHAL PRADESH' ,pincode:"171-177"},
    { code: '03', name: 'PUNJAB',pincode : "140-160"},
    { code: '04', name: 'CHANDIGARH',pincode : "160-160"},
    { code: '05', name: 'UTTARAKHAND',pincode : "244-263"},
    { code: '06', name: 'HARYANA',pincode :"121-136" },
    { code: '07', name: 'DELHI',pincode : "110-110"},
    { code: '08', name: 'RAJASTHAN',pincode :"301-345" },
    { code: '09', name: 'UTTAR PRADESH',pincode : "201-285"},
    { code: 10, name: 'BIHAR',pincode : "800-855"},
    { code: 11, name: 'SIKKIM',pincode : "737-737"},
    { code: 12, name: 'ARUNACHAL PRADESH',pincode : "790-792"},
    { code: 13, name: 'NAGALAND',pincode : "797-798"},
    { code: 14, name: 'MANIPUR',pincode : "795-795"},
    { code: 15, name: 'MIZORAM',pincode :"796-796" },
    { code: 16, name: 'TRIPURA',pincode :"799-799" },
    { code: 17, name: 'MEGHALAYA',pincode : "793-794"},
    { code: 18, name: 'ASSAM',pincode : "781-788"},
    { code: 19, name: 'WEST BENGAL',pincode : "700-743"},
    { code: 20, name: 'JHARKHAND',pincode : "813-835"},
    { code: 21, name: 'ODISHA',pincode : "751-770"},
    { code: 22, name: 'CHHATTISGARH',pincode : "490-497"},
    { code: 23, name: 'MADHYA PRADESH',pincode : "450-488"},
    { code: 24, name: 'GUJARAT',pincode :"360-396" },
    { code: 25, name: 'DAMAN AND DIU',pincode : "362-362"},
    { code: 26, name: 'DADRA AND NAGAR HAVELI',pincode :"396-396" },
    { code: 27, name: 'MAHARASHTRA',pincode :"400-445" },
    { code: 29, name: 'KARNATAKA',pincode : "560-591"},
    { code: 30, name: 'GOA',pincode :"403-403" },
    { code: 31, name: 'LAKSHADWEEP',pincode : "682-682"},
    { code: 32, name: 'KERELA',pincode : "670-695"},
    { code: 33, name: 'TAMIL NADU',pincode : "600-643"},
    { code: 34, name: 'PUDUCHERRY',pincode : "533-533"},
    { code: 35, name: 'ANDAMAN AND NICOBAR',pincode : "744-744"},
    { code: 36, name: 'TELANGANA'},
    { code: 37, name: 'ANDHRA PRADESH',pincode : "500-535"},
    { code: 38, name: 'LADAKH'}
  ];
  countries:any = [
    { code: 'AD', name: 'Andorra' },
    { code: 'AE', name: 'United Arab Emirates' },
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AG', name: 'Antigua and Barbuda' },
    { code: 'AI', name: 'Anguilla' },
    { code: 'AL', name: 'Albania' },
    { code: 'AM', name: 'Armenia' },
    { code: 'AO', name: 'Angola' },
    { code: 'AQ', name: 'Antarctica' },
    { code: 'AR', name: 'Argentina' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AT', name: 'Austria' },
    { code: 'AU', name: 'Australia' },
    { code: 'AW', name: 'Aruba' },
    { code: 'AX', name: 'Åland Islands' },
    { code: 'AZ', name: 'Azerbaijan' },
    { code: 'BA', name: 'Bosnia and Herzegovina' },
    { code: 'BB', name: 'Barbados' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BE', name: 'Belgium' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BG', name: 'Bulgaria' },
    { code: 'BH', name: 'Bahrain' },
    { code: 'BI', name: 'Burundi' },
    { code: 'BJ', name: 'Benin' },
    { code: 'BL', name: 'Saint Barthélemy' },
    { code: 'BM', name: 'Bermuda' },
    { code: 'BN', name: 'Brunei Darussalam' },
    { code: 'BO', name: 'Bolivia (Plurinational State of)' },
    { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { code: 'BR', name: 'Brazil' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BT', name: 'Bhutan' },
    { code: 'BV', name: 'Bouvet Island' },
    { code: 'BW', name: 'Botswana' },
    { code: 'BY', name: 'Belarus' },
    { code: 'BZ', name: 'Belize' },
    { code: 'CA', name: 'Canada' },
    { code: 'CC', name: 'Cocos (Keeling) Islands' },
    { code: 'CD', name: 'Congo, Democratic Republic of the' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'CG', name: 'Congo' },
    { code: 'CH', name: 'Switzerland' },
    { code: 'CI', name: 'Côte dIvoire' },
    { code: 'CK', name: 'Cook Islands' },
    { code: 'CL', name: 'Chile' },
    { code: 'CM', name: 'Cameroon' },
    { code: 'CN', name: 'China' },
    { code: 'CO', name: 'Colombia' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CV', name: 'Cabo Verde' },
    { code: 'CW', name: 'Curaçao' },
    { code: 'CX', name: 'Christmas Island' },
    { code: 'CY', name: 'Cyprus' },
    { code: 'CZ', name: 'Czechia' },
    { code: 'DE', name: 'Germany' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DK', name: 'Denmark' },
    { code: 'DM', name: 'Dominica' },
    { code: 'DO', name: 'Dominican Republic' },
    { code: 'DZ', name: 'Algeria' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'EE', name: 'Estonia' },
    { code: 'EG', name: 'Egypt' },
    { code: 'EH', name: 'Western Sahara' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'ES', name: 'Spain' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'FI', name: 'Finland' },
    { code: 'FJ', name: 'Fiji' },
    { code: 'FK', name: 'Falkland Islands (Malvinas)' },
    { code: 'FM', name: 'Micronesia (Federated States of)' },
    { code: 'FO', name: 'Faroe Islands' },
    { code: 'FR', name: 'France' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland' },
    { code: 'GD', name: 'Grenada' },
    { code: 'GE', name: 'Georgia' },
    { code: 'GF', name: 'French Guiana' },
    { code: 'GG', name: 'Guernsey' },
    { code: 'GH', name: 'Ghana' },
    { code: 'GI', name: 'Gibraltar' },
    { code: 'GL', name: 'Greenland' },
    { code: 'GM', name: 'Gambia' },
    { code: 'GN', name: 'Guinea' },
    { code: 'GP', name: 'Guadeloupe' },
    { code: 'GQ', name: 'Equatorial Guinea' },
    { code: 'GR', name: 'Greece' },
    { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'GU', name: 'Guam' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'GY', name: 'Guyana' },
    { code: 'HK', name: 'Hong Kong' },
    { code: 'HM', name: 'Heard Island and McDonald Islands' },
    { code: 'HN', name: 'Honduras' },
    { code: 'HR', name: 'Croatia' },
    { code: 'HT', name: 'Haiti' },
    { code: 'HU', name: 'Hungary' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'IE', name: 'Ireland' },
    { code: 'IL', name: 'Israel' },
    { code: 'IM', name: 'Isle of Man' },
    { code: 'IN', name: 'India' },
    { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'IQ', name: 'Iraq' },
    { code: 'IR', name: 'Iran (Islamic Republic of)' },
    { code: 'IS', name: 'Iceland' },
    { code: 'IT', name: 'Italy' },
    { code: 'JE', name: 'Jersey' },
    { code: 'JM', name: 'Jamaica' },
    { code: 'JO', name: 'Jordan' },
    { code: 'JP', name: 'Japan' },
    { code: 'KE', name: 'Kenya' },
    { code: 'KG', name: 'Kyrgyzstan' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'KM', name: 'Comoros' },
    { code: 'KN', name: 'Saint Kitts and Nevis' },
    { code: 'KP', name: 'Korea (Democratic Peoples Republic of)' },
    { code: 'KR', name: 'Korea, Republic of' },
    { code: 'KW', name: 'Kuwait' },
    { code: 'KY', name: 'Cayman Islands' },
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'LA', name: 'Lao Peoples Democratic Republic' },
    { code: 'LB', name: 'Lebanon' },
    { code: 'LC', name: 'Saint Lucia' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LK', name: 'Sri Lanka' },
    { code: 'LR', name: 'Liberia' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LT', name: 'Lithuania' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'LV', name: 'Latvia' },
    { code: 'LY', name: 'Libya' },
    { code: 'MA', name: 'Morocco' },
    { code: 'MC', name: 'Monaco' },
    { code: 'MD', name: 'Moldova, Republic of' },
    { code: 'ME', name: 'Montenegro' },
    { code: 'MF', name: 'Saint Martin (French part)' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'MH', name: 'Marshall Islands' },
    { code: 'MK', name: 'North Macedonia' },
    { code: 'ML', name: 'Mali' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'MN', name: 'Mongolia' },
    { code: 'MO', name: 'Macao' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'MQ', name: 'Martinique' },
    { code: 'MR', name: 'Mauritania' },
    { code: 'MS', name: 'Montserrat' },
    { code: 'MT', name: 'Malta' },
    { code: 'MU', name: 'Mauritius' },
    { code: 'MV', name: 'Maldives' },
    { code: 'MW', name: 'Malawi' },
    { code: 'MX', name: 'Mexico' },
    { code: 'MY', name: 'Malaysia' },
    { code: 'MZ', name: 'Mozambique' },
    { code: 'NA', name: 'Namibia' },
    { code: 'NC', name: 'New Caledonia' },
    { code: 'NE', name: 'Niger' },
    { code: 'NF', name: 'Norfolk Island' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'NI', name: 'Nicaragua' },
    { code: 'NL', name: 'Netherlands' },
    { code: 'NO', name: 'Norway' },
    { code: 'NP', name: 'Nepal' },
    { code: 'NR', name: 'Nauru' },
    { code: 'NU', name: 'Niue' },
    { code: 'NZ', name: 'New Zealand' },
    { code: 'OM', name: 'Oman' },
    { code: 'PA', name: 'Panama' },
    { code: 'PE', name: 'Peru' },
    { code: 'PF', name: 'French Polynesia' },
    { code: 'PG', name: 'Papua New Guinea' },
    { code: 'PH', name: 'Philippines' },
    { code: 'PK', name: 'Pakistan' },
    { code: 'PL', name: 'Poland' },
    { code: 'PM', name: 'Saint Pierre and Miquelon' },
    { code: 'PN', name: 'Pitcairn' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'PS', name: 'Palestine, State of' },
    { code: 'PT', name: 'Portugal' },
    { code: 'PW', name: 'Palau' },
    { code: 'PY', name: 'Paraguay' },
    { code: 'QA', name: 'Qatar' },
    { code: 'RE', name: 'Réunion' },
    { code: 'RO', name: 'Romania' },
    { code: 'RS', name: 'Serbia' },
    { code: 'RU', name: 'Russian Federation' },
    { code: 'RW', name: 'Rwanda' },
    { code: 'SA', name: 'Saudi Arabia' },
    { code: 'SB', name: 'Solomon Islands' },
    { code: 'SC', name: 'Seychelles' },
    { code: 'SD', name: 'Sudan' },
    { code: 'SE', name: 'Sweden' },
    { code: 'SG', name: 'Singapore' },
    { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { code: 'SI', name: 'Slovenia' },
    { code: 'SJ', name: 'Svalbard and Jan Mayen' },
    { code: 'SK', name: 'Slovakia' },
    { code: 'SL', name: 'Sierra Leone' },
    { code: 'SM', name: 'San Marino' },
    { code: 'SN', name: 'Senegal' },
    { code: 'SO', name: 'Somalia' },
    { code: 'SR', name: 'Suriname' },
    { code: 'SS', name: 'South Sudan' },
    { code: 'ST', name: 'Sao Tome and Principe' },
    // { code: 'SU', name: 'El Salvador' },
    { code: 'SV', name: 'El Salvador' },
    { code: 'SX', name: 'Sint Maarten (Dutch part)' },
    { code: 'SY', name: 'Syrian Arab Republic' },
    { code: 'SZ', name: 'Eswatini' },
    { code: 'TC', name: 'Turks and Caicos Islands' },
    { code: 'TD', name: 'Chad' },
    { code: 'TF', name: 'French Southern Territories' },
    { code: 'TG', name: 'Togo' },
    { code: 'TH', name: 'Thailand' },
    { code: 'TJ', name: 'Tajikistan' },
    { code: 'TK', name: 'Tokelau' },
    { code: 'TL', name: 'Timor-Leste' },
    { code: 'TM', name: 'Turkmenistan' },
    { code: 'TN', name: 'Tunisia' },
    { code: 'TO', name: 'Tonga' },
    { code: 'TR', name: 'Turkey' },
    { code: 'TT', name: 'Trinidad and Tobago' },
    { code: 'TV', name: 'Tuvalu' },
    { code: 'TW', name: 'Taiwan, Province of China' },
    { code: 'TZ', name: 'Tanzania, United Republic of' },
    { code: 'UA', name: 'Ukraine' },
    { code: 'UG', name: 'Uganda' },
    { code: 'UM', name: 'United States Minor Outlying Islands' },
    { code: 'US', name: 'United States of America' },
    { code: 'UY', name: 'Uruguay' },
    { code: 'UZ', name: 'Uzbekistan' },
    { code: 'VA', name: 'Holy See' },
    { code: 'VC', name: 'Saint Vincent and the Grenadines' },
    { code: 'VE', name: 'Venezuela (Bolivarian Republic of)' },
    { code: 'VG', name: 'Virgin Islands (British)' },
    { code: 'VI', name: 'Virgin Islands (U.S.)' },
    { code: 'VN', name: 'Viet Nam' },
    { code: 'VU', name: 'Vanuatu' },
    { code: 'WF', name: 'Wallis and Futuna' },
    { code: 'WS', name: 'Samoa' },
    { code: 'YE', name: 'Yemen' },
    { code: 'YT', name: 'Mayotte' },
    { code: 'ZA', name: 'South Africa' },
    { code: 'ZM', name: 'Zambia' },
    { code: 'ZW', name: 'Zimbabwe' },
  ];
  ports:any = [
    {
        "code": "INABG1",
        "name": "Alibag"
    },
    {
        "code": "INACH1",
        "name": "Achra"
    },
    {
        "code": "INADA6",
        "name": "Ahmedabad Adalaj"
    },
    {
        "code": "INADI1",
        "name": "Androth Island"
    },
    {
        "code": "INAGI1",
        "name": "Agatti Island"
    },
    {
        "code": "INAGR4",
        "name": "Agra"
    },
    {
        "code": "INAGR5",
        "name": "FPO AGRA"
    },
    {
        "code": "INAGR6",
        "name": "Agra"
    },
    {
        "code": "INAGTB",
        "name": "Agartala"
    },
    {
        "code": "INAGX4",
        "name": "Agatti Island"
    },
    {
        "code": "INAHD6",
        "name": "ICD SANAND"
    },
    {
        "code": "INAIG6",
        "name": "GE PVT. LTD."
    },
    {
        "code": "INAII6",
        "name": "M/S IGATE GS LTD."
    },
    {
        "code": "INAIK6",
        "name": "IBRAHIMPURICD KHURJA"
    },
    {
        "code": "INAIR6",
        "name": "SP PVT. LTD."
    },
    {
        "code": "INAJE6",
        "name": "WLSPUN ANJAR SEZ"
    },
    {
        "code": "INAJJ6",
        "name": "Arakkonam Melpakkam"
    },
    {
        "code": "INAJL4",
        "name": "Aizawl"
    },
    {
        "code": "INAJM6",
        "name": "MUNDRA PORT SEZ"
    },
    {
        "code": "INAKB6",
        "name": "BIACPL SEZ"
    },
    {
        "code": "INAKD4",
        "name": "Akola"
    },
    {
        "code": "INAKP6",
        "name": "APIICL SEZ"
    },
    {
        "code": "INAKR6",
        "name": "RPCIPL SEZ"
    },
    {
        "code": "INAKV6",
        "name": "Ankleshwar ICD"
    },
    {
        "code": "INALA1",
        "name": "ALANG SBY"
    },
    {
        "code": "INALF1",
        "name": "Allepey"
    },
    {
        "code": "INAMD4",
        "name": "Ahmedabad"
    },
    {
        "code": "INAMD5",
        "name": "Ahmedabad"
    },
    {
        "code": "INAMD6",
        "name": "Ahmedabad"
    },
    {
        "code": "INAMG6",
        "name": "Amingaon (Gauhati)"
    },
    {
        "code": "INAMI1",
        "name": "Amini Island"
    },
    {
        "code": "INAMK6",
        "name": "KSITIL/SEZ/ALAPPUZHA"
    },
    {
        "code": "INANG1",
        "name": "Anijengo"
    },
    {
        "code": "INANL1",
        "name": "Arnala"
    },
    {
        "code": "INAPI6",
        "name": "AAP-SEZ AHMEDABAD"
    },
    {
        "code": "INAPL6",
        "name": "Dadri ACPL CFS"
    },
    {
        "code": "INAPT6",
        "name": "Anaparti"
    },
    {
        "code": "INARR6",
        "name": "Aroor"
    },
    {
        "code": "INASR2",
        "name": "Amritsar Railway Stn"
    },
    {
        "code": "INASR6",
        "name": "Amritsar"
    },
    {
        "code": "INATQ4",
        "name": "Amritsar"
    },
    {
        "code": "INATQ6",
        "name": "Amritsar"
    },
    {
        "code": "INATRB",
        "name": "Attari Road"
    },
    {
        "code": "INATT2",
        "name": "Attari Railway Stat"
    },
    {
        "code": "INAWM6",
        "name": "SEZ, SHENDRA"
    },
    {
        "code": "INAWS6",
        "name": "SOMANI SEZ BHIWADI"
    },
    {
        "code": "INAWW6",
        "name": "WIDL AEZ, AURANGABAD"
    },
    {
        "code": "INAZK1",
        "name": "Azhikkal"
    },
    {
        "code": "INBAG6",
        "name": "GEM&JEWEL-SEZ/ULWE"
    },
    {
        "code": "INBAI6",
        "name": "IT/ITES-B-SEZ/ULWE"
    },
    {
        "code": "INBAM6",
        "name": "MULTISERVCE-SEZ/ULWE"
    },
    {
        "code": "INBAP6",
        "name": "MULTISERVICEDRONAGIR"
    },
    {
        "code": "INBAT6",
        "name": "IT/ITES-C SEZ/UIWE"
    },
    {
        "code": "INBAU6",
        "name": "IT/ITES-A-SEZ/ULWE"
    },
    {
        "code": "INBAW6",
        "name": "ICD BAWAL"
    },
    {
        "code": "INBBI4",
        "name": "Bhubaneswar"
    },
    {
        "code": "INBBM6",
        "name": "Bari Brahamna ICD"
    },
    {
        "code": "INBBP1",
        "name": "Bahabal Pur"
    },
    {
        "code": "INBBS6",
        "name": "SEZ, OIIDC, BHUBANES"
    },
    {
        "code": "INBCH6",
        "name": "HARI SEZ VARANASI"
    },
    {
        "code": "INBCO6",
        "name": "EURO MULTIVISION SEZ"
    },
    {
        "code": "INBCP6",
        "name": "PRITECH/SEZ/BANGLORE"
    },
    {
        "code": "INBDB6",
        "name": "PRECIOUSCARGOCUSTOMS"
    },
    {
        "code": "INBDG1",
        "name": "Badagara"
    },
    {
        "code": "INBDH6",
        "name": "ICD BADOHI"
    },
    {
        "code": "INBDI6",
        "name": "ICD BADDI,"
    },
    {
        "code": "INBDM6",
        "name": "ICD, PANCHI, SONIPAT"
    },
    {
        "code": "INBDQ1",
        "name": "Vadodara"
    },
    {
        "code": "INBDR1",
        "name": "Baindur"
    },
    {
        "code": "INBED1",
        "name": "Bedi"
    },
    {
        "code": "INBEK4",
        "name": "Bareilly"
    },
    {
        "code": "INBEP4",
        "name": "Bellary"
    },
    {
        "code": "INBET1",
        "name": "Betul"
    },
    {
        "code": "INBEY1",
        "name": "Beypore"
    },
    {
        "code": "INBFR6",
        "name": "M/S GRFL, ICD, BALLA"
    },
    {
        "code": "INBGK6",
        "name": "Bhagat ki Kothi Jodh"
    },
    {
        "code": "INBGMB",
        "name": "Baghmara"
    },
    {
        "code": "INBGQ6",
        "name": "QUEST SEZ BELGAUM"
    },
    {
        "code": "INBGUB",
        "name": "Bairgania"
    },
    {
        "code": "INBGW1",
        "name": "Bhagwa"
    },
    {
        "code": "INBHC6",
        "name": "JUBILANT/SEZ/VILAYAT"
    },
    {
        "code": "INBHD6",
        "name": "DAHEZ SEZ"
    },
    {
        "code": "INBHJ4",
        "name": "Bhuj"
    },
    {
        "code": "INBHL6",
        "name": "Bhilwara"
    },
    {
        "code": "INBHM1",
        "name": "Bheemunipatnam"
    },
    {
        "code": "INBHO4",
        "name": "Bhopal"
    },
    {
        "code": "INBHS6",
        "name": "STERLING BHARUCH"
    },
    {
        "code": "INBHU1",
        "name": "Bhavanagar"
    },
    {
        "code": "INBHU4",
        "name": "Bhavanagar"
    },
    {
        "code": "INBKB4",
        "name": "Bikaner"
    },
    {
        "code": "INBKR1",
        "name": "Belekeri"
    },
    {
        "code": "INBKT1",
        "name": "Bankot"
    },
    {
        "code": "INBLC6",
        "name": "CESSNA/SEZ/BANGALORE"
    },
    {
        "code": "INBLE6",
        "name": "CONCOR ICD BALASORE"
    },
    {
        "code": "INBLJ6",
        "name": "Agra"
    },
    {
        "code": "INBLK1",
        "name": "Belekeri"
    },
    {
        "code": "INBLM1",
        "name": "Bilimora"
    },
    {
        "code": "INBLP1",
        "name": "Belapur"
    },
    {
        "code": "INBLR4",
        "name": "Bangalore"
    },
    {
        "code": "INBLR5",
        "name": "Bangalore"
    },
    {
        "code": "INBLR6",
        "name": "Bangalore"
    },
    {
        "code": "INBLTB",
        "name": "Balet"
    },
    {
        "code": "INBLV6",
        "name": "VTV/SEZ/BANGALORE"
    },
    {
        "code": "INBMA6",
        "name": "APIIC PRAKASHAM"
    },
    {
        "code": "INBMR2",
        "name": "Barmer Railway Stati"
    },
    {
        "code": "INBNC6",
        "name": "KBITS SEZ BANGALORE"
    },
    {
        "code": "INBND1",
        "name": "Bandra"
    },
    {
        "code": "INBNG6",
        "name": "MAHAGAON ICD, THANE"
    },
    {
        "code": "INBNK6",
        "name": "KOLKATA IT PARK"
    },
    {
        "code": "INBNP1",
        "name": "Bheemunipatnam"
    },
    {
        "code": "INBNRB",
        "name": "Bhimnagar"
    },
    {
        "code": "INBNT6",
        "name": "TCS"
    },
    {
        "code": "INBNW6",
        "name": "WIPRO SEZ"
    },
    {
        "code": "INBNX6",
        "name": "CANDOR ONE HTSPL,SEZ"
    },
    {
        "code": "INBNYB",
        "name": "Berhni"
    },
    {
        "code": "INBOK6",
        "name": "BORKHEDI ICD NAGPUR"
    },
    {
        "code": "INBOLB",
        "name": "Bolanganj"
    },
    {
        "code": "INBOM1",
        "name": "Mumbai (Ex Bombay)"
    },
    {
        "code": "INBOM4",
        "name": "Mumbai (Ex Bombay)"
    },
    {
        "code": "INBOM5",
        "name": "Mumbai (Ex Bombay)"
    },
    {
        "code": "INBOM6",
        "name": "Mumbai(EPZ/SEZ)"
    },
    {
        "code": "INBPL5",
        "name": "BHOPAL FPO"
    },
    {
        "code": "INBPS5",
        "name": "APSO MUMBAI"
    },
    {
        "code": "INBRAB",
        "name": "Barsora"
    },
    {
        "code": "INBRC6",
        "name": "Baroda"
    },
    {
        "code": "INBRH1",
        "name": "Broach"
    },
    {
        "code": "INBRI6",
        "name": "RNBIPL SEZ BIKANER"
    },
    {
        "code": "INBRL6",
        "name": "L & T/SEZ/VADODARA"
    },
    {
        "code": "INBRM1",
        "name": "Borlai ¿ Mandla"
    },
    {
        "code": "INBRS6",
        "name": "S E & C/SEZ/WAGHODIA"
    },
    {
        "code": "INBRY1",
        "name": "Borya"
    },
    {
        "code": "INBSAB",
        "name": "Banbasa"
    },
    {
        "code": "INBSB6",
        "name": "Varanasi"
    },
    {
        "code": "INBSL6",
        "name": "Bhusaval ICD"
    },
    {
        "code": "INBSN1",
        "name": "Bassein"
    },
    {
        "code": "INBSR1",
        "name": "Bulsar"
    },
    {
        "code": "INBSW6",
        "name": "AFS KAPASHERA"
    },
    {
        "code": "INBTK1",
        "name": "Bhatkal"
    },
    {
        "code": "INBTMB",
        "name": "Bhithamore(Sursnad)"
    },
    {
        "code": "INBTR1",
        "name": "Bitra Island"
    },
    {
        "code": "INBUD1",
        "name": "Budge-Budge"
    },
    {
        "code": "INBUL6",
        "name": "AN FTWZ BULANDSHAHR"
    },
    {
        "code": "INBUP4",
        "name": "Bhatinda"
    },
    {
        "code": "INBUP6",
        "name": "Bhatinda"
    },
    {
        "code": "INBVC6",
        "name": "CONCR-ICD/BALLABGARH"
    },
    {
        "code": "INBWD6",
        "name": "Bhiwadi"
    },
    {
        "code": "INBWN1",
        "name": "Bhiwandi"
    },
    {
        "code": "INBXR6",
        "name": "DLF LTD., SEZ"
    },
    {
        "code": "INBYT1",
        "name": "Beyt"
    },
    {
        "code": "INCAG6",
        "name": "TATA STEEL SEZGANJAM"
    },
    {
        "code": "INCAM1",
        "name": "Cambay"
    },
    {
        "code": "INCAP1",
        "name": "Calingapatam"
    },
    {
        "code": "INCAR1",
        "name": "Carijam"
    },
    {
        "code": "INCAS6",
        "name": "SAPL SEZ/ GANJAM"
    },
    {
        "code": "INCBC6",
        "name": "CHIPL SEZ/ COIMBATOR"
    },
    {
        "code": "INCBD4",
        "name": "Car Nicobar"
    },
    {
        "code": "INCBDB",
        "name": "Changrabandh"
    },
    {
        "code": "INCBE6",
        "name": "COIMBATORE"
    },
    {
        "code": "INCBL1",
        "name": "Chandbali"
    },
    {
        "code": "INCBS6",
        "name": "SE&C COIMBATORE"
    },
    {
        "code": "INCCH6",
        "name": "Chinchwad ICD"
    },
    {
        "code": "INCCI6",
        "name": "WIPRO SEZ HINJEWADI"
    },
    {
        "code": "INCCJ1",
        "name": "Kozhikode (ex Calicu"
    },
    {
        "code": "INCCJ4",
        "name": "Kozhikode (ex Calicu"
    },
    {
        "code": "INCCP6",
        "name": "PEPPL SEZ, CHINCHWAD"
    },
    {
        "code": "INCCQ6",
        "name": "M/S QB PARK LTD."
    },
    {
        "code": "INCCT6",
        "name": "KINFRAFP SEZ"
    },
    {
        "code": "INCCU1",
        "name": "Calcutta"
    },
    {
        "code": "INCCU4",
        "name": "Calcutta"
    },
    {
        "code": "INCCU5",
        "name": "Calcutta"
    },
    {
        "code": "INCCW6",
        "name": "WIPRO LTD."
    },
    {
        "code": "INCDC6",
        "name": "RGT PARK (PHASE-II)"
    },
    {
        "code": "INCDD6",
        "name": "RGT PARK (PHASE-I)"
    },
    {
        "code": "INCDL1",
        "name": "Cuddalore"
    },
    {
        "code": "INCDP1",
        "name": "Coondapur (Ganguly)"
    },
    {
        "code": "INCDP4",
        "name": "Cuddapah"
    },
    {
        "code": "INCDP6",
        "name": "M/S APIIC LTD SEZ."
    },
    {
        "code": "INCDQ6",
        "name": "QUARKCITY/SEZ/MOHALI"
    },
    {
        "code": "INCDR6",
        "name": "SUN PHARMACEUTICALS"
    },
    {
        "code": "INCEC6",
        "name": "L&T AE IT SEZ PVT."
    },
    {
        "code": "INCGA6",
        "name": "MWCD APARELS CHENGPA"
    },
    {
        "code": "INCGE6",
        "name": "ETA TECH. PVT. LTD."
    },
    {
        "code": "INCGI6",
        "name": "MWCD SEZ CHENGALPATU"
    },
    {
        "code": "INCGL6",
        "name": "MWCD AUTO ANCILARIES"
    },
    {
        "code": "INCHE6",
        "name": "Tiruppur-Chettipalay"
    },
    {
        "code": "INCHJ6",
        "name": "ICD WARDHA"
    },
    {
        "code": "INCHL1",
        "name": "Colachel"
    },
    {
        "code": "INCHMB",
        "name": "Chamurchi"
    },
    {
        "code": "INCHN6",
        "name": "Vadodara Chhani CFS"
    },
    {
        "code": "INCHPB",
        "name": "Champai"
    },
    {
        "code": "INCHR1",
        "name": "Chapora"
    },
    {
        "code": "INCJA6",
        "name": "L&T AE IT SEZ"
    },
    {
        "code": "INCJB4",
        "name": "Coimbatore"
    },
    {
        "code": "INCJB6",
        "name": "Coimbatore"
    },
    {
        "code": "INCJC6",
        "name": "NCTL (ENGG.) SEZ/ KA"
    },
    {
        "code": "INCJD6",
        "name": "DLF INFOCITY DEV."
    },
    {
        "code": "INCJE6",
        "name": "ECTN KANCHEEPURAM"
    },
    {
        "code": "INCJF6",
        "name": "FTIL SRIPERUMBUDUR"
    },
    {
        "code": "INCJH6",
        "name": "ICD WARDHA"
    },
    {
        "code": "INCJI6",
        "name": "ETL IS LTD."
    },
    {
        "code": "INCJJ6",
        "name": "J M FTZ/SEZ/MANNUR"
    },
    {
        "code": "INCJN6",
        "name": "NIPL SEZ SRIPERUMBUD"
    },
    {
        "code": "INCJO6",
        "name": "SIPCOT ORAGADAM SRIP"
    },
    {
        "code": "INCJS6",
        "name": "SIPCOT SRIPERUMBUDUR"
    },
    {
        "code": "INCLK6",
        "name": "KSITIL/SEZ/KOZHIKODE"
    },
    {
        "code": "INCLU6",
        "name": "ULCCS/SEZ/KOZHIKODE"
    },
    {
        "code": "INCLX6",
        "name": "Chirala"
    },
    {
        "code": "INCMB1",
        "name": "Cambay"
    },
    {
        "code": "INCML6",
        "name": "KATHUWAS ICD ALWAR"
    },
    {
        "code": "INCNB1",
        "name": "Car-Nicobar"
    },
    {
        "code": "INCNC6",
        "name": "NCT PVT. LTD. SEZ"
    },
    {
        "code": "INCNN1",
        "name": "Cannanore"
    },
    {
        "code": "INCOA6",
        "name": "KAKINADA SEZ KAKINAD"
    },
    {
        "code": "INCOH4",
        "name": "Cooch Behar"
    },
    {
        "code": "INCOK1",
        "name": "Cochin"
    },
    {
        "code": "INCOK4",
        "name": "Cochin"
    },
    {
        "code": "INCOK6",
        "name": "Cochin(EPZ/SEZ)"
    },
    {
        "code": "INCOL1",
        "name": "Colochel"
    },
    {
        "code": "INCOO1",
        "name": "Coondapur (Ganguly)"
    },
    {
        "code": "INCOP6",
        "name": "PARRY SEZ KAKINADA"
    },
    {
        "code": "INCPC6",
        "name": "Kanpur Chakeri"
    },
    {
        "code": "INCPL6",
        "name": "Dadri CGML"
    },
    {
        "code": "INCPR6",
        "name": "ICD CHAWAPAYAL"
    },
    {
        "code": "INCRL6",
        "name": "RGA SEZ CHIKKANKANNE"
    },
    {
        "code": "INCRN1",
        "name": "Cornwallis"
    },
    {
        "code": "INCRW6",
        "name": "WIPRO/SEZ/BANGALORE"
    },
    {
        "code": "INCRXB",
        "name": "LOKSAN LCS"
    },
    {
        "code": "INCSP6",
        "name": "SI PVT. LTD."
    },
    {
        "code": "INCSV6",
        "name": "SPVL SEZ COIMBATORE"
    },
    {
        "code": "INCTI1",
        "name": "Chetlat Island"
    },
    {
        "code": "INCUM1",
        "name": "Cutch Mandvi"
    },
    {
        "code": "INDAE4",
        "name": "Daparizo"
    },
    {
        "code": "INDAH1",
        "name": "Dahej"
    },
    {
        "code": "INDAI4",
        "name": "Darjeeling"
    },
    {
        "code": "INDAM1",
        "name": "Daman"
    },
    {
        "code": "INDAM4",
        "name": "Daman"
    },
    {
        "code": "INDAR6",
        "name": "ARTHA SEZ GR. NOIDA"
    },
    {
        "code": "INDBD4",
        "name": "Dhanbad"
    },
    {
        "code": "INDBS6",
        "name": "SANTA-SEZ/ViII-MUPPI"
    },
    {
        "code": "INDDL6",
        "name": "PSWC ICD DHANDARIKAL"
    },
    {
        "code": "INDEA6",
        "name": "AS PVT. LTD."
    },
    {
        "code": "INDED4",
        "name": "Dehra Dun"
    },
    {
        "code": "INDEF6",
        "name": "INFOSYS SEZ DADRI"
    },
    {
        "code": "INDEG1",
        "name": "Deogad"
    },
    {
        "code": "INDEH6",
        "name": "HCL TECH. LTD. DEV."
    },
    {
        "code": "INDEI6",
        "name": "IVR SEZ NOIDA"
    },
    {
        "code": "INDEJ6",
        "name": "JUBILANT SEZ NOIDA"
    },
    {
        "code": "INDEL4",
        "name": "Delhi"
    },
    {
        "code": "INDEL5",
        "name": "Delhi"
    },
    {
        "code": "INDEM6",
        "name": "MOSERBAER/SEZ/NOIDA"
    },
    {
        "code": "INDEN6",
        "name": "M/S NIIT TECH. LTD."
    },
    {
        "code": "INDEP4",
        "name": "Deparizo"
    },
    {
        "code": "INDER6",
        "name": "Dadri"
    },
    {
        "code": "INDES6",
        "name": "M/S SEAVIEW DEV. LTD"
    },
    {
        "code": "INDET6",
        "name": "UNITECH HITECH NOIDA"
    },
    {
        "code": "INDEU6",
        "name": "UNITECHINFRA NOIDA"
    },
    {
        "code": "INDEW6",
        "name": "M/S WIPRO LTD."
    },
    {
        "code": "INDGI6",
        "name": "GALLANT SEZ NOIDA"
    },
    {
        "code": "INDGT6",
        "name": "GOLDEN SEZ NOIDA"
    },
    {
        "code": "INDHA6",
        "name": "Indore-Dhannad"
    },
    {
        "code": "INDHBB",
        "name": "Dhubri Steamerghat"
    },
    {
        "code": "INDHLB",
        "name": "Dhalaighat"
    },
    {
        "code": "INDHM4",
        "name": "Dharamsala"
    },
    {
        "code": "INDHN1",
        "name": "Dahanu"
    },
    {
        "code": "INDHP1",
        "name": "Dabhol Port"
    },
    {
        "code": "INDHR1",
        "name": "Dholera"
    },
    {
        "code": "INDHU1",
        "name": "Dahanu"
    },
    {
        "code": "INDIB4",
        "name": "Dibrugarh"
    },
    {
        "code": "INDID6",
        "name": "SEZ, MIDC, NANDED"
    },
    {
        "code": "INDIG1",
        "name": "Dighi Port"
    },
    {
        "code": "INDIG6",
        "name": "Dighi (Pune)"
    },
    {
        "code": "INDIT6",
        "name": "ANSAL IT/SEZ/NOIDA"
    },
    {
        "code": "INDIU1",
        "name": "Diu"
    },
    {
        "code": "INDIU4",
        "name": "Diu"
    },
    {
        "code": "INDIV1",
        "name": "Div"
    },
    {
        "code": "INDLAB",
        "name": "Dharchula"
    },
    {
        "code": "INDLH6",
        "name": "APLLP SEZ BENGALURU"
    },
    {
        "code": "INDLI2",
        "name": "Delhi Railway Statio"
    },
    {
        "code": "INDLOB",
        "name": "BIRPARA LCS"
    },
    {
        "code": "INDLUB",
        "name": "Dalu"
    },
    {
        "code": "INDMA1",
        "name": "DHAMRA"
    },
    {
        "code": "INDMRB",
        "name": "Demagir"
    },
    {
        "code": "INDMT1",
        "name": "Dharamtar"
    },
    {
        "code": "INDMU4",
        "name": "Dimapur"
    },
    {
        "code": "INDPC4",
        "name": "DPC BKC Mumbai"
    },
    {
        "code": "INDPR6",
        "name": "DAPPER"
    },
    {
        "code": "INDRC6",
        "name": "RCIPL SEZ NOIDA"
    },
    {
        "code": "INDRGB",
        "name": "Darranga"
    },
    {
        "code": "INDRK1",
        "name": "Dwarka (Rupen)"
    },
    {
        "code": "INDRL1",
        "name": "Dabolim"
    },
    {
        "code": "INDRU6",
        "name": "Belgaum Desur"
    },
    {
        "code": "INDSK1",
        "name": "Dhanu-shkodi"
    },
    {
        "code": "INDSM6",
        "name": "SMRTPL SEZ NOIDA"
    },
    {
        "code": "INDTW1",
        "name": "Dantiwara"
    },
    {
        "code": "INDUR6",
        "name": "ICD Durgapur"
    },
    {
        "code": "INDWA1",
        "name": "Dwarka"
    },
    {
        "code": "INDWKB",
        "name": "Dawki"
    },
    {
        "code": "INDWN6",
        "name": "ICD SAMALKHA PANIPAT"
    },
    {
        "code": "INDWR6",
        "name": "INFOSYS SEZ HUBLI"
    },
    {
        "code": "INENR1",
        "name": "KAMARAJAR PORT LTD"
    },
    {
        "code": "INERP6",
        "name": "PUTHUVYPEEN SEZ"
    },
    {
        "code": "INERV6",
        "name": "VALLARPADOM SEZ"
    },
    {
        "code": "INESH1",
        "name": "Elphinstone Harbour"
    },
    {
        "code": "INFBD6",
        "name": "ICD Faridabad"
    },
    {
        "code": "INFBE6",
        "name": "ESPIRE SEZ FARIDABAD"
    },
    {
        "code": "INFBM6",
        "name": "METRO SEZ GURGAON"
    },
    {
        "code": "INFBP6",
        "name": "PERPETUAL FARIDABAD"
    },
    {
        "code": "INFBRB",
        "name": "Fulbari LCS"
    },
    {
        "code": "INFBS6",
        "name": "SELECTOSEZ FARIDABAD"
    },
    {
        "code": "INFCH5",
        "name": "FPO(SEA) CHENNAI"
    },
    {
        "code": "INFLT6",
        "name": "Falta(SEZ)"
    },
    {
        "code": "INFMA6",
        "name": "APIICL MEDAK"
    },
    {
        "code": "INFMH6",
        "name": "HGSEZL RANGA REDDY"
    },
    {
        "code": "INFMJ6",
        "name": "M/S J.T. SEZ, RANGAR"
    },
    {
        "code": "INFMS6",
        "name": "M/S STARGAZE, SEZ"
    },
    {
        "code": "INGAIB",
        "name": "Gauriphanta"
    },
    {
        "code": "INGALB",
        "name": "Galgalia"
    },
    {
        "code": "INGAO6",
        "name": "OPGS POWER GUJARAT"
    },
    {
        "code": "INGAS6",
        "name": "ANSAL SEZ GURGAON"
    },
    {
        "code": "INGAU1",
        "name": "Gauhati (Panidi)"
    },
    {
        "code": "INGAU2",
        "name": "Gauhati (Panidi)"
    },
    {
        "code": "INGAU4",
        "name": "Gauhati (Panidi)"
    },
    {
        "code": "INGAU5",
        "name": "FPO GUWAHATI"
    },
    {
        "code": "INGAW2",
        "name": "Guwahati"
    },
    {
        "code": "INGAY4",
        "name": "Gaya"
    },
    {
        "code": "INGDL6",
        "name": "DLF CYBERCITY SEZ,"
    },
    {
        "code": "INGDM6",
        "name": "MF PARK PVT. LTD."
    },
    {
        "code": "INGDP6",
        "name": "FLLPL SEZ THIRUVALUR"
    },
    {
        "code": "INGED2",
        "name": "Gede Railway Station"
    },
    {
        "code": "INGGA1",
        "name": "Gogha"
    },
    {
        "code": "INGGB6",
        "name": "SBPL SEZ GURGAON"
    },
    {
        "code": "INGGC6",
        "name": "ORIENT SEZ GURGAON"
    },
    {
        "code": "INGGD6",
        "name": "DLF LTD."
    },
    {
        "code": "INGGE6",
        "name": "AEPL SEZ GURGAON"
    },
    {
        "code": "INGGF6",
        "name": "FRESH SEZ GURGAON"
    },
    {
        "code": "INGGG6",
        "name": "GHI SEZ GURGAON"
    },
    {
        "code": "INGGI6",
        "name": "GIL SEZ, GURGAON"
    },
    {
        "code": "INGGL6",
        "name": "LUXOR SEZ GURGAON"
    },
    {
        "code": "INGGM6",
        "name": "MIPPL SEZ GURGAON"
    },
    {
        "code": "INGGN2",
        "name": "Gurgaon"
    },
    {
        "code": "INGGO6",
        "name": "ORIENTCRAFT SEZ GGN"
    },
    {
        "code": "INGGP6",
        "name": "SUNWISE SEZ GURGAON"
    },
    {
        "code": "INGGS6",
        "name": "SUNCITY SEZ GURGAON"
    },
    {
        "code": "INGGU6",
        "name": "URP LTD."
    },
    {
        "code": "INGGV1",
        "name": "GANGAVARAM PORT"
    },
    {
        "code": "INGHA1",
        "name": "Ghogha"
    },
    {
        "code": "INGHC6",
        "name": "ASF INSIGNIA SEZ"
    },
    {
        "code": "INGHPB",
        "name": "Ghasuapara"
    },
    {
        "code": "INGHR6",
        "name": "Garhi Harsaru Gurgao"
    },
    {
        "code": "INGHWB",
        "name": "Gauhati Steamerghat"
    },
    {
        "code": "INGID6",
        "name": "MID PLTD SEZ GURGAON"
    },
    {
        "code": "INGIN6",
        "name": "Gandhidham"
    },
    {
        "code": "INGJIB",
        "name": "Gunji"
    },
    {
        "code": "INGJXB",
        "name": "Ghojadanga"
    },
    {
        "code": "INGKJ2",
        "name": "Golakganj Railway St"
    },
    {
        "code": "INGKJB",
        "name": "Golakganj LCS"
    },
    {
        "code": "INGLY6",
        "name": "APIIC MAHABOOBNAGAR"
    },
    {
        "code": "INGMI6",
        "name": "MID PVT LTD."
    },
    {
        "code": "INGNA6",
        "name": "APPL, SEZ"
    },
    {
        "code": "INGNC6",
        "name": "GIFT SEZ LTD"
    },
    {
        "code": "INGNG6",
        "name": "GIDC, SEZ"
    },
    {
        "code": "INGNL6",
        "name": "LOMA ITPARK GHANSOLI"
    },
    {
        "code": "INGNP6",
        "name": "GPRPL SEZ GURGAON"
    },
    {
        "code": "INGNR6",
        "name": "LIPL/MARRIPLEMGUNTUR"
    },
    {
        "code": "INGNT6",
        "name": "TCSL, SEZ"
    },
    {
        "code": "INGOI4",
        "name": "Goa"
    },
    {
        "code": "INGOP4",
        "name": "Gorakhpur"
    },
    {
        "code": "INGPB6",
        "name": "PROGRESSIVE GURGAON"
    },
    {
        "code": "INGPR1",
        "name": "Gopalpur"
    },
    {
        "code": "INGRD6",
        "name": "Mumbai DP-II"
    },
    {
        "code": "INGRL6",
        "name": "GPRL SEZ GURGAON"
    },
    {
        "code": "INGRN6",
        "name": "RELIANCE SEZ GURGAON"
    },
    {
        "code": "INGRR6",
        "name": "Mumbai DP-I"
    },
    {
        "code": "INGRS6",
        "name": "RAHEJA SEZ GURGAON"
    },
    {
        "code": "INGRW6",
        "name": "APM ICD BHAMBOLIKHED"
    },
    {
        "code": "INGTGB",
        "name": "Gitaldah road"
    },
    {
        "code": "INGTI6",
        "name": "INFOSYS TECH. LTD."
    },
    {
        "code": "INGTR2",
        "name": "Guntur"
    },
    {
        "code": "INGTS6",
        "name": "SP PVT. LTD."
    },
    {
        "code": "INGTZB",
        "name": "Getandah"
    },
    {
        "code": "INGUX4",
        "name": "Guna"
    },
    {
        "code": "INGWL4",
        "name": "Gwalior"
    },
    {
        "code": "INGWL6",
        "name": "Malanpuri (Gwalior)"
    },
    {
        "code": "INGWM4",
        "name": "VIJAYAWADA AIRPORT"
    },
    {
        "code": "INHAL1",
        "name": "Haldia"
    },
    {
        "code": "INHAN6",
        "name": "HINDAUN ICD KARAULI"
    },
    {
        "code": "INHAO6",
        "name": "OPTOINFRA/SEZ/HASSAN"
    },
    {
        "code": "INHAS6",
        "name": "HASSAN ICD"
    },
    {
        "code": "INHBB6",
        "name": "SDPL SEZ BENGALURU"
    },
    {
        "code": "INHBX4",
        "name": "Hubli"
    },
    {
        "code": "INHDD6",
        "name": "SIDCUL ICD PANTNAGAR"
    },
    {
        "code": "INHEB6",
        "name": "GOCL SEZ BENGALURU"
    },
    {
        "code": "INHEI6",
        "name": "INFOSYS/SEZ/MYSORE"
    },
    {
        "code": "INHEM6",
        "name": "MEBP/SEZ/BANGALORE"
    },
    {
        "code": "INHGLB",
        "name": "Hingalganj"
    },
    {
        "code": "INHGT1",
        "name": "Hangarkatta"
    },
    {
        "code": "INHIR6",
        "name": "SHB ICD SURAT"
    },
    {
        "code": "INHJR4",
        "name": "Khajuraho"
    },
    {
        "code": "INHLD2",
        "name": "Haldibari Railway St"
    },
    {
        "code": "INHLE6",
        "name": "INFY SEZ BEGURHOBLI"
    },
    {
        "code": "INHLIB",
        "name": "Hilli"
    },
    {
        "code": "INHND1",
        "name": "HEMNAGAR PORT"
    },
    {
        "code": "INHON1",
        "name": "Honavar"
    },
    {
        "code": "INHPI6",
        "name": "SANDKHERA ICD KASHIP"
    },
    {
        "code": "INHRN1",
        "name": "Harnai"
    },
    {
        "code": "INHSF6",
        "name": "KIADBFP SEZ HASSAN"
    },
    {
        "code": "INHSP6",
        "name": "KIADBP SEZ HASSAN"
    },
    {
        "code": "INHSS4",
        "name": "Hissar"
    },
    {
        "code": "INHST6",
        "name": "KIADBT SEZ HASSAN"
    },
    {
        "code": "INHSU6",
        "name": "ICD HOSUR"
    },
    {
        "code": "INHTSB",
        "name": "Hatisar"
    },
    {
        "code": "INHUR6",
        "name": "M/S RASSI, SEZ"
    },
    {
        "code": "INHWR1",
        "name": "Honawar"
    },
    {
        "code": "INHYB6",
        "name": "LHT PARK PVT. LTD."
    },
    {
        "code": "INHYD4",
        "name": "Hyderabad"
    },
    {
        "code": "INHYD5",
        "name": "FPO HYDERABAD"
    },
    {
        "code": "INHYD6",
        "name": "Hyderabad"
    },
    {
        "code": "INHZA1",
        "name": "HAZIRA, SURAT"
    },
    {
        "code": "INHZA6",
        "name": "Hazira SEZ, Surat"
    },
    {
        "code": "INIDR4",
        "name": "Indore"
    },
    {
        "code": "INIDR6",
        "name": "Indore(EPZ/SEZ)"
    },
    {
        "code": "INIGU6",
        "name": "Coimbatore Irugur IC"
    },
    {
        "code": "INILP6",
        "name": "Tiruvallur-ILP ICD"
    },
    {
        "code": "INIMF4",
        "name": "Imphal"
    },
    {
        "code": "ININB6",
        "name": "CRYSTAL IT PARK, SEZ"
    },
    {
        "code": "ININD6",
        "name": "ICD Pithampur"
    },
    {
        "code": "ININI6",
        "name": "IMPETUS INFOTECH SEZ"
    },
    {
        "code": "ININN6",
        "name": "INFOSYS SEZ INDORE"
    },
    {
        "code": "ININT6",
        "name": "TCS SEZ INDORE"
    },
    {
        "code": "INISK4",
        "name": "Nasik"
    },
    {
        "code": "INISK6",
        "name": "Nasik"
    },
    {
        "code": "INIXA4",
        "name": "Agartala"
    },
    {
        "code": "INIXB4",
        "name": "Bagdogra"
    },
    {
        "code": "INIXC4",
        "name": "Chandigarh"
    },
    {
        "code": "INIXD4",
        "name": "Allahabad"
    },
    {
        "code": "INIXE1",
        "name": "Mangalore"
    },
    {
        "code": "INIXE4",
        "name": "Mangalore"
    },
    {
        "code": "INIXG4",
        "name": "Belgaum"
    },
    {
        "code": "INIXH4",
        "name": "Kailashahar"
    },
    {
        "code": "INIXI4",
        "name": "Lilabari"
    },
    {
        "code": "INIXJ4",
        "name": "Jammu"
    },
    {
        "code": "INIXK4",
        "name": "Keshod"
    },
    {
        "code": "INIXL4",
        "name": "Leh"
    },
    {
        "code": "INIXL5",
        "name": "FPO LEH"
    },
    {
        "code": "INIXM4",
        "name": "Madurai"
    },
    {
        "code": "INIXM6",
        "name": "MaduraiICD"
    },
    {
        "code": "INIXN4",
        "name": "Khowai"
    },
    {
        "code": "INIXP4",
        "name": "Pathankot"
    },
    {
        "code": "INIXQ4",
        "name": "Kamalpur"
    },
    {
        "code": "INIXR4",
        "name": "Ranchi"
    },
    {
        "code": "INIXS4",
        "name": "Silchar"
    },
    {
        "code": "INIXT4",
        "name": "Pasighat"
    },
    {
        "code": "INIXU4",
        "name": "AURANGABAD"
    },
    {
        "code": "INIXW4",
        "name": "Jamshedpur"
    },
    {
        "code": "INIXW6",
        "name": "Jamshedpur ICD"
    },
    {
        "code": "INIXY1",
        "name": "Kandla"
    },
    {
        "code": "INIXY4",
        "name": "Kandla"
    },
    {
        "code": "INIXY6",
        "name": "Kandla SEZ"
    },
    {
        "code": "INIXZ1",
        "name": "Port Blair"
    },
    {
        "code": "INIXZ4",
        "name": "Port Blair"
    },
    {
        "code": "INJAI4",
        "name": "Jaipur"
    },
    {
        "code": "INJAI5",
        "name": "Jaipur"
    },
    {
        "code": "INJAI6",
        "name": "Jaipur"
    },
    {
        "code": "INJAK1",
        "name": "Jakhau"
    },
    {
        "code": "INJAL6",
        "name": "Jalgaon"
    },
    {
        "code": "INJAYB",
        "name": "Jayanagar"
    },
    {
        "code": "INJBD1",
        "name": "Jafrabad"
    },
    {
        "code": "INJBL6",
        "name": "E-COMPLEX SEZ/Amreli"
    },
    {
        "code": "INJBNB",
        "name": "JOGBANI"
    },
    {
        "code": "INJDA1",
        "name": "Jodia"
    },
    {
        "code": "INJDH4",
        "name": "Jodhpur"
    },
    {
        "code": "INJDH6",
        "name": "Jodhpur"
    },
    {
        "code": "INJGA4",
        "name": "Jamnagar"
    },
    {
        "code": "INJGB4",
        "name": "Jagdalpur"
    },
    {
        "code": "INJGD1",
        "name": "Jaigad"
    },
    {
        "code": "INJGI6",
        "name": "GENPACT SEZ JAIPUR"
    },
    {
        "code": "INJHA6",
        "name": "VEDANTA ALU. LTD."
    },
    {
        "code": "INJHOB",
        "name": "Jhulaghat (Pithoraga"
    },
    {
        "code": "INJHV6",
        "name": "VEDANTASEZJHARSUGUDA"
    },
    {
        "code": "INJIGB",
        "name": "Jaigaon"
    },
    {
        "code": "INJJK6",
        "name": "ICD JSL JAJPUR"
    },
    {
        "code": "INJKA6",
        "name": "SACHANA ICD VIRAMGAM"
    },
    {
        "code": "INJLR4",
        "name": "Jabalpur"
    },
    {
        "code": "INJNJ6",
        "name": "NEWFOUND SEZ THANE"
    },
    {
        "code": "INJNR4",
        "name": "Nashik-Janori ACC"
    },
    {
        "code": "INJNR6",
        "name": "Nashik-Janori ICD"
    },
    {
        "code": "INJPGB",
        "name": "Jalpaiguri"
    },
    {
        "code": "INJPI6",
        "name": "RIICO SEZ 1 SITAPUR"
    },
    {
        "code": "INJPV6",
        "name": "VATIKA SEZ JAIPUR"
    },
    {
        "code": "INJPW6",
        "name": "MWC JAIPUR LTD."
    },
    {
        "code": "INJRH4",
        "name": "Jorhat"
    },
    {
        "code": "INJSA4",
        "name": "Jaisalmer"
    },
    {
        "code": "INJSG6",
        "name": "CONCOR JHARSUGUDA"
    },
    {
        "code": "INJSM6",
        "name": "ADANI POWER SEZ"
    },
    {
        "code": "INJSZ6",
        "name": "JAIPUR SEZ"
    },
    {
        "code": "INJTP1",
        "name": "Jaitapur"
    },
    {
        "code": "INJUC6",
        "name": "Jalandhar"
    },
    {
        "code": "INJUX6",
        "name": "Basni Jodhpur ICD"
    },
    {
        "code": "INJWAB",
        "name": "Jarwa"
    },
    {
        "code": "INKAK1",
        "name": "Kakinada"
    },
    {
        "code": "INKAK6",
        "name": "Kakinada"
    },
    {
        "code": "INKAL1",
        "name": "Kallai"
    },
    {
        "code": "INKAP6",
        "name": "Kapadra (Surat)"
    },
    {
        "code": "INKAR6",
        "name": "Karur ICD"
    },
    {
        "code": "INKAT1",
        "name": "KATTUPALLI PORT"
    },
    {
        "code": "INKBC6",
        "name": "ICD HAZIRA"
    },
    {
        "code": "INKBT1",
        "name": "Khambhat"
    },
    {
        "code": "INKCG6",
        "name": "NSL SEZ PVT. LTD."
    },
    {
        "code": "INKDD6",
        "name": "Karedu"
    },
    {
        "code": "INKDI1",
        "name": "Kadmat Island"
    },
    {
        "code": "INKDL6",
        "name": "KANDLA SEZ"
    },
    {
        "code": "INKDN1",
        "name": "Kodinar(Muldwarka)"
    },
    {
        "code": "INKDP1",
        "name": "Kondiapetnam"
    },
    {
        "code": "INKELB",
        "name": "Kel Sahar Subdivisio"
    },
    {
        "code": "INKGG6",
        "name": "GLOBAL/SEZ/BANGALORE"
    },
    {
        "code": "INKGJ1",
        "name": "KARIMGANJ STEAMERGHA"
    },
    {
        "code": "INKHD6",
        "name": "ICD Kheda-Dhar"
    },
    {
        "code": "INKIW1",
        "name": "Kelwa"
    },
    {
        "code": "INKJA6",
        "name": "APP/SEZ/DODAKANAHALI"
    },
    {
        "code": "INKJB6",
        "name": "BAGMAN/SEZ/BANGALORE"
    },
    {
        "code": "INKJD6",
        "name": "DIVYA/SEZ/BANGALORE"
    },
    {
        "code": "INKJG6",
        "name": "GOPALAN/SEZ/BANGALOR"
    },
    {
        "code": "INKJH6",
        "name": "HCL/SEZ/BANGALORE"
    },
    {
        "code": "INKJIB",
        "name": "PIPRAUN LCS"
    },
    {
        "code": "INKJM6",
        "name": "BD SEZ BENGALURU"
    },
    {
        "code": "INKJR6",
        "name": "BBREL KUNDALAHALLI"
    },
    {
        "code": "INKKR1",
        "name": "Kilakari"
    },
    {
        "code": "INKKU6",
        "name": "Kanakpura Jaipur ICD"
    },
    {
        "code": "INKLB6",
        "name": "BLUE/SEZ/ERNAKULAM"
    },
    {
        "code": "INKLC6",
        "name": "CARB/SEZ/ERNAKULAM"
    },
    {
        "code": "INKLG6",
        "name": "SGS/SEZ/ERNAKULAM"
    },
    {
        "code": "INKLH4",
        "name": "Kohlapur"
    },
    {
        "code": "INKLI6",
        "name": "INFOPARK/SEZ/EKM"
    },
    {
        "code": "INKLK6",
        "name": "KINFRA KANAYANNOOR"
    },
    {
        "code": "INKLM6",
        "name": "MULTISERVCEKALAMBOLI"
    },
    {
        "code": "INKLN6",
        "name": "IT/ITES/SEZ/KALAMBOL"
    },
    {
        "code": "INKLS6",
        "name": "SMART CITY SEZ KOCHI"
    },
    {
        "code": "INKLY1",
        "name": "Kalyan"
    },
    {
        "code": "INKMAB",
        "name": "KULKULI LCS"
    },
    {
        "code": "INKMB1",
        "name": "Kumbharu"
    },
    {
        "code": "INKMI6",
        "name": "INFO SEZ ERNAKULAM"
    },
    {
        "code": "INKML6",
        "name": "DEIPL KURUBARAPALLI"
    },
    {
        "code": "INKND1",
        "name": "Kankudy"
    },
    {
        "code": "INKNK6",
        "name": "INFOPARK SEZ KORATTY"
    },
    {
        "code": "INKNLB",
        "name": "Kunauli"
    },
    {
        "code": "INKNU4",
        "name": "Kanpur"
    },
    {
        "code": "INKNU5",
        "name": "FPO KANPUR"
    },
    {
        "code": "INKNU6",
        "name": "Kanpur"
    },
    {
        "code": "INKOC5",
        "name": "Kochi"
    },
    {
        "code": "INKOD1",
        "name": "Kodinar"
    },
    {
        "code": "INKOI1",
        "name": "Koilthottum"
    },
    {
        "code": "INKOK1",
        "name": "Koka"
    },
    {
        "code": "INKON1",
        "name": "Konarak"
    },
    {
        "code": "INKPK6",
        "name": "CONCOR ICD MIHAN"
    },
    {
        "code": "INKRI1",
        "name": "Krishnapatnam"
    },
    {
        "code": "INKRK1",
        "name": "Karaikal"
    },
    {
        "code": "INKRM6",
        "name": "MADC LTD."
    },
    {
        "code": "INKRN1",
        "name": "Karanja"
    },
    {
        "code": "INKRP1",
        "name": "Kiranpani"
    },
    {
        "code": "INKRW1",
        "name": "Karwar"
    },
    {
        "code": "INKSG1",
        "name": "Kasargod"
    },
    {
        "code": "INKSH1",
        "name": "Kelshi"
    },
    {
        "code": "INKSP1",
        "name": "Kulasekarapatnam"
    },
    {
        "code": "INKTD1",
        "name": "Kotda"
    },
    {
        "code": "INKTGB",
        "name": "Katarniyaghat"
    },
    {
        "code": "INKTI1",
        "name": "Kiltan Island"
    },
    {
        "code": "INKTRB",
        "name": "Kathihar"
    },
    {
        "code": "INKTT6",
        "name": "Kota"
    },
    {
        "code": "INKTU4",
        "name": "Kota"
    },
    {
        "code": "INKTU6",
        "name": "Kota"
    },
    {
        "code": "INKTW1",
        "name": "Koteshwar"
    },
    {
        "code": "INKTY6",
        "name": "CHEYYAR POCHAMPALLI"
    },
    {
        "code": "INKUK1",
        "name": "KOLLAM PORT"
    },
    {
        "code": "INKUK6",
        "name": "KSITIL/SEZ/KOLLAM"
    },
    {
        "code": "INKUR6",
        "name": "OIIDC SEZ KHURDA"
    },
    {
        "code": "INKUU4",
        "name": "Kulu"
    },
    {
        "code": "INKVI1",
        "name": "Kavi"
    },
    {
        "code": "INKVL1",
        "name": "Kovalam"
    },
    {
        "code": "INKVR6",
        "name": "WFPML SEZ KOVVUR"
    },
    {
        "code": "INKVT1",
        "name": "Kavaratti Island"
    },
    {
        "code": "INKWAB",
        "name": "Khunwa"
    },
    {
        "code": "INKWGB",
        "name": "Khowaighat"
    },
    {
        "code": "INKWHB",
        "name": "KAKRAHAWA LCS"
    },
    {
        "code": "INKXJ2",
        "name": "Karimganj Railway St"
    },
    {
        "code": "INKYM6",
        "name": "KOTTAYAM"
    },
    {
        "code": "INKZE6",
        "name": "E TECH-I/SEZ/THPURAM"
    },
    {
        "code": "INKZP6",
        "name": "E PARK-III/SEZ/T'RAM"
    },
    {
        "code": "INKZT6",
        "name": "E PARK-II/SEZ/TPURAM"
    },
    {
        "code": "INLCH6",
        "name": "HCL IT SEZ LUCKNOW"
    },
    {
        "code": "INLDA4",
        "name": "Malda"
    },
    {
        "code": "INLDH6",
        "name": "Ludhiana"
    },
    {
        "code": "INLGLB",
        "name": "Lalgola Town"
    },
    {
        "code": "INLKO4",
        "name": "Lucknow"
    },
    {
        "code": "INLKQB",
        "name": "LAUKAHA LCS"
    },
    {
        "code": "INLON6",
        "name": "ICD Loni"
    },
    {
        "code": "INLPB6",
        "name": "BBLLP NANAKRAMGUDA"
    },
    {
        "code": "INLPC6",
        "name": "CMC LTD."
    },
    {
        "code": "INLPD6",
        "name": "M/S DLF, SEZ"
    },
    {
        "code": "INLPG6",
        "name": "GCPL SEZ KOKAPET"
    },
    {
        "code": "INLPI6",
        "name": "M/S SUNDEW, SEZ"
    },
    {
        "code": "INLPJ6",
        "name": "RELIANCE SEZ"
    },
    {
        "code": "INLPM6",
        "name": "MDL NANAKRAMGUDA"
    },
    {
        "code": "INLPR1",
        "name": "Leapuram"
    },
    {
        "code": "INLPS6",
        "name": "D NSL IP LTD."
    },
    {
        "code": "INLPW6",
        "name": "WIPRO LTD. HYDERABAD"
    },
    {
        "code": "INLTBB",
        "name": "Latu Bazar"
    },
    {
        "code": "INLUD6",
        "name": "LUDHIANA"
    },
    {
        "code": "INLUH4",
        "name": "Ludhiana"
    },
    {
        "code": "INLUH5",
        "name": "FPO LUDHIANA"
    },
    {
        "code": "INLUH6",
        "name": "Ludhiana"
    },
    {
        "code": "INLWG6",
        "name": "WIPRO LTD."
    },
    {
        "code": "INMAA1",
        "name": "Chennai (Ex Madras)"
    },
    {
        "code": "INMAA4",
        "name": "Chennai (Ex Madras)"
    },
    {
        "code": "INMAA5",
        "name": "Chennai (Ex Madras)"
    },
    {
        "code": "INMAA6",
        "name": "Chennai(EPZ/SEZ)"
    },
    {
        "code": "INMAB6",
        "name": "BRIGADE/SEZ/KANNADA"
    },
    {
        "code": "INMAE6",
        "name": "EC OF TAMIL NADU"
    },
    {
        "code": "INMAH1",
        "name": "Mahe"
    },
    {
        "code": "INMAI6",
        "name": "INFOSYS/SEZ/MANGALOR"
    },
    {
        "code": "INMAL1",
        "name": "Malpe"
    },
    {
        "code": "INMAP1",
        "name": "Masulipatnam"
    },
    {
        "code": "INMAQ6",
        "name": "MANGALORE SEZ"
    },
    {
        "code": "INMAS6",
        "name": "TRIL INFOPARK LTD."
    },
    {
        "code": "INMBC6",
        "name": "Moradabad(EPZ/SEZ)"
    },
    {
        "code": "INMBD6",
        "name": "Pakwara (Moradabad)"
    },
    {
        "code": "INMBS6",
        "name": "Madhosingh ICD"
    },
    {
        "code": "INMCI1",
        "name": "Minicoi I"
    },
    {
        "code": "INMDA1",
        "name": "Magdalla"
    },
    {
        "code": "INMDD6",
        "name": "Mandideep ICD"
    },
    {
        "code": "INMDE6",
        "name": "APIIC SEZ MEDAK"
    },
    {
        "code": "INMDG6",
        "name": "Margao"
    },
    {
        "code": "INMDK1",
        "name": "Muldwarka"
    },
    {
        "code": "INMDP1",
        "name": "Mandapam"
    },
    {
        "code": "INMDU6",
        "name": "KERN ICD MADURAI"
    },
    {
        "code": "INMDV1",
        "name": "Mandvi"
    },
    {
        "code": "INMDW1",
        "name": "Meadows"
    },
    {
        "code": "INMEA6",
        "name": "APIIC-SEZ/ViII-LALGA"
    },
    {
        "code": "INMEC6",
        "name": "EC OF TAMIL NADU"
    },
    {
        "code": "INMGHB",
        "name": "Mahendraganj"
    },
    {
        "code": "INMGR1",
        "name": "Mangrol"
    },
    {
        "code": "INMHA1",
        "name": "Mahuva"
    },
    {
        "code": "INMHDB",
        "name": "Kotawalighat (Mohedi"
    },
    {
        "code": "INMHE1",
        "name": "Mahe"
    },
    {
        "code": "INMHGB",
        "name": "Mahurighat"
    },
    {
        "code": "INMHN2",
        "name": "Mahisashan Railway S"
    },
    {
        "code": "INMKCB",
        "name": "Manikarchar"
    },
    {
        "code": "INMKD6",
        "name": "MIKADO SEZ GURGAON"
    },
    {
        "code": "INMLI1",
        "name": "Maroli"
    },
    {
        "code": "INMLP1",
        "name": "Mallipuram"
    },
    {
        "code": "INMLW1",
        "name": "Malwan"
    },
    {
        "code": "INMNB2",
        "name": "Munabao Railway Stat"
    },
    {
        "code": "INMNR1",
        "name": "Manori"
    },
    {
        "code": "INMNUB",
        "name": "Manu"
    },
    {
        "code": "INMNW1",
        "name": "Mandwa"
    },
    {
        "code": "INMOH4",
        "name": "Mohanbari"
    },
    {
        "code": "INMOR2",
        "name": "Moradabad"
    },
    {
        "code": "INMPC1",
        "name": "TEST"
    },
    {
        "code": "INMPR6",
        "name": "Malanpur ICD"
    },
    {
        "code": "INMQK6",
        "name": "KIADB/SEZ/MANGALORE"
    },
    {
        "code": "INMRA1",
        "name": "Mora"
    },
    {
        "code": "INMRD1",
        "name": "Murad"
    },
    {
        "code": "INMREB",
        "name": "Moreh"
    },
    {
        "code": "INMRG4",
        "name": "Margao"
    },
    {
        "code": "INMRJ6",
        "name": "Miraj"
    },
    {
        "code": "INMRM1",
        "name": "Marmagoa"
    },
    {
        "code": "INMSR6",
        "name": "RENAIS/SEZ/BANGALORE"
    },
    {
        "code": "INMTW1",
        "name": "Metwad"
    },
    {
        "code": "INMUC6",
        "name": "SCPL/SEZ/MULUND"
    },
    {
        "code": "INMUL6",
        "name": "Mulund ICD"
    },
    {
        "code": "INMUN1",
        "name": "Mundra"
    },
    {
        "code": "INMUR1",
        "name": "Murad"
    },
    {
        "code": "INMUZ6",
        "name": "MODINAGAR ICD"
    },
    {
        "code": "INMWA6",
        "name": "ICD Maliwada"
    },
    {
        "code": "INMYB1",
        "name": "Mayabandar"
    },
    {
        "code": "INMYL6",
        "name": "L AND T/SEZ/MYSORE"
    },
    {
        "code": "INMYO6",
        "name": "OPTOINFRA/SEZ/MYSORE"
    },
    {
        "code": "INMYQ4",
        "name": "Mysore"
    },
    {
        "code": "INMZA4",
        "name": "Muzaffarnagar"
    },
    {
        "code": "INMZU4",
        "name": "Muzaffarpur"
    },
    {
        "code": "INNAG4",
        "name": "Nagpur"
    },
    {
        "code": "INNAG6",
        "name": "Nagpur"
    },
    {
        "code": "INNAN1",
        "name": "Nancowrie"
    },
    {
        "code": "INNAV1",
        "name": "Navlakhi"
    },
    {
        "code": "INNDA6",
        "name": "Noida(EPZ/SEZ)"
    },
    {
        "code": "INNDC4",
        "name": "Nanded"
    },
    {
        "code": "INNDG1",
        "name": "Nandgaon"
    },
    {
        "code": "INNDP1",
        "name": "Tuglakabad"
    },
    {
        "code": "INNEE1",
        "name": "Neendakara"
    },
    {
        "code": "INNEL1",
        "name": "Nellore"
    },
    {
        "code": "INNGB6",
        "name": "BUTIBORI ICD NAGPUR"
    },
    {
        "code": "INNGKB",
        "name": "NAGARKATA LCS"
    },
    {
        "code": "INNGO6",
        "name": "OSE SEZ NOIDA"
    },
    {
        "code": "INNGP6",
        "name": "Nagpur"
    },
    {
        "code": "INNGRB",
        "name": "Nepalgunj Road"
    },
    {
        "code": "INNGSB",
        "name": "Village Namgaya Ship"
    },
    {
        "code": "INNKI6",
        "name": "INDIABULLS NASHIK"
    },
    {
        "code": "INNKNB",
        "name": "Namkhana"
    },
    {
        "code": "INNML1",
        "name": "New Mangalore"
    },
    {
        "code": "INNMTB",
        "name": "Neamati steamer Ghat"
    },
    {
        "code": "INNNN6",
        "name": "AMRL SEZ NANGUNERI"
    },
    {
        "code": "INNPGB",
        "name": "Nampong"
    },
    {
        "code": "INNPT1",
        "name": "Nagapattinam"
    },
    {
        "code": "INNRP6",
        "name": "AA LTD."
    },
    {
        "code": "INNSA1",
        "name": "Jawaharlal Nehru (Nh"
    },
    {
        "code": "INNSK6",
        "name": "Nasik"
    },
    {
        "code": "INNTLB",
        "name": "SHERATHANG LCS"
    },
    {
        "code": "INNTVB",
        "name": "THOOTHIBARI LCS"
    },
    {
        "code": "INNUR6",
        "name": "Kundli"
    },
    {
        "code": "INNVB1",
        "name": "Navabunder(with its"
    },
    {
        "code": "INNVP1",
        "name": "Navaspur"
    },
    {
        "code": "INNVT1",
        "name": "Nivti"
    },
    {
        "code": "INNVY4",
        "name": "Neyveli"
    },
    {
        "code": "INNWP1",
        "name": "Newapur"
    },
    {
        "code": "INNYP6",
        "name": "APIIC LTD."
    },
    {
        "code": "INOKH1",
        "name": "Okha"
    },
    {
        "code": "INOMN4",
        "name": "Osmanabad"
    },
    {
        "code": "INOMU1",
        "name": "Old Mundra Port"
    },
    {
        "code": "INONJ1",
        "name": "Onjal"
    },
    {
        "code": "INPAB4",
        "name": "Bilaspur"
    },
    {
        "code": "INPAK6",
        "name": "KSITIL/SEZ/PAYYANOOR"
    },
    {
        "code": "INPAN1",
        "name": "Panaji Port"
    },
    {
        "code": "INPAO6",
        "name": "HBS SEZ PANOLI"
    },
    {
        "code": "INPAP2",
        "name": "Panipat"
    },
    {
        "code": "INPAT4",
        "name": "Patna"
    },
    {
        "code": "INPAV1",
        "name": "Pipavav (Victor) Por"
    },
    {
        "code": "INPAV2",
        "name": "Pipavav (Victor) Por"
    },
    {
        "code": "INPBD1",
        "name": "Porbandar"
    },
    {
        "code": "INPBD4",
        "name": "Porbandar"
    },
    {
        "code": "INPBLB",
        "name": "KAMARDWISA LCS"
    },
    {
        "code": "INPDD1",
        "name": "Padubidri Minor Port"
    },
    {
        "code": "INPEK6",
        "name": "EKIPL/SEZ/PUNE"
    },
    {
        "code": "INPGH4",
        "name": "Pantnagar"
    },
    {
        "code": "INPHBB",
        "name": "Phulbari"
    },
    {
        "code": "INPID1",
        "name": "Port Indai"
    },
    {
        "code": "INPIN1",
        "name": "Pindhara"
    },
    {
        "code": "INPIT6",
        "name": "ITL/SEZ/PUNE"
    },
    {
        "code": "INPKD6",
        "name": "IGEL SEZ SETTIPALI"
    },
    {
        "code": "INPKR6",
        "name": "ICD PALI, REWARI"
    },
    {
        "code": "INPMB1",
        "name": "Pamban"
    },
    {
        "code": "INPMP6",
        "name": "Pimpri"
    },
    {
        "code": "INPMT6",
        "name": "MTDCCL/SEZ/PUNE"
    },
    {
        "code": "INPNB6",
        "name": "BTSL SEZ PUNE"
    },
    {
        "code": "INPNE6",
        "name": "NT PVT. LTD."
    },
    {
        "code": "INPNF5",
        "name": "PATNA FPO"
    },
    {
        "code": "INPNI6",
        "name": "MITTAL SEZ PANIPAT"
    },
    {
        "code": "INPNJ1",
        "name": "Panjim"
    },
    {
        "code": "INPNK6",
        "name": "KLPPL PANKI"
    },
    {
        "code": "INPNL6",
        "name": "PERSIPINA SEZ RAIGAD"
    },
    {
        "code": "INPNM1",
        "name": "Panambur"
    },
    {
        "code": "INPNN1",
        "name": "Ponnani"
    },
    {
        "code": "INPNP6",
        "name": "Babarpur"
    },
    {
        "code": "INPNQ2",
        "name": "Pune"
    },
    {
        "code": "INPNQ4",
        "name": "Pune"
    },
    {
        "code": "INPNQ6",
        "name": "SERUM BIO/SEZ/PUNE"
    },
    {
        "code": "INPNTB",
        "name": "Panitanki(Naxalbari)"
    },
    {
        "code": "INPNU6",
        "name": "TMSF PVT. LTD."
    },
    {
        "code": "INPNV6",
        "name": "ICD Panvel"
    },
    {
        "code": "INPNY1",
        "name": "Pondicherry"
    },
    {
        "code": "INPNY4",
        "name": "Pondicherry"
    },
    {
        "code": "INPNY6",
        "name": "ICD PULICHAPALLAM"
    },
    {
        "code": "INPPG6",
        "name": "Patparganj ICD,DELHI"
    },
    {
        "code": "INPPJ1",
        "name": "Pellet Plant Jetty a"
    },
    {
        "code": "INPRD6",
        "name": "PREPL SEZ PERUNGUDI"
    },
    {
        "code": "INPRG1",
        "name": "Purangad"
    },
    {
        "code": "INPRK6",
        "name": "ICD POWARKHEDA"
    },
    {
        "code": "INPRT1",
        "name": "Paradeep"
    },
    {
        "code": "INPSH1",
        "name": "Palshet"
    },
    {
        "code": "INPSI6",
        "name": "SIPL/SEZ/PUNE"
    },
    {
        "code": "INPSN6",
        "name": "PERFECT SEZ NOIDA"
    },
    {
        "code": "INPSP6",
        "name": "NSPL SEZ HINJEWADI"
    },
    {
        "code": "INPTL6",
        "name": "Patli ICD"
    },
    {
        "code": "INPTN1",
        "name": "Portonovo"
    },
    {
        "code": "INPTPB",
        "name": "Petrapole LCS"
    },
    {
        "code": "INPUA6",
        "name": "AIGP SEZ WAGHOLI"
    },
    {
        "code": "INPUE6",
        "name": "EON KHARADI SEZ"
    },
    {
        "code": "INPUI6",
        "name": "KIPPLGDPLSEZ KHARADI"
    },
    {
        "code": "INPUL1",
        "name": "Pulicat"
    },
    {
        "code": "INPUM6",
        "name": "MIDC PUNE"
    },
    {
        "code": "INPUN6",
        "name": "M/S KBTV PVT., PUNE"
    },
    {
        "code": "INPUR1",
        "name": "Puri"
    },
    {
        "code": "INPUT4",
        "name": "Puttaparthi"
    },
    {
        "code": "INPVL6",
        "name": "Panvel ICD"
    },
    {
        "code": "INPVS6",
        "name": "SNPIP SEZ TAMBARAM"
    },
    {
        "code": "INPWL6",
        "name": "PALWAL ICD"
    },
    {
        "code": "INPYB4",
        "name": "Jeypore"
    },
    {
        "code": "INPYS6",
        "name": "SIPCOT SEZ ERODE"
    },
    {
        "code": "INQRH6",
        "name": "HTPL ICD KILARAIPUR"
    },
    {
        "code": "INQRP6",
        "name": "ADANI ICD KILARAIPUR"
    },
    {
        "code": "INQUI1",
        "name": "Quilon"
    },
    {
        "code": "INRAI6",
        "name": "Raipur"
    },
    {
        "code": "INRAJ4",
        "name": "Rajkot"
    },
    {
        "code": "INRAJ6",
        "name": "Rajkot"
    },
    {
        "code": "INRAM1",
        "name": "Rameshwaram"
    },
    {
        "code": "INRDP2",
        "name": "Radhikapur Railway S"
    },
    {
        "code": "INREA6",
        "name": "Rewari"
    },
    {
        "code": "INRED1",
        "name": "Redi"
    },
    {
        "code": "INREW4",
        "name": "Rewa"
    },
    {
        "code": "INRGBB",
        "name": "Old Raghna Bazar"
    },
    {
        "code": "INRGH4",
        "name": "Balurghat"
    },
    {
        "code": "INRGJ2",
        "name": "RAIGANJ LCS RLY"
    },
    {
        "code": "INRGT1",
        "name": "Ranghat Bay"
    },
    {
        "code": "INRGUB",
        "name": "Ryngku"
    },
    {
        "code": "INRJA4",
        "name": "Rajahmundry"
    },
    {
        "code": "INRJI4",
        "name": "Rajouri"
    },
    {
        "code": "INRJN6",
        "name": "LS PVT. LTD."
    },
    {
        "code": "INRJP1",
        "name": "Rajpara"
    },
    {
        "code": "INRJR1",
        "name": "Rajpuri"
    },
    {
        "code": "INRKG1",
        "name": "Rajakkamangalam"
    },
    {
        "code": "INRMD4",
        "name": "Ramagundam"
    },
    {
        "code": "INRML6",
        "name": "CONCOR ICDNAYARAIPUR"
    },
    {
        "code": "INRNC5",
        "name": "RANCHI FPO"
    },
    {
        "code": "INRNG2",
        "name": "Ranaghat Railway Sta"
    },
    {
        "code": "INRNR1",
        "name": "Ranpar"
    },
    {
        "code": "INRPL6",
        "name": "Raddipalam"
    },
    {
        "code": "INRPR4",
        "name": "Raipur"
    },
    {
        "code": "INRPR6",
        "name": "Raipur"
    },
    {
        "code": "INRPU5",
        "name": "RAIPUR FPO"
    },
    {
        "code": "INRRI1",
        "name": "Raili"
    },
    {
        "code": "INRRK4",
        "name": "Rourkela"
    },
    {
        "code": "INRTC1",
        "name": "Ratnagiri"
    },
    {
        "code": "INRTC4",
        "name": "Ratnagiri"
    },
    {
        "code": "INRTM6",
        "name": "CONCOR ICD RATLAM"
    },
    {
        "code": "INRUP4",
        "name": "Rupsi"
    },
    {
        "code": "INRVD1",
        "name": "Revdanda"
    },
    {
        "code": "INRWR1",
        "name": "Rameshwaram"
    },
    {
        "code": "INRXLB",
        "name": "Raxaul"
    },
    {
        "code": "INSABB",
        "name": "Sabroom"
    },
    {
        "code": "INSAC6",
        "name": "Sachin (Surat)"
    },
    {
        "code": "INSAJ6",
        "name": "NAVKAR ICD UMBERGAON"
    },
    {
        "code": "INSAL1",
        "name": "Salaya"
    },
    {
        "code": "INSAS6",
        "name": "INFOSYS SEZ MOHALI"
    },
    {
        "code": "INSAU6",
        "name": "THAR DRY PORT"
    },
    {
        "code": "INSBC6",
        "name": "BIOCON SEZ BANGALORE"
    },
    {
        "code": "INSBH1",
        "name": "Sinbhour"
    },
    {
        "code": "INSBI6",
        "name": "ICD Sabarmati"
    },
    {
        "code": "INSBK6",
        "name": "KIADB/SEZ/BANGALORE"
    },
    {
        "code": "INSBL6",
        "name": "INFOSYS/SEZ/BANGLORE"
    },
    {
        "code": "INSBW6",
        "name": "WIPRO ELEC/SEZ/BLR"
    },
    {
        "code": "INSBZ1",
        "name": "SHELLA BAZAR"
    },
    {
        "code": "INSCH6",
        "name": "SAP-SEZ SURAT"
    },
    {
        "code": "INSGF6",
        "name": "GRFL ICD SAHNEWAL"
    },
    {
        "code": "INSHI1",
        "name": "Shirola"
    },
    {
        "code": "INSHL4",
        "name": "Shillong"
    },
    {
        "code": "INSHP1",
        "name": "Sinbhour Port"
    },
    {
        "code": "INSIK1",
        "name": "Sikka"
    },
    {
        "code": "INSJR6",
        "name": "Surajpur"
    },
    {
        "code": "INSKD6",
        "name": "ICD KALINGANAGAR"
    },
    {
        "code": "INSKPB",
        "name": "Sukhia Pokhari"
    },
    {
        "code": "INSLL6",
        "name": "Singanallur"
    },
    {
        "code": "INSLR2",
        "name": "Silcher R.M.S. Offic"
    },
    {
        "code": "INSLRB",
        "name": "Silcher Steamerghat"
    },
    {
        "code": "INSLT6",
        "name": "SALT LAKE (EPZ/SEZ)"
    },
    {
        "code": "INSLV4",
        "name": "Simla"
    },
    {
        "code": "INSMK6",
        "name": "KEONICS/SEZ/SHIMOGA"
    },
    {
        "code": "INSMPB",
        "name": "Srimantapur"
    },
    {
        "code": "INSMR1",
        "name": "Simor"
    },
    {
        "code": "INSNA6",
        "name": "ANSAL SEZ SONEPAT"
    },
    {
        "code": "INSNBB",
        "name": "Sonabarsa"
    },
    {
        "code": "INSNF6",
        "name": "ICD, Hyderabad"
    },
    {
        "code": "INSNG2",
        "name": "Singabad Railway Sta"
    },
    {
        "code": "INSNI6",
        "name": "KANECH, ICD SAHNEWAL"
    },
    {
        "code": "INSNLB",
        "name": "Sonauli"
    },
    {
        "code": "INSNN6",
        "name": "APIIC LTD."
    },
    {
        "code": "INSNR6",
        "name": "ARI LTD."
    },
    {
        "code": "INSNS6",
        "name": "NLE PVT. LTD."
    },
    {
        "code": "INSPC6",
        "name": "CCLPIL SEZ VIZAG"
    },
    {
        "code": "INSPE6",
        "name": "ASDI SEZ NELLORE"
    },
    {
        "code": "INSRE6",
        "name": "Saharanpur"
    },
    {
        "code": "INSRK6",
        "name": "KSITILA/SEZ/PALI/ALZ"
    },
    {
        "code": "INSRV1",
        "name": "Surasani-Yanam"
    },
    {
        "code": "INSSE4",
        "name": "Sholapur"
    },
    {
        "code": "INSTFB",
        "name": "SIKTA LCS"
    },
    {
        "code": "INSTIB",
        "name": "Sitai"
    },
    {
        "code": "INSTM6",
        "name": "MIDC PHALTAN SEZ"
    },
    {
        "code": "INSTP1",
        "name": "Satpati"
    },
    {
        "code": "INSTRB",
        "name": "Sutarkandi"
    },
    {
        "code": "INSTT6",
        "name": "Dadri STTPL CFS"
    },
    {
        "code": "INSTU6",
        "name": "MIDC SEZ SATARA"
    },
    {
        "code": "INSTV1",
        "name": "Surat"
    },
    {
        "code": "INSTV4",
        "name": "Surat"
    },
    {
        "code": "INSTV6",
        "name": "Surat(EPZ/SEZ)"
    },
    {
        "code": "INSWD1",
        "name": "Shriwardhan"
    },
    {
        "code": "INSXE6",
        "name": "ELCOT AMMAPALAYAM"
    },
    {
        "code": "INSXR4",
        "name": "Srinagar"
    },
    {
        "code": "INSXR5",
        "name": "FPO SRINAGAR"
    },
    {
        "code": "INSXV4",
        "name": "Salem"
    },
    {
        "code": "INSXV6",
        "name": "Salem"
    },
    {
        "code": "INTAD1",
        "name": "Tadri"
    },
    {
        "code": "INTAS6",
        "name": "SRICITYPVTLTD-SEZ"
    },
    {
        "code": "INTBC6",
        "name": "CTS PVT. LTD."
    },
    {
        "code": "INTBM6",
        "name": "PHPL SEZ KANCHEPURAM"
    },
    {
        "code": "INTBP6",
        "name": "SPIPL SEZ, PERUGULTH"
    },
    {
        "code": "INTBS6",
        "name": "HEXAWARE TECH. LTD."
    },
    {
        "code": "INTBT6",
        "name": "TCS LTD."
    },
    {
        "code": "INTCR6",
        "name": "M/S TICS, ICD MATHIL"
    },
    {
        "code": "INTDE6",
        "name": "Tudiyalur-Coimbatore"
    },
    {
        "code": "INTEI4",
        "name": "Tezu"
    },
    {
        "code": "INTEL1",
        "name": "Tellicherry"
    },
    {
        "code": "INTEN6",
        "name": "SIPCOT GANGAKONDAN"
    },
    {
        "code": "INTEZ4",
        "name": "Tezpur"
    },
    {
        "code": "INTGN6",
        "name": "KEIPL/ PUNE"
    },
    {
        "code": "INTHA6",
        "name": "Thar Dry Port Jodhpu"
    },
    {
        "code": "INTHL1",
        "name": "Thal"
    },
    {
        "code": "INTHO6",
        "name": "Tiruppur-Thottiplaya"
    },
    {
        "code": "INTIR4",
        "name": "Tirupati"
    },
    {
        "code": "INTIV1",
        "name": "Tiviri"
    },
    {
        "code": "INTJA1",
        "name": "Talaja"
    },
    {
        "code": "INTJPB",
        "name": "Tezpur Steamerghat"
    },
    {
        "code": "INTJV4",
        "name": "Thanjavur"
    },
    {
        "code": "INTKD2",
        "name": "Tuglakabad"
    },
    {
        "code": "INTKD6",
        "name": "Tuglakabad"
    },
    {
        "code": "INTKNB",
        "name": "Tikonia"
    },
    {
        "code": "INTLG6",
        "name": "Pune-Talegoan ICD"
    },
    {
        "code": "INTLT6",
        "name": "L&T SBL L&T CHENNAI"
    },
    {
        "code": "INTMI6",
        "name": "M/S IFFCO KISAN, SEZ"
    },
    {
        "code": "INTMP1",
        "name": "Trombay"
    },
    {
        "code": "INTMX6",
        "name": "ICD, THIMMAPUR,"
    },
    {
        "code": "INTNA1",
        "name": "Thana"
    },
    {
        "code": "INTNC6",
        "name": "CCCL SEZ TUTICORIN"
    },
    {
        "code": "INTND1",
        "name": "Tondi"
    },
    {
        "code": "INTNGB",
        "name": "Tungi"
    },
    {
        "code": "INTNI6",
        "name": "HIPL SEZ"
    },
    {
        "code": "INTNK1",
        "name": "Tankari"
    },
    {
        "code": "INTNS6",
        "name": "SSL SEZ TUTICORIN"
    },
    {
        "code": "INTPH1",
        "name": "Thopputhurai"
    },
    {
        "code": "INTPJ6",
        "name": "ECTN SEZ TRICHY"
    },
    {
        "code": "INTPN1",
        "name": "Talpona"
    },
    {
        "code": "INTRA1",
        "name": "Tranquebar"
    },
    {
        "code": "INTRL6",
        "name": "ICD Tiruvallur"
    },
    {
        "code": "INTRP1",
        "name": "Tarapur"
    },
    {
        "code": "INTRV4",
        "name": "Thiruvananthapuram ("
    },
    {
        "code": "INTRZ4",
        "name": "Tiruchirapalli"
    },
    {
        "code": "INTSI6",
        "name": "HVCPL SEZ KADAYAM"
    },
    {
        "code": "INTTP6",
        "name": "Dadri TTPL"
    },
    {
        "code": "INTTS1",
        "name": "T.T. SHED (KIDDERPOR"
    },
    {
        "code": "INTUI6",
        "name": "FCPL SEZ KESAVARAM"
    },
    {
        "code": "INTUN1",
        "name": "Tuna"
    },
    {
        "code": "INTUP6",
        "name": "Tirupur"
    },
    {
        "code": "INTUT1",
        "name": "Tuticorin"
    },
    {
        "code": "INTUT6",
        "name": "Tuticorin ICD"
    },
    {
        "code": "INTVC6",
        "name": "KINFRAA SEZ"
    },
    {
        "code": "INTVT6",
        "name": "ICD TONDIARPET CHENN"
    },
    {
        "code": "INTYR1",
        "name": "Tirukkadayyur"
    },
    {
        "code": "INUDI6",
        "name": "SYNEFRA-SEZ/UDUPI"
    },
    {
        "code": "INUDN6",
        "name": "GHB-SEZ SURAT"
    },
    {
        "code": "INUDR4",
        "name": "Udaipur"
    },
    {
        "code": "INUDR6",
        "name": "Udaipur"
    },
    {
        "code": "INUDZ6",
        "name": "Udaipur"
    },
    {
        "code": "INUKL6",
        "name": "ETLISL SEZ ERODE"
    },
    {
        "code": "INULPB",
        "name": "Ultapani"
    },
    {
        "code": "INULW1",
        "name": "Ulwa"
    },
    {
        "code": "INUMB1",
        "name": "Umbergoan"
    },
    {
        "code": "INUMR1",
        "name": "Umarsadi"
    },
    {
        "code": "INURF6",
        "name": "FAB CITY SPV-SEZ/SRI"
    },
    {
        "code": "INURG6",
        "name": "GMR HYDTABAD AVIATIO"
    },
    {
        "code": "INURI6",
        "name": "INDU TECHZONE PVT."
    },
    {
        "code": "INURT6",
        "name": "TCS LTD. ADIBATLA"
    },
    {
        "code": "INUTN1",
        "name": "Uttan"
    },
    {
        "code": "INVAD1",
        "name": "Vadinar"
    },
    {
        "code": "INVAL6",
        "name": "Valvada ICD"
    },
    {
        "code": "INVAP1",
        "name": "Vapi"
    },
    {
        "code": "INVEN1",
        "name": "Vengurla"
    },
    {
        "code": "INVEP1",
        "name": "Veppalodai"
    },
    {
        "code": "INVGA4",
        "name": "Vijayawada"
    },
    {
        "code": "INVGA5",
        "name": "FPO VIJAYAWADA"
    },
    {
        "code": "INVGR6",
        "name": "BHOJVA ICD VIRAMGAM"
    },
    {
        "code": "INVKH6",
        "name": "HIRANANDANI BUIL."
    },
    {
        "code": "INVKM1",
        "name": "Valinokkam"
    },
    {
        "code": "INVLD6",
        "name": "DISH/SEZ/KALYANGADH"
    },
    {
        "code": "INVLN6",
        "name": "NG REALTY-SEZ/TALUKA"
    },
    {
        "code": "INVLR6",
        "name": "SIPCOT LTD."
    },
    {
        "code": "INVNG1",
        "name": "Vengurla"
    },
    {
        "code": "INVNS4",
        "name": "Varanasi"
    },
    {
        "code": "INVNS5",
        "name": "FPO VARANASI"
    },
    {
        "code": "INVNS6",
        "name": "Varanasi"
    },
    {
        "code": "INVPI6",
        "name": "Vapi"
    },
    {
        "code": "INVRD1",
        "name": "Varavda"
    },
    {
        "code": "INVRU1",
        "name": "Vadarevu"
    },
    {
        "code": "INVSA6",
        "name": "APIIC (HALL NO.3)"
    },
    {
        "code": "INVSI1",
        "name": "Vansi-Borsi"
    },
    {
        "code": "INVSK6",
        "name": "APIIC- (HALL NO. 2)"
    },
    {
        "code": "INVSP6",
        "name": "WIPRO SEZRESPUVANIPA"
    },
    {
        "code": "INVSV1",
        "name": "Varsova"
    },
    {
        "code": "INVTC6",
        "name": "CHEYYAR VELLORE"
    },
    {
        "code": "INVTZ1",
        "name": "Visakhapatnam"
    },
    {
        "code": "INVTZ4",
        "name": "Visakhapatnam"
    },
    {
        "code": "INVTZ6",
        "name": "Visakhapatnam SEZ"
    },
    {
        "code": "INVVA1",
        "name": "Veraval"
    },
    {
        "code": "INVYD1",
        "name": "Vijaydurg"
    },
    {
        "code": "INVZJ1",
        "name": "Vazhinjam"
    },
    {
        "code": "INVZM6",
        "name": "DLL SEZ"
    },
    {
        "code": "INVZR6",
        "name": "M/S DR. REDDY SEZ"
    },
    {
        "code": "INWAL6",
        "name": "Waluj (Aurangabad)"
    },
    {
        "code": "INWFD6",
        "name": "ICD Bangalore"
    },
    {
        "code": "INWFI6",
        "name": "INFO/SEZ/BANGALORE"
    },
    {
        "code": "INWFT6",
        "name": "ITPL SEZ II BENGALUR"
    },
    {
        "code": "INWGC4",
        "name": "Warrangal"
    },
    {
        "code": "INWRR6",
        "name": "WARDHA POWER CO LTD"
    },
    {
        "code": "INYMA6",
        "name": "MODERN ASSET SEZ II"
    },
    {
        "code": "INYNA6",
        "name": "MOSERN ASSET SEZ I"
    },
    {
        "code": "INYNK6",
        "name": "KIP/SEZ/N BANGALORE"
    },
    {
        "code": "INYNL6",
        "name": "L&T CEL SEZ II"
    },
    {
        "code": "INYNM6",
        "name": "MILESTONE/SEZ/BLR"
    },
    {
        "code": "INYNT6",
        "name": "L&T CEL SEZ I"
    },
    {
        "code": "INZIP6",
        "name": "ZIPL-SEZ AHMEDABAD"
    }
  ];
  currencycode:any = [
    
      {
          "code": "BDT",
          "name": "Bangladeshi taka"
      },
      {
          "code": "AED",
          "name": "United Arab Emirates dirham"
      },
      {
          "code": "AFN",
          "name": "Afghan afghani"
      },
      {
          "code": "ALL",
          "name": "Albanian lek"
      },
      {
          "code": "AMD",
          "name": "Armenian dram"
      },
      {
          "code": "ANG",
          "name": "Netherlands Antillean guilder"
      },
      {
          "code": "AOA",
          "name": "Angolan kwanza"
      },
      {
          "code": "ARS",
          "name": "Argentine peso"
      },
      {
          "code": "AUD",
          "name": "Australian dollar"
      },
      {
          "code": "AWG",
          "name": "Aruban florin"
      },
      {
          "code": "AZN",
          "name": "Azerbaijani manat"
      },
      {
          "code": "BAM",
          "name": "Bosnia and Herzegovina convertible mark"
      },
      {
          "code": "BBD",
          "name": "Barbados dollar"
      },
      {
          "code": "BDT",
          "name": "Bangladeshi taka"
      },
      {
          "code": "BGN",
          "name": "Bulgarian lev"
      },
      {
          "code": "BHD",
          "name": "Bahraini dinar"
      },
      {
          "code": "BIF",
          "name": "Burundian franc"
      },
      {
          "code": "BMD",
          "name": "Bermudian dollar"
      },
      {
          "code": "BND",
          "name": "Brunei dollar"
      },
      {
          "code": "BOB",
          "name": "Boliviano"
      },
      {
          "code": "BOV",
          "name": "Bolivian Mvdol (funds code)"
      },
      {
          "code": "BRL",
          "name": "Brazilian real"
      },
      {
          "code": "BSD",
          "name": "Bahamian dollar"
      },
      {
          "code": "BTN",
          "name": "Bhutanese ngultrum"
      },
      {
          "code": "BWP",
          "name": "Botswana pula"
      },
      {
          "code": "BYN",
          "name": "Belarusian ruble"
      },
      {
          "code": "BZD",
          "name": "Belize dollar"
      },
      {
          "code": "CAD",
          "name": "Canadian dollar"
      },
      {
          "code": "CDF",
          "name": "Congolese franc"
      },
      {
          "code": "CHE",
          "name": "WIR Euro (complementary currency)"
      },
      {
          "code": "CHF",
          "name": "Swiss franc"
      },
      {
          "code": "CHW",
          "name": "WIR Franc (complementary currency)"
      },
      {
          "code": "CLF",
          "name": "Unidad de Fomento (funds code)"
      },
      {
          "code": "CLP",
          "name": "Chilean peso"
      },
      {
          "code": "CNY",
          "name": "Renminbi (Chinese) yuan"
      },
      {
          "code": "COP",
          "name": "Colombian peso"
      },
      {
          "code": "COU",
          "name": "Unidad de Valor Real (UVR) (funds code)"
      },
      {
          "code": "CRC",
          "name": "Costa Rican colon"
      },
      {
          "code": "CUC",
          "name": "Cuban convertible peso"
      },
      {
          "code": "CUP",
          "name": "Cuban peso"
      },
      {
          "code": "CVE",
          "name": "Cape Verdean escudo"
      },
      {
          "code": "CZK",
          "name": "Czech koruna"
      },
      {
          "code": "DJF",
          "name": "Djiboutian franc"
      },
      {
          "code": "DKK",
          "name": "Danish krone"
      },
      {
          "code": "DOP",
          "name": "Dominican peso"
      },
      {
          "code": "DZD",
          "name": "Algerian dinar"
      },
      {
          "code": "EGP",
          "name": "Egyptian pound"
      },
      {
          "code": "ERN",
          "name": "Eritrean nakfa"
      },
      {
          "code": "ETB",
          "name": "Ethiopian birr"
      },
      {
          "code": "EUR",
          "name": "Euro"
      },
      {
          "code": "FJD",
          "name": "Fiji dollar"
      },
      {
          "code": "FKP",
          "name": "Falkland Islands pound"
      },
      {
          "code": "GBP",
          "name": "Pound sterling"
      },
      {
          "code": "GEL",
          "name": "Georgian lari"
      },
      {
          "code": "GHS",
          "name": "Ghanaian cedi"
      },
      {
          "code": "GIP",
          "name": "Gibraltar pound"
      },
      {
          "code": "GMD",
          "name": "Gambian dalasi"
      },
      {
          "code": "GNF",
          "name": "Guinean franc"
      },
      {
          "code": "GTQ",
          "name": "Guatemalan quetzal"
      },
      {
          "code": "GYD",
          "name": "Guyanese dollar"
      },
      {
          "code": "HKD",
          "name": "Hong Kong dollar"
      },
      {
          "code": "HNL",
          "name": "Honduran lempira"
      },
      {
          "code": "HRK",
          "name": "Croatian kuna"
      },
      {
          "code": "HTG",
          "name": "Haitian gourde"
      },
      {
          "code": "HUF",
          "name": "Hungarian forint"
      },
      {
          "code": "IDR",
          "name": "Indonesian rupiah"
      },
      {
          "code": "ILS",
          "name": "Israeli new shekel"
      },
      {
          "code": "INR",
          "name": "Indian rupee"
      },
      {
          "code": "IQD",
          "name": "Iraqi dinar"
      },
      {
          "code": "IRR",
          "name": "Iranian rial"
      },
      {
          "code": "ISK",
          "name": "Icelandic króna"
      },
      {
          "code": "JMD",
          "name": "Jamaican dollar"
      },
      {
          "code": "JOD",
          "name": "Jordanian dinar"
      },
      {
          "code": "JPY",
          "name": "Japanese yen"
      },
      {
          "code": "KES",
          "name": "Kenyan shilling"
      },
      {
          "code": "KGS",
          "name": "Kyrgyzstani som"
      },
      {
          "code": "KHR",
          "name": "Cambodian riel"
      },
      {
          "code": "KMF",
          "name": "Comoro franc"
      },
      {
          "code": "KPW",
          "name": "North Korean won"
      },
      {
          "code": "KRW",
          "name": "South Korean won"
      },
      {
          "code": "KWD",
          "name": "Kuwaiti dinar"
      },
      {
          "code": "KYD",
          "name": "Cayman Islands dollar"
      },
      {
          "code": "KZT",
          "name": "Kazakhstani tenge"
      },
      {
          "code": "LAK",
          "name": "Lao kip"
      },
      {
          "code": "LBP",
          "name": "Lebanese pound"
      },
      {
          "code": "LKR",
          "name": "Sri Lankan rupee"
      },
      {
          "code": "LRD",
          "name": "Liberian dollar"
      },
      {
          "code": "LSL",
          "name": "Lesotho loti"
      },
      {
          "code": "LYD",
          "name": "Libyan dinar"
      },
      {
          "code": "MAD",
          "name": "Moroccan dirham"
      },
      {
          "code": "MDL",
          "name": "Moldovan leu"
      },
      {
          "code": "MGA",
          "name": "Malagasy ariary"
      },
      {
          "code": "MKD",
          "name": "Macedonian denar"
      },
      {
          "code": "MMK",
          "name": "Myanmar kyat"
      },
      {
          "code": "MNT",
          "name": "Mongolian tögrög"
      },
      {
          "code": "MOP",
          "name": "Macanese pataca"
      },
      {
          "code": "MRU",
          "name": "Mauritanian ouguiya"
      },
      {
          "code": "MUR",
          "name": "Mauritian rupee"
      },
      {
          "code": "MVR",
          "name": "Maldivian rufiyaa"
      },
      {
          "code": "MWK",
          "name": "Malawian kwacha"
      },
      {
          "code": "MXN",
          "name": "Mexican peso"
      },
      {
          "code": "MXV",
          "name": "Mexican Unidad de Inversion (UDI) (funds code)"
      },
      {
          "code": "MYR",
          "name": "Malaysian ringgit"
      },
      {
          "code": "MZN",
          "name": "Mozambican metical"
      },
      {
          "code": "NAD",
          "name": "Namibian dollar"
      },
      {
          "code": "NGN",
          "name": "Nigerian naira"
      },
      {
          "code": "NIO",
          "name": "Nicaraguan córdoba"
      },
      {
          "code": "NOK",
          "name": "Norwegian krone"
      },
      {
          "code": "NPR",
          "name": "Nepalese rupee"
      },
      {
          "code": "NZD",
          "name": "New Zealand dollar"
      },
      {
          "code": "OMR",
          "name": "Omani rial"
      },
      {
          "code": "PAB",
          "name": "Panamanian balboa"
      },
      {
          "code": "PEN",
          "name": "Peruvian sol"
      },
      {
          "code": "PGK",
          "name": "Papua New Guinean kina"
      },
      {
          "code": "PHP",
          "name": "Philippine peso"
      },
      {
          "code": "PKR",
          "name": "Pakistani rupee"
      },
      {
          "code": "PLN",
          "name": "Polish zloty"
      },
      {
          "code": "PYG",
          "name": "Paraguayan guaraní"
      },
      {
          "code": "QAR",
          "name": "Qatari riyal"
      },
      {
          "code": "RON",
          "name": "Romanian leu"
      },
      {
          "code": "RSD",
          "name": "Serbian dinar"
      },
      {
          "code": "RUB",
          "name": "Russian ruble"
      },
      {
          "code": "RWF",
          "name": "Rwandan franc"
      },
      {
          "code": "SAR",
          "name": "Saudi riyal"
      },
      {
          "code": "SBD",
          "name": "Solomon Islands dollar"
      },
      {
          "code": "SCR",
          "name": "Seychelles rupee"
      },
      {
          "code": "SDG",
          "name": "Sudanese pound"
      },
      {
          "code": "SEK",
          "name": "Swedish krona/kronor"
      },
      {
          "code": "SGD",
          "name": "Singapore dollar"
      },
      {
          "code": "SHP",
          "name": "Saint Helena pound"
      },
      {
          "code": "SLL",
          "name": "Sierra Leonean leone"
      },
      {
          "code": "SOS",
          "name": "Somali shilling"
      },
      {
          "code": "SRD",
          "name": "Surinamese dollar"
      },
      {
          "code": "SSP",
          "name": "South Sudanese pound"
      },
      {
          "code": "STN",
          "name": "São Tomé and Príncipe dobra"
      },
      {
          "code": "SVC",
          "name": "Salvadoran colón"
      },
      {
          "code": "SYP",
          "name": "Syrian pound"
      },
      {
          "code": "SZL",
          "name": "Swazi lilangeni"
      },
      {
          "code": "THB",
          "name": "Thai baht"
      },
      {
          "code": "TJS",
          "name": "Tajikistani somoni"
      },
      {
          "code": "TMT",
          "name": "Turkmenistan manat"
      },
      {
          "code": "TND",
          "name": "Tunisian dinar"
      },
      {
          "code": "TOP",
          "name": "Tongan pa?anga"
      },
      {
          "code": "TRY",
          "name": "Turkish lira"
      },
      {
          "code": "TTD",
          "name": "Trinidad and Tobago dollar"
      },
      {
          "code": "TWD",
          "name": "New Taiwan dollar"
      },
      {
          "code": "TZS",
          "name": "Tanzanian shilling"
      },
      {
          "code": "UAH",
          "name": "Ukrainian hryvnia"
      },
      {
          "code": "UGX",
          "name": "Ugandan shilling"
      },
      {
          "code": "USD",
          "name": "United States dollar"
      },
      {
          "code": "USN",
          "name": "United States dollar (next day) (funds code)"
      },
      {
          "code": "UYI",
          "name": "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)"
      },
      {
          "code": "UYU",
          "name": "Uruguayan peso"
      },
      {
          "code": "UYW",
          "name": "Unidad previsional"
      },
      {
          "code": "UZS",
          "name": "Uzbekistan som"
      },
      {
          "code": "VES",
          "name": "Venezuelan bolívar soberano"
      },
      {
          "code": "VND",
          "name": "Vietnamese d?ng"
      },
      {
          "code": "VUV",
          "name": "Vanuatu vatu"
      },
      {
          "code": "WST",
          "name": "Samoan tala"
      },
      {
          "code": "XAF",
          "name": "CFA franc BEAC"
      },
      {
          "code": "XAG",
          "name": "Silver (one troy ounce)"
      },
      {
          "code": "XAU",
          "name": "Gold (one troy ounce)"
      },
      {
          "code": "XBA",
          "name": "European Composite Unit (EURCO) (bond market unit)"
      },
      {
          "code": "XBB",
          "name": "European Monetary Unit (E.M.U.-6) (bond market unit)"
      },
      {
          "code": "XBC",
          "name": "European Unit of Account 9 (E.U.A.-9) (bond market unit)"
      },
      {
          "code": "XBD",
          "name": "European Unit of Account 17 (E.U.A.-17) (bond market unit)"
      },
      {
          "code": "XCD",
          "name": "East Caribbean dollar"
      },
      {
          "code": "XDR",
          "name": "Special drawing rights"
      },
      {
          "code": "XOF",
          "name": "CFA franc BCEAO"
      },
      {
          "code": "XPD",
          "name": "Palladium (one troy ounce)"
      },
      {
          "code": "XPF",
          "name": "CFP franc (franc Pacifique)"
      },
      {
          "code": "XPT",
          "name": "Platinum (one troy ounce)"
      },
      {
          "code": "XSU",
          "name": "SUCRE"
      },
      {
          "code": "XTS",
          "name": "Code reserved for testing"
      },
      {
          "code": "XUA",
          "name": "ADB Unit of Account"
      },
      {
          "code": "XXX",
          "name": "No currency"
      },
      {
          "code": "YER",
          "name": "Yemeni rial"
      },
      {
          "code": "ZAR",
          "name": "South African rand"
      },
      {
          "code": "ZMW",
          "name": "Zambian kwacha"
      },
      {
          "code": "ZWL",
          "name": "Zimbabwean dollar"
      }
  
  ];
  gstRate: any = [
    { value: 0 },
    { value: 0.1 },
    { value: 0.25 },
    { value: 1 },
    { value: 1.5 },
    { value: 3 },
    { value: 5 },
    { value: 6 },
    { value: 7.5 },
    { value: 12 },
    { value: 18 },
    { value: 28 },
  ]
  unitTypes: any = [
    {
      code: 'BAG',
      desc: 'BAGS',
    },
    {
      code: 'BAL',
      desc: 'BALE',
    },
    {
      code: 'BDL',
      desc: 'BUNDLES',
    },
    {
      code: 'BKL',
      desc: 'BUCKLES',
    },
    {
      code: 'BOU',
      desc: 'BILLION OF UNITS',
    },
    {
      code: 'BOX',
      desc: 'BOX',
    },
    {
      code: 'BTL',
      desc: 'BOTTLES',
    },
    {
      code: 'BUN',
      desc: 'BUNCHES',
    },
    {
      code: 'CAN',
      desc: 'CANS',
    },
    {
      code: 'CCM',
      desc: 'CUBIC CENTIMETERS',
    },
    {
      code: 'CMS',
      desc: 'CENTIMETERS',
    },
    {
      code: 'CBM',
      desc: 'CUBIC METERS',
    },
    {
      code: 'CTN',
      desc: 'CARTONS',
    },
    {
      code: 'DOZ',
      desc: 'DOZENS',
    },
    {
      code: 'DRM',
      desc: 'DRUMS',
    },
    {
      code: 'GGK',
      desc: 'GREAT GROSS',
    },
    {
      code: 'GMS',
      desc: 'GRAMMES',
    },
    {
      code: 'GRS',
      desc: 'GROSS',
    },
    {
      code: 'GYD',
      desc: 'GROSS YARDS',
    },
    {
      code: 'KGS',
      desc: 'KILOGRAMS',
    },
    {
      code: 'KLR',
      desc: 'KILOLITRE',
    },
    {
      code: 'KME',
      desc: 'KILOMETRE',
    },
    {
      code: 'LTR',
      desc: 'LITRES',
    },
    {
      code: 'MLS',
      desc: 'MILLI LITRES',
    },
    {
      code: 'MLT',
      desc: 'MILILITRE',
    },
    {
      code: 'MTR',
      desc: 'METERS',
    },
    {
      code: 'MTS',
      desc: 'METRIC TON',
    },
    {
      code: 'NOS',
      desc: 'NUMBERS',
    },
    {
      code: 'OTH',
      desc: 'OTHERS',
    },
    {
      code: 'PAC',
      desc: 'PACKS',
    },
    {
      code: 'PCS',
      desc: 'PIECES',
    },
    {
      code: 'PRS',
      desc: 'PAIRS',
    },
    {
      code: 'QTL',
      desc: 'QUINTAL',
    },
    {
      code: 'ROL',
      desc: 'ROLLS',
    },
    {
      code: 'SET',
      desc: 'SETS',
    },
    {
      code: 'SQF',
      desc: 'SQUARE FEET',
    },
    {
      code: 'SQM',
      desc: 'SQUARE METERS',
    },
    {
      code: 'SQY',
      desc: 'SQUARE YARDS',
    },
    {
      code: 'TBS',
      desc: 'TABLETS',
    },
    {
      code: 'TGM',
      desc: 'TEN GROSS',
    },
    {
      code: 'THD',
      desc: 'THOUSANDS',
    },
    {
      code: 'TON',
      desc: 'TONNES',
    },
    {
      code: 'TUB',
      desc: 'TUBES',
    },
    {
      code: 'UGS',
      desc: 'US GALLONS',
    },
    {
      code: 'UNT',
      desc: 'UNITS',
    },
    {
      code: 'YDS',
      desc: 'YARDS',
    },
  ];
  confirmOptions: any = [
    { label: 'Yes', code: 'Y' },
    { label: 'No', code: 'N' },
  ];
  errorList:any = [
    {
        "ErrorType": "Vital",
        "ErrorCode": 1004,
        "ErrorMessage": "Header GSTIN is required"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1005,
        "ErrorMessage": "Invalid Token",
        "Reason": "1. Token has expired \n2. While calling other APIs, wrong GSTIN / User Id/ Token passed in the request header",
        "Resolution": "1. Token is valid for 6 hours , if it has expired, call the Auth. API again and get new token\n 2. Pass correct values for GSTIN, User Id and Auth Token in the request headers while calling APIs other than Auth API"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1006,
        "ErrorMessage": "Header User Name is required"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1007,
        "ErrorMessage": "Authentication failed. Pls. inform the helpdesk",
        "Reason": "Wrong formation of request payload",
        "Resolution": "Prepare the request payload as per the API documentation"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1010,
        "ErrorMessage": "Invalid Client-ID/Client-Secret",
        "Reason": "Either the ClientId or the ClientSecret passed in the request header is wrong",
        "Resolution": "Pass the correct ClientId and the ClientSecret"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1011,
        "ErrorMessage": "Client Id is required"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1012,
        "ErrorMessage": "Client Secret is required"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1013,
        "ErrorMessage": "Decryption of password failed",
        "Reason": "Auth.API is not able to decrypt the password",
        "Resolution": "Use the correct public key for encrypting the password while calling the Auth API. The public key is sent by mail while providing the access to Production environment as well as available for download from the portal under API user management. This public key is different on Sandbox and Production and it is different from the one used for verification of the signed content.Refer to the developer portal for encryption method used and sample code."
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1015,
        "ErrorMessage": "Invalid GSTIN for this user",
        "Reason": "The GSTIN of the user who has generated the auth token is different from the GSTIN being passed in the request header",
        "Resolution": "Send the correct GSTIN in the header for APIs other than Auth API"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1016,
        "ErrorMessage": "Decryption of App Key failed",
        "Reason": "Auth.API is not able to decrypt the password",
        "Resolution": "Use the correct public key for encrypting the appkey while calling the Auth API. The public key is sent by mail while providing the access to Production environment as well as available for download from the portal under API user management. This public key is different on Sandbox and Production and it is different from the one used for verification of the signed content.Refer to the developer portal for encryption method used and sample code."
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1017,
        "ErrorMessage": "Incorrect user id/User does not exists",
        "Reason": "User id passed in request payload is incorrect",
        "Resolution": "Pass the correct user id. If not available, please log in to the portal using the main user id (the one without '"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1018,
        "ErrorMessage": "Client Id is not mapped to this user",
        "Reason": "The UserId is not mapped to the ClientId that is being sent as request header",
        "Resolution": "Please send the correct userId for the respective clientId. If using direct integration as well as through GSP or through multiple GSPs, please pass the correct set of ClientId"
      },
      {
        "ErrorType": "Vital",
        "ErrorCode": 1019,
        "ErrorMessage": "Incorrect Password",
        "Reason": "Password is wrong",
        "Resolution": "Use the correct password, if forgotten, may use forgot password option in the portal"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2143,
        "ErrorMessage": "Invoice does not belongs to the user GSTIN",
        "Reason": "User is attempting to perform some operations on IRN which does not belong to the currently logged in user",
        "Resolution": "Certain operations can not be carried out on other’s IRNs such as cancel etc"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2148,
        "ErrorMessage": "Requested IRN data is not available",
        "Reason": "User is trying to do some operation on an IRN which is not available in the eInvoice system",
        "Resolution": "Please check whether IRN is correct or the operation is being carried out within permitted time duration."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2150,
        "ErrorMessage": "Duplicate IRN",
        "Reason": "Attempting to register a document again which is already registered and IRN is generated",
        "Resolution": "Don't fire the same request siimultaneously. This results in one request giving the error as 'Duplicate IRN request'. Best way to avoid firing of IRN request repeatedly for the same request, is update in your system with IRN number when the response comes back. Next time check the IRN, if it is not there, then fire for IRN."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2154,
        "ErrorMessage": "IRN details are not found",
        "Reason": "Attempting to register a document again which is already registered and IRN is generated.",
        "Resolution": "Don't fire the same request simultaneously. This results in one request giving the error as 'Duplicate IRN request'. Best way to avoid firing of IRN request repeatedly for the same request, is update in your system with IRN number when the response comes back. Next time check the IRN, if it is not there, then fire for IRN."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2155,
        "ErrorMessage": "Supplier GSTIN is required",
        "Reason": "Request payload dos not have supplier GSTIN",
        "Resolution": "Supplier GSTIN is mandatory"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2159,
        "ErrorMessage": "Invalid value for reverse charge applicable field",
        "Reason": "Invalid value has been sent for the attribute to pass whether reverse charge applicable",
        "Resolution": "Please refer to the request JSON schema"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2162,
        "ErrorMessage": "Reverse Charge is not applicable for this transaction",
        "Reason": "Trying to set ‘Reverse Charges applicable’ for transactions other than B2B transaction",
        "Resolution": "Check the ‘Reverse Charges Applicable’ parameter as per the current type of transaction"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2163,
        "ErrorMessage": "The document date should not be future date .",
        "Reason": "Attempting to set future date for the document date",
        "Resolution": "Check the document date being passed"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2169,
        "ErrorMessage": "Request for IRN is neither from supplier GSTIN or e-Commerce GSTIN",
        "Reason": "Logged in user GSTIN does not match with either the supplier GSTIN or eCommerce GSTIN",
        "Resolution": "If a supplier is registering the Invoice, GSTIN passed in the request header should match with the supplier GSTIN. If an eCommerce operator is registering the Invoice, GSTIN in header should match with the eCommerce operator’s GSTIN in the request payload"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2172,
        "ErrorMessage": "For Intra-state transaction, IGST amount is not applicable; only CGST and SGST amounts are applicable\r\n\r\n\r\n",
        "Reason": "IGST amount is being passed for Intra State transaction where as CGST and SGST are applicable.",
        "Resolution": "Please check the applicable taxes for current transaction. If the State code of Point of Supply (POS) of recipient is same is state code of Supplier, then CSGT and SGST applies. However, under exception circumstances as per rule if IGST is applicable for intrastate transaction, then pass the ‘IGST_on_IntraState_Supply’ flag as ‘Y’ with IGST value so that system can process your request."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2173,
        "ErrorMessage": "You have exceeded the limit of number of items",
        "Reason": "User has passed the requests payload with more number of li",
        "Resolution": "Please check the number of line items being passed. Please refer to the specific API for large number of items. If limit for that API is also exceeded, contact helpdesk with invoice details"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2174,
        "ErrorMessage": "For inter-state transaction, CGST and SGST amounts are not applicable; only IGST amount is applicable",
        "Reason": "CGST and SGST taxes are being passed for inter state transactions",
        "Resolution": "Please check the applicable taxes for current transaction. If the State code of Point of Supply (POS) of recipient is different than the state code of Supplier, then IGST applies. Pl check the details."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2176,
        "ErrorMessage": "HSN code(s)-{0} is invalid\r\n",
        "Reason": "Wrong HSN code is being passed",
        "Resolution": "Pl check the HSN code being passed and Cross-check the correctness of HSN code on e-invoice portal. Still, if you feel it is correct, then please send the details to the helpdesk for verification at e-invoice system side."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2177,
        "ErrorMessage": "Unit Quantity Code(s)-{0} is/are not as per master",
        "Reason": "Invalid unit code has been passed",
        "Resolution": "Please pass the correct unit code as per the master provided for e-invoice. Check the correct unit code in the portal under Search -> Master codes. If the unit code is not matching with your required unit, you may have to convert unit to one existing in the master list of unit code."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2182,
        "ErrorMessage": "Taxable value of all items is not equal to total taxable value\r\n\r\n\r\n",
        "Reason": "Sum of taxable amounts of all items should be equal to invoice level taxable amount.",
        "Resolution": "Please check the request payload for the taxable value validation. Total Taxable Value = Taxable value of all line items"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2183,
        "ErrorMessage": "SGST value of all items is not equal to total SGST Value",
        "Reason": "Sum of SGST amounts of all items should be equal to invoice level SGST amount",
        "Resolution": "Please check the request payload for the SGST value validation."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2184,
        "ErrorMessage": "CGST value of all items must be equal to total CGST value",
        "Reason": "Sum of CGST amounts of all items should be equal to invoice level CGST amount",
        "Resolution": "Please check the request payload for the CGST value validation."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2185,
        "ErrorMessage": "IGST value of all items must be equal to total IGST Value",
        "Reason": "Sum of IGST amounts of all items should be equal to invoice level IGST amount",
        "Resolution": "Please check the request payload for the IGST value validation."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2186,
        "ErrorMessage": "Cess value of all items must be equal to total Cess Value",
        "Reason": "Sum of Cess amounts of all items should be equal to invoice level Cess amount",
        "Resolution": "Please check the request payload for the Cess value validation."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2187,
        "ErrorMessage": "State Cess Value of all items must be equal to total State CessValue",
        "Reason": "Sum of State Cess amounts of all items should be equal to invoice level State Cess amount",
        "Resolution": "Please check the request payload for the State Cess value validation."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2189,
        "ErrorMessage": "Total Invoice Value is not matching with calculated value\r\n\r\n",
        "Reason": "Invoice level total value has not been calculated as per the passed values.",
        "Resolution": "Invoice level total value is equal to totals of all the total item value plus other charges minus discount with tolerance limit of +/- One. Pl refer to the validations in sandbox portal."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2193,
        "ErrorMessage": "For Sl. No. {0}, Taxable Value (AssAmt) value is not equal to (TotAmt - Discount)\r\n\r\n\r\n",
        "Reason": "Incorrect assessable amount is passed for the specified item.",
        "Resolution": "For the specific item, assessable amount should be arrived at by deducting discount from total amount"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2194,
        "ErrorMessage": "For Sl. No {0}, Total item value does not match with taxable and tax values of that item\r\n\r\n",
        "Reason": "Total item value passed is incorrect.",
        "Resolution": "Total item value should be sum of assessable value, all tax and cess values, other charges with tolerance limit of +/- One. Please refer to the validations in the portal."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2195,
        "ErrorMessage": "e-Commerce GSTIN is not registered as TCS-GSTIN",
        "Reason": "The eCommerce GSTIN passed does not belong to the type Tax Collector",
        "Resolution": "Please check the GSTIN type at the common portal. If it is TCS in common portal still it is showing the error, contact the helpdesk with GSTIN details"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2200,
        "ErrorMessage": "State code {0} is invalid for dispatch details or ship details",
        "Reason": "Wrong state code is passed in dispatch or ship details",
        "Resolution": "Please refer to the state codes in the portal at Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2201,
        "ErrorMessage": "Invalid Port Code",
        "Reason": "Wrong port code is passed in dispatch or ship details",
        "Resolution": "Please refer to the port codes in the portal at Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2202,
        "ErrorMessage": "Invalid Country Code",
        "Reason": "Wrong country code is passed in dispatch or ship details",
        "Resolution": "Please refer to the country codes in the portal at Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2203,
        "ErrorMessage": "Invalid Foreign Currency",
        "Reason": "Wrong foreign currency code is passed in dispatch or ship details",
        "Resolution": "Please refer to the foreign currency codes in the portal at Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2211,
        "ErrorMessage": "Supplier and recipient GSTIN should not be the same.",
        "Reason": "Same GSTIN has been passed for Supplier and Buyer GSTIN.",
        "Resolution": "Self generated Invoice are not allowed for e-invoice generation."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2212,
        "ErrorMessage": "The recipient GSTIN cannot be URP for supply type {0}",
        "Reason": "Un Registered Person (URP) is passed wrongly for B2B type of transaction.",
        "Resolution": "For this type of transaction, actual GSTIN needs to be passed."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2213,
        "ErrorMessage": "The supplier GSTIN cannot be URP",
        "Reason": "URP has been set as the Supplier GSTIN",
        "Resolution": "Supplier GSTIN can not be URP"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2227,
        "ErrorMessage": "For Sl. No {0}, SGST and CGST amounts are not equal",
        "Reason": "For the specified item, CGST and SGST amounts are not equal",
        "Resolution": "CGST and SGST amounts should be same for the specified item."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2228,
        "ErrorMessage": "Item list cannot be empty",
        "Reason": "There are no items in the item list",
        "Resolution": "There should be at least one item in the item list"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2231,
        "ErrorMessage": "Cannot generate e-Invoice as the supplier GSTIN - {0} is SEZ",
        "Reason": "SEZ GSTIN user is trying to generate the eInvoice",
        "Resolution": "eInvoice is not applicable for SEZ supplier"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2232,
        "ErrorMessage": "POS is incorrect for {0} transaction",
        "Reason": "POS is sent other than 96 for the specified type of transaction",
        "Resolution": "Send Other Country code (96) for POS state code for the specified transaction type"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2233,
        "ErrorMessage": "Duplicate SI numbers are not allowed in line items\r\n\r\n\r\n",
        "Reason": "Duplicate serial number for the items has been sent in the item list.",
        "Resolution": "The serial number of the items in the item list should be unique in an invoice."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2234,
        "ErrorMessage": "For Sl. No {0}, SGST and CGST amount passed does not match with taxable value and tax rate\r\n\r\n",
        "Reason": "Wrong SGST and CGST amounts are passed for the specified item in the item list.",
        "Resolution": "Correct values for the SGST and CGST amounts have to be passed. SGST and CGST values should be equal to [taxable value X tax rate / 2] for that line item with tolerance limit of +/- One."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2235,
        "ErrorMessage": "For Sl. No {0}, IGST amount passed is not matching with taxable value and tax rate",
        "Reason": "Wrong IGST amount is passed for the specified item in the item list.",
        "Resolution": "Correct value for the IGST amount has to be passed. IGST value should be equal to [taxable value X tax rate] for that line item with tolerance limit of +/- One."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2238,
        "ErrorMessage": "For Sl. No {0}, Quantity is not passed",
        "Reason": "Value for quantity is missing for the specified item in the item list",
        "Resolution": "Send the quantity for the specified item"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2239,
        "ErrorMessage": "For Sl. No {0}, Unit Quantity Code (UQC) is not passed",
        "Reason": "Value for unit is missing for the specified item in the item list",
        "Resolution": "Send the value for unit for the specified item"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2240,
        "ErrorMessage": "For Sl. No {0}, GST rate of tax is incorrect or not as notified\r\n\r\n",
        "Reason": "Wrong GST rate is passed for the specified item in the item list.",
        "Resolution": "Correct value for the GST rate has to be passed. Standard tax Rates are 0, 0.5, 1, 2, 3, 5, 12, 18, 28"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2242,
        "ErrorMessage": "Recipient POS is incorrect for transaction type {0}",
        "Reason": "For the specified type of transaction, POS passed is 96 – Other Country",
        "Resolution": "For the specified transaction type, POS can not be 96, pass a valid state code. Please refer to the State Codes in the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2243,
        "ErrorMessage": "POS code is invalid",
        "Reason": "Invalid State code has been passed for the POS",
        "Resolution": "Pass correct State code for POS. Please refer to the State Codes in the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2246,
        "ErrorMessage": "Recepient cannot be SEZ for - {0} transaction",
        "Reason": "SEZ GSTIN has been passed as GSTIN in Recipient details for the specified type of transaction",
        "Resolution": "For the specified type of transaction, GSTIN of SEZ can not be passed as GSTIN in Recipient details"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2247,
        "ErrorMessage": "Recepient should be SEZ for transaction {0}",
        "Reason": "Non SEZ GSTIN has been passed as the GSTIN in Recipient details for the given type of transaction.",
        "Resolution": "For the given type of transaction GSTIN in Recipient details should be of SEZ"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2248,
        "ErrorMessage": "Recepient has to be URP in case of Export transactions",
        "Reason": "In case of export, Recipient GSTIN has not been set to ‘URP’",
        "Resolution": "In case of export , GSTIN in Recipient details should be ‘URP’"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2256,
        "ErrorMessage": "Invalid Signature",
        "Reason": "The data is altered or improper public key is used for verification of the signed content",
        "Resolution": "Make sure that the correct public key is used for verification of the signed content. The public keys are available for download from the portal, which are used in specific time periods."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2258,
        "ErrorMessage": "Supplier GSTIN state code does not match with the state code passed in supplier details",
        "Reason": "The state code passed and first two digits of the GSTIN passed in the Supplier details do not match",
        "Resolution": "In the Supplier details, first two digits of the GSTIN should match the state code passed"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2259,
        "ErrorMessage": "Invalid supplier state code",
        "Reason": "State code passed in the Supplier Details is incorrect",
        "Resolution": "Pass the correct State code in the Supplier Details. For State code master, visit the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2260,
        "ErrorMessage": "The recipient state code is invalid",
        "Reason": "State code passed in the Recipient Details is incorrect",
        "Resolution": "Pass the correct State code in the Recipient Details. For State code master, visit the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2261,
        "ErrorMessage": "The recipient state code is incorrect for {0} transaction",
        "Reason": "State code under Recipient Details is sent other than 96, for the specified type of transaction",
        "Resolution": "Send Other Country code (96) for State code under Recipient Details, for the specified transaction type"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2262,
        "ErrorMessage": "For Intra-state supply, IGST cannot be charged",
        "Reason": "IGSTonIntra value is set as ‘Y’ but different state codes are mentioned under Supplier Details and POS",
        "Resolution": "When IGSTonIntra value is set as ‘Y’, it should be an intra state transaction and hence State code under Supplier Details should be same as POS under Recipient Details"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2265,
        "ErrorMessage": "Recipient GSTIN state code does not match with the state code passed in recipient details\r\n\r\n\r\n",
        "Reason": "The state code passed and first two digits of the GSTIN passed in the Recipient details do not match.",
        "Resolution": "In the Recipient details, first two digits of the GSTIN should match the state code passed."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2266,
        "ErrorMessage": "For Sl. No {0}, Cess amount passed does not match with the calculated value",
        "Reason": "Incorrect Cess amount is passed for the specified line item",
        "Resolution": "Correct Cess amount may be passed for the specified line item"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2267,
        "ErrorMessage": "For Sl. No {0}, State Cess amount passed is not as per calculated value",
        "Reason": "Incorrect State Cess amount is passed for the specified line item",
        "Resolution": "Correct State Cess amount may be passed for the specified line item"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2268,
        "ErrorMessage": "IGST on instra state supply is not applicable to export/SEZ transactions",
        "Reason": "IGSTonIntra has been set for export and SEZ transactions",
        "Resolution": "IGSTonIntra value can not be ‘Y’ for export and SEZ transactions"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2270,
        "ErrorMessage": "The allowed cancellation time limit is crossed, you cannot cancel the IRN",
        "Reason": "Attempted to cancel the IRN after the time limit",
        "Resolution": "IRN can be cancelled only within the time limit"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2271,
        "ErrorMessage": "SHIP TO - state code is not valid statecode for B2B, SEZ and Deemed Export transactions",
        "Reason": "Invalid State code is passed under Ship To details for B2B, SEZ and Deemed export transaction",
        "Resolution": "Correct state code should be passed under Ship To Details for B2B, SEZ and Deemed export transactions. For valid State codes, may visit the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2272,
        "ErrorMessage": "SHIP TO - PIN code cannot be 999999 for B2B, SEZ and Deemed Export transactions",
        "Reason": "PIN code under ShipTo Details has been passed as 999999 for B2B, SEZ and Deemed Export transactions",
        "Resolution": "Correct PIN code should be passed under Ship To Details for B2B, SEZ and Deemed export transactions. For valid PIN codes, may visit the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2273,
        "ErrorMessage": "Dispatch from PIN code cannot be 999999",
        "Reason": "PIN code under Dispatch From Details has been passed as 999999 for B2B, SEZ and Deemed Export transactions",
        "Resolution": "Correct PIN code should be passed under Dispatch From Details for B2B, SEZ and Deemed export transactions. For valid PIN codes, may visit the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2274,
        "ErrorMessage": "Recipient PIN code cannot be 999999 for {0} transaction.",
        "Reason": "PIN code under Recipient Details has been passed as 999999 for specified type of transactions",
        "Resolution": "Correct PIN code should be passed under Recipient Details for specified type of transactions. For valid PIN codes, may visit the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2275,
        "ErrorMessage": "Recipient PIN code should be 999999 for Direct Export",
        "Reason": "PIN code under Recipient Details has been passed as other than 999999 for specified type of transactions",
        "Resolution": "999999 as PIN code should be passed under Recipient Details for specified type of transactions."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2276,
        "ErrorMessage": "Supplier PIN code cannot be 999999",
        "Reason": "PIN code under Supplier Details has been passed as 999999",
        "Resolution": "Correct PIN code should be passed under Supplier Details. For valid PIN codes, may visit the portal under Search -> Master Codes"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2277,
        "ErrorMessage": "E-Commerce Opertaor has to pass supplier GSTIN in header to cancel the IRN",
        "Reason": "in header, supplier GSTIN is missing while eCommerce operator is cancelling the IRN",
        "Resolution": "While eCommerce operator is cancelling the IRN, Supplier GSTIN should be passed in the request header"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2278,
        "ErrorMessage": "IRN is already generated and is cancelled for this Document number. The duplicate IRN cannot be generated for the same number."
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2279,
        "ErrorMessage": "Invalid schema version"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2280,
        "ErrorMessage": "Invalid document type"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2283,
        "ErrorMessage": "IRN details cannot be provided as it is generated more than {0} days prior"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2284,
        "ErrorMessage": "IRN cannot be generated for the document date which is prior to {0}"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2285,
        "ErrorMessage": "Invalid value for IGST on intra field"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2288,
        "ErrorMessage": "Invalid Recipient GSTIN"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2291,
        "ErrorMessage": "Invalid Supplier GSTIN"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2294,
        "ErrorMessage": "Reverse charge is mandatory if IGST on intra is applicable"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2295,
        "ErrorMessage": "IRN is already generated and registered with GSTN Lookup Portal by other IRP"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2297,
        "ErrorMessage": "Reverse charge supply is not allowed for ISD recipients"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2298,
        "ErrorMessage": "Recipient cannot be composition/UIN taxpayer for Deemed Exports"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2299,
        "ErrorMessage": "E-Commerce Operator GSTIN field should be empty when Tax Payer generates e-Invoice"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2300,
        "ErrorMessage": "Supplier GSTIN should be of type Regular or SED"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 2301,
        "ErrorMessage": "E-Commerce Operator GSTIN field should not be empty when e-Commerce operator generates e-Invoice"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 9999,
        "ErrorMessage": "Invoice is not active"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3026,
        "ErrorMessage": "E-Invoice Options are not enabled for this GSTIN -{0}"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3038,
        "ErrorMessage": "{0} details Details:Pincode-{1} does not exists"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3039,
        "ErrorMessage": "Pincode -{1} of {0} does not belong to his/her State - {2}"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3047,
        "ErrorMessage": "The HSN - {0} does not belong to Goods"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3048,
        "ErrorMessage": "The HSN - {0} does not belong to Service"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3074,
        "ErrorMessage": "GSTIN - {0} is cancelled and document date - {1} is later than de-registration date"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3075,
        "ErrorMessage": "GSTIN- {0} is cancelled and de-registration date is not available"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3076,
        "ErrorMessage": "GSTIN - {0} is inactive"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3077,
        "ErrorMessage": "GSTIN - {0} is cancelled and document date - {1} is earlier than registration date"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3078,
        "ErrorMessage": "GSTIN- {0} is cancelled and registration date is not available"
      },
      {
        "ErrorType": "Invoice",
        "ErrorCode": 3079,
        "ErrorMessage": "For active GSTINs the document date should not be earlier than registration date"
      }
  ];
  pincodes:any = [];
 

  tabClicked(tabName) {

    this.activeTab = tabName;
    // this.resetFlags();
  }
  
  redirectToPrivacy(){
    window.scrollTo(0,0);
    this.router.navigate([`/privacy`]);
  }
  redirectToTerms(){
    window.scrollTo(0,0);
    this.router.navigate([`/terms-conditions`]);
  }
}

