
  <!--breadcrumb-END-->
  <app-auth-header></app-auth-header>
  <div class="custom_container">
    <div class="pt-2 m-1">
      <div class="custom_tab">
        <ul class="nav nav-tabs font-size-16" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('stateCode')" [class.active]="activeTab =='stateCode'"
              id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home"
              aria-selected="true">
              State Codes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('countryCode')" [class.active]="activeTab =='countryCode'"
              id="countryCode-tab" data-bs-toggle="tab" data-bs-target="#countryCode" type="button" role="tab"
              aria-controls="countryCode" aria-selected="false">
              Country Codes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('portCode')" [class.active]="activeTab =='portCode'"
              id="portCode-tab" data-bs-toggle="tab" data-bs-target="#portCode" type="button" role="tab"
              aria-controls="portCode" aria-selected="false">
              Port Codes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('currencyUqcCode')"
              [class.active]="activeTab =='currencyUqcCode'" id="currencyUqcCode-tab" data-bs-toggle="tab"
              data-bs-target="#currencyUqcCode" type="button" role="tab" aria-controls="currencyUqcCode"
              aria-selected="false">
              Currency Codes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('uqcCode')" [class.active]="activeTab =='uqcCode'"
              id="uqcCode-tab" data-bs-toggle="tab" data-bs-target="#uqcCode" type="button" role="tab"
              aria-controls="uqcCode" aria-selected="false">
              UQC Codes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('taxRate')" [class.active]="activeTab =='taxRate'"
              id="taxRate-tab" data-bs-toggle="tab" data-bs-target="#taxRate" type="button" role="tab"
              aria-controls="taxRate" aria-selected="false">
              Tax Rates
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('pinCode')" [class.active]="activeTab =='pinCode'"
              id="pinCode-tab" data-bs-toggle="tab" data-bs-target="#pinCode" type="button" role="tab"
              aria-controls="pinCode" aria-selected="false">
              Pincodes
            </button>
          </li>
      
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('pincodeStateMap')"
              [class.active]="activeTab =='pincodeStateMap'" id="pincodeStateMap-tab" data-bs-toggle="tab"
              data-bs-target="#pincodeStateMap" type="button" role="tab" aria-controls="pincodeStateMap"
              aria-selected="false">
              Pincode State Mapping pattern
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" (click)="tabClicked('errorList')" [class.active]="activeTab =='errorList'"
              id="errorList-tab" data-bs-toggle="tab" data-bs-target="#errorList" type="button" role="tab"
              aria-controls="errorList" aria-selected="false">
              Error List
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade " [class.active]="activeTab =='stateCode'" [class.show]="activeTab =='stateCode'"
            id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tabWidth">
                <thead class="font-size-14">
                  <tr>
                    <th></th>
                    <th>State Codes</th>
                    <th></th>
                    <th>State Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let s of states">
                    <td></td>
                    <td>{{s.code}}</td>
                    <td></td>
                    <td>{{s.name}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='countryCode'" [class.show]="activeTab =='countryCode'"
            id="countryCode" role="tabpanel" aria-labelledby="countryCode-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tabWidth">
                <thead class="font-size-14">
                  <tr>
                    <th></th>
                    <th>Country Codes</th>
                    <th></th>
                    <th>Country Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let c of countries">
                    <td></td>
                    <td>{{c.code}}</td>
                    <td></td>
                    <td>{{c.name}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='portCode'" [class.show]="activeTab =='portCode'"
            id="portCode" role="tabpanel" aria-labelledby="portCode-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tabWidth">
                <thead class="font-size-14">
                  <tr>
                    <th></th>
                    <th>Port Codes</th>
                    <th></th>
                    <th>Port Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let p of ports">
                    <td></td>
                    <td>{{p.code}}</td>
                    <td></td>
                    <td>{{p.name}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='currencyUqcCode'"
            [class.show]="activeTab =='currencyUqcCode'" id="currencyUqcCode" role="tabpanel"
            aria-labelledby="currencyUqcCode-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tabWidth">
                <thead class="font-size-14">
                  <tr>
                    <th></th>
                    <th>Currency Codes</th>
                    <th></th>
                    <th>Currency Name</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let currency of currencycode">
                    <td></td>
                    <td>{{currency.code}}</td>
                    <td></td>
                    <td>{{currency.name}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='uqcCode'" [class.show]="activeTab =='uqcCode'"
            id="uqcCode" role="tabpanel" aria-labelledby="uqcCode-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tabWidth">
                <thead class="font-size-14">
                  <tr>
                    <th></th>
                    <th>Unit Code</th>
                    <th></th>
                    <th>Unit Description</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let uqc of unitTypes">
                    <td></td>
                    <td>{{uqc.code}}</td>
                    <td></td>
                    <td>{{uqc.desc}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='taxRate'" [class.show]="activeTab =='taxRate'"
            id="taxRate" role="tabpanel" aria-labelledby="taxRate-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tableheadTaxRate">
                <thead class="font-size-14">
                  <tr>
                    <th>Tax Rate %</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let rate of gstRate">
                    <td>{{rate.value}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='pinCode'" [class.show]="activeTab =='pinCode'"
            id="pinCode" role="tabpanel" aria-labelledby="pinCode-tab">
            <div class="col-md-6 col-12">
              <div class="row align-items-center mt-3">
                <div class="col-sm-3 col-12">
                  <label class="form-label font-size-14">State<span style="color: red">*</span></label>
                </div>
             
  
                <div class="table-responsive mt-5">
  
                  <div class="hr_line mb-3"></div>
  
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='pincodeStateMap'"
            [class.show]="activeTab =='pincodeStateMap'" id="pincodeStateMap" role="tabpanel"
            aria-labelledby="pincodeStateMap-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tabWidth">
                <thead class="font-size-14">
                  <tr>
                    <th></th>
                    <th>State Codes</th>
                    <th></th>
                    <th>State Name</th>
                    <th></th>
                    <th>Pincode</th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let statePin of pinCodeMap">
                    <td></td>
                    <td>{{statePin.code}}</td>
                    <td></td>
                    <td>{{statePin.name}}</td>
                    <td></td>
                    <td>{{statePin.pincode}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
          <div class="tab-pane fade " [class.active]="activeTab =='errorList'" [class.show]="activeTab =='errorList'"
            id="errorList" role="tabpanel" aria-labelledby="errorList-tab">
            <div class="table-responsive mt-5">
              <table class="data_table tableSize">
                <thead class="font-size-14">
                  <tr>
                    <th></th>
                    <th>Error Type</th>
                    <th></th>
                    <th>Error Code</th>
                    <th></th>
                    <th>Error Message</th>
                    <th></th>
                    <th>Reason for Error</th>
                    <th></th>
                    <th>Resolution for Error</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody class="font-size-14">
                  <tr *ngFor="let error of errorList">
                    <td></td>
                    <td>{{error.ErrorType}}</td>
                    <td></td>
                    <td>{{error.ErrorCode}}</td>
                    <td></td>
                    <td>{{error.ErrorMessage}}</td>
                    <td></td>
                    <td class="tbody">{{error.Reason}}</td>
                    <td></td>
                    <td class="tbody">{{error.Resolution}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="hr_line mb-3"></div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>Last Updated date was 31-01-2023</p>
  </div>
  <div style="padding: 70px 30px 10px;;background-color: #fafafa;">
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h6>Our products</h6>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoicing for
                        Taxpayers
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> E-Invoice APIs
                        for
                        Solution
                        Providers
                    </li>
                </ul>
                <h6>Find us here</h6>
                <i class="bi bi-twitter"></i>
                <i class="mx-2 bi bi-linkedin"></i>
            </div>
            <div class="col-md-4">
                <h6>Resources</h6>
                <ul style="list-style: none; padding-left: 10px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;   vertical-align: middle;
                        margin-right: 5px;"></i>Notifications
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <i class="bi bi-dash" style="font-size: 18px !important;  vertical-align: middle;
                        margin-right: 5px;"></i> Blog
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <h6>Contact us</h6>
                <ul style="list-style: none; padding-left: 0px;">
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        T231, Tower 2, Third floor, International Infotech Park Vashi Navi Mumbai 400703
                        Maharashtra, India
                    </li>
                    <li style="color:#252525; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
                        <a href="tel:+9122 6723 1000">+9122 6723 1000</a><br>
                        <a href="javascript:;" href="mailto:hello@irisirp.com">hello@irisirp.com</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-4">
                <h6>© 2023 IRIS Business Services Ltd. All Rights Reserved. | <a (click)="redirectToPrivacy()"  href="javascript:;">Privacy
                        Policy</a> | <a (click)="redirectToTerms()" href="javascript:;">Terms &
                        Conditions</a></h6>
            </div>
        </div>
    </div>
</div>