import { Component, OnInit } from '@angular/core';
import { ApiIntegratorDashboardService } from '../../services/api-integrator-dashboard.service';
@Component({
  selector: 'app-news-videotutorial',
  templateUrl: './news-videotutorial.component.html',
  styleUrls: ['./news-videotutorial.component.scss']
})
export class NewsVideotutorialComponent implements OnInit {
  newsList: any = [];
  newsData = [];
  news = [];
  constructor(private apiDashboardService: ApiIntegratorDashboardService) { }

  ngOnInit(): void {
    this.getNewsData()
  }

  getNewsData() {
    const newsResponse = this.apiDashboardService.getNews().subscribe(res => {
      this.newsList = res;
      
      // tbl.appendChild(this.newsList.response.news[0].moreInfoModalBody);
      this.newsData = this.newsList.response.news;
      for (const news in this.newsData) {
        this.news.push(this.newsData[news])
      }
    })

  }
}
